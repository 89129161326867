import * as React from 'react';
import _ from 'lodash';

type SpinnerProps = {
    color: string;
}

export default function Spinner({ color }: SpinnerProps) {


    return (
        <div className="spinner">
            {
                _.range(3).map((_, i) => {
                    return (
                        <div key={`spinner-dot-${i}`} className="dots" style={{ backgroundColor: color }} />
                    )
                })
            }
        </div>
    )

}
