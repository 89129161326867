import { API } from 'aws-amplify';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useParams, NavLink, Link, useHistory } from "react-router-dom";
import Highlight from 'react-highlight.js';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Bugsnag from "@bugsnag/js";
import Spinner from '../components/Spinner'
import EmptyState from './audio/PodcastsEmptyState'
import rssIcon from '../assets/rss.svg';
import copyIcon from '../assets/copy-alt.svg';
import moreIcon from '../assets/more.svg';
import codeIcon from '../assets/code.svg';
import externalLinkIcon from '../assets/external_link.svg';
import spotify from '../assets/spotify.svg';
import applePodcast from '../assets/apple-podcast.svg';
import googlePodcast from '../assets/google-podcast.svg';


function Podcast() {
    const { org_id, id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showEmbedCodeModal, setShowEmbedCodeModal] = useState(false);
    const [isDeletingShow, setIsDeletingShow] = useState(false);
    const [podcasts, setPodcasts] = useState([]);
    const [integration, setIntegration] = useState(null);
    const [selectedShow, setSelectedShow] = useState(null);
    const history = useHistory();

    const embedCode = `<iframe 
    id="l2it-widget-iframe" 
    src="${process.env.REACT_APP_WIDGET_BASE_URL}/inline.html?org_id=${org_id}&amp;integration_id=${id}&amp;url=https%3A%2F%2Fwww.getlisten2it.com%2Fblog%2Fthe-7-best-content-ideas-generator-tools-for-your-blog%2F&amp;has_paywall=false" 
    style="border:none;width:100%;height:80px;"
>

</iframe>
`;

    const getPodcasts = () => {
        return API.get('main', `/organisation/${org_id}/integration/${id}/podcast`);
    };

    const getIntegration = () => {
        return API.get('main', `/organisation/${org_id}/integration/${id}`);
    }

    const deleteShow = async (podcastId) => {

        setIsDeletingShow(true)
        await API.del('main', `/organisation/${org_id}/integration/${id}/podcast/${podcastId}`);
        toast.success('Podcast show has been deleted!');
        history.go(0)

    }

    const publishChannel = (podcast_id, index) => {

        const podcastsCopy = [...podcasts];
        const currentPodcast = podcastsCopy[index];

        API.put('main', `/organisation/${org_id}/integration/${id}/podcast/${podcast_id}/publish/publish`, { headers: { "Content-Type": "application/json" } })
            .then(() => {

                toast.success('Show has been published successfully.');
                currentPodcast.is_published = true;
                podcastsCopy[index] = currentPodcast;
                setPodcasts(podcastsCopy);

            })
            .catch((error) => {

                toast.error("Something went wrong while changing settings.");
                Bugsnag.notify(error);

            })
    };

    const unpublishChannel = (podcast_id, index) => {

        const podcastsCopy = [...podcasts];
        const currentPodcast = podcastsCopy[index];

        API.put('main', `/organisation/${org_id}/integration/${id}/podcast/${podcast_id}/publish/unpublish`, { headers: { "Content-Type": "application/json" } })
            .then(() => {

                toast.success('Show has been unpublished successfully.');
                currentPodcast.is_published = false;
                podcastsCopy[index] = currentPodcast;
                setPodcasts(podcastsCopy);

            })
            .catch((error) => {

                toast.error("Something went wrong while changing settings.");
                Bugsnag.notify(error);

            })
    };

    useEffect(() => {

        getIntegration().then((response) => {

            setIntegration(response.data);

        })

    }, []);

    useEffect(() => {

        if (integration === null) {
            return;
        }

        setIsLoading(true);
        getPodcasts().then((response) => {

            setPodcasts(response.data)

        }).finally(() => {

            setIsLoading(false);

        })

    }, [integration]);

    const Shimmer = () => {
        return (
            <div className="list-group list-group-flush list-group--content">
                {
                    _.range(4).map((index) => {

                        return (
                            <li className="list-group-item list-group-item-action" key={`content-${index}`}>
                                <div className="content-title-shimmer shine" />
                                <div className="content-meta-shimmer shine" />
                            </li>
                        )

                    })
                }
            </div>
        )
    };

    const PublishedBadge = ({ isPublished }) => {
        if (isPublished) {
            return (
                <span className="badge badge-success">Published</span>
            )
        }
        return (
            <span className="badge badge-secondary">Unpublished</span>
        );
    }

    const CustomToggle = React.forwardRef(({ onClick }, ref) => (
        <img src={moreIcon} className="icon mr-0 more-icon" alt="more icon" ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }} />
    ));

    const PodcastList = () => {
        return (
            <div className="list-group list-group-flush list-group--podcast">
                {
                    podcasts.map((item, index) => {
                        return (
                            <li onClick={(e) => {
                                setSelectedShow(item)
                            }} className="list-group-item list-group-item-action d-flex justify-content-start" key={`podcast-${index}`}>
                                <img alt="poster" style={{ cursor: 'pointer' }} onClick={() => {
                                    history.push(`/organisation/${org_id}/integration/${id}/podcast/${item.id}/episodes`, {
                                        showDetails: item,
                                    })
                                }} className="poster" src={item.poster_path} />
                                <div>
                                    <h4 style={{ cursor: 'pointer' }} onClick={() => {
                                        history.push(`/organisation/${org_id}/integration/${id}/podcast/${item.id}/episodes`, {
                                            showDetails: item,
                                        })
                                    }}>
                                        {item.title}
                                    </h4>
                                    <p className="d-block">{item.body}</p>
                                    <div className="actions d-flex align-items-center">
                                        <PublishedBadge isPublished={item.is_published} />
                                        <span className="dot-separator" />
                                        <span>{item.is_automatic ? integration.content_count : item.episode_count} Episodes</span>
                                        <span className="dot-separator" />
                                        <a href={`${process.env.REACT_APP_PODCAST_LANDING_PAGE_BASE_URL}/${org_id}/${id}/${item.id}`} target="_blank" rel="noopener noreferrer">
                                            <img src={externalLinkIcon} className="icon link-icon" alt="link-icon" />
                                        </a>
                                        <img src={codeIcon} className="icon code-icon" alt="code icon" onClick={() => { setShow(true) }} />
                                        <img src={rssIcon} onClick={() => {
                                            setSelectedShow(item);
                                            setShowEmbedCodeModal(true);
                                        }} className="icon rss-icon" alt="rss icon" />
                                        <Dropdown as="span">
                                            <Dropdown.Toggle as={CustomToggle} />
                                            <Dropdown.Menu>
                                                <Dropdown.Item as="div" onClick={() => history.push(`/organisation/${org_id}/integration/${id}/podcast/${item.id}`)}>Edit Settings</Dropdown.Item>
                                                {
                                                    (item.is_published)
                                                        ?
                                                        (
                                                            <Dropdown.Item as="div" onClick={() => unpublishChannel(item.id, index)}>Unpublish Show</Dropdown.Item>
                                                        )
                                                        :
                                                        (
                                                            <Dropdown.Item as="div" onClick={() => publishChannel(item.id, index)}>Publish Show</Dropdown.Item>
                                                        )
                                                }
                                                <Dropdown.Item as="div" onClick={() => {
                                                    setSelectedShow(item)
                                                    setShowConfirmation(true);
                                                }}>Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </li>
                        );
                    })
                }
            </div>
        );
    };

    return (
        <>
            <Modal className="modal-playlist-code" show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Embed Code
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="mb-3" >To embed on your website, copy and paste this snippet in the HTML.</p>
                    <div className="embed-code-container">
                        <div className="d-flex flex-row justify-content-end align-items-center mr-5" style={{ gap: 32 }}>
                            <a href="">Live Preview</a>
                            <CopyToClipboard text={embedCode} onCopy={() => toast.success("Copied to clipboard")}>
                                <div className="row justify-content-center" style={{
                                    gap: 4,
                                    borderRadius: 6,
                                    padding: '2px 16px',
                                    background: '#E6EFFE',
                                    cursor: 'pointer',
                                }}>
                                    <img src={copyIcon} style={{ cursor: 'pointer' }} alt="search icon" />
                                    <span>Copy</span>
                                </div>
                            </CopyToClipboard>
                        </div>
                        <Highlight as="div" className="pt-3 pt-sm-0" language="html">
                            {embedCode}
                        </Highlight>
                    </div>
                    {
                        selectedShow && (
                            <>
                                <p className="mt-5 mb-3">You can also link directly to the podcast using the below link</p>
                                <div className="form-group">
                                    <div className="input-group input-group--search">
                                        <input type="text" className="form-control border-right-0" value={`${process.env.REACT_APP_PODCAST_LANDING_PAGE_BASE_URL}/${org_id}/${id}/${selectedShow.id}`} readOnly />
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <CopyToClipboard text={`${process.env.REACT_APP_PODCAST_LANDING_PAGE_BASE_URL}/${org_id}/${id}/${selectedShow.id}`} onCopy={() => toast.success("Copied to clipboard")}>
                                                    <img src={copyIcon} style={{ cursor: 'pointer' }} alt="search icon" />
                                                </CopyToClipboard>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <p className="m-3">Need help? Reach out to us via <a href="">chat</a> or at <a href="mailto:support@getlisten2it.com">support@getlisten2it.com</a></p>
                </Modal.Footer>
            </Modal>

            <Modal show={showConfirmation} onHide={() => { setShowConfirmation(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Podcast show</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure want to delete this show?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline-primary mr-3" onClick={() => setShowConfirmation(false)}>Cancel</button>
                    {
                        (isDeletingShow) ?
                            (
                                <button type="button" className="btn btn-danger text-center">
                                    <Spinner color="#FFF" />
                                </button>
                            )
                            :
                            (
                                <button type="button" className="btn btn-danger" onClick={() => deleteShow(selectedShow.id)}>Delete</button>
                            )
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={showEmbedCodeModal} onHide={() => { setShowEmbedCodeModal(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Distribute your podcast using RSS
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        selectedShow && (
                            <div className="mt-3">
                                <p className="mb-3">Use this RSS feed url to list your podcast to directories</p>
                                <div className="form-group">
                                    <div className="input-group input-group--search">
                                        <input type="text" className="form-control border-right-0" value={`${process.env.REACT_APP_PODCAST_RSS_BASE_URL}/${org_id}/${id}/${selectedShow.id}`} readOnly />
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <CopyToClipboard text={`${process.env.REACT_APP_PODCAST_RSS_BASE_URL}/${org_id}/${id}/${selectedShow.id}`} onCopy={() => toast.success("Copied to clipboard")}>
                                                    <img src={copyIcon} style={{ cursor: 'pointer' }} alt="search icon" />
                                                </CopyToClipboard>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    <div className="mt-4 mb-5">
                        <p className="mb-3">You can easily submit your podcast to major podcast platforms with this link. Refer to our how-to guides below.</p>
                        <div className="d-flex flex-row flex-wrap justify-content-start align-items-center" style={{ gap: 36 }}>
                            {[
                                {
                                    platformName: 'Spotify',
                                    icon: spotify,
                                },
                                {
                                    platformName: 'Apple Podcast',
                                    icon: applePodcast,
                                },
                                {
                                    platformName: 'Google Podcast',
                                    icon: googlePodcast,
                                },
                            ].map((item) => (
                                <div className="card d-flex flex-column align-items-center" style={{
                                    width: 112,
                                    height: 112,
                                    justifyContent: 'space-evenly',
                                    textAlign: 'center'
                                }}>
                                    <img src={item.icon} />
                                    {item.platformName}
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <div className="mb-2 d-flex flex-row justify-content-between align-items-center">
                <h3 className="page-title">Podcast Shows</h3>
                <Link to={`/organisation/${org_id}/integration/${id}/podcast/new`}>
                    <Button variant="primary">New Podcast</Button>
                </Link>

            </div>
            {
                isLoading
                    ?
                    (
                        <Shimmer />
                    )
                    :
                    (
                        (podcasts.length > 0)
                            ?
                            (
                                <PodcastList />
                            )
                            :
                            (
                                <EmptyState />
                            )
                    )
            }
        </>
    );
}

export default Podcast;
