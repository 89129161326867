import React, {useState} from 'react'
import Track from "./Track"
// Images
import musicIcon from "../../assets/music.svg"
import TTSIcon from "../../assets/composer_mic.svg"
import AudioUploadModal from './AudioUploadModal'

function Section({section, sectionIndex, orgId, integrationId}) {

  const sectionIcon = (type) => {
    const mapping = {
      "AUDIO" : {icon : musicIcon, title : "Audio Track"},
      "TTS" : {icon : TTSIcon, title : "TTS Track"}
    }
    return mapping[type]
  }
  return (
    <>
    <div className="audio-composer-section">
        <div className="audio-composer-section--icon-container">
          <img width="18" title={sectionIcon(section.type).title} src={sectionIcon(section.type).icon} />
        </div>
        <div className="audio-composer-section--tracks-container">
            {section.tracks.map((track, trackIndex) => {
                return <Track key={`track-${trackIndex}`} sectionIndex={sectionIndex} trackIndex={trackIndex} track={track} orgId={orgId} integrationId={integrationId} /> 
            })}
        </div>
    </div>
    {
      (sectionIndex == 1) ? <AudioUploadModal sectionIndex={sectionIndex} trackIndex={0} orgId={orgId} integrationId={integrationId} /> : null
    }
    </>
  )
}

export default Section