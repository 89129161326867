import { API } from 'aws-amplify';
import { useQuery } from "@tanstack/react-query";

export const fetchSavedProfiles = (org_id: string, id: string) => {
    return API.get('main', `/organisation/${org_id}/integration/${id}/audio_profile`, {headers: {'Content-Type': "application/json"}});
}

interface IuseSavedProfilesProps {
    org_id: string
    id: string 
}

export const useSavedProfiles = ({org_id, id}: IuseSavedProfilesProps) => {
    return useQuery(['audio_profile', org_id, id], () => fetchSavedProfiles(org_id, id))
}
