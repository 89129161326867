import React, {useState} from 'react';
import Trend from './Trend';
import Contribution from './Contribution';
import Aggregate from './Aggregate';
import { DateRangePicker } from 'react-date-range';
import { format, subDays } from 'date-fns';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import calendarIcon from '../../assets/calendar.svg';
import {Link, useParams} from "react-router-dom";
import Spinner from "../../components/Spinner";
import { useOrganisation } from '../../http-hooks/organisation/useOrganisation';
import { useAnalyticsOverview } from '../../http-hooks/analytics/useAnalyticsOverview';

export default function Overview(){

    const { org_id, id: integration_id } = useParams();

    const { data, isLoading } = useOrganisation({ org_id });
    const [ timePeriod, setTimePeriod ] = useState([
        {
          startDate: subDays(new Date(), 7),
          endDate: new Date(),
          key: 'selection'
        }
    ]);
    const [showDateFilterModal, setShowDateFilterModal] = useState(false);
    const analytics = useAnalyticsOverview({ 
        org_id, 
        integration_id, 
        start_date: moment(timePeriod[0].startDate).format('YYYY-MM-DD HH:mm:ss'), 
        end_date: moment(timePeriod[0].endDate).add(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss') 
    })
    
    if (isLoading) {
        return (
            <div className="mt-3 text-center">
                <Spinner color="#657B90" />
            </div>
        )
    }

    const org = data.data;

    if (!org.gates || !org.gates.can_access_advanced_analytics) {
        return (
            <div className="text-center mt-5 bg-white rounded p-5 shadow-sm">
                <h5 className="font-weight-bold">Upgrade to view advanced analytics</h5>
                <p>Upgrade your plan to view the performance of your audio and start optimising your content.</p>
                <Link to={`/organisation/${org_id}/upgrade`} className="btn btn-primary">View Plans</Link>
            </div>
        )
    }

    return(
        <React.Fragment>
            <h3 className="page-title">Analytics Overview</h3>
            <div style={{cursor:'pointer'}} onClick={()=>{setShowDateFilterModal(true)}}>
                <img className="mr-2 align-middle" src={calendarIcon} alt="calendar icon" />
                <span className="align-middle" style={{fontSize: '12px', borderBottom: '1px dashed #C2CCD6'}}>From {moment(timePeriod[0].startDate).format('MMM Do YY')} to {moment(timePeriod[0].endDate).format('MMM Do YY')}</span>
            </div>
            <div className="aggregate-wrapper">
                <Aggregate label="TOTAL LISTENS" metric="total_plays" query={analytics[0]} />
                <Aggregate label="TOTAL IMPRESSIONS" metric="total_impressions" query={analytics[0]} />
                <Aggregate label="UNIQUE LISTENS" metric="total_unique_listens" query={analytics[0]} />
                <Aggregate label="AVG LISTEN LENGTH" metric="avg_listen_duration" query={analytics[0]} />
                <Aggregate label="CTR" metric="ctr" query={analytics[0]} />

                <Aggregate label="NEW LISTENERS" metric="total_new_listeners" query={analytics[1]} />
                <Aggregate label="REPEAT LISTENERS" metric="total_repeated_listeners" query={analytics[1]} />
            </div>
            <div className="trend-wrapper">
                <Trend metric={analytics[2]} />
            </div>
            <div className="pie-chart-wrapper">
                <Contribution label="BROWSERS" metric={analytics[3]} />
                <Contribution label="COUNTRY" metric={analytics[4]} />
                <Contribution label="OPERATING SYSTEMS" metric={analytics[5]} />
                <Contribution label="DEVICES" metric={analytics[6]} />
            </div>
            <Modal show={showDateFilterModal} onHide={()=>{setShowDateFilterModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Pick a date range</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DateRangePicker
                        onChange={item => { 
                            setTimePeriod([item.selection]);

                        }}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        ranges={timePeriod}
                        maxDate={ new Date() }
                        direction="vertical"
                        />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );


}
