import { call, put, takeEvery, delay, select } from "redux-saga/effects";
import { saveAs } from 'file-saver';
import {
    FETCH_CONTENTS_REQUESTED,
    FETCH_CONTENTS_SEARCH_REQUESTED,
    GENERATE_SRT_REQUESTED,
    PING_SRT_REQUESTED
} from "../actionTypes"

import {
    fetchContentsService,
    fetchContentsSearchService,
    generateSRTService,
    pingSRTService
} from "../../services/content"

import {
    fetchContentsSuccess,
    fetchContentsFailure,
    fetchContentsSearchSuccess,
    generateSRTSuccess,
    generateSRTFailure,
    pingSRTRequested,
    pingSRTSuccess,
    pingSRTFailure,
    toggleDownloadContentModal
} from "../actions";

function* fetchContentsSaga(action) {
    try {
        const contents = yield call(fetchContentsService, action.payload);
        yield put(fetchContentsSuccess(contents))
    } catch (e) {
        yield put(fetchContentsFailure(e));
    }
}

function* fetchContentsSearchSaga(action) {
    try {
        const contents = yield call(fetchContentsSearchService, action.payload);
        yield put(fetchContentsSearchSuccess(contents))
    } catch (e) {
        yield put(fetchContentsFailure(e));
    }
}

function* generateSRTSaga(action) {
    try {
        
        const res = yield call(generateSRTService, action.payload);
        yield put(generateSRTSuccess({ srtUniqueId : res['srtUniqueId'] }))
        yield put(pingSRTRequested(action.payload))
        
    } catch (e) {
        yield put(generateSRTFailure(e));
    }
}

function* pingSRTSaga(action) {
    try {
        // Ping SRT
        let isSRTSuccess = false
        const {srtUniqueId} = yield select(state => state.content);
        for(let i = 0; i < 15; i++){
            const response = yield call(pingSRTService, action.payload);

            if(response['srt_url'] && (srtUniqueId === response['srt_unique_id'])){
                isSRTSuccess = true
                yield put(pingSRTSuccess({ url : response['srt_url'] }));
                saveAs(`${process.env.REACT_APP_TEMP_AUDIO_BASE_URL}/${response['srt_url']}`, `Untitled SRT.srt`)
                break;
            }
            
            yield delay(4000)
        }

        yield put(toggleDownloadContentModal(false))

        if(!isSRTSuccess){
            yield put(pingSRTFailure("Timeout. SRT Cannot be generated"));
        }
        
    } catch (e) {
        yield put(pingSRTFailure(e));
    }
}

function* contentsSaga() {
    yield takeEvery(FETCH_CONTENTS_REQUESTED, fetchContentsSaga);
    yield takeEvery(FETCH_CONTENTS_SEARCH_REQUESTED, fetchContentsSearchSaga);
    yield takeEvery(GENERATE_SRT_REQUESTED, generateSRTSaga);
    yield takeEvery(PING_SRT_REQUESTED, pingSRTSaga);
}

export default contentsSaga;
