import React from 'react';
import { CreateCustomProfileModal, SetDefaultVoiceModal } from '../components/ai-voices/ConfigureVoiceModal';
import LeftPane from '../components/ai-voices/LeftPane';
import { Voices } from '../components/ai-voices/Voices';
import Spinner from '../components/Spinner';
import { useLanguages } from '../http-hooks/ai-voices/useLanguages';
import { useAIVoicesStore } from '../zustand-store/useAIVoicesStore';

export default function AIVoices() {

    const setLanguages = useAIVoicesStore(state => state.setLanguages)
    const { isLoading } = useLanguages((data) => setLanguages(JSON.parse(data.data)));

    return (
        <div>
            <h3 className="page-title">All AI Voices</h3>
            <div className='row'>
                <div className='col-md-3 d-flex flex-column'>
                    <LeftPane />
                </div>
                <div className='col-md-9'>
                    {
                        isLoading
                        ? (
                            <Spinner color="#115CD9" />
                        )
                        : (
                            <Voices isPicker={false} />
                        )
                    }
                </div>
            </div>
            <SetDefaultVoiceModal />
            <CreateCustomProfileModal />
        </div>
    )
}
