import encodeQueryData from '../utils/Query';
import { API } from 'aws-amplify';

export const fetchContentsService = ({org_id, id, filter}) => {
    const {nextPageToken, startDate, endDate, contentType} = filter

    let url = `/organisation/${org_id}/integration/${id}/content`;

    let params = { };

    if(nextPageToken){

        params["next_page_token"] = nextPageToken;

    }

    if(startDate){

        params["start_date"] = startDate;

    }

    if(endDate){

        params["end_date"] = endDate;

    }

    if(contentType){

        params['contentType'] = contentType

    }

    if(Object.keys(params).length > 0){

        url = url + "?" + encodeQueryData(params);

    }
    
    return API.get(`main`, url).then(res => {
        let data = {
            contents : res.data,
            filter
        }
        if(res.hasOwnProperty("next_page_token")){
            data["nextPageToken"] = res.next_page_token
        }
        return data
    }).catch(err => {
        return err
    })
}

export const fetchContentsSearchService = ({org_id, id, keyword}) => {
    return API.get(`main`, `/organisation/${org_id}/integration/${id}/search?keyword=${keyword}`,{headers: {"Content-Type":"application/json"}}).then((res)=>{
        let data = {
            contents : res.data,
        }
        return data
    }).catch(err => {
        return err
    })
}

export const generateSRTService = ({org_id, id, contentId, body}) => {
    return API.put('main', `/organisation/${org_id}/integration/${id}/content/${contentId}/generate-srt`, {
        body, headers: { "Content-Type": "application/json" }
    }).then(res => {
        return res
    }).catch(err => {
        return err
    })
}

export const pingSRTService = ({org_id, id, contentId}) => {
    return API.get('main', `/organisation/${org_id}/integration/${id}/content/${contentId}/get-srt`, {
        headers: { "Content-Type": "application/json" }
    }).then(res => {
        return res.data
        
    }).catch(err => {
        console.log(err)
        return err
        
    })
}
