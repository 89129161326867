import React from "react";
import { Dropdown } from "react-bootstrap";

import dropdownIcon from '../../../assets/down_chevron_primary.svg';
import { MemoizeImage } from "../Modals/MemoizeImage";

export const PitchPicker = ({ value, onChange, disabled }) => {

    const CustomToggleContent = React.forwardRef(({ onClick }, ref) => (
        <button style={{ fontWeight: 600, opacity: disabled ? 0.65 : 1 }} disabled={disabled} type="button" className="btn--pitch" ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }} >
            {value}
            <MemoizeImage className="ml-2 action-icon" width={9} height={6} src={dropdownIcon} alt="dropdown" />
        </button>
    ));

    return (
        <Dropdown as="span">
            <Dropdown.Toggle as={CustomToggleContent} />
            <Dropdown.Menu>
                <Dropdown.Item as="span" onClick={() => onChange('default')}>
                    Default
                </Dropdown.Item>
                <Dropdown.Item as="span" onClick={() => onChange('x-low')}>
                    x-low
                </Dropdown.Item>
                <Dropdown.Item as="span" onClick={() => onChange('low')}>
                    low
                </Dropdown.Item>
                <Dropdown.Item as="span" onClick={() => onChange('medium')}>
                    medium
                </Dropdown.Item>
                <Dropdown.Item as="span" onClick={() => onChange('high')}>
                    high
                </Dropdown.Item>
                <Dropdown.Item as="span" onClick={() => onChange('x-high')}>
                    x-high
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}