import { API } from "aws-amplify";
import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import Highlight from 'react-highlight.js';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import _ from 'lodash';
import { useParams, Link, useHistory } from "react-router-dom";
import { Dropdown, Modal } from 'react-bootstrap';
import Bugsnag from '@bugsnag/browser';
import { toast } from 'react-toastify';
import classNames from 'classnames';

import PodcastEpisodeSettings from '../components/PodcastEpisodeSettings';
import Spinner from '../components/Spinner';
import AddAudioToPodcastModal from '../components/AddAudioToPodcastModal';
import AudioPlayer from '../components/AudioPlayer';

import formatAudioFileDuration from '../utils/AudioDuration';
import encodeQueryData from '../utils/Query';

import moreIcon from '../assets/more.svg';
import copyIcon from '../assets/copy-alt.svg';
import codeIcon from '../assets/code.svg';
import playIcon from '../assets/play.svg';
import settingsIcon from '../assets/settings.svg';
import leftArrowIcon from '../assets/arrow-left.svg';
import linkIcon from '../assets/link.svg';

function PodcastAudios() {

    const { org_id, id, podcast_id } = useParams();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [isFetchingMore, setIsFetchingMore] = useState(false);
    const [showEpisodeSettings, setShowEpisodeSettings] = useState(false);
    const [episodes, setEpisodes] = useState([]);
    const [selectedEpisode, setSelectedEpisode] = useState(null);
    const [selectedEpisodeIndex, setSelectedEpisodeIndex] = useState(-1);
    const [nextPageToken, setNextPageToken] = useState(null);
    const [showEmbedCode, setShowEmbedCode] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isDeletingEpisode, setIsDeletingEpisode] = useState(false);
    const [showDetails, setShowDetails] = useState(null);
    const [showAudiosModal, setShowAudiosModal] = useState(false);
    const [audio, setAudio] = useState(null);
    const [audioTitle, setAudioTitle] = useState("Press play to listen to this.")

    const [embedCode, setEmbedCode] = useState('');

    const getNextPage = () => {

        setIsFetchingMore(true)
        getPodcastEpisodes(nextPageToken)
            .then((response) => {

                setEpisodes([...episodes, ...response.data]);
                setNextPageToken(response.next_page_token)

            })
            .catch((error) => {

                Bugsnag.notify(error)

            })
            .finally(() => {
                setIsFetchingMore(false)
            })

    };

    const getPodcastEpisodes = (nextPageToken = null) => {

        let params = {};
        let url = `/organisation/${org_id}/integration/${id}/podcast/${podcast_id}/episodes`;

        if (nextPageToken) {

            params["next_page_token"] = nextPageToken.PK.split('#')[1] + ':' + nextPageToken.SK.split('#')[1];

        }

        if (Object.keys(params).length > 0) {

            url = url + "?" + encodeQueryData(params);

        }

        return API.get('main', url, {
            body: {
                nextPageToken
            }
        });

    }

    const getPodcast = () => {

        return API.get('main', `/organisation/${org_id}/integration/${id}/podcast/${podcast_id}`)

    }

    const publishEpisode = (index) => {

        const episodesCopy = [...episodes];
        const currentEpisode = episodesCopy[index];

        API.put('main', `/organisation/${org_id}/integration/${id}/podcast/${podcast_id}/episode/${currentEpisode.content_id}/publish/publish`, { headers: { "Content-Type": "application/json" } })
            .then(() => {

                toast.success('Episode has been published successfully.');
                currentEpisode.is_published = true;
                episodesCopy[index] = currentEpisode;
                setEpisodes(episodesCopy);

            })
            .catch((error) => {

                toast.error("Something went wrong while changing settings.");
                Bugsnag.notify(error);

            })

    }

    const unpublishEpisode = (index) => {

        const episodesCopy = [...episodes];
        const currentEpisode = episodesCopy[index];

        API.put('main', `/organisation/${org_id}/integration/${id}/podcast/${podcast_id}/episode/${currentEpisode.content_id}/publish/unpublish`, { headers: { "Content-Type": "application/json" } })
            .then(() => {

                toast.success('Episode has been unpublished successfully.');
                currentEpisode.is_published = false;
                episodesCopy[index] = currentEpisode;
                setEpisodes(episodesCopy);

            })
            .catch((error) => {

                toast.error("Something went wrong while changing settings.");
                Bugsnag.notify(error);

            })

    }

    const deleteEpisode = () => {

        setIsDeletingEpisode(true);

        API.del('main', `/organisation/${org_id}/integration/${id}/podcast/${podcast_id}/episode/${selectedEpisode.content_id}/`).then((response) => {

            setShowConfirmation(false);
            setIsDeletingEpisode(false);
            setIsLoading(true);

            getPodcastEpisodes().then(response => {
                setEpisodes(response.data)
                setNextPageToken(response.next_page_token)
            }).finally(() => {
                setIsLoading(false)
            });

        })

    }

    useEffect(() => {

        getPodcast().then(response => {

            setShowDetails(response.data);

        });

    }, []);

    useEffect(() => {

        if (showDetails) {
            return;
        }

        setIsLoading(true);
        getPodcastEpisodes().then(response => {
            setEpisodes(response.data)
            setNextPageToken(response.next_page_token)
        }).finally(() => {
            setIsLoading(false)
        });

    }, [showDetails]);

    const Shimmer = () => {
        return (
            <div className="list-group list-group-flush list-group--content">
                {
                    _.range(4).map((index) => {

                        return (
                            <li className="list-group-item list-group-item-action" key={`content-${index}`}>
                                <div className="content-title-shimmer shine" />
                                <div className="content-meta-shimmer shine" />
                            </li>
                        )

                    })
                }
            </div>
        )
    };

    const CustomToggle = React.forwardRef(({ onClick }, ref) => (
        <img src={moreIcon} className="icon mr-0 more-icon" alt="more icon" ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }} />
    ));

    const EmptyState = () => {
        return (
            <>
                <h5 style={{ marginTop: '32px' }}>This show does not have any episodes.</h5>
                <p>Start by adding episodes from your existing audios.</p>
                <button onClick={() => setShowAudiosModal(true)} className="btn btn-primary">Add Episodes</button>
            </>
        );
    }

    const PublishedBadge = ({ isPublished }) => {
        if (isPublished !== false) {
            return (
                <span className="badge badge-success mr-3">Published</span>
            )
        }
        return (
            <span className="badge badge-secondary mr-3">Unpublished</span>
        );
    }

    const EpisodesList = () => {
        return (
            <div className="list-group list-group-flush list-group--episode">
                {
                    episodes.map((item, index) => {
                        return (
                            <li onClick={() => {

                                setSelectedEpisode(item);
                                setSelectedEpisodeIndex(index);

                            }} className="list-group-item list-group-item-action d-flex justify-content-start" key={`episode-${index}`}>
                                <img alt={item.title} className="poster" src={item.episode_art} />
                                <div>
                                    <h4>
                                        {item.title}
                                    </h4>
                                    <p>{item.description}</p>
                                    <div className="actions d-flex align-items-center">
                                        <PublishedBadge isPublished={item.is_published} />
                                        {
                                            item.audio_duration && (
                                                <>
                                                    <span className="audio-duration">{formatAudioFileDuration(item.audio_duration)}</span>
                                                    <span className="dot-separator" />
                                                </>
                                            )
                                        }
                                        <img src={playIcon} className="icon play-icon" alt="play icon" onClick={() => {

                                            setAudio(`${process.env.REACT_APP_AUDIO_BUCKET}/${item.full_audio_url ?? item.short_audio_url}`);
                                            setAudioTitle(item.title);

                                        }} />

                                        <CopyToClipboard text={`${process.env.REACT_APP_WIDGET_BASE_URL}/inline.html?org_id=${org_id}&integration_id=${id}&${item.url ? `url=${encodeURIComponent(item.url)}` : `content_id=${item.SK.split('#')[1]}`}`} onCopy={() => toast.success("Copied to clipboard")} >
                                            <img src={linkIcon} className="icon link-icon" alt="link-icon" />
                                        </CopyToClipboard>

                                        <img src={codeIcon} className="icon code-icon" alt="code-icon" onClick={() => {

                                            setEmbedCode(`<iframe
    id="l2it-widget-iframe"
    src="${process.env.REACT_APP_WIDGET_BASE_URL}/inline.html?org_id=${org_id}&integration_id=${id}&${item.url ? `url=${encodeURIComponent(item.url)}` : `content_id=${item.SK.split('#')[1]}`}&has_paywall=false"
    style="border:none;width:100%;height:80px;"
>
</iframe>`);
                                            setShowEmbedCode(true);

                                        }} />
                                        <img src={settingsIcon} className="icon settings-icon" alt="settings-icon" onClick={() => {

                                            setShowEpisodeSettings(true);

                                        }} />
                                        <Dropdown as="span">
                                            <Dropdown.Toggle as={CustomToggle} />
                                            <Dropdown.Menu>
                                                <Dropdown.Item as="div" onClick={() => {
                                                    try {

                                                        saveAs(`${process.env.REACT_APP_AUDIO_BUCKET}/${item.full_audio_url}`, 'preview.mp3')

                                                    } catch (e) {

                                                        Bugsnag.notify(e)

                                                    }
                                                }}>
                                                    Download
                                                </Dropdown.Item>
                                                {
                                                    (item.is_published !== false)
                                                        ?
                                                        (
                                                            <Dropdown.Item as="div" onClick={() => { unpublishEpisode(index) }}>Unpublish</Dropdown.Item>
                                                        )
                                                        :
                                                        (
                                                            <Dropdown.Item as="div" onClick={() => { publishEpisode(index) }}>Publish</Dropdown.Item>
                                                        )
                                                }
                                                {
                                                    showDetails && !showDetails.is_automatic && (
                                                        <Dropdown.Item as="div" onClick={() => {

                                                            setShowConfirmation(true);

                                                        }}>Delete</Dropdown.Item>
                                                    )
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </li>
                        );
                    })
                }
                {
                    (isFetchingMore) ?
                        (
                            <button className={classNames("btn--fetch-more", "mt-3")}>
                                <Spinner color="#657B90" />
                            </button>
                        )
                        :
                        (
                            <button className={classNames("btn--fetch-more", "mt-3", { "d-none": null == nextPageToken })} onClick={() => { getNextPage() }}>Fetch More</button>
                        )
                }
            </div>
        );
    };

    return (
        <>
            <div className="d-flex" style={{ gap: 4, color: 'var(--blue)', cursor: 'pointer' }} onClick={() => history.push(`/organisation/${org_id}/integration/${id}/podcast`)}>
                <img src={leftArrowIcon} width={18} />
                Back to podcast shows
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <h3 className="page-title">{showDetails && showDetails.title + ' - Episodes'}</h3>
                {
                    episodes.length >= 0 && showDetails && !showDetails.is_automatic
                    && (
                        <Link to={`/organisation/${org_id}/integration/${id}/podcast/${podcast_id}/episodes-add`}>
                            <button className="btn--new-episode">Add New Episodes</button>
                        </Link>
                    )
                }
            </div>

            <AddAudioToPodcastModal
                show={showAudiosModal}
                orgId={org_id}
                integrationId={id}
                podcastId={podcast_id}
                onHide={() => setShowAudiosModal(false)}
                showDetails={showDetails}
                onSuccess={() => {
                    setIsLoading(true);
                    getPodcastEpisodes().then(response => {
                        setEpisodes(response.data)
                        setNextPageToken(response.next_page_token)
                    }).finally(() => {
                        setIsLoading(false)
                    })
                }}
            />

            <PodcastEpisodeSettings
                show={showEpisodeSettings}
                onHide={() => setShowEpisodeSettings(false)}
                orgId={org_id}
                integrationId={id}
                podcastId={podcast_id}
                selectedEpisode={selectedEpisode}
                onSuccess={(data) => {

                    const copy = [...episodes];

                    copy[selectedEpisodeIndex] = {
                        ...copy[selectedEpisodeIndex],
                        title: data.episode_title,
                        ...(data.episode_art && { episode_art: data.episode_art }),
                        description: data.episode_description,
                        category: data.episode_category,
                        author: data.episode_author,
                        is_published: data.is_published
                    }

                    setEpisodes(copy)

                }}
            />

            <Modal className="modal-embed-code" show={showEmbedCode} onHide={() => setShowEmbedCode(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Embed Code
                        <p style={{ fontSize: 16, fontWeight: 400, margin: 0 }}>Add the following line to your website’s HTML code as per below.</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CopyToClipboard text={embedCode} onCopy={() => toast.success("Copied to clipboard")}>
                        <div
                            className="position-absolute row justify-content-center"
                            style={{
                                right: 48,
                                top: 16,
                                gap: 4,
                                borderRadius: 6,
                                padding: '2px 16px',
                                background: '#E6EFFE',
                                cursor: 'pointer',
                            }}
                        >
                            <img src={copyIcon} style={{ cursor: 'pointer' }} alt="copy icon" />
                            <span>Copy</span>
                        </div>
                    </CopyToClipboard>
                    <Highlight as="div" className="pt-3 pt-sm-0" language="html">
                        {embedCode}
                    </Highlight>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary mr-3" onClick={() => setShowEmbedCode(false)}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={showConfirmation} onHide={() => { setShowConfirmation(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Episode</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure want to delete this episode?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline-primary mr-3" onClick={() => setShowConfirmation(false)}>Cancel</button>
                    {
                        (isDeletingEpisode) ?
                            (
                                <button type="button" className="btn btn-danger text-center">
                                    <Spinner color="#FFF" />
                                </button>
                            )
                            :
                            (
                                <button type="button" className="btn btn-danger" onClick={() => deleteEpisode()}>Delete</button>
                            )
                    }
                </Modal.Footer>
            </Modal>

            {
                isLoading
                    ?
                    (
                        <Shimmer />
                    )
                    :
                    (
                        episodes.length === 0
                            ?
                            (
                                <EmptyState />
                            )
                            :
                            (
                                <EpisodesList />
                            )
                    )
            }
            {
                (audio) &&
                <div className="audio-player-wrapper">
                    <AudioPlayer audio={audio} title={audioTitle} onClose={() => setAudio(null)} />
                </div>
            }
        </>
    );
}

export default PodcastAudios;
