import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import Spinner from '../../components/Spinner';
import { Auth, API } from 'aws-amplify';
import Bugsnag from "@bugsnag/js";
import { toast } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha";
import CustomURL from '../../utils/CustomURL';
import { fetchLocation } from '../../utils/Country'
import { EMAIL_VERIFIED, PLAN_SELECTED, SIGN_UP_COMPLETE, SIGN_UP_LOAD } from '../../constants/gtag-events';
import AuthSpritesImg from "../../assets/auth-sprites.svg"
import { ACCOUNT_CREATED } from '../../constants/hotjar-events';
import { useDispatch } from 'react-redux';
import { addMixPanelEvent } from '../../store/actions';
import { useHistory } from 'react-router';
const classNames = require('classnames');
const kindsOfWork = ["Content Creator", "Product Owner", "Educator", "Marketer", "Corporate Trainer", "Customer Support", "Podcaster", "Student", "Video Producer", "Writer / Author", "Digital Agency"]
const audioForList = ["Audio Ads", "E-Learning", "Audio Book", "Presentation", "Social Media", "Announcement", "Dubbing / Translation", "Video", "Podcasts", "Apps / Games", "Personal Listening", "Articles / Blogs"]

export default function SignUp(props) {

    let query = new URLSearchParams(new URL(window.location.href).search);

    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch()
    const history = useHistory()
    const signUpForm = useForm();
    const confirmSignUpForm = useForm();
    const [recaptchaToken, setRecaptchaToken] = useState(null)
    const [authState, setAuthState] = useState("signup");
    const [organisation, setOrganisation] = useState(null);
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [kindOfWork, setKindOfWork] = useState("")
    const [audioFor, setAudioFor] = useState("")
    const [orgId, setOrgId] = useState("")
    const [integrationId, setIntegrationId] = useState("")
    const recaptchaRef = useRef()

    useEffect(() => {
        window.dataLayer.push({
            event: SIGN_UP_LOAD
        });
    }, [])

    const onSubmitSignUpForm = async data => {

        setIsLoading(true);

        // Recaptcha token should be generated only once
        let token = recaptchaToken
        if (!recaptchaToken) {
            token = await recaptchaRef.current.executeAsync()
            setRecaptchaToken(token)
        }

        const { username, password, name, family_name } = data;

        const email_verificaiton = await API.post('secondary', `/verify_email`, {
            body: { emailId: username }
        })

        if (email_verificaiton.data.result !== "valid") {
            toast.error("Please enter a valid email address")
            setIsLoading(false);
            return;
        }

        let localOrganisation = `${name}'s organisation`
        setOrganisation(localOrganisation);
        setUsername(username);
        setPassword(password);

        Auth.signUp({
            username,
            password,
            attributes: {
                given_name: name,
                family_name,
                'custom:org': localOrganisation,
                'custom:phone': '',
                'custom:usecase': '',
                'custom:referrer_url': window.document.referrer
            },
            validationData: [{
                Name: 'recaptchaToken',
                Value: token,
            }]
        }).then((response) => {

            setAuthState("confirmSignUp");

            window.dataLayer.push({
                event: SIGN_UP_COMPLETE
            });

            window.hj("event", ACCOUNT_CREATED);

        }).catch((error) => {

            toast.error(error.message);

        }).finally(() => {

            setIsLoading(false);

        });

    };

    const onResendCode = async () => {

        Auth.resendSignUp(username)
            .then(data => {

                toast.success("Code has been resent successfully.");

            })
            .catch(error => {

                toast.error(error.message);

            }).finally(() => {

                setIsLoading(false);

            });

    }

    const updateKindOfWork = () => {
        if (!kindOfWork) return toast.error("Please select something from list")

        dispatch(addMixPanelEvent({
            orgId: orgId, body: {
                eventName: "UPDATE_ORGANISATION_USE_CASE",
                eventData: {
                    useCase: kindOfWork
                }
            }
        }))

        setAuthState("audioFor")
    }

    const updateAudioFor = async () => {

        if (!audioFor) return toast.error("Please select something from list")

        setIsLoading(true)

        if (audioFor === "Articles / Blogs") {
            await API.put('main', `/organisation/${orgId}/audio-article-customer`, {
                headers: { "Content-Type": "application/json" },
            })
        }

        dispatch(addMixPanelEvent({
            orgId, body: {
                eventName: "UPDATE_ORGANISATION_AUDIO_FOR",
                eventData: {
                    audioFor
                }
            }
        }))

        handleNextRoute()

    }

    const handleNextRoute = () => {

        if (!query.has('plan_id') || query.get('plan_id') === 'free-plan') {
            history.push(`/organisation/${orgId}/integration/${integrationId}/content?show_upgrade_modal=true`)
            props.onChangeAuthState("signedin")
            return;
        }

        let path = new CustomURL(`/organisation/${orgId}/upgrade`);

        if (query.has('quantity')) {

            path.appendSearchParam('quantity', query.get('quantity'));

        }

        if (query.has('plan_id')) {

            path.appendSearchParam('plan_id', query.get('plan_id'));

        }

        window.location.href = path.href();
    }

    const onSubmitConfirmSignUp = async data => {

        const { code } = data;

        setIsLoading(true);

        const country = await fetchLocation()

        const location = {
            country: country.country,
            region: country.region,
            city: country.city
        }

        Auth.confirmSignUp(username, code.trim()).then(() => {

            window.dataLayer.push({
                event: EMAIL_VERIFIED
            })

            Auth.signIn(username, password).then(userResponse => {

                props.onSetUser(userResponse);

                API.post(`main`, `/organisation`, {
                    body: { name: organisation, location },
                    headers: { "Content-Type": "application/json" },
                    // withCredentials: "true"
                }).then(response => {

                    window.hj('identify', response.data.orgId, {
                        'Page': 'Sign in',
                    });

                    setOrgId(response.data.orgId)
                    setIntegrationId(response.data.id)

                    window.dataLayer.push({
                        event: PLAN_SELECTED,
                    });

                    if (!response.success) {
                        Bugsnag.notify(response);
                        toast.error("Something went wrong!")
                        return;
                    }

                    setAuthState("kindOfWork");

                }).finally(() => {
                    setIsLoading(false)
                })
            })

        }).catch((error) => {
            setIsLoading(false);
            toast.error(error.message);

        })

    }

    return (
        <div className="row my-5 my-md-0">
            <div className="col-md-6 offset-md-3">
                {
                    (authState === "signup") &&
                    <form onSubmit={signUpForm.handleSubmit(onSubmitSignUpForm)} >
                        <div className="progress-bar"><span style={{ width: "0%" }} className="current-progress"></span></div>
                        <h4 className="text-center form-title">Sign up to get started</h4>
                        <p className="text-center mb-5"><small>No credit card required</small></p>
                        <div className="form-row">
                            <div className="form-group col">
                                <label>First name</label>
                                <input style={{ backgroundImage: `url(${AuthSpritesImg})`, backgroundPosition: "4px 0px" }} type="text" name="name" className={classNames("form-control", "auth-input", { "is-invalid": signUpForm.errors.name })} ref={signUpForm.register({ required: true, minLength: 3 })} />
                                {
                                    signUpForm.errors.name &&
                                    <div className="invalid-feedback">
                                        {
                                            ("required" === signUpForm.errors.name.type) && "First Name is required."
                                        }
                                        {
                                            ("minLength" === signUpForm.errors.name.type) && "First Name should be at least 3 characters long."
                                        }
                                    </div>
                                }
                            </div>
                            <div className="form-group col">
                                <label>Last name</label>
                                <input style={{ backgroundImage: `url(${AuthSpritesImg})`, backgroundPosition: "4px 0px" }} type="text" name="family_name" className={classNames("form-control", "auth-input", { "is-invalid": signUpForm.errors.family_name })} ref={signUpForm.register({ required: true, minLength: 2 })} />
                                {
                                    signUpForm.errors.family_name &&
                                    <div className="invalid-feedback">
                                        {
                                            ("required" === signUpForm.errors.family_name.type) && "Last Name is required."
                                        }
                                        {
                                            ("minLength" === signUpForm.errors.family_name.type) && "Last Name should be at least 2 characters long."
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input style={{ backgroundImage: `url(${AuthSpritesImg})`, backgroundPosition: "4px -35px" }} type="username" name="username" className={classNames("form-control", "auth-input", { "is-invalid": signUpForm.errors.username })} ref={signUpForm.register({ required: true, pattern: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,6}$/g })} />
                            {
                                signUpForm.errors.username &&
                                <div className="invalid-feedback">
                                    {
                                        ("required" === signUpForm.errors.username.type) && "Email address is required."
                                    }
                                    {
                                        ("pattern" === signUpForm.errors.username.type) && "Email address is invalid."
                                    }
                                </div>
                            }
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input style={{ backgroundImage: `url(${AuthSpritesImg})`, backgroundPosition: "4px -70px" }} placeholder="8 characters or more" type="password" name="password" className={classNames("form-control", "auth-input", { "is-invalid": signUpForm.errors.password })} ref={signUpForm.register({ required: true, minLength: 8 })} />
                            {
                                signUpForm.errors.password &&
                                <div className="invalid-feedback">
                                    {
                                        ("required" === signUpForm.errors.password.type) && "Password is required."
                                    }
                                    {
                                        ("minLength" === signUpForm.errors.password.type) && "Password should be at least 8 characters long."
                                    }
                                </div>
                            }
                        </div>
                        <div className="form-group">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey="6Lfva6ocAAAAABMVwdCkYHulnkP-dXzp3dRSMZJE"
                            />
                        </div>
                        {
                            (isLoading) ?
                                (
                                    <button type="button" className="btn btn-primary btn-block btn-rounded-30 btn-dark-blue-1">
                                        <Spinner color="#fff" />
                                    </button>
                                )
                                :
                                (
                                    <button type="submit" className="btn btn-primary btn-block btn-rounded-30 btn-dark-blue-1">Let's get started</button>
                                )
                        }

                        <p className="text-center mt-2 mb-4"><b>Already have an account? <span style={{ color: '#2D79F6', cursor: 'pointer' }} onClick={() => { props.onChangeAuthState("signin") }}>Sign In</span></b></p>
                        <p className="text-center mb-4">
                            <small>By continuing, you agree to the <span><a style={{ textDecoration: 'underline', color: '#48525B', cursor: 'pointer' }} href={process.env.REACT_APP_TERMS_URL} target="_blank" rel="noopener noreferrer">Terms of Service</a></span> and <span><a style={{ color: '#48525B', textDecoration: 'underline', cursor: 'pointer' }} href={process.env.REACT_APP_PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">Privacy Policy</a></span></small>
                        </p>
                    </form>
                }
                {
                    (authState === "confirmSignUp") &&
                    <form className="mt-5" onSubmit={confirmSignUpForm.handleSubmit(onSubmitConfirmSignUp)}>
                        <div className="progress-bar"><span style={{ width: "33%" }} className="current-progress"></span></div>
                        <h4 className="text-center form-title">Verify your email</h4>
                        <p className="text-center mb-5"><small>We have sent you an email with a code.</small></p>
                        <div className="form-group">
                            <label>Please enter the code below</label>
                            <input style={{ backgroundImage: `url(${AuthSpritesImg})`, backgroundPosition: "4px -70px" }} type="text" name="code" className={classNames("form-control", "auth-input", { "is-invalid": confirmSignUpForm.errors.code })} ref={confirmSignUpForm.register({ required: true })} />
                            {
                                confirmSignUpForm.errors.code &&
                                <div className="invalid-feedback">
                                    {
                                        ("required" === confirmSignUpForm.errors.code.type) && "Code is required."
                                    }
                                </div>
                            }
                        </div>
                        {
                            (isLoading) ?
                                (
                                    <button type="button" className="btn btn-primary btn-block btn-rounded-30 btn-dark-blue-1">
                                        <Spinner color="#fff" />
                                    </button>
                                )
                                :
                                (
                                    <button type="submit" className="btn btn-primary btn-block btn-rounded-30 btn-dark-blue-1">Continue</button>
                                )
                        }
                        <p className="mt-1 text-center"><small>Haven't received the code? Check your spam folder or <span style={{ color: '#2D79F6', cursor: 'pointer' }} onClick={() => { onResendCode() }}>click here to resend</span> the code.</small></p>
                    </form>
                }
                {
                    (authState === "kindOfWork") &&
                    <form className="mt-5">
                        <div className="progress-bar"><span style={{ width: "67%" }} className="current-progress"></span></div>
                        <h4 className="text-center form-title">What kind of work do you do?</h4>
                        <p className="text-center mb-5"><small>This will help us personalize your experience in Listen2It.</small></p>
                        <div className="d-flex mb-3" style={{ flexWrap: "wrap", gap: "15px 10px" }}>
                            {
                                kindsOfWork.map((tagName, tagNameIndex) => {
                                    return (
                                        <div onClick={() => setKindOfWork(tagName)} className={`tag ${tagName == kindOfWork ? 'selected' : ''}`} key={`kind-of-work-${tagNameIndex}`}>{tagName}</div>
                                    )
                                })
                            }
                        </div>
                        <div className="form-group">
                            <label>Others</label>
                            <input onChange={(e) => setKindOfWork(e.target.value)} value={!kindsOfWork.includes(kindOfWork) ? kindOfWork : ""} placeholder="Your answer" type="text" name="code" className={classNames("form-control")} />
                        </div>
                        {
                            (isLoading) ?
                                (
                                    <button type="button" className="btn btn-primary btn-block btn-rounded-30 btn-dark-blue-1">
                                        <Spinner color="#fff" />
                                    </button>
                                )
                                :
                                (
                                    <button onClick={() => updateKindOfWork()} type="button" className="btn btn-primary btn-block btn-rounded-30 btn-dark-blue-1">Continue</button>
                                )
                        }
                    </form>
                }
                {
                    (authState === "audioFor") &&
                    <form className="mt-5">
                        <div className="progress-bar"><span style={{ width: "100%" }} className="current-progress"></span></div>
                        <h4 className="text-center form-title">What are you creating audio for?</h4>
                        <p className="text-center mb-5"><small>This will help us personalize your experience in Listen2It.</small></p>
                        <div className="d-flex mb-3" style={{ flexWrap: "wrap", gap: "15px 10px" }}>
                            {
                                audioForList.map((tagName, tagNameIndex) => {
                                    return (
                                        <div onClick={() => setAudioFor(tagName)} className={`tag ${tagName == audioFor ? 'selected' : ''}`} key={`audio-for-${tagNameIndex}`}>{tagName}</div>
                                    )
                                })
                            }
                        </div>
                        <div className="form-group">
                            <label>Others</label>
                            <input onChange={(e) => setAudioFor(e.target.value)} value={!audioForList.includes(audioFor) ? audioFor : ""} placeholder="Your answer" type="text" name="code" className={classNames("form-control")} />
                        </div>
                        {
                            (isLoading) ?
                                (
                                    <button type="button" className="btn btn-primary btn-block btn-rounded-30 btn-dark-blue-1">
                                        <Spinner color="#fff" />
                                    </button>
                                )
                                :
                                (
                                    <button type="button" onClick={() => updateAudioFor()} className="btn btn-primary btn-block btn-rounded-30 btn-dark-blue-1">Continue</button>
                                )
                        }
                    </form>
                }
            </div>
        </div>
    );

}
