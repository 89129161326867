import React, { useRef, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { ReactEditor, useSlate } from 'slate-react'
import {
	Editor,
	Transforms,
	Text,
	Range
} from 'slate';
import * as SlateReact from "slate-react";

import dropdownIcon from '../assets/down_chevron_primary.svg';
import volumeIcon from '../assets/volume-2.svg';
import stopIcon from '../assets/stop-circle.svg';
import Spinner from './Spinner';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Info from './Info';
import { toast } from 'react-toastify';
import { addMixPanelEvent, updatePronunciationLibrary } from '../store/actions';


const Portal = ({ children }) => {
	return typeof document === 'object'
		? ReactDOM.createPortal(children, document.body)
		: null
}

const HoveringToolbar = ({ isEmphasisDisabled, onListenClick, onStopClick, isSelectionPreviewLoading, isSelectionPreviewPlaying, savePronunciationLibrary, orgId, integrationId, contentId }) => {
	const ref = useRef()
	const editor = useSlate()

	useEffect(() => {

		const el = ref.current
		const { selection } = editor

		if (isSelectionPreviewLoading ||
			isSelectionPreviewPlaying
		) {
			el.style.opacity = '1';
			return;
		}

		if (!el) {
			return
		}

		if (
			!selection ||
			!ReactEditor.isFocused(editor) ||
			Range.isCollapsed(selection) ||
			Editor.string(editor, selection) === ''
		) {
			el.removeAttribute('style')
			return
		}

		

		const domSelection = window.getSelection()
		const domRange = domSelection.getRangeAt(0)
		const rect = domRange.getBoundingClientRect()

		const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

		let left = rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2
		let top = rect.top + window.pageYOffset - el.offsetHeight;

		if (left < 24) {
			left = 24
		}

		if (left + 520 >= vw) {
			left = vw - 550;
		}

		el.style.opacity = '1'
		el.style.top = `${top}px`
		el.style.left = `${left}px`

	});

	const ListenToSelection = () => {
		return (
			<button
				type="button"
				className='toolbar-item--preview-selection'
				onClick={isSelectionPreviewPlaying ? onStopClick : onListenClick}
			>
				{
					isSelectionPreviewLoading 
					? <Spinner color='#93A4B4' />
					: (
						<>
							{
								isSelectionPreviewPlaying 
									? <img width={18} height={18} className='action-icon play-selection-preview-icon' src={stopIcon} alt='preview' /> 
									: <img width={12} height={12} className='action-icon play-selection-preview-icon' src={volumeIcon} alt='preview' /> }
							
							<span>Listen</span>
						</>
					)
				}
			</button>
		);
	}

	return (
		<Portal>
			<div
				ref={ref}
				className='floating--menu'
			>
				<ListenToSelection onClick={onListenClick} />
				<Pipe />
				<AddEmphasisButton orgId={orgId} integrationId={integrationId} contentId={contentId} disabled={isEmphasisDisabled} />
				<Pipe />
				<Pronunciation orgId={orgId} integrationId={integrationId} contentId={contentId} savePronunciationLibrary={savePronunciationLibrary} />
				<Pipe />
				<SayAs orgId={orgId} integrationId={integrationId} contentId={contentId} />
				{/* <AddPause /> */}
			</div>
		</Portal>
	)
}

const SayAs = ({orgId, integrationId, contentId}) => {
	
	
	const dispatch = useDispatch();
	const editor = SlateReact.useSlate();
	const match = getMatchingSayAs(editor);
	const matchedNode = match ? match[0] : null;

	const [showSayAs, setShowSayAs] = useState(false);
	const sayAsButtonRef = useRef(null);
	const sayAsPopupRef = useRef(null);
	const [sayAs, setSayAs] = useState(null);

	const toggleSayAs = (e, interpretAs) => {
		e.preventDefault();
		const isActive = isMatchingSayAs(editor, interpretAs);

		Transforms.setNodes(
			editor,
			isActive ? {
				say_as: null,
			} : {
				say_as: true,
				interpret_as: interpretAs,
				format: null,
			},
			{ match: Text.isText, split: true }
		);

		if (!isActive) {
			dispatch(addMixPanelEvent({
				orgId, integrationId, contentId, body : {
					eventName : "ADD_SAY_AS",
					eventData : {
						interpretAs
					}
				}
			}))
		}
		
	}

	const clearSayAs = (e) => {
		e.preventDefault();
		Transforms.setNodes(
			editor, 
			{ say_as: null },
			{ match: Text.isText, split: true }
		)
	}

	useEffect(() => {
		if (matchedNode) {
			setSayAs(matchedNode.interpret_as)
		}
	}, [matchedNode])

	useEffect(() => {

		const listener = ('click', ev => {
			if (sayAsPopupRef.current && sayAsPopupRef.current.contains(ev.target)) {
				setShowSayAs(true);
			} else if (sayAsButtonRef.current && sayAsButtonRef.current.contains(ev.target)) {
				setShowSayAs(prev => !prev);
			} else {
				setShowSayAs(false);
			}
		});

		window.addEventListener('click', listener);
		
		return () => window.removeEventListener('click', listener);
	});

	useEffect(() => {

		if (showSayAs) {
			const el = sayAsPopupRef.current;

			const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
			const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
			
			const rect = el.getBoundingClientRect();

			if (rect.left + rect.width >= vw) {
				el.style.left = "-160px";
			}
			if (rect.top + rect.height >= vh) {
				el.style.top = "-110px"
			}
		}

	})

	return (
		<div
			ref={sayAsButtonRef}
			type="button"
			className='toolbar-item--emphasis'
		>
			Say as
			<img className="ml-2" width={9} height={6} src={dropdownIcon} alt="dropdown" />
			{
				showSayAs && (
					<div ref={sayAsPopupRef} onClick={(e) => e.stopPropagation()} className="hovering-toolbar-popup">
						<h6>Say as</h6>
						<div className='d-flex flex-row align-items-center p-2' style={{ fontSize: 13 }}>
							<label style={{margin: 0}}>Say as: </label>
							<select className='ml-1 flex-grow-1' value={sayAs} onChange={(e) => setSayAs(e.target.value)}>
								{
									['address', 'cardinal', 'spell-out', 'date', 'digits', 'fraction', 'ordinal', 'telephone', 'time', 'name', 'expletive', 'unit'].map((item) => (
										<option value={item}>
											{_.capitalize(item)}
										</option>
									))
								}
							</select>
						</div>
						<div className='hovering-toolbar-popup-footer'>
							<button onClick={(e) => clearSayAs(e)}>Clear</button>
							<button onClick={(e) => toggleSayAs(e, sayAs)}>Apply</button>
						</div>
					</div>
				)
			}
		</div>
	);

}

const Pronunciation = ({ savePronunciationLibrary, orgId, integrationId, contentId }) => {

	const dispatch = useDispatch();

	const editor = SlateReact.useSlate();
	const match = getPronunciationMatch(editor);
	const matchedNode = match ? match[0] : null;
	const matchedNodeLocation = match ? match[1] : null;

	const slate_value = useSelector(state => state.audio_composer.slate_value)

	const [showPronunciation, setShowPronnunciation] = useState(false);
	const pronunciationButtonRef = useRef(null);
	const pronunciationPopupRef = useRef(null);
	const [replaceWith, setReplaceWith] = useState('');
	const [shouldSaveToLibrary, setShouldSaveToLibrary] = useState(false);

	const pronunciationLibrary = useSelector(state => state.integration.pronunciationLibrary)

	const setPronunciation = (e) => {
		e.preventDefault();

		if (shouldSaveToLibrary) {
			const newPronunciationLibrary = [
				...pronunciationLibrary,
				{
					pronunciation: true,
					original: Editor.string(editor, editor.selection),
					replaceWith, 
					language: slate_value[editor.selection.anchor.path[0]].audio_profile.lang_code
				}
			]
			savePronunciationLibrary(newPronunciationLibrary)
			setTimeout(() => dispatch(updatePronunciationLibrary(newPronunciationLibrary)), 1)
		} else {
			Transforms.setNodes(
				editor,
				{
					pronunciation: true,
					original: Editor.string(editor, editor.selection),
					replaceWith,
				},
				{
					match: Text.isText,
					split: true,
				}
			);
		}

		dispatch(addMixPanelEvent({
			orgId, integrationId, contentId, body : {
				eventName : "ADD_PRONUNCIATION",
				eventData : {
					original: Editor.string(editor, editor.selection),
					replaceWith, 
				}
			}
		}))
		
	}

	const clearPronunciation = (e) => {
		e.preventDefault();
		Transforms.setNodes(
			editor,
			{
				pronunciation: null,
			},
			{
				match: Text.isText,
				split: true,
			}
		);
	}

	useEffect(() => {
		if (matchedNode) {
			setReplaceWith(matchedNode.replaceWith);
		} else {
			setReplaceWith('');
		}
	}, [matchedNode, matchedNodeLocation]);

	useEffect(() => {

		const listener = ('click', ev => {
			if (pronunciationPopupRef.current && pronunciationPopupRef.current.contains(ev.target)) {
				setShowPronnunciation(true);
			} else if (pronunciationButtonRef.current && pronunciationButtonRef.current.contains(ev.target)) {
				setShowPronnunciation(prev => !prev);
			} else {
				setShowPronnunciation(false);
			}
		});

		window.addEventListener('click', listener);
		
		return () => window.removeEventListener('click', listener);
	});

	return (
		<div
			ref={pronunciationButtonRef}
			type="button"
			className="toolbar-item--pronnunciation"
		>
			Pronunciation
			<img className="ml-2" width={9} height={6} src={dropdownIcon} alt="dropdown" />
			{
				showPronunciation && (
					<div ref={pronunciationPopupRef} onClick={(e) => e.stopPropagation()} className='hovering-toolbar-popup'>
						<h6>Pronunciation</h6>
						<div className="pronunciation-popup-original">
							<label>Original: </label>
							<input disabled type="text" value={Editor.string(editor, editor.selection)} />
						</div>
						<div className="pronunciation-popup-replace-with">
							<label>Replace with:</label>
							<input placeholder='Enter replacement text' type="text" value={replaceWith} onChange={(e) => setReplaceWith(e.target.value)} />
						</div>
						<div className="d-flex p-2">
							<input value={shouldSaveToLibrary} onChange={(e) => setShouldSaveToLibrary(e.target.checked)} type="checkbox"/>
							<label className='m-0 ml-1' >Add to pronunciation library</label>
						</div>
						<div className='hovering-toolbar-popup-footer'>
							<button onClick={clearPronunciation}>Clear</button>
							<button onClick={setPronunciation}>Apply</button>
						</div>
					</div>
				)
			}
		</div>
	)

}

const AddEmphasisButton = ({ disabled, orgId, integrationId, contentId }) => {
	
	const dispatch = useDispatch();
	const editor = SlateReact.useSlate();
	const match = getEmphasisMatch(editor);
	const matchedNode = match ? match[0] : null;

	const [showEmphasis, setShowEmphasis] = useState(false);
	const emphasisButtonRef = useRef(null);
	const emphasisPopupRef = useRef(null);
	const [strength, setStrength] = useState(null);

	const toggleEmphasis = (e, strength) => {
		e.preventDefault();
		if (!strength) toast.error("Please choose strength")

		Transforms.setNodes(
			editor,
			{
				'emphasis': true,
				'emphasis_strength': strength
			},
			{ match: Text.isText, split: true }
		)

		dispatch(addMixPanelEvent({
			orgId, integrationId, contentId, body : {
				eventName : "ADD_EMPHASIS",
				eventData : {
					strength
				}
			}
		}))
	}

	const clearEmphasis = (e) => {
		e.preventDefault();
		
		Transforms.setNodes(
			editor,
			{
				'emphasis': null
			},
			{ match: Text.isText, split: true }
		)
	}

	useEffect(() => {
		if (matchedNode) {
			setStrength(matchedNode.emphasis_strength)
		}
	}, [matchedNode])

	useEffect(() => {

		const listener = ('click', ev => {
			if (emphasisPopupRef.current && emphasisPopupRef.current.contains(ev.target)) {
				setShowEmphasis(true);
			} else if (emphasisButtonRef.current && emphasisButtonRef.current.contains(ev.target)) {
				setShowEmphasis(prev => !prev);
			} else {
				setShowEmphasis(false);
			}
		});

		window.addEventListener('click', listener);
		
		return () => window.removeEventListener('click', listener);
	});

	return (
		<div
			ref={emphasisButtonRef}
			type="button"
			className='toolbar-item--emphasis'
		>
			Emphasis
			<img className="ml-2" width={9} height={6} src={dropdownIcon} alt="dropdown" />
			{
				showEmphasis && (
					<div ref={emphasisPopupRef} onClick={(e) => e.stopPropagation()} className="hovering-toolbar-popup">
						<h6>Emphasis {disabled && <Info text="Emphasis unavailable for selected voice" />}</h6>
						<div className='d-flex flex-row align-items-center p-2' style={{ fontSize: 13 }}>
							<label style={{margin: 0}}>Strength: </label>
							<select disabled={disabled} className='ml-1 flex-grow-1' value={strength} onChange={(e) => setStrength(e.target.value)}>
								<option value={null}></option>
								<option value="strong">Strong</option>
								<option value="moderate">Moderate</option>
								<option value="reduced">Reduced</option>
							</select>
						</div>
						<div className='hovering-toolbar-popup-footer'>
							<button onClick={(e) => clearEmphasis(e)}>Clear</button>
							<button onClick={(e) => toggleEmphasis(e, strength)}>Apply</button>
						</div>
					</div>
				)
			}
		</div>
	)
}

const isMatchingSayAs = (editor, interpret_as) => {
	
	try {
		
		const [match] = Editor.nodes(editor, {
			match: n => n.say_as === true && n.interpret_as === interpret_as,
			mode: 'all',
		})
		return !!match

	} catch (e) {

		return null;

	}
	
}

const getMatchingSayAs = (editor) => {
	
	try {

		const [match] = Editor.nodes(editor, {
			match: n => n.say_as === true,
			mode: 'all',
		})
		return !!match

	} catch (e) {

		return null;

	}

}

const getPronunciationMatch = (editor) => {
	
	try {

		const [match] = Editor.nodes(editor, {
			match: n => n.pronunciation === true,
			mode: 'all'
		})
		return match;

	} catch (e) {

		return null;

	}

}

const getEmphasisMatch = (editor) => {

	try {

		const [match] = Editor.nodes(editor, {
			match: n => n.emphasis === true,
			mode: 'all'
		})
		return match;

	} catch (e) {

		return null;

	}
	
}

// const isEmphasisActive = (editor, strength) => {
// 	const [match] = Editor.nodes(editor, {
// 		match: n => n.emphasis === true && n.emphasis_strength === strength,
// 		mode: 'all',
// 	})
// 	return !!match
// }

// const AddPause = () => {
// 	const editor = SlateReact.useSlate();

// 	const addPause = (e, duration) => {
// 		e.preventDefault();
// 		if (isPauseActive(editor)) {
// 			unwrapPause(editor);
// 		} else {
// 			insertPause(editor, duration);
// 		}
// 	}

// 	const PauseCustomDropdown = React.forwardRef(({ onClick }, ref) => (
// 		<button type="button" className="toolbar-item--add-pause" ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }} >
// 			Add pause
// 			<img className="ml-2" width={9} height={6} src={dropdownIcon} alt="dropdown" />
// 		</button>
// 	));

// 	return (
// 		<Dropdown as="span">
// 			<Dropdown.Toggle as={PauseCustomDropdown} />
// 			<Dropdown.Menu>
// 				<Dropdown.Item as="button" onClick={(e) => addPause(e, 0.2)}>
// 					0.2s
// 				</Dropdown.Item>
// 				<Dropdown.Item as="button" onClick={(e) => addPause(e, 0.5)}>
// 					0.5s
// 				</Dropdown.Item>
// 				<Dropdown.Item as="button" onClick={(e) => addPause(e, 1.0)}>
// 					1s
// 				</Dropdown.Item>
// 				<Dropdown.Item as="button" onClick={(e) => addPause(e, 1.5)}>
// 					1.5s
// 				</Dropdown.Item>
// 				<Dropdown.Item as="button" onClick={(e) => addPause(e, 2.0)}>
// 					2s
// 				</Dropdown.Item>
// 			</Dropdown.Menu>
// 		</Dropdown>
// 	);

// };

// const insertPause = (editor, duration) => {
// 	if (editor.selection) {
// 		wrapPause(editor, duration);
// 	}
// };

// const unwrapPause = (editor) => {
// 	Transforms.unwrapNodes(editor, {
// 		match: (n) =>
// 			!Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "pause"
// 	});
// };

// const wrapPause = (editor, duration) => {
// 	if (isPauseActive(editor)) {
// 		unwrapPause(editor);
// 	}

// 	const { selection } = editor;
// 	const isCollapsed = selection && Range.isCollapsed(selection);
// 	const pause = {
// 		type: "pause",
// 		duration: duration,
// 		children: isCollapsed ? [{ text: "pause" }] : []
// 	};

// 	if (isCollapsed) {
// 		Transforms.insertNodes(editor, pause);
// 	} else {
// 		Transforms.wrapNodes(editor, pause, { split: true });
// 		Transforms.collapse(editor, { edge: "end" });
// 	}
// };

// const isPauseActive = (editor) => {
// 	const [pause] = Editor.nodes(editor, {
// 		match: (n) =>
// 			!Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "pause"
// 	});
// 	return !!pause;
// };

export default HoveringToolbar;

const Pipe = () => {
	return (
		<div style={{
			width: 1,
			background: '#000',
			margin: '0 12px',
		}}></div>
	);
}
