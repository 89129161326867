import React, { useState, useEffect } from 'react';
import calendarIcon from '../../assets/calendar.svg';
import { DateRangePicker } from 'react-date-range';
import { subDays } from 'date-fns';
import Modal from 'react-bootstrap/Modal'
import {Link, useParams} from "react-router-dom";
import moment from 'moment';
import { NavLink } from "react-router-dom";
import encodeQueryData from '../../utils/Query';
import { API } from 'aws-amplify';
import _ from 'lodash';

function ContentAnalytics(){

    const { org_id, id } = useParams();

    const [isLoadingContent, setIsLoadingContent] = useState(false);
    const [content, setContent] = useState([]);
    const [org, setOrg] = useState(null);
    const [isLoadingOrg, setIsLoadingOrg] = useState(true);

    const [ timePeriod, setTimePeriod ] = useState([
        {
          startDate: subDays(new Date(), 7),
          endDate: new Date(),
          key: 'selection'
        }
      ]);

    const [ isDateFilterApplied, setIsDateFilterApplied ] = useState(false)

    const [show, setShow] = useState(false);

    const getOrganisation = (org_id) => {
        return API.get(`main`, `/organisation/${org_id}`);
    }

    const getData = (startDate=null, endDate=null) => {

        let url = `/organisation/${org_id}/integration/${id}/analytics/content`;

        let params = {};

        if(startDate){

            params["start_date"] = startDate;

        }

        if(endDate){

            params["end_date"] = endDate;

        }

        if(Object.keys(params).length > 0){

            url = url + "?" + encodeQueryData(params);

        }

        return API.get(`main`, url);

    }

    const Shimmer = () => {

        return (
            <div className="list-group list-group-flush list-group--content">
                {
                    _.range(4).map((index)=>{

                        return(
                            <li className="list-group-item list-group-item-action" key={`content-${index}`}>
                                <div className="content-title-shimmer shine" />
                                <div className="content-meta-shimmer shine" />
                            </li>
                        )

                    })
                }
            </div>
        )
    }

    const EmptyState = () => {

        return(
            <React.Fragment>
                <h5 style={{marginTop:'32px'}}>We are waiting to receive any content from your Website or APP.</h5>
                <p>Follow the instruction on the installation page to integrate Listen2It with your application.</p>
                <NavLink to={`/organisation/${org_id}/integration/${id}/installation`} activeClassName="active">
                    <button className="btn btn-primary">Go to Installation Page</button>
                </NavLink>
            </React.Fragment>
        );
    }

    const AppliedFilter = () => {

        return(
            (isDateFilterApplied) &&
            <div className="content-filter">
                <span className="mr-2">Showing content from {moment(timePeriod[0].startDate).format('MMM Do YY')} to {moment(timePeriod[0].endDate).format('MMM Do YY')}</span>
                <span className="clear-content-filter" onClick={()=>{setIsDateFilterApplied(false)}}>Clear</span>
            </div>
        )
    }

    const Content = () => {

        return(
            <React.Fragment>
                <div className="list-group list-group-flush list-group--content">
                    {
                        content.map((item, index)=>{

                            return(
                                (item) &&
                                <li className="list-group-item list-group-item-action" key={`content-${index}`}>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h4>{item.title}</h4>
                                            <span className="publish_date">Published on {moment(item.created_at).format("MMM D, YYYY")}</span>
                                        </div>
                                        <div className="col-sm-6 d-flex justify-content-between my-3 my-md-0">
                                            <div className="text-center mx-3">
                                                <h5 style={{fontWeight: 600, fontSize: '18px', lineHeight: '27px', color: '#3D4852'}}>{(item.total_plays)?item.total_plays:0}</h5>
                                                <span style={{fontWeight: 600, fontSize: '12px', lineHeight: '18px', color: '#657B90'}}>Listens</span>
                                            </div>
                                            <div className="text-center mx-3">
                                                <h5 style={{fontWeight: 600, fontSize: '18px', lineHeight: '27px', color: '#3D4852'}}>{(item.total_impressions)?item.total_impressions:0}</h5>
                                                <span style={{fontWeight: 600, fontSize: '12px', lineHeight: '18px', color: '#657B90'}}>Impressions</span>
                                            </div>
                                            <div className="text-center mx-3">
                                                <h5 style={{fontWeight: 600, fontSize: '18px', lineHeight: '27px', color: '#3D4852'}}>{
                                                    (item.avg_listen_duration)
                                                    ? new Date(parseFloat(item.avg_listen_duration) * 1000).toISOString().slice(14, 19)
                                                    : 0}s</h5>
                                                <span style={{fontWeight: 600, fontSize: '12px', lineHeight: '18px', color: '#657B90'}}>Listen Length</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            )

                        })
                    }
                </div>
            </React.Fragment>
        )

    }

    useEffect(()=>{

        let startDate = null;
        let endDate = null;

        if(true === isDateFilterApplied){

            startDate = moment(timePeriod[0].startDate).format();
            endDate = moment(timePeriod[0].endDate).add(1, 'day').startOf('day').format();

        }

        setIsLoadingContent(true);

        getData(startDate, endDate).then((response)=>{

            let content = response.data;

            content = content.map((c)=>{

                let minutes = parseInt(c.avg_session_length / 60);
                let seconds = parseInt(c.avg_session_length % 60);

                c.avg_session_length = minutes + 'm ' + seconds + 's';

                return c;

            });

            setContent(content);

        }).finally(()=>{

            setIsLoadingContent(false);

        });

    }, [timePeriod, isDateFilterApplied]);

    useEffect(()=>{

        if(null === org){
            return;
        }

        if(false === org.gates.can_access_audio_level_analytics){
            setIsLoadingContent(false);
            return
        }

        setIsLoadingContent(true)
        getData().then((response)=>{

            let content = response.data;

            content = content.map((c)=>{

                let minutes = parseInt(c.avg_session_length / 60);
                let seconds = parseInt(c.avg_session_length % 60);

                c.avg_session_length = minutes + 'm ' + seconds + 's';

                return c;

            });

            setContent(content);

        }).finally(()=>{

            setIsLoadingContent(false);

        });

    },[org, id])

    useEffect(()=>{

        getOrganisation(org_id).then((response)=>{
            setOrg(response.data);
        }).finally(()=>{
            setIsLoadingOrg(false)
        });

    },[org_id]);

    return(
        <React.Fragment>
            <h3 className="page-title">Content</h3>
            <div className="controls">
                <div className="date-filter">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <img src={calendarIcon} alt="calendar icon" />
                        </span>
                        <button type="button" className="btn--date-filter w-100 d-none d-md-inline-block" onClick={()=>{setShow(true)}}>    
                            <span>Filter by date</span>
                        </button>
                    </div>
                </div>
                
                <Modal show={show} onHide={()=>{setShow(false)}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Pick a date range</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <DateRangePicker
                            onChange={item => { setTimePeriod([item.selection]); setIsDateFilterApplied(true) }}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={1}
                            ranges={timePeriod}
                            maxDate={ new Date() }
                            direction="vertical"
                            />
                    </Modal.Body>
                </Modal>
            </div>

           {
               (isLoadingOrg)
               ?
               (
                    <Shimmer />
               )
               :
               (
                   (true === org.gates.can_access_audio_level_analytics)?
                       (
                           (isLoadingContent)
                           ?
                               (
                                   <Shimmer />
                               )
                               :
                               (
                                   (content.length > 0)
                                       ?
                                       (
                                           <React.Fragment>
                                               <AppliedFilter />
                                               <Content />
                                           </React.Fragment>
                                       )
                                       :
                                       (
                                           (isDateFilterApplied)
                                               ?
                                               (
                                                   <React.Fragment>
                                                       <AppliedFilter />
                                                       <p style={{marginTop: '32px'}}>No content found in this date range. Try adjusting the date range.</p>
                                                   </React.Fragment>
                                               )
                                               :
                                               (
                                                   <EmptyState />
                                               )
                                       )
                               )
                       )
                       :
                       (
                           <div className="text-center mt-5 bg-white rounded p-5 shadow-sm">
                               <h5 className="font-weight-bold">Upgrade to view advanced analytics</h5>
                               <p>Upgrade your plan to view the performance of your audio and start optimising your content.</p>
                               <Link to={`/organisation/${org_id}/upgrade`} className="btn btn-primary">View Plans</Link>
                           </div>
                       )
               )
           }
        </React.Fragment>
    );
}

export default ContentAnalytics;
