import { API } from "aws-amplify";
import React, { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useParams, useHistory, Link } from "react-router-dom";
import classNames from 'classnames';
import ReactHowler from "react-howler";

import AudioPreview from '../components/AudioPreview';
import Spinner from '../components/Spinner';
import Info from '../components/Info';

import playIcon from "../assets/play_solid.svg";
import ideaBulbIcon from '../assets/idea-bulb.svg';
import { INTEGRATION_CREATED } from '../constants/gtag-events';
import { toast } from 'react-toastify';
import Bugsnag from '@bugsnag/browser';
import { DEFAULT_LANG_CODE } from '../constants/constants';

const CreateProject = () => {

    const { org_id } = useParams();
    const history = useHistory();

    const [org, setOrg] = useState(null);
    const [integrations, setIntegrations] = useState(null);

    const [isLoading, setIsLoading] = useState(true);
    const [isCreatingProject, setIsCreatingProject] = useState(false);
    const [languages, setLanguages] = useState(null);
    const [language, setLanguage] = useState(null);
    const [canCreateNewProperty, setCanCreateNewProperty] = useState(false);
    const [audio, setAudio] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [voices, setVoices] = useState([]);
    const [voice, setVoice] = useState(null);
    const [advanceSettingsVisibility, setAdvanceSettingsVisibility] = useState(false);

    const [isPitchDisabled, setIsPitchDisabled] = useState(true);
    const [isStyleDisabled, setIsStyleDisabled] = useState(false);
    const [isBreathingDisabled, setIsBreathingDisabled] = useState(false);

    const [profile, setProfile] = useState({ lang_code: DEFAULT_LANG_CODE });

    const projectForm = useForm();
    const formControl = projectForm.control;
    const watchAll = useWatch({
        control: formControl,
    })

    const watchAllProjectForm = projectForm.watch()
    const watchLangCode = projectForm.watch("lang_code");
    const watchEngine = projectForm.watch("engine");
    const watchVoice = projectForm.watch("voice");
    const watchVolume = projectForm.watch("volume");
    const watchSpeed = projectForm.watch("speed");

    const onSubmit = (data) => {

        let req = {
            integration_name: data.integration_name,
            lang_code: data.lang_code,
            player_settings: {
                player_type: 'inline'
            },
            audio_profile: {
                platform: data.platform,
                engine: data.engine,
                voice: data.voice,
                volume: data.volume,
                speed: data.speed,
                ...(!isPitchDisabled && { pitch: data.pitch }),
                ...(!isStyleDisabled && { style: data.style }),
                ...(!isBreathingDisabled && { auto_breathing: data.auto_breathing })
            }
        }

        createProject(req)
    };

    const createProject = (req) => {
        setIsCreatingProject(true);
        API.post(`main`, `/organisation/${org_id}/integration`, { body: req, headers: { "Content-Type": "application/json" } })
            .then((response) => {

                toast.success('Project has been created successfully!');
                window.dataLayer.push({
                    event: INTEGRATION_CREATED
                })
                history.push(`/organisation/${org_id}/integration/${response.data.id}/content`)

            }).catch((error) => {
                const errorMsg = (error.response && error.response.data && error.response.data.message) || "Something went wrong while creating the integration."
                toast.error(errorMsg)
                Bugsnag.notify(error);

            }).finally(() => {

                setIsCreatingProject(false);

            });
    };

    const onAdvanceSettingToggle = (event) => {

        if (event.target.checked) {
            setAdvanceSettingsVisibility(true);
            return;
        }

        setAdvanceSettingsVisibility(false);

    }

    const getLanguages = () => {
        return API.get(`main`, `/voices`, { headers: { "Content-Type": "application/json" } });
    }

    const getOrganisations = (org_id) => {
        return API.get(`main`, `/organisation/${org_id}`);
    }

    const getIntegrations = useCallback(() => {
        return API.get(`main`, `/organisation/${org_id}/integration`);
    }, [org_id]);

    const onPlayPreview = (audio) => {
        setAudio(audio);
        setIsPlaying(true);
    }

    useEffect(() => {

        setProfile({
            lang_code: watchAllProjectForm.lang_code,
            platform: watchAllProjectForm.platform,
            engine: watchAllProjectForm.engine,
            voice: watchAllProjectForm.voice,
            volume: (watchAllProjectForm.volume) ? parseFloat(watchAllProjectForm.volume) : 0,
            speed: (watchAllProjectForm.speed) ? parseInt(watchAllProjectForm.speed) : 100,
            ...(!isPitchDisabled && { pitch: watchAllProjectForm.pitch }),
            ...(!isStyleDisabled && { style: watchAllProjectForm.style }),
            ...(!isBreathingDisabled && { auto_breathing: watchAllProjectForm.auto_breathing })
        });

    }, [watchAll]);

    useEffect(() => {

        if (!watchLangCode) return
        const language = languages[watchLangCode];
        setLanguage(language);

        const neuralVoices = language.voices.filter(voice => voice.engine === "neural");
        const standardVoices = language.voices.filter(voice => voice.engine === "standard");

        if (neuralVoices.length > 0) {
            setVoices(neuralVoices);

            const defaultVoice = neuralVoices[0];
            setVoice(defaultVoice);
        } else {
            setVoices(standardVoices);

            const defaultVoice = standardVoices[0];
            setVoice(defaultVoice);
        }

    }, [watchLangCode]);

    useEffect(() => {

        const voiceCode = projectForm.getValues('voice');

        if (!voiceCode) {
            return;
        }

        const voice = voices.filter(voice => voiceCode === voice.code)[0];
        projectForm.setValue('platform', voice.platform);
        setVoice(voice);

        setProfile({ lang_code: watchLangCode, engine: voice.engine, platform: voice.platform, voice: voice.code, volume: 0, speed: 100 })

        setIsPitchDisabled(("polly" === voice.platform && "neural" === voice.engine))
        setIsStyleDisabled(voice.styles.length === 0);
        setIsBreathingDisabled(!("polly" === voice.platform && "standard" === voice.engine))

    }, [watchVoice]);

    useEffect(() => {
        const engine = projectForm.getValues("engine");

        if (language) {
            const voices = language.voices.filter(voice => voice.engine === engine);
            setVoices(voices);
            const defaultVoice = voices[0];
            setVoice(defaultVoice);

            setIsPitchDisabled(true)
            setIsStyleDisabled(true);
            setIsBreathingDisabled(true)

            setProfile({
                lang_code: watchLangCode,
                engine: engine,
                ...(defaultVoice && { platform: defaultVoice.platform }),
                ...(defaultVoice && { voice: defaultVoice.code }),
                volume: 0,
                speed: 100
            })
        }

    }, [watchEngine])

    useEffect(() => {
        getOrganisations(org_id).then((response) => {
            setOrg(response.data);
        }).then(getIntegrations).then((response) => {
            setIntegrations(response.data);
        }).finally(() => setIsLoading(false))
    }, [org_id, getIntegrations]);

    useEffect(() => {

        if (org && true === org.gates?.can_create_project) {
            setCanCreateNewProperty(true);
        }

    }, [org, integrations]);

    useEffect(() => {
        getLanguages().then((response) => {
            setLanguages(JSON.parse(response.data));
        })
    }, []);

    if (isLoading) {
        return (
            <div className="container">
                <div className="container-fluid mt-5 text-center">
                    <Spinner color="#657B90" />
                </div>
            </div>
        );
    }

    if (!canCreateNewProperty) {
        return (
            <div className="container mt-3">
                <div className="container-fluid">
                    <div className="card text-center mx-md-4 mb-5 pb-5 pt-3">
                        <div className="card-body">
                            <h4>Upgrade to create multiple projects.</h4>
                            <p>Create and manage multiple projects by upgrading your account.</p>
                        </div>
                        <div className="d-flex justify-content-center" style={{ gap: 16 }}>
                            <button type="button" className="btn btn-outline-primary" onClick={() => { history.goBack() }}>
                                Cancel
                            </button>
                            <button type="button" className="btn btn-primary" onClick={() => { history.push(`/organisation/${org_id}/upgrade`) }}>
                                Upgrade
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <div className="row my-5">
                        <div className="col-sm-11 order-2 order-sm-1">
                            <h4>Create a new Listen2It Project</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="container my-5">
                    <div className="row my-5">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-xl-6 order-2 order-xl-1">
                                    <form onSubmit={projectForm.handleSubmit(onSubmit)}>
                                        <div className="form-group">
                                            <label>Project Name <sup className="text-danger">*</sup></label>
                                            <input type="text" name="integration_name" className={classNames("form-control", { "is-invalid": projectForm.errors.integration_name })} ref={projectForm.register({ required: true, minLength: 3 })} />
                                            {
                                                projectForm.errors.integration_name &&
                                                <div className="invalid-feedback">
                                                    {
                                                        ("required" === projectForm.errors.integration_name.type) && "Name field is required."
                                                    }
                                                    {
                                                        ("minLength" === projectForm.errors.integration_name.type) && "Name field should be at least 3 character long."
                                                    }
                                                </div>
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label>Choose Default Language <sup className="text-danger">*</sup></label>
                                            <select className={classNames("custom-select", { "is-invalid": projectForm.errors.lang_code })} name="lang_code" ref={projectForm.register({ required: true })}>
                                                <option value="">-- Choose --</option>
                                                {
                                                    languages && Object.keys(languages).sort().map((lang) => {

                                                        return (
                                                            <option value={languages[lang].code} key={`general-lang-${languages[lang].code}`}>{languages[lang].name}</option>
                                                        )

                                                    })
                                                }
                                            </select>
                                            {
                                                projectForm.errors.lang_code &&
                                                <div className="invalid-feedback">
                                                    {
                                                        ("required" === projectForm.errors.lang_code.type) && "Language field is required."
                                                    }
                                                </div>
                                            }
                                            <small className="form-text text-muted">Can be changed later.</small>
                                        </div>
                                        {
                                            watchLangCode &&
                                            <div className="form-group">
                                                <label>Quality <Info text="Premium Engine can produce higher quality voices than the Standard voices." /></label>
                                                <div className="d-block">
                                                    {
                                                        (language && language.voices.filter(voice => voice.engine === "neural").length > 0)
                                                            ?
                                                            (
                                                                <>
                                                                    <div className="form-check d-inline mr-4">
                                                                        <input className="form-check-input" type="radio" name="engine" value="neural" ref={projectForm.register({ required: true })} defaultChecked />
                                                                        <label className="form-check-label">Premium</label>
                                                                    </div>
                                                                    <div className="form-check d-inline">
                                                                        <input className="form-check-input" type="radio" name="engine" value="standard" ref={projectForm.register({ required: true })} />
                                                                        <label className="form-check-label">Standard</label>
                                                                    </div>
                                                                </>
                                                            ) :
                                                            (
                                                                <>
                                                                    <div className="form-check d-inline mr-4">
                                                                        <input className="form-check-input" type="radio" name="engine" value="neural" ref={projectForm.register({ required: true })} disabled />
                                                                        <label className="form-check-label">Premium</label>
                                                                    </div>
                                                                    <div className="form-check d-inline">
                                                                        <input className="form-check-input" type="radio" name="engine" value="standard" ref={projectForm.register({ required: true })} defaultChecked />
                                                                        <label className="form-check-label">Standard</label>
                                                                    </div>
                                                                </>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            (voices.length > 0) &&
                                            <>
                                                <div className="form-group mt-4">
                                                    <label>Choose Default Voice  <sup className="text-danger">*</sup></label>
                                                    <div className="mb-4 d-flex align-items-center" style={{ backgroundColor: '#E8EFFD', borderRadius: 6, padding: '6px 10px', color: '#3D4852', fontWeight: 600, fontSize: 14, maxWidth: 'fit-content' }}>
                                                        <img className="mr-3" src={ideaBulbIcon} alt="" />
                                                        You can change voices at any time. However, already created audios will continue to be in older voice.
                                                    </div>
                                                    <input type="hidden" name="platform" ref={projectForm.register({ required: true })} />
                                                    {
                                                        voices.map((voice) => {

                                                            return (
                                                                <div className="form-check d-flex align-items-center my-2" key={`voice-${voice.name}`}>
                                                                    <input className="form-check-input mt-0" type="radio" name="voice" value={voice.code} ref={projectForm.register({ required: true })} disabled={org && !org.gates.can_select_premium_voices && (voice.engine == "neural")} />
                                                                    <label className="form-check-label label-voice">
                                                                        <span className="play-icon"><img src={playIcon} alt="play icon" onClick={() => { onPlayPreview(`${process.env.REACT_APP_AUDIO_PREVIEW_URL}/${voice.audio}`) }} /></span> {`${voice.name}(${voice.gender})`}
                                                                    </label>
                                                                </div>
                                                            )

                                                        })
                                                    }
                                                    {
                                                        projectForm.errors.voice &&
                                                        <div className="text-danger">
                                                            {
                                                                ("required" === projectForm.errors.voice.type) && <small>Voice selection is required.</small>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                <div className="form-check my-3">
                                                    <input className="form-check-input" type="checkbox" checked={advanceSettingsVisibility} onChange={onAdvanceSettingToggle} />
                                                    <label className="form-check-label">
                                                        Show Advance Settings
                                                    </label>
                                                </div>
                                                <div style={{ display: advanceSettingsVisibility ? 'block' : 'none' }}>
                                                    <div className="form-group">
                                                        <label className="d-inline-flex align-items-center"><span>Volume</span><span className="badge badge-secondary ml-1">{(watchVolume) ? watchVolume : 0}</span></label>
                                                        <input type="range" className="form-control-range" min="-6" max="6" step="0.5" defaultValue="0" name="volume" ref={projectForm.register} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="d-inline-flex align-items-center"><span>Speed</span><span className="badge badge-secondary ml-1">{(watchSpeed) ? watchSpeed : 100}</span></label>
                                                        <input type="range" className="form-control-range" min="20" max="200" step="1" defaultValue="100" name="speed" ref={projectForm.register} />
                                                    </div>
                                                    <div style={{ display: isPitchDisabled ? 'none' : 'block' }} className="form-group mt-4">
                                                        <label>Pitch</label>
                                                        <select className="custom-select" name="pitch" ref={projectForm.register} disabled={isPitchDisabled}>
                                                            <option value="">Default</option>
                                                            <option value="x-low">x-low</option>
                                                            <option value="low">low</option>
                                                            <option value="medium">medium</option>
                                                            <option value="high">high</option>
                                                            <option value="x-high">x-high</option>
                                                        </select>
                                                    </div>
                                                    {
                                                        (voice) &&
                                                        <div style={{ display: isStyleDisabled ? 'none' : 'block' }} className="form-group mt-4">
                                                            <label>Styles</label>
                                                            <select className="custom-select" name="style" ref={projectForm.register} disabled={isStyleDisabled}>
                                                                <option value="">Default</option>
                                                                {
                                                                    voice.styles.map((style) => {
                                                                        return <option value={style} key={style}>{style}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    }
                                                    <div style={{ display: isBreathingDisabled ? 'none' : 'block' }} className="form-check mt-4">
                                                        <input className="form-check-input" type="checkbox" name="auto_breathing" ref={projectForm.register} disabled={isBreathingDisabled} />
                                                        <label className="form-check-label">
                                                            Add Auto Breathing
                                                        </label>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="mt-4">
                                            <Link to={`/organisation/${org_id}/integration`}>
                                                <button type="button" className="btn btn-outline-primary mr-3">
                                                    Cancel
                                                </button>
                                            </Link>
                                            {
                                                isCreatingProject
                                                    ? (
                                                        <button type="button" className="btn btn-primary text-center mr-3">
                                                            <Spinner color="#FFF" />
                                                        </button>
                                                    ) : (
                                                        <button disabled={isCreatingProject} className="btn btn-primary mr-3" type="submit">Create Project</button>
                                                    )
                                            }
                                        </div>
                                    </form>
                                </div>
                                <div className="col-xl-6 order-1 order-xl-2 pb-3">
                                    <AudioPreview profile={profile} />
                                </div>
                                {
                                    (audio) &&
                                    <ReactHowler src={audio} playing={isPlaying} onEnd={() => { setIsPlaying(false) }} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateProject;
