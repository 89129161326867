import React from 'react';
import ReactDOM from 'react-dom';
import App from './modules/App';
import * as serviceWorker from './serviceWorker';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { Amplify, Auth } from 'aws-amplify';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux'
import store from "./store"
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PreGenAudioPlayer } from './components/PreGenAudioPlayer';
// import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

ReactGA.initialize('UA-180790043-1');
ReactGA.pageview(window.location.pathname + window.location.search);

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)


Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: "main",
        endpoint: process.env.REACT_APP_BASE_URL,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
        }
      },
      {
        name: "secondary",
        endpoint: process.env.REACT_APP_BASE_URL,
      }
    ]
  }
});

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

TagManager.initialize(tagManagerArgs);

export const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <App />
          <PreGenAudioPlayer />
        </Provider>
      </QueryClientProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
