import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Link, useParams, useHistory } from "react-router-dom";
import { API } from 'aws-amplify';
import { toast } from 'react-toastify';
import clean from '../../utils/Clean';
import only from '../../utils/Only';
import Spinner from '../../components/Spinner';
import Bugsnag from "@bugsnag/js";
import APIKeysSection from '../installation/APIKeysSection';
import { Modal } from 'react-bootstrap';
const classNames = require('classnames');

function General(){

    const history = useHistory();
    const { org_id, id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [integration, setIntegration] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isDeletingProject, setIsDeletingProject] = useState(false);
    
    const { register, reset, errors, handleSubmit } = useForm();
    const [ languages, setLanguages ] = useState(null);

    const onSubmit = data => {

        data = clean(data);

        setIsUpdating(true);

        API.put(`main`, `/organisation/${org_id}/integration/${id}/general_settings`,{body: clean(data), headers: {"Content-Type":"application/json"}})
            .then(()=>{

                toast.success('General settings have been saved successfully!');

            }).catch((error)=>{

                toast.error("Something went wrong while saving the General Settings.")
                Bugsnag.notify(error);

            }).finally(()=>{

                setIsUpdating(false);

            });

    };

    const getIntegration = (org_id, id) => {

        return API.get(`main`, `/organisation/${org_id}/integration/${id}`);

    }

    const getLanguages = () => {

        return API.get(`main`, `/voices`,{headers: {"Content-Type":"application/json"}});

    }

    useEffect(()=>{

        if(!languages){

            return;

        }

        getIntegration(org_id, id).then((response)=>{

            setIntegration(response.data)
            reset(only(response.data, ["integration_name","description","site_url","category","platform","lang_code"]));

        }).finally(()=>{

            setIsLoading(false);

        });

    },[languages]);

    useEffect(()=>{

        getLanguages().then((response)=>{

            setLanguages(JSON.parse(response.data));

        })

    },[org_id, id]);

    const deleteProject = () => {

        setIsDeletingProject(true);

        API.del('main', `/organisation/${org_id}/integration/${id}`).then((response) => {

            setShowConfirmation(false);
            setIsDeletingProject(false);
            history.push(`/organisation/${org_id}/integration`);
            
        })

    }


    return(
        <React.Fragment>
            <Modal show={showConfirmation} onHide={() => {setShowConfirmation(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure want to delete this project?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline-primary mr-3" onClick={()=>setShowConfirmation(false)}>Cancel</button>
                    {
                        (isDeletingProject)?
                            (
                                <button type="button" className="btn btn-danger text-center">
                                    <Spinner color="#FFF" />
                                </button>
                            )
                            :
                            (
                                <button type="button" className="btn btn-danger" onClick={() => deleteProject()}>Delete</button>
                            )
                    }
                </Modal.Footer>
            </Modal>
            <h3 className="page-title">Project Settings</h3>
            <p className="page-subtitle">General configuration settings for your project</p>
            {
                (isLoading)
                ?
                (
                    <div className="my-1">
                        <Spinner color="#3d4852" />
                    </div>
                )
                :
                (
                    <div className="row">
                        <div className="col-xl-6">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card mt-4">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <span className="form-section-title">General Settings</span>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="form-group">
                                                    <label>Name</label>
                                                    <input type="text" name="integration_name" className={classNames("form-control", { "is-invalid": errors.integration_name})} ref={register({required: true, minLength:3})} />
                                                    {
                                                        errors.integration_name &&
                                                        <div className="invalid-feedback">
                                                            {
                                                                ("required"===errors.integration_name.type) && "Name field is required."
                                                            }
                                                            {
                                                                ("minLength"===errors.integration_name.type) && "Name field should be at least 3 character long."
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <APIKeysSection orgId={org_id} integration={integration} />
                                <div className="d-flex mt-4 justify-content-end mx-0">
                                    <Link to={`/organisation/${org_id}/integration/${id}/content`}><button className="btn btn-outline-primary mr-3">Cancel</button></Link>
                                    {
                                        (isUpdating)?
                                        (
                                            <button className="btn btn-primary" style={{width:'145px'}} type="button">
                                                <Spinner color="#fff" />
                                            </button>
                                        )
                                        :
                                        (
                                            <button className="btn btn-primary" style={{width:'145px'}} type="submit">Save Changes</button>
                                        )
                                    }
                                </div>
                            </form>
                            <div className="col">
                                <div className="delete-project-separator"></div>
                                <div className="mt-3 mb-3 d-flex delete-project-container">
                                    <div>
                                        <h5>Delete Project</h5>
                                        <p>Permanently delete this project including all content, links and settings</p>
                                    </div>
                                    <div>
                                        <button onClick={() => {setShowConfirmation(true)}} className="btn btn-danger delete-project-button">Delete Project</button>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                )
            }
        </React.Fragment>
    );
}

export default General;
