import { API } from 'aws-amplify';

export const fetchOrganisationService = ({org_id}) => {
    return API.get('main', `/organisation/${org_id}`).then(res => {
        return res.data
    }).catch(err => {
        return err
    })
}

export const fetchOrganisationUsageService = ({ org_id }) => {
    return API.get('main', `/organisation/${org_id}/usage`).then(res => {
        return res.data
    }).catch(err => {
        return err
    })
}
