import React from 'react';

export default function Aggregate({ label, metric, query }) {

    const { data: queryData, isLoading } = query;

    if (isLoading) {
        return <></>
    }

    const data = queryData.data

    return (
        <div className="aggregate">
            <span className="label"><small>{label}</small></span>
            <span className="value">{data[metric]}</span>
        </div>
    )
}
