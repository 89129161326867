import React from 'react';
import { Link } from "react-router-dom";
import logo from '../assets/logo-full.png';
import Avatar from './Avatar';

export default function FullWidthLayoutFlex({children}){

    return(
        <div className="d-flex flex-column" style={{"minHeight": "100vh", background: "rgb(245, 248, 253)"}}>
            <div style={{background: "rgb(245, 248, 253)"}} className="container-fluid container-fluid--header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="header">
                                <Link className="text-decoration-none" to="/">
                                    <div className="d-flex align-items-center">
                                        <img className="logo mr-1" src={logo} alt="logo" />
                                    </div>
                                </Link>
                                <div className="d-flex align-items-center">
                                    <Avatar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                children
            }
        </div>
    )

}
