import { API } from "aws-amplify";
import Bugsnag from '@bugsnag/browser';
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Spinner from './Spinner';
import uploadIcon from '../assets/upload-alt.svg';
import { useForm } from 'react-hook-form';
import clean from '../utils/Clean';
const classNames = require('classnames');


function UploadAudio({ show, onHide, onSuccess = () => { }, orgId, integrationId, selectedAudio }) {

    const [isUploadingAudio, setIsUploadingAudio] = useState(false);
    const [audioFile, setAudioFile] = useState(null);
    const { register, reset, errors, handleSubmit } = useForm();

    const onUploadAudio = async (data) => {

        if (audioFile === null) {
            toast.error('Select an audio file to upload!')
            return;
        }

        setIsUploadingAudio(true);

        try {

            const signedResponse = await API.get('main', `/organisation/${orgId}/integration/${integrationId}/audio/signed-url`, {
                queryStringParameters: {
                    file_name: audioFile.name,
                    content_type: audioFile.type,
                    file_name_prefix: "fa"
                }
            });

            const requestOptions = {
                method: 'PUT',
                body: audioFile,
                redirect: 'follow'
            };

            const uploadURL = signedResponse.data.signed_url;
            const url = signedResponse.data.url;

            await fetch(uploadURL, requestOptions);

            if (selectedAudio) { // existing audio - EDIT/PUT

                await API.put('main', `/organisation/${orgId}/integration/${integrationId}/content/${selectedAudio.SK.split('#')[1]}/audio/upload`, {
                    body: clean({
                        title: data.title,
                        url: url,
                    }),
                    headers: { "Content-Type": "application/json" }
                })

            } else { // new audio - NEW/POST

                await API.post('main', `/organisation/${orgId}/integration/${integrationId}/audio/upload`, {
                    body: clean({
                        title: data.title,
                        url: url,
                    }),
                    headers: { "Content-Type": "application/json" }
                })

            }

            onSuccess();

        } catch (e) {

            toast.error("Something went wrong while adding article.")
            Bugsnag.notify(e);

        } finally {

            setIsUploadingAudio(false);
            onHide()

        }
    }

    useEffect(() => {
        if (selectedAudio) {
            reset({
                title: selectedAudio.title
            })
        } else {
            reset({
                title: "",
            })
        }
    }, [selectedAudio])

    useEffect(() => {

        if (!show) {
            reset({ title: '' });
            setAudioFile(null);
        }

    }, [show])

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Upload Audio file
                    <p className="modal-subtitle">Only mp3 files are allowed</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className="form-group">
                        <label>Title</label>
                        <input type="text" name="title" className={classNames("form-control", { "is-invalid": errors.title })} ref={register({ required: true, minLength: 3 })} />
                        {
                            errors.title &&
                            <div className="invalid-feedback">
                                {
                                    ("required" === errors.title.type) && "Title field is required."
                                }
                                {
                                    ("minLength" === errors.title.type) && "Title field should be at least 3 character long."
                                }
                            </div>
                        }
                    </div>
                </form>
                <div className="upload-audio-dropbox d-flex flex-column justify-content-center">
                    <input name="audio-file" type="file" accept=".mp3" onChange={(event) => setAudioFile(event.target.files[0])} />
                    <label for="audio-file" className="d-flex flex-column align-items-center">
                        <img src={uploadIcon} />
                        <p>Drag and drop or browse to choose a file</p>
                        {
                            audioFile && (
                                <p>({audioFile.name})</p>
                            )
                        }
                    </label>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-outline-primary mr-3" onClick={onHide}>Cancel</button>
                {
                    (isUploadingAudio) ?
                        (
                            <button type="button" className="btn btn-primary text-center">
                                <Spinner color="#FFF" />
                            </button>
                        )
                        :
                        (
                            <button onClick={handleSubmit(onUploadAudio)} type="button" className="btn btn-primary">Upload</button>
                        )
                }
            </Modal.Footer>
        </Modal>
    );
}

export default UploadAudio;
