import {
    FETCH_CONTENT_FAILURE,
    FETCH_CONTENT_REQUESTED,
    FETCH_CONTENT_SUCCESS, 
    PLAY_TTS_BLOCK_PREVIEW, 
    SET_CONTENT, 
    SET_CONTENT_ID, 
    STOP_TTS_BLOCK_PREVIEW, 
    UPDATE_PUBLISHED_AT,
    TOGGLE_PRONUNCIATION_LIBRARY_MODAL,
    TOGGLE_DOWNLOAD_CONTENT_MODAL,
    TOGGLE_EDITOR_VOICE_PICKER_MODAL,
    TOGGLE_EDITOR_ADD_FROM_URL_MODAL
} from "../actionTypes";

const initialState = {
    content: null,
    contentId: null,
    isLoading: false,
    isError: false,
    published_at: null,
    isTTSBlockPreviewPlaying: false,
    ttsBlockPreviewAudio: null,
    showPronunciationLibraryModal: false,
    showDownloadContentModal : false,
    showVoicePickerModal : false,
    showEditorAddFromUrlModal : false
}

export default function EditContentReducer(state = initialState, action) {

    const { type } = action;

    switch (type) {

        case TOGGLE_EDITOR_ADD_FROM_URL_MODAL: {
            return {
                ...state,
                showEditorAddFromUrlModal : action.payload
            }
        }

        case TOGGLE_EDITOR_VOICE_PICKER_MODAL: {
            return {
                ...state,
                showVoicePickerModal : action.payload
            }
        }

        case TOGGLE_DOWNLOAD_CONTENT_MODAL: {
            return {
                ...state,
                showDownloadContentModal : action.payload
            }
        }

        case TOGGLE_PRONUNCIATION_LIBRARY_MODAL: {
            return {
                ...state,
                showPronunciationLibraryModal : action.payload
            }
        }
        case FETCH_CONTENT_SUCCESS:
            return {
                ...state,
                content: action.payload,
                contentId: action.payload.SK.split('#')[1],
                isLoading: false
            };
        case FETCH_CONTENT_REQUESTED:
            return {
                ...state,
                isLoading: action.payload.shouldNotLoad ? false : true,
                ...(!action.payload.shouldNotLoad && {content: null, contentId: null})
            }
        case FETCH_CONTENT_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        case SET_CONTENT:
            return {
                ...state,
                content: action.payload,
            }
        case UPDATE_PUBLISHED_AT:
            return {
                ...state,
                published_at: action.payload || (new Date()).toISOString(),
            }
        case SET_CONTENT_ID:
            return {
                ...state,
                contentId: action.payload,
            }
        case PLAY_TTS_BLOCK_PREVIEW:
            return {
                ...state,
                isTTSBlockPreviewPlaying: true,
                ...(action.payload && { ttsBlockPreviewAudio: action.payload }),
            }
        case STOP_TTS_BLOCK_PREVIEW:
            return {
                ...state,
                isTTSBlockPreviewPlaying: false,
                ttsBlockPreviewAudio: null
            }
        default:
            return state;
    }


}
