import { useEffect, useState } from 'react';

export const useBuildAudioSocket = (onOpen: () => void, onMessage: (message: object) => void, onClose: () => void) => {

    const [session, setSession] = useState<WebSocket | null>(null);
    const updateOpenHandler = () => {
        if (!session) return;
        session.addEventListener('open', onOpen);
        return () => {
            session.removeEventListener('open', onOpen);
        };
    };

    const onMessageHandler = (event: MessageEvent) => {
        onMessage(JSON.parse(event.data))
    }

    const updateMessageHandler = () => {
        if (!session) return;
        session.addEventListener('message', onMessageHandler);
        return () => {
            session.removeEventListener('message', onMessageHandler);
        };
    };

    const updateCloseHandler = () => {
        if (!session) return;
        session.addEventListener('close', onClose);
        return () => {
            session.removeEventListener('close', onClose);
        };
    };

    useEffect(updateOpenHandler, [session, onOpen]);
    useEffect(updateMessageHandler, [session, onMessage]);
    useEffect(updateCloseHandler, [session, onClose]);

    const connect = () => {
        setSession(new WebSocket(process.env.REACT_APP_AUDIO_BUILDER_WEBSOCKET as string))
    }

    const disconnect = () => {
        session?.close();
        setSession(null);
    }

    const sendMessage = (message: string) => {
        session?.send(message)
    }

    return [connect, disconnect, sendMessage];

}
