import React, { useEffect, useRef, useState, useMemo } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {BLOCK_SIZE_IN_SECONDS, pixelsToSeconds, secondsToPixels, getConversionValue, returnTimeLabel} from "./utils"
import {stopComposerPlayer} from "../../store/actions"


function TimeTracker({ maxTime, currentSeek }) {
    const dispatch = useDispatch()

    const [currentTimeLabel, setCurrentTimeLabel] = useState("00:00")
    const isDragging = useRef(false)
    const timePinRef = useRef(null)
    const audioComposerContainerRef = useRef(null)

    const {extraSettings} = useSelector(state => state.audio_composer)

    useEffect(() => {
        const timePinMouseMove = (e) => {
            // e.preventDefault()
            if(isDragging.current){
                const newX = e.clientX - audioComposerContainerRef.current.getBoundingClientRect().x
                if(newX < 0){
                    return timePinRef.current.style.left = `${0}px`
                }

                // Converting newX in px to time
                timePinRef.current.style.left = `${newX}px`
                setCurrentTimeLabel(returnTimeLabel(pixelsToSeconds(newX, extraSettings.zoomLevel)))
                
                dispatch(stopComposerPlayer({ seek : pixelsToSeconds(newX, extraSettings.zoomLevel)}))
            }
        }

        const timePinMouseUp = (e) => {
            // e.preventDefault()
            isDragging.current = false;
        }

        if(timePinRef.current && audioComposerContainerRef.current){
            // Adding event for mouse move
            document.addEventListener("mousemove", timePinMouseMove)
            document.addEventListener("mouseup", timePinMouseUp)
        }

        return () => {
            document.removeEventListener("mousemove", timePinMouseMove)
            document.removeEventListener("mouseup", timePinMouseUp)
        }
    }, [timePinRef, audioComposerContainerRef])

    useEffect(() => {
        // Calculating the new left position based on current seek
        const newX = secondsToPixels(currentSeek, extraSettings.zoomLevel)
        if(timePinRef){
            timePinRef.current.style.left = `${newX}px`
            setCurrentTimeLabel(returnTimeLabel(pixelsToSeconds(newX, extraSettings.zoomLevel)))
        }
    }, [currentSeek])

    const timeBlocks = (maxTime) => {
        const numBlocks = maxTime/BLOCK_SIZE_IN_SECONDS + 1
        let blocks = []
        for(let i = 0;i < numBlocks;i++){
            const totalTimeInSeconds = i*BLOCK_SIZE_IN_SECONDS            
            const label = returnTimeLabel(totalTimeInSeconds)
            blocks.push({ label, width: (BLOCK_SIZE_IN_SECONDS*getConversionValue(extraSettings.zoomLevel)) })
        }
        return blocks
    }

    const timeBlocksList = useMemo(() => timeBlocks(maxTime), [maxTime, extraSettings]);

    const onMouseDownListener = (e) => {
        e.preventDefault()
        if(timePinRef.current){
            isDragging.current = true            
        }
    }

    return (
        <div ref={audioComposerContainerRef} className="audio-composer-time-tracker">
            <div onMouseDown={onMouseDownListener} ref={timePinRef} className='time-pin' draggable>
                <div className='time-pin--top'>{currentTimeLabel}</div>
            </div>
            {timeBlocksList.map((timeBlock, timeBlockIndex) => {
                return (<div className='block' key={`Time-Block-${timeBlockIndex}`} style={{width: `${timeBlock.width}px`, minWidth: `${timeBlock.width}px`}}>
                    <div className='line'></div>
                    <div className='line-small' style={{left: `${(timeBlock.width/2)-1}px`}}></div>
                    <div className='label'>{timeBlock.label}</div>
                </div>)
            })}
        </div>
    )
}

export default TimeTracker