import { API } from "aws-amplify";
import { useDispatch, useSelector } from 'react-redux';
import Bugsnag from '@bugsnag/browser';
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from './Spinner';
import { toggleEditorAddFromURLModal } from "../store/actions"
import DocumentIcon from "../assets/editor/import-url-document.svg"
import LinkIcon from "../assets/editor/import-url-link.svg"
import DownArrowWhiteIcon from '../assets/editor/down-arrow-white.svg'
import uploadIcon from '../assets/upload-alt.svg';

const classNames = require('classnames');

function ImportFromURL({ onHide, orgId, integrationId, contentId }) {
    const dispatch = useDispatch()
    const history = useHistory();

    const [importType, setImportType] = useState(0)
    const [articleURLError, setArticleURLError] = useState(null);
    const [articleURL, setArticleURL] = useState('');
    const [isConvertingArticleFromURL, setIsConvertingArticleFromURL] = useState(false);
    const [audioFile, setAudioFile] = useState(null);
    const [isReplaceContent, setIsReplaceContent] = useState(false)

    const { showEditorAddFromUrlModal } = useSelector(state => state.edit_content)


    const convertArticleFromURL = async (articleURL) => {

        if (importType === 1) {
            if (articleURL.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g) === null) {
                setArticleURLError('Please enter a valid URL');
                return;
            }
        }

        let body = {
            importType,
            isReplaceContent
        }

        if (importType === 1) {
            body['article_url'] = encodeURIComponent(articleURL)
        } else if (importType === 0) {
            body['article_url'] = articleURL
        }

        try {

            const response = await API.post(
                'main',
                `/organisation/${orgId}/integration/${integrationId}/content/${contentId}/import_url`,
                {
                    body,
                    headers: { "Content-Type": "application/json" }
                }
            );

            if (response.success) {
                history.go(0)
            } else {
                toast.error(response.data.message)
            }

        } catch (e) {

            toast.error("Something went wrong")
            Bugsnag.notify(e);
            setIsConvertingArticleFromURL(false);

        }
    }

    const importFromFile = async () => {
        try {
            if (audioFile === null) {
                toast.error('Select an audio file to upload!')
                return false
            }

            if (audioFile.size / 1024 >= 500) {
                toast.error('File size should be less than 500 KB')
                return false
            }

            const signedResponse = await API.put('main', `/organisation/${orgId}/integration/${integrationId}/temp-signed-url`, {
                body: {
                    file_name: audioFile.name,
                    content_type: audioFile.type,
                    file_name_prefix: "import"
                }
            });

            const requestOptions = {
                method: 'PUT',
                body: audioFile,
                redirect: 'follow'
            };

            const uploadURL = signedResponse.data.signed_url;
            const url = signedResponse.data.url;

            await fetch(uploadURL, requestOptions);

            return url
        } catch (e) {
            setIsConvertingArticleFromURL(false);
        }

    }

    const importInEditor = async () => {
        setIsConvertingArticleFromURL(true);
        if (importType === 0) {
            const fileURL = await importFromFile()
            if (fileURL) {
                await convertArticleFromURL(fileURL)
            }

        } else {
            await convertArticleFromURL(articleURL)
        }

        setIsConvertingArticleFromURL(false);
        dispatch(toggleEditorAddFromURLModal(false))
    }

    return (
        <Modal className="content-import-modal" show={showEditorAddFromUrlModal} onHide={() => dispatch(toggleEditorAddFromURLModal(false))}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Import Content
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="download-icons-row">
                    <div onClick={() => setImportType(0)} className={`download-card ${importType === 0 ? "is-selected" : ""}`}>
                        <div className='w-100 d-flex justify-content-center'>
                            <img alt="" src={DocumentIcon} />
                        </div>
                        <div className='w-100 text-center text'>Document</div>
                    </div>

                    <div onClick={() => setImportType(1)} className={`download-card ${importType === 1 ? "is-selected" : ""}`}>
                        <div className='w-100 d-flex justify-content-center'>
                            <img alt="" src={LinkIcon} /></div>
                        <div className='w-100 text-center text'>URL</div>
                    </div>
                </div>
                <div className='import-main'>
                    <p className="modal-subtitle">
                        {(importType === 0) && (
                            <center>Import text in .txt, .docx or .pdf formats<br></br><i>(Maximum file size up to 500Kb)</i></center>
                        )}
                        {(importType === 1) && (
                            <center>Paste the URL below to import content<br></br><i>(Text content will be imported)</i></center>
                        )}
                    </p>
                    <div className="form-group">

                        {(importType === 0) && (
                            <div style={{ height: 90 }} className="upload-audio-dropbox d-flex flex-column justify-content-center">
                                <input name="audio-file" type="file" accept=".docx,.txt,.pdf" onChange={(event) => setAudioFile(event.target.files[0])} />
                                <label htmlFor="audio-file" className="mb-0 d-flex flex-column align-items-center">
                                    <img alt="upload" width="40" src={uploadIcon} />
                                    <p className="mb-0" style={{ fontSize: 12 }}>Drag and drop or browse to choose a file</p>
                                    {
                                        audioFile && (
                                            <p className="mb-0" style={{ fontSize: 12 }}>({audioFile.name})</p>
                                        )
                                    }
                                </label>
                            </div>
                        )}

                        {(importType === 1) && (
                            <input type="text" className={classNames("form-control", { "is-invalid": articleURLError })} value={articleURL} onChange={(event) => { setArticleURLError(null); setArticleURL(event.target.value) }} />
                        )}

                        <div className="mt-2 form-check">
                            <input style={{ marginTop: 6 }} className="form-check-input" type="checkbox" onChange={e => setIsReplaceContent(e.target.checked)} />
                            <label style={{ fontSize: 12 }} className="form-check-label">Clear and replace existing content</label>
                        </div>

                        {
                            (articleURLError) &&
                            <div className="invalid-feedback">
                                {articleURLError}
                            </div>
                        }
                    </div>

                    <div className="w-100 d-flex justify-content-center">
                        <Button onClick={importInEditor} style={{ marginRight: 15 }} className="editor-download-button" variant="primary">
                            {(isConvertingArticleFromURL) ? <Spinner color="#ffffff" /> : <>
                                <img alt='' height="14" style={{ marginRight: 5 }} src={DownArrowWhiteIcon} />
                                <span style={{ fontSize: 14 }}>Import</span></>}
                        </Button>
                    </div>

                </div>

            </Modal.Body>
        </Modal>
    );
}

export default ImportFromURL;
