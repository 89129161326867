import { API, Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, Link, useHistory } from "react-router-dom";
import Bugsnag from '@bugsnag/js';

import Spinner from '../components/Spinner';
import only from '../utils/Only';

import PODCAST_CATEGORIES from '../constants/podcast_categories.json';
import cameraIcon from '../assets/camera-thin.svg';

function CreatePodcast() {

    const { org_id, id } = useParams();
    const history = useHistory();
    const { register, reset, errors, handleSubmit } = useForm();

    const [category, setCategory] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [posterFile, setPosterFile] = useState(null);
    const [posterError, setPosterError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [integration, setIntegration] = useState(null);

    const onCategoryChange = (event) => {
        setCategory(event.target.value)
    }

    const onSubmit = async (data) => {

        if (!posterFile) {
            setPosterError('Podcast image is required')
            return;
        }

        data = only(data, ['title', 'description', 'author', 'email', 'category', 'sub_category', 'is_explicit', 'is_automatic', 'is_published', 'poster_path'])

        setIsUpdating(true);

        try {

            const signedResponse = await API.get(`main`, `/podast/cover/signed-url`, {
                queryStringParameters: {
                    file_name: posterFile.name,
                    content_type: posterFile.type
                }
            });

            const requestOptions = {
                method: 'PUT',
                body: posterFile,
                redirect: 'follow'
            };

            const uploadURL = signedResponse.data.signed_url;
            const url = signedResponse.data.url;

            await fetch(uploadURL, requestOptions);

            data.poster_path = url;

            await API.post('main', `/organisation/${org_id}/integration/${id}/podcast`, { body: data, headers: { 'Content-Type': 'application/json' } });

            toast.success('New podcast show created successfully!');
            history.push(`/organisation/${org_id}/integration/${id}/podcast`);

        } catch (error) {

            toast.error("Something went wrong while creating the Podcast channel.")
            Bugsnag.notify(error);

        } finally {
            setIsUpdating(false);
        }
    };

    const PodcastPosterImagePicker = () => {
        return (
            <>
                <div className="poster-container d-flex align-items-center justify-content-center mx-auto mx-xl-0 position-relative mb-3">
                    <input type="file" id="poster" onChange={(event) => { setPosterFile(event.target.files[0]) }} />
                    <label htmlFor="poster" className="position-absolute" style={!posterFile ? {} : { top: 0 }}>
                        {
                            !posterFile
                                ? (
                                    <img width={64} alt="poster" src={cameraIcon} />
                                )
                                : (
                                    <img className="poster-image" alt="poster" src={URL.createObjectURL(posterFile)} />
                                )
                        }
                    </label>
                </div>
            </>
        );
    };

    const getIntegration = (org_id, id) => {
        return API.get(`main`, `/organisation/${org_id}/integration/${id}`);
    }

    useEffect(() => {
        reset({ is_published: 'checked' })
        Auth.currentAuthenticatedUser({
            bypassCache: true
        }).then(user => {

            reset({
                email: user.attributes.email,
                author: user.attributes.given_name,
                is_published: 'checked'
            });

        }).catch(err => {

            toast.error("Something went wrong.")
            Bugsnag.notify(err)

        })
    }, [integration, reset])

    useEffect(()=>{

        getIntegration(org_id, id).then((response)=>{
           setIntegration(response.data);
        }).finally(()=>{
            setIsLoading(false);
        });

    },[org_id, id])

    return (
        <>
            <h3 className="page-title">Create new podcast show</h3>
            <p className="page-subtitle">Start a new podcast show to grow your audience with audio.</p>
            {
                (isLoading)?
                    (
                        <div className="my-1">
                            <Spinner color="#3d4852" />
                        </div>
                    )
                    :
                    (
                        (true === integration.gates?.can_create_podcast)?
                            (
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row flex-xl-row-reverse justify-content-xl-end">
                                        <div className="col-xl-8">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <span
                                                                className="form-section-title mb-4 mb-md-0 d-block">General</span>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="form-group">
                                                                <label>Podcast Title*</label>
                                                                <input type="text" name="title"
                                                                       className={classNames("form-control", {"is-invalid": errors.title})}
                                                                       ref={register({required: true, minLength: 3})}/>
                                                                {
                                                                    errors.title &&
                                                                    <div className="invalid-feedback">
                                                                        {
                                                                            ("required" === errors.title.type) && "Podcast title field is required."
                                                                        }
                                                                        {
                                                                            ("minLength" === errors.title.type) && "Podcast Title field should be at least 3 character long."
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Podcast Description*</label>
                                                                <textarea className="form-control" name="description"
                                                                          rows="3" ref={register({required: true})}/>
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Podcast Author*</label>
                                                                <input type="text" name="author"
                                                                       className={classNames("form-control", {"is-invalid": errors.author})}
                                                                       ref={register({required: true, minLength: 3})}/>
                                                                {
                                                                    errors.author &&
                                                                    <div className="invalid-feedback">
                                                                        {
                                                                            ("required" === errors.author.type) && "Author Name field is required."
                                                                        }
                                                                        {
                                                                            ("minLength" === errors.author.type) && "Author Name field should be at least 3 character long."
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Author Email*</label>
                                                                <input type="email" name="email"
                                                                       className={classNames("form-control", {"is-invalid": errors.email})}
                                                                       ref={register({
                                                                           required: true,
                                                                           pattern: /^\S+@\S+\.\S+$/i
                                                                       })}/>
                                                                {
                                                                    errors.email &&
                                                                    <div className="invalid-feedback">
                                                                        {
                                                                            ("required" === errors.email.type) && "Author email field is required."
                                                                        }
                                                                        {
                                                                            ("pattern" === errors.email.type) && "Author email field should be a valid email address"
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Category*</label>
                                                                <select
                                                                    className={classNames("custom-select", {"is-invalid": errors.category})}
                                                                    name="category" ref={register({required: true})}
                                                                    onChange={onCategoryChange}>
                                                                    <option value=''></option>
                                                                    {
                                                                        Object.keys(PODCAST_CATEGORIES).map((category, i) => {
                                                                            return (
                                                                                <option
                                                                                    value={category}>{category}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                                {
                                                                    errors.category &&
                                                                    <div className="invalid-feedback">
                                                                        {
                                                                            ("required" === errors.category.type) && "Category field is required."
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Sub-Category</label>
                                                                <select
                                                                    className={classNames("custom-select", {"is-invalid": errors.sub_category})}
                                                                    name="sub_category" ref={register({})}
                                                                    disabled={!category || PODCAST_CATEGORIES[category].length === 0}>
                                                                    {
                                                                        !!category &&
                                                                        PODCAST_CATEGORIES[category].map((sub_category, i) => {
                                                                            return (
                                                                                <option
                                                                                    value={sub_category}>{sub_category}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Language</label>
                                                                <select className={classNames("custom-select")}
                                                                        name="lang_code" disabled value={"English"}>
                                                                    <option value="English">English</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-check my-3">
                                                                <input name="is_explicit" className="form-check-input"
                                                                       type="checkbox" ref={register()}
                                                                       onChange={() => {
                                                                       }}/>
                                                                <label>
                                                                    Contains explicit content
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card my-4 ">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-4 mb-3 mb-md-0">
                                                            <span className="form-section-title mb-0 mb-xl-2 d-block">Podcast Image*</span>
                                                            <span className="form-section-sub-title d-block">The image should be square and atleast 1400x1400 pixels in size. We support images in jpg, png, gif and tiff formats up to a maximum of 4MB in size.</span>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <PodcastPosterImagePicker/>
                                                            {
                                                                posterError && !posterFile && (
                                                                    <div className="invalid-feedback d-block">
                                                                        {posterError}
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card my-4 ">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <span
                                                                className="form-section-title mb-4 mb-md-0 d-block">Status</span>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="custom-control custom-switch">
                                                                <input type="checkbox" className="custom-control-input"
                                                                       id="is_published" name="is_published"
                                                                       ref={register()}/>
                                                                <label className="custom-control-label"
                                                                       htmlFor="is_published">Publish this
                                                                    Podcast?</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card my-4 ">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <span className="form-section-title mb-1 mb-md-0 d-block">Automatic Publishing</span>
                                                            <span className="form-section-sub-title d-block mb-4">All your audios will be automatically added (as soon as they are created) as podcast episodes and published if this is enabled. You can still remove them later if you want to.</span>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="custom-control custom-switch">
                                                                <input type="checkbox" className="custom-control-input"
                                                                       id="is_automatic" name="is_automatic"
                                                                       ref={register()}/>
                                                                <label className="custom-control-label"
                                                                       htmlFor="is_automatic">Automatically publish all
                                                                    my audios as episodes in this show.<br/>(This cannot
                                                                    be changed afterwards)</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row my-4">
                                                <div className="col-12 text-right">
                                                    <Link to={`/organisation/${org_id}/integration/${id}/podcast`}>
                                                        <button className="btn btn-outline-primary mr-3"
                                                                type="button">Cancel
                                                        </button>
                                                    </Link>
                                                    {
                                                        (isUpdating) ?
                                                            (
                                                                <button className="btn btn-primary"
                                                                        style={{width: '145px'}} type="button">
                                                                    <Spinner color="#fff"/>
                                                                </button>
                                                            )
                                                            :
                                                            (
                                                                <button className="btn btn-primary"
                                                                        style={{width: '145px'}}
                                                                        type="submit">Create</button>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )
                            :
                            (
                                <div className="text-center mt-5 bg-white rounded p-5 shadow-sm">
                                    <h5 className="font-weight-bold">Upgrade to publish podcasts</h5>
                                    <p>Upgrade your plan to publish podcasts and submit them to various other podcast directories.</p>
                                    <Link to={`/organisation/${org_id}/upgrade`} className="btn btn-primary">View Plans</Link>
                                </div>
                            )

                    )
            }
        </>
    );
}

export default CreatePodcast;
