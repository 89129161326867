import { API } from 'aws-amplify';

export const fetchLanguagesService = () => {
    return API.get(`main`, `/voices`,{headers: {"Content-Type":"application/json"}}).then((res)=>{
        return JSON.parse(res.data)
    }).catch(err => {
        return err
    })
}

export const updateAudioProfileService = ({body, org_id, integration_id, id}) => {
    const updateRoute = (id) ? `/organisation/${org_id}/integration/${integration_id}/audio_profile/${id}` : `/organisation/${org_id}/integration/${integration_id}/audio_profile`
    
    return API.put(`main`, updateRoute, {body, headers: {"Content-Type":"application/json"}})
        .then(()=>{
            
            return { id, data : body }

        }).catch((error)=>{
            
            return error

        })
}
