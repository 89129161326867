export const FETCH_ORGANISATION_REQUESTED = "FETCH_ORGANISATION_REQUESTED"

export const FETCH_ORGANISATION_SUCCESS = "FETCH_ORGANISATION_SUCCESS"

export const FETCH_ORGANISATION_FAILURE = "FETCH_ORGANISATION_FAILURE"

export const FETCH_ORGANISATION_USAGE_SUCCESS = "FETCH_ORGANISATION_USAGE_SUCCESS";

export const FETCH_ORGANISATION_USAGE_REQUESTED = "FETCH_ORGANISATION_USAGE_REQUESTED";

export const UPDATE_SIDE_BAR_PAGE_TYPE = "UPDATE_SIDE_BAR_PAGE_TYPE";