import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import orgIcon from '../assets/org.svg';
import rightArrowIcon from '../assets/arrow-right.svg';
import { API } from 'aws-amplify';
import _ from 'lodash';
import { Auth } from 'aws-amplify';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Spinner from '../components/Spinner';
import Bugsnag from "@bugsnag/js";
import { fetchLocation } from '../utils/Country'
// import { fetchIntegrationsService } from '../services/integration';
import { goToCurrentIntegration, goToUpgradePage } from '../utils/General';
const classNames = require('classnames');

function Organisation() {
    
    const history = useHistory();
    const { search } = useLocation();
	const queryString = new URLSearchParams(search)
	const plan_id = queryString.get("plan_id")

    const [organisations, setOrganisations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [userAttr, setUserAttr] = useState(null);
    const [show, setShow] = useState(false);
    const [showOrgCreationModal, setShowOrgCreationModal] = useState(false);
    const [isCreatingOrg, setIsCreatingOrg] = useState(false);
    const [orgName, setOrgName] = useState("");
    const [orgNameError, setOrgNameError] = useState(null);

    const onNewOrganisation = () => {

        setShow(true);

    }

    const onCreateOrganisation = async () => {

        if (orgName.length === 0) {
            setOrgNameError("Name is required");
            return;
        }

        setIsCreatingOrg(true);

        const country = await fetchLocation()

        const location = {
            country: country.country,
            region: country.region,
            city: country.city
        }

        API.post(`main`, `/organisation`, { body: { name: orgName, location }, headers: { "Content-Type": "application/json" } })
            .then((response) => {

                toast.success("Organisation has been created");
                history.push(`/organisation/${response.data.id}/plans`);

            }).catch((error) => {

                toast.error("Something went wrong wile creating the organisation.");
                Bugsnag.notify(error);

            }).finally(() => {

                setIsCreatingOrg(false);

            });

    }

    const Shimmer = () => {

        return (
            <React.Fragment>
                <div className="row my-5">
                    <div className="col-12">
                        <h3>Welcome {(userAttr && userAttr.hasOwnProperty("name") ? (userAttr.name) : (" to Listen2It"))}!</h3>
                        <p>&nbsp;</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="d-md-flex flex-wrap">
                            {
                                _.range(4).map(() => {
                                    return (
                                        <div className="card--integration-shimmer mr-md-3 mb-3 shine" />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )

    }

    const Organisations = () => {

        return (
            <React.Fragment>
                <div className="row my-5">
                    <div className="col-12">
                        <h3>Welcome {(userAttr && userAttr.hasOwnProperty("given_name") ? (userAttr.given_name) : (" to Listen2It"))}!</h3>
                        <p>Please choose an Organisation</p>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-12">
                        <div className="d-md-flex flex-wrap">
                            {
                                organisations.map((org) => {

                                    return (
                                        <div className="card card--integration mr-md-3 mb-3">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <img src={orgIcon} alt="org icon" className="mr-2" />
                                                    <span className="integration-name">{org.org_name}</span>
                                                </div>
                                            </div>
                                            <div className="card-footer d-flex justify-content-between align-items-center">
                                                <span className="btn--open" onClick={() => { history.push(`/organisation/${org.PK.split("#")[1]}/integration`) }}>Projects <img src={rightArrowIcon} alt="right arrow" /></span>
                                            </div>
                                        </div>
                                    );

                                })
                            }
                            <div className="card card--new-integration mb-3" onClick={onNewOrganisation}>
                                <div className="card-body text-center">
                                    <svg className="w-6 h-6" fill="none" stroke="#3D4852" style={{ marginTop: "30px" }} height="100px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <p className="font-weight-bold">New Organisation</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )

    }

    const EmptyState = () => {

        return (
            <div className="row">
                <div className="col-md-6 py-5">
                    <h3 className="mb-5">Welcome {(userAttr && userAttr.hasOwnProperty("name") ? (userAttr.name) : (" to Listen2It"))}!</h3>
                    <p>Get started by creating your first organisation.</p>
                    <p>For more information, we recommend going through our Getting Started Guide.</p>
                    <button type="button" className="btn btn-primary" onClick={() => { setShowOrgCreationModal(true) }}>Create an Organisation</button>
                </div>
            </div>
        )
    }

    useEffect(() => {

        Auth.currentAuthenticatedUser({
            bypassCache: true
        }).then(user => { setUserAttr(user.attributes); })
            .catch(err => Bugsnag.notify(err));

        if(plan_id){
            goToUpgradePage(history, plan_id)
        }else{
            goToCurrentIntegration(history)
        }

    }, []);

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="container">
                    {
                        (isLoading) ?
                            (
                                <Shimmer />
                            )
                            :
                            (
                                (organisations.length > 0)
                                    ?
                                    (
                                        <Organisations />
                                    )
                                    :
                                    (
                                        <EmptyState />
                                    )
                            )
                    }
                </div>
                <Modal show={show} onHide={() => { setShow(false) }}>
                    <Modal.Body>
                        <div className="text-center">
                            <p><strong>The free and full plan supports only one organisation.</strong></p>
                            <p>Upgrade to enterprise plan to unlock unlimited organisations.</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-outline-primary mr-3" onClick={() => setShow(false)}>Cancel</button>
                        <a href="https://www.getlisten2it.com/contact" target="_blank" rel="noopener noreferrer">
                            <button type="button" className="btn btn-primary">Contact Us</button>
                        </a>
                    </Modal.Footer>
                </Modal>
                <Modal show={showOrgCreationModal} onHide={() => { setShowOrgCreationModal(false) }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create an Organisation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="form-group">
                                <label>Give it a name</label>
                                <input type="text" className={classNames("form-control", { "is-invalid": orgNameError })} onChange={(event) => { setOrgNameError(null); setOrgName(event.target.value) }} />
                                {
                                    (orgNameError) &&
                                    <div className="invalid-feedback">
                                        {orgNameError}
                                    </div>
                                }
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-outline-primary mr-3" onClick={() => setShowOrgCreationModal(false)}>Cancel</button>
                        {
                            (isCreatingOrg) ?
                                (
                                    <button type="button" className="btn btn-primary text-center">
                                        <Spinner color="#FFF" />
                                    </button>
                                )
                                :
                                (
                                    <button type="button" className="btn btn-primary" onClick={onCreateOrganisation}>Save</button>
                                )
                        }
                    </Modal.Footer>
                </Modal>
            </div>
        </React.Fragment>
    );

}


export default Organisation;
