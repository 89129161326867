import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Accordion, Card, OverlayTrigger } from 'react-bootstrap';
import ReactHowler from 'react-howler';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import { API } from 'aws-amplify';
import { toast } from 'react-toastify';
import Bugsnag from '@bugsnag/js';

import Spinner from '../../components/Spinner';
import Badge from 'react-bootstrap/Badge'
import ShowVoicesEditor from '../../components/voice/ShowVoicesEditor'
import { PlayerButtonTooltip, SelectVoiceTooltip } from '../../components/voice/SingleVoiceLabelEditor';

import clean from '../../utils/Clean';
import only from '../../utils/Only';

// Redux 
import {
    updateVoiceSettingsFilter,
    updateShowEditorVoiceProfiles,
    updateVoiceForAllBlocks,
    toggleEditorVoicePickerModal
} from "../../store/actions"

import downChevron from '../../assets/down_chevron.svg';
import playIcon from '../../assets/voice/tile-play.svg';
import pauseIcon from '../../assets/voice/tile-pause.svg';
import { DEFAULT_LANG_CODE } from '../../constants/constants';

const classNames = require('classnames');

function VoicePickerEditor({ onVoiceChange, onProfileChange }) {

    const dispatch = useDispatch()

    const isLoading = useSelector((state) => state.integration.isLoading)
    const showEditorVoiceProfiles = useSelector(state => state.voice.showEditorVoiceProfiles)

    const [applyVoiceToAllBlocks, setApplyVoiceToAllBlocks] = useState(false);

    if (isLoading) {
        return (
            <div className="my-1">
                <Spinner color="#3d4852" />
            </div>
        )
    }

    return (
        <>
            <form className='d-flex flex-column h-100'>
                <div style={{ gap: 4 }} className='d-flex flex-column align-items-center justify-content-center mb-3'>
                    <div className="form-profile-view-switch">
                        <span className={`all-voices ${!showEditorVoiceProfiles ? 'active' : ''}`} onClick={() => dispatch(updateShowEditorVoiceProfiles(false))}>All voices</span>
                        <span className={`profiles ${showEditorVoiceProfiles ? 'active' : ''}`} onClick={() => dispatch(updateShowEditorVoiceProfiles(true))}>Saved Profiles</span>
                    </div>
                </div>

                {
                    !showEditorVoiceProfiles
                        ? <VoicesList
                            onVoiceChange={onVoiceChange}
                            applyVoiceToAllBlocks={applyVoiceToAllBlocks}
                            setApplyVoiceToAllBlocks={setApplyVoiceToAllBlocks}
                        />
                        : <AudioProfilesList
                            onProfileChange={onProfileChange}
                            applyVoiceToAllBlocks={applyVoiceToAllBlocks}
                            setApplyVoiceToAllBlocks={setApplyVoiceToAllBlocks}
                        />
                }

            </form>

        </>
    );
}

const AudioProfilesList = ({ onProfileChange, applyVoiceToAllBlocks, setApplyVoiceToAllBlocks }) => {

    const dispatch = useDispatch();

    const audioProfile = useSelector((state) => state.voice.selectedAudioProfile);
    const savedProfiles = useSelector(state => state.integration.profiles);
    const languages = useSelector((state) => state.voice.languages);

    const [selectedAudioProfile, setSelectedAudioProfile] = useState(audioProfile);

    useState(() => {
        setSelectedAudioProfile(audioProfile)
    }, [audioProfile])

    if (!savedProfiles || savedProfiles.length === 0) {
        return (
            <div className="col-md-12 mb-4">
                <span className="form-section-title">
                    <h6>No saved audio profiles found</h6>
                </span>
            </div>
        )
    }

    return (
        <>
            <div className="card editor-voice-picker-card flex-grow-1">
                <div className='card-body'>
                    <div className='row'>
                        <div style={{ rowGap: 16 }} className='col-12 row'>
                            {
                                savedProfiles.map((profile, profileIndex) => {

                                    const language = languages[profile.lang_code];
                                    const voice = languages[profile.lang_code].voices.find(item => item.code === profile.voice && item.engine === profile.engine)

                                    return (
                                        <Accordion key={`AC-${profileIndex}`} className="col-12 col-md-6 col-xl-4">
                                            <Card className="voice-tile-editor" style={{
                                                'border': (
                                                    (selectedAudioProfile) && (selectedAudioProfile.id === profile.id))
                                                    ? '2px solid rgb(116 129 144)'
                                                    : null
                                            }}>
                                                <Card.Header>
                                                    <ProfileLabel
                                                        onSelect={() => {
                                                            setSelectedAudioProfile(profile)
                                                        }}
                                                        eventKey={`PL-${profileIndex}`}
                                                        profile={profile}
                                                        isSelected={selectedAudioProfile && profile.profile_name === selectedAudioProfile.profile_name}
                                                    />
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={`PL-${profileIndex}`} >
                                                    <Card.Body className="audio-profile-card-details">
                                                        <div className='row'>
                                                            <div className='col-6 d-flex flex-column'>
                                                                <label>Language</label>
                                                                <span>{language.name}</span>
                                                            </div>
                                                            <div className='col-6 d-flex flex-column'>
                                                                <label>Voice</label>
                                                                <span className="voice">{voice?.name}</span>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    )
                                })
                            }
                        </div>
                        <div style={{ fontSize: 14 }} className='mt-4 ml-3'>
                            Profiles can be customized from the Voice Profiles section
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-end align-items-center my-3'>
                <div className='d-flex align-items-center mr-2'>
                    <input className='mr-2' type="checkbox" name="all-blocks" checked={applyVoiceToAllBlocks} onChange={(e) => {
                        setApplyVoiceToAllBlocks(e.target.checked);
                    }} />
                    <span style={{ fontSize: 13 }}>Apply to all blocks</span>
                </div>
                <button style={{ height: 36, whiteSpace: 'nowrap' }} onClick={(e) => {
                    e.preventDefault();
                    onProfileChange(selectedAudioProfile)
                    if (applyVoiceToAllBlocks) {
                        dispatch(updateVoiceForAllBlocks({ ...selectedAudioProfile, isAudioProfile: true }))
                    }
                    setTimeout(() => dispatch(toggleEditorVoicePickerModal(false)));
                }} className='btn btn-primary'>
                    Choose Profile
                </button>
            </div>
        </>
    )
}

export const VoicesList = ({ onVoiceChange, applyVoiceToAllBlocks, setApplyVoiceToAllBlocks }) => {

    const dispatch = useDispatch();

    const languages = useSelector((state) => state.voice.languages)
    const gender = useSelector((state) => state.voice.filters.gender)
    const language = useSelector((state) => state.voice.filters.language)
    const quality = useSelector((state) => state.voice.filters.quality)
    const style = useSelector((state) => state.voice.filters.style)
    const voice = useSelector((state) => state.voice.voice)

    const [selectedVoice, setSelectedVoice] = useState(voice);
    const [selectedLanguage, setSelectedLanguage] = useState(language ? language : DEFAULT_LANG_CODE);

    const getFilteredVoices = (input) => {
        console.log("voicesToShow", {gender, quality, style})
        if (gender && gender !== "all") {
            input = input.filter(voice => voice.gender === gender);
        }
        if (quality && quality !== "all") {
            input = input.filter(voice => voice.engine === quality);
        }
        if (style) {
            input = input.filter(voice => voice.styles.length > 0)
        }
        return input;
    }

    const voices = getFilteredVoices(languages[selectedLanguage].voices);

    console.log("voicesToShow", selectedLanguage, voices)

    // useState(() => {
    //     setSelectedVoice(voice)
    // }, [voice])

    // useState(() => {
    //     setSelectedLanguage(language)
    // }, [language])

    return (
        <>
            <div
                style={{
                    border: '1px solid #70707073',
                }}
                className="card editor-voice-picker-card flex-grow-1"
            >
                <div className="card-body p-0">
                    <div style={{ minHeight: "100%", height: "100%" }} className="row mx-0">

                        <div className="col-md-3 filter-box">
                            <div className="form-group">
                                <div style={{ height: 42 }} className='d-flex flex-row justify-content-between align-items-center'>
                                    <span className="filter-heading"><b>Language</b></span>
                                </div>
                                <select value={selectedLanguage} className={classNames("custom-select")} name="lang_code" onChange={(e) => {
                                    dispatch(updateVoiceSettingsFilter({ key: "language", value: e.target.value }))
                                    setSelectedLanguage(e.target.value)
                                }} >
                                    {
                                        Object.keys(languages).map((lang) => {

                                            return (
                                                <option value={languages[lang].code} key={`step-one-lang-${languages[lang].code}`}>{languages[lang].name}</option>
                                            )

                                        })
                                    }
                                </select>
                            </div>

                            <div className="form-group mt-2">
                                <div className='d-flex flex-row justify-content-between align-items-center'>
                                    <span className="filter-heading"><b>Filters</b></span>
                                    <span onClick={() => {

                                        dispatch(updateVoiceSettingsFilter({ key: 'gender', value: 'all' }));
                                        dispatch(updateVoiceSettingsFilter({ key: 'quality', value: 'all' }));
                                        dispatch(updateVoiceSettingsFilter({ key: 'style', value: false }));

                                    }} style={{ fontSize: 14, lineHeight: 2 }} className='btn btn-link'>
                                        Reset
                                    </span>
                                </div>
                                <select onChange={(e) => dispatch(updateVoiceSettingsFilter({ key: "gender", value: e.target.value }))} value={gender ? gender : undefined} className={classNames("custom-select")} name="gender" >
                                    <option value="all">All</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <select onChange={(e) => dispatch(updateVoiceSettingsFilter({ key: "quality", value: e.target.value }))} value={quality ? quality : undefined} className={classNames("custom-select")} name="quality" >
                                    <option value="all">All</option>
                                    <option value="neural">Premium</option>
                                    <option value="standard">Standard</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <input
                                    type="checkbox"
                                    onChange={
                                        (e) => dispatch(updateVoiceSettingsFilter({ key: "style", value: e.target.checked }))
                                    }
                                    value={style ? style : undefined}
                                    name="style"
                                />
                                <span style={{ color: "#333B6C", fontSize: 14, marginLeft: 4 }}>Voice Styles</span>
                            </div>
                        </div>

                        <div className="col language-box">

                            <p><b>{languages[language] ? languages[language].name : languages[DEFAULT_LANG_CODE].name} Voices</b></p>
                            <ShowVoicesEditor
                                onVoiceChange={(newVoice) => {
                                    setSelectedVoice(newVoice)
                                }}
                                selectedVoice={selectedVoice}
                                voices={voices}
                            />

                        </div>

                    </div>
                </div>
            </div>
            <div className="my-3 d-flex flex-row justify-content-between">
                <div className="d-flex flex-wrap align-items-center">
                    <div className='mr-3'>
                        <Badge variant="warning">Premium</Badge> <span style={{ fontSize: 12 }}>Highly realistic AI Voices</span>
                    </div>
                    <div>
                        <Badge variant="primary">Styles</Badge> <span style={{ fontSize: 12 }}>Voices with emotion based speaking styles</span>
                    </div>
                </div>
                <div className='d-flex justify-content-end align-items-center'>
                    <div className='d-flex align-items-center mr-2'>
                        <input className='mr-2' type="checkbox" name="all-blocks" checked={applyVoiceToAllBlocks} onChange={(e) => {
                            setApplyVoiceToAllBlocks(e.target.checked);
                        }} />
                        <span style={{ fontSize: 13 }}>Apply to all blocks</span>
                    </div>
                    <button style={{ height: 36, whiteSpace: 'nowrap' }} onClick={(e) => {

                        e.preventDefault();

                        if (!selectedVoice) {
                            return;
                        }

                        onVoiceChange(selectedVoice);
                        if (applyVoiceToAllBlocks) {
                            dispatch(updateVoiceForAllBlocks(selectedVoice))
                        }

                        setTimeout(() => dispatch(toggleEditorVoicePickerModal(false)));
                    }} className='btn btn-primary'>
                        Choose Voice
                    </button>
                </div>
            </div>
        </>
    )
}

function ProfileLabel({ eventKey, profile, isSelected, onSelect }) {

    const decoratedOnClick = useAccordionToggle(eventKey);

    return (
        <div className="row">
            <div style={{ display: "flex", "justifyContent": "space-between" }} className='col-12 form-check d-flex align-items-center'>
                <div className='d-flex align-items-center'>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 50, hide: 400 }}
                        overlay={SelectVoiceTooltip}
                    >
                        <input style={{ width: 20, height: 20, cursor: 'pointer' }} type='checkbox' value={!!isSelected} checked={isSelected} onChange={onSelect} />
                    </OverlayTrigger>
                    <span className='mx-2 label-voice d-flex'>{`${profile.profile_name}`}</span>
                </div>
                <div className='d-flex align-items-center'>
                    <PlayerIconButton profile={profile} />
                    <label onClick={decoratedOnClick} style={{ cursor: 'pointer' }} className='form-check-label'>
                        <span><img style={{ height: "7px" }} src={downChevron} alt="expand icon" /></span>
                    </label>
                </div>
            </div>
        </div>
    )
}


const PlayerIconButton = ({ profile }) => {

    const [audio, setAudio] = useState(null);
    const [playing, setPlaying] = useState(false);

    const onPlayPreview = async () => {

        if (playing) {
            setPlaying(false)
            return;
        }

        try {

            const audioURL = (await previewTTS(profile)).data.audio_url;
            setAudio(audioURL);
            setPlaying(true)

        } catch (e) {
            Bugsnag.notify(e);
            console.log(e);
            toast.error("Something went wrong playing the Profile's preview")
        }
    }

    return (
        <>
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 50, hide: 400 }}
                overlay={PlayerButtonTooltip}
            >
                {
                    (audio)
                        ? <span className="play-icon">
                            <img src={pauseIcon} alt="pause icon" />
                        </span>
                        : <span className="play-icon">
                            <img src={playIcon} alt="play icon" onClick={onPlayPreview} />
                        </span>
                }
            </OverlayTrigger>
            {
                (audio) &&
                <ReactHowler src={audio} playing={playing} onEnd={() => {
                    setAudio(null);
                    setPlaying(false);
                }} />
            }
        </>
    )
}

const previewTTS = (profile) => {

    const SAMPLE = [
        {
            type: "paragraph",
            children: [
                {
                    text: "If you set your goals ridiculously high and it's a failure, you will fail above everyone else's success."
                }
            ]
        }
    ]

    return API.post(
        'main',
        `/audio/preview`,
        {
            body: {
                slate_value: SAMPLE,
                ...clean(only({
                    ...profile,
                    ...(profile.volume && { volume: parseFloat(profile.volume) }),
                    ...(profile.speed && { speed: parseInt(profile.speed) })
                }, ['lang_code', 'platform', 'engine', 'voice', 'volume', 'speed']))
            },
            headers: { 'Content-Type': 'application/json' }
        });

}

export default VoicePickerEditor;
