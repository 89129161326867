import React, { useCallback, useEffect, useState } from "react";
import {Crisp} from "crisp-sdk-web"
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import Info from '../../components/Info';
import Spinner from "../../components/Spinner";
import { fetchCountry } from "../../utils/Country";
import Bugsnag from "@bugsnag/js";
import PLANS from "../../constants/plans.json";
const classNames = require("classnames");

const plans = PLANS.filter(item => item.is_active).slice(1);

export default function Plans() {

	const { search } = useLocation();
	const queryString = new URLSearchParams(search)
	const plan_id = queryString.get("plan_id")
	const { org_id } = useParams();
	const [org, setOrg] = useState(null);
	const [isAnnualBillingCycle, setIsAnnualBillingCycle] = useState(false);

	const [isLoading, setIsLoading] = useState(true);
	const [country, setCountry] = useState(null);

	const getOrganisation = (orgId) => {
		return API.get("main", `/organisation/${orgId}`);
	};

	const openCheckout = useCallback(async (plan_id) => {

		const url = window.location.origin + `/organisation/${org_id}/integration`;
		const response = await API.post(
			`main`,
			`/organisation/${org_id}/generate_checkout_hp_url`,
			{
				body: { plan_id: plan_id, redirectURL: url, cancelURL: url },
				headers: { "Content-Type": "application/json" },
			}
		);

		window.open(response.data.hosted_page.url, "_self")

	}, [org_id])

	const onSubscribe = useCallback((plan, country) => {

		if ("India" === country) {
			openCheckout(isAnnualBillingCycle ? plan.plan_id_inr_annual : plan.plan_id_inr);
		} else {
			openCheckout(isAnnualBillingCycle ? plan.plan_id_usd_annual : plan.plan_id_usd);
		}
	}, [isAnnualBillingCycle, openCheckout]);

	const autoOpenCheckout = useCallback(async () => {

		setIsLoading(true);

		try {

			const country = await fetchCountry();
			const org = (await getOrganisation(org_id)).data;

			setCountry(country);
			setOrg(org);

			if (country && plan_id) {
				const plan = plans.find(
					row => (row.plan_id_usd === plan_id) || (row.plan_id_inr === plan_id)
				)
				if (plan) {
					setTimeout(() => {
						onSubscribe(plan, country)
					}, 0)
				}
			}

		} catch (err) {

			console.log("checkouterror", err)
			toast.error("Something went wrong!");
			Bugsnag.notify(err);

		} finally {
			setIsLoading(false);
		}

	}, [org_id, plan_id, onSubscribe])

	useEffect(() => {
		autoOpenCheckout()
	}, [autoOpenCheckout]);

	return (
		<>
			<div className="container-fluid">
				<div className="container">
					<div className="row my-5">
						<div className="col-12">
							<h3 className="text-center">Choose plan</h3>
						</div>
						<div className="mt-2 col-12 row justify-content-center billing-cycle-switch">
							<h6 className="">Billing cycle: </h6>
							<span className={!isAnnualBillingCycle ? 'selected-billing-cycle' : ''}>Monthly</span>
							<div className="custom-control custom-switch ml-2">
								<input onChange={(e) => {
									setIsAnnualBillingCycle(e.target.checked)
								}} type="checkbox" className="custom-control-input" id="is_published" name="is_published" />
								<label className={`custom-control-label ${isAnnualBillingCycle && 'selected-billing-cycle'}`} htmlFor="is_published">Annual (Pay one month less)</label>
							</div>
						</div>
					</div>
					{isLoading ? (
						<div className="my-1 text-center">
							<Spinner color="#3d4852" />
						</div>
					) : (
						<>
							<div className="row mt-5">
								{plans.map((plan) => {
									return (
										<div className="col-12 col-lg-8 col-xl-3 m-auto px-3" key={plan.plan_id_inr}>
											<div className={classNames("card", "card-pricing-summary")}>
												<div className="card-body">
													<p className="pricing-summary-title">
														{plan.plan_title}
														{org &&
															(org.plan_id === plan.plan_id_inr ||
																org.plan_id === plan.plan_id_usd) ? (
															<span
																className={classNames("is-current-plan-label")}
															>
																(Current Plan)
															</span>
														) : null}
													</p>
													<p className="pricing-summary-sub-title">
														{plan.plan_sub_title}
													</p>
													<div className="pricing-summary-price">
														<span>${isAnnualBillingCycle ? plan.annual_plan_monthly_price : plan.price}</span>
														<span>/month</span>
													</div>
													{isAnnualBillingCycle ? (
														<div className="pricing-summary-annual-text">
															<span>$ {plan.annual_price} Billed Annually</span>
														</div>
													) : (
														<div className="pricing-summary-annual-text">
															<span>Billed Monthly</span>
														</div>
													)}

													<div
														onClick={() => {
															onSubscribe(plan, country);
														}}
														className="d-grid gap-2"
													>
														<span
															className={`btn btn-outline-primary btn-block pricing-summary-cta-select-plan`}
														>
															<span>Choose Plan</span>
														</span>


													</div>
													<ul className="pricing-summary-points">
														<li>{plan.words_count.toLocaleString()} New Words / Month</li>
													</ul>
													<hr className="pricing-points-divider" />
													<p className="fw-bold mb-3">Key Features</p>
													<ul className="pricing-summary-points">
														{plan.features.map((feature, featureIndex) => (
															<li key={`${plan.plan_id_inr}-feature-${featureIndex}`}>{feature.text} {feature.help_text ? <Info text={feature.help_text} /> : null}</li>
														))}
													</ul>
												</div>
											</div>
										</div>
									);
								})}
							</div>
							<div className="row my-5 align-items-center justify-content-center">
								<div className="col-md-9 text-center">
									<span style={{ fontSize: "24px", color: "#3D4852" }}>
										Do not fit into any of the plans? Chat with us
										<br /> and get an{" "}
										<span style={{ color: "#2D79F6" }}>Enterprise Plan</span>
									</span>
								</div>
								<div className="col-md-3">
									<button
										type="button"
										onClick={(e) => {
											Crisp.chat.open()
										}}
										className="btn btn-primary btn-block mt-3 mt-sm-0"
									>
										Talk to us
									</button>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}
