import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from "react-router-dom";
import helpIcon from '../../assets/editor/help.svg';

function EditorHelpCenter({ setShowStarterCardModal }) {
    
    const CustomToggle = React.forwardRef(({ onClick }, ref) => (
        <img alt="help" ref={ref} onClick={(e) => { e.preventDefault(); onClick(e);}} src={helpIcon} />
    ));

    return(
        <Dropdown className="mr-2">
            <Dropdown.Toggle as={CustomToggle} />
            <Dropdown.Menu>
            	<Dropdown.Header>Help Center</Dropdown.Header>
                {
                    /** 
                     * @TODO
                     * replace with correct YouTube video link
                    */
                }
                <Dropdown.Item as="div" onClick={() => setShowStarterCardModal(true)}>Get Started</Dropdown.Item>
                <Dropdown.Item as="div"><Link to={{ pathname : "https://support.getlisten2it.com/portal/en/kb" }} target="_blank">Knowledge Base</Link></Dropdown.Item>
            	
            </Dropdown.Menu>
        </Dropdown>
    )

}

export default EditorHelpCenter;
