import React, {useEffect, useRef, useState} from "react"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'
import { useOutsideClick } from "../../hooks/useOutsideClick";

export default function ({ helpText = "", text, size = 16, placement = "right" }) {
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    useOutsideClick(ref, () => setShow(false))
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {helpText}
        </Tooltip>
    );

    useEffect(() => {
        let timeout
        if (show) {
            timeout = setTimeout(() => {setShow(false)}, 3500)
        }
        return () => clearTimeout(timeout)
    }, [show])

    return (
        <>
            {
                (helpText && helpText.length) ? (
                    <OverlayTrigger
                        show={show}
                        placement={placement}
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                        <span ref={ref} onClick={() => setShow(!show)} className="help-text">{text}</span>
                    </OverlayTrigger>
                ) : (
                    <span>{text}</span>
                )
            }
        </>
    )

}