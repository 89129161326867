import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ExtraAudioUploadModal from './ExtraAudioUploadModal';
import { API } from "aws-amplify";
import Bugsnag from '@bugsnag/browser';
import { toast } from 'react-toastify';
import Spinner from '../Spinner';
import Info from '../Info';
import PlayerPreviewIcon from '../../assets/play.svg';
import EditIcon from '../../assets/edit-icon.svg';
import UploadIcon from '../../assets/upload.svg';
import DeleteIcon from '../../assets/trash.svg';

function ExtraAudiosUpload({ orgId, integrationId, integration, onPlay }) {
    const [showUploadAudio, setShowUploadAudio] = useState();
    const [selectedAudio, setSelectedAudio] = useState(null);
    const [audioKey, setAudioKey] = useState(null);
    const [isAudioDeleting, setIsAudioDeleting] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false)

    const onDeleteAudio = async () => {
        setIsAudioDeleting(true)
        try {

            await API.put('main', `/organisation/${orgId}/integration/${integrationId}/extraaudio?key=${audioKey}`, {
                headers: { "Content-Type": "application/json" }
            })

        } catch (e) {

            toast.error("Something went wrong while deleting audio.")
            Bugsnag.notify(e);

        } finally {
            setIsAudioDeleting(false)
            setShowConfirmation(false)
            window.location = window.location.href
        }
    }

    return (
        <div className="card mt-4">
            <div className="card-body">
                <div className="form-group">
                    <label>Pre Audio <Info text="Pre Audio is played before the actual content" /></label>
                    <div className="float-right">
                        {integration.pre_audio_url ?
                            <button type="button" className="btn btn-sm small-button mr-1"
                                title="Play Audio"
                                onClick={() => {
                                    onPlay(integration.pre_audio_url);
                                }}><img style={{ height: "20px" }} src={PlayerPreviewIcon} /></button> : null}

                        <button type="button" className="btn btn-sm small-button mx-1"
                            title={integration.pre_audio_url ? "Edit your uploaded audio" : "Upload a new audio"}
                            onClick={() => {
                                setShowUploadAudio(true);
                                setSelectedAudio(integration.pre_audio_url);
                                setAudioKey("pre_audio_url")
                            }}>
                            <img style={{ height: "20px" }} src={integration.pre_audio_url ? EditIcon : UploadIcon} />
                        </button>

                        {
                            integration.pre_audio_url ?
                                <button title="Delete an already uploaded audio" type="button" className="btn btn-sm small-button text-center mx-1" onClick={() => {
                                    setAudioKey("pre_audio_url"); setShowConfirmation(true);
                                }}>
                                    <img style={{ height: "20px" }} src={DeleteIcon} />
                                </button> : null
                        }

                    </div>
                </div>
                <div className="form-group">
                    <label>Post Audio <Info text="Post Audio is played after the actual content" /></label>

                    <div className="float-right">
                        {integration.post_audio_url ?
                            <button type="button" className="btn btn-sm small-button mr-1"
                                title="Play Audio"
                                onClick={() => {
                                    onPlay(integration.post_audio_url);
                                }}><img style={{ height: "20px" }} src={PlayerPreviewIcon} /></button> : null}

                        <button type="button" className="btn btn-sm small-button mx-1"
                            title={integration.post_audio_url ? "Edit your uploaded audio" : "Upload a new audio"}
                            onClick={() => {
                                setShowUploadAudio(true);
                                setSelectedAudio(integration.post_audio_url);
                                setAudioKey("post_audio_url")
                            }}>
                            <img style={{ height: "20px" }} src={integration.post_audio_url ? EditIcon : UploadIcon} />
                        </button>

                        {
                            integration.post_audio_url ?
                                <button title="Delete an already uploaded audio" type="button" className="btn btn-sm small-button text-center mx-1" onClick={() => {
                                    setAudioKey("post_audio_url"); setShowConfirmation(true);
                                }}>
                                    <img style={{ height: "20px" }} src={DeleteIcon} />
                                </button> : null
                        }


                    </div>
                </div>
                <div className="form-group">
                    <label>Background Audio <Info text="Background Audio is played with the actual content in background" /></label>
                    <div className="float-right">
                        {integration.background_audio_url ?
                            <button type="button" className="btn btn-sm small-button mr-1"
                                title="Play Audio"
                                onClick={() => {
                                    onPlay(integration.background_audio_url);
                                }}><img style={{ height: "20px" }} src={PlayerPreviewIcon} /></button> : null}

                        <button type="button" className="btn btn-sm small-button mx-1"
                            title={integration.background_audio_url ? "Edit your uploaded audio" : "Upload a new audio"}
                            onClick={() => {
                                setShowUploadAudio(true);
                                setSelectedAudio(integration.background_audio_url);
                                setAudioKey("background_audio_url")
                            }}>
                            <img style={{ height: "20px" }} src={integration.background_audio_url ? EditIcon : UploadIcon} />
                        </button>

                        {
                            integration.background_audio_url ?
                                <button title="Delete an already uploaded audio" type="button" className="btn btn-sm small-button text-center mx-1" onClick={() => {
                                    setAudioKey("background_audio_url");
                                    setShowConfirmation(true);
                                }}>
                                    <img style={{ height: "20px" }} src={DeleteIcon} />
                                </button> : null
                        }

                    </div>
                </div>
            </div>
            <ExtraAudioUploadModal
                orgId={orgId}
                integrationId={integrationId}
                audioKey={audioKey}
                show={showUploadAudio}
                selectedAudio={selectedAudio}
                onSuccess={() => {
                    setSelectedAudio(null);
                    window.location = window.location.href
                }} onHide={() => {
                    setShowUploadAudio(false);
                    setSelectedAudio(null)
                }}
            />

            <Modal show={showConfirmation} onHide={() => { setShowConfirmation(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Audio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure want to delete this audio?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline-primary mr-3" onClick={() => setShowConfirmation(false)}>Cancel</button>
                    {
                        (isAudioDeleting) ?
                            (
                                <button type="button" className="btn btn-danger text-center">
                                    <Spinner color="#FFF" />
                                </button>
                            )
                            :
                            (
                                <button type="button" className="btn btn-danger" onClick={() => onDeleteAudio()}>Delete</button>
                            )
                    }
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ExtraAudiosUpload;
