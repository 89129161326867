import React from 'react';
import ReactHowler from 'react-howler';
import { usePreGenAudioPlayer } from '../zustand-store/usePreGenAudioPlayerStore';

export const PreGenAudioPlayer = () => {

    const { src, isPlaying, stop } = usePreGenAudioPlayer((state) => state)

    if (src === null) {
        return <></>
    }

    return (
        <ReactHowler
            src={src}
            playing={isPlaying}
            onEnd={() => {
                stop()
            }}
        />
    )
}
