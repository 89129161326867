import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import { PREVIEW_PLANS } from '../constants/constants';
import { TOPBAR_VIEW_PLANS } from '../constants/hotjar-events';

function TopBanner({ inline }) {

    const { org_id } = useParams();
    const history = useHistory();
    const [org, setOrg] = useState(null);

    const getOrganisation = (orgId) => {
        return API.get('main', `/organisation/${orgId}`);
    }

    useEffect(() => {

        if (undefined === org_id) {
            return;
        }

        getOrganisation(org_id).then(response => {

            let org = response.data;

            org.words_usage_current_month_in_per = Math.round((org.current_month_words_usage / org.words_per_month) * 100);

            setOrg(org)
        });

    }, [org_id]);

    const Nudge = ({ message, dismissable }) => {

        const [dismissed, setDismissed] = useState(false);

        const closeTopBanner = () => {
            setDismissed(true)
        }

        return (
            <div className={`position-relative  pt-5 pt-md-1 pb-2 pb-md-1 px-2 justify-content-center my-2 ${dismissed ? 'd-none' : 'd-flex'}`} style={{ backgroundColor: '#115CD9', color: '#FFF', borderRadius: (inline) ? '3px' : '0px' }}>
                <span>{message}</span>
                {/* <span 
                    className="ml-3 text-decoration-none" 
                    style={{color:'#F6BD41', borderBottom: 'dotted 2px #F6BD41', cursor: 'pointer'}} 
                    onClick={() => {
                        window.hj("event", TOPBAR_VIEW_PLANS);
                        history.push(`/organisation/${org_id}/upgrade`)
                    }}
                >
                    View Plans
                </span> */}
                {
                    dismissable && (
                        <svg onClick={closeTopBanner} role="button" className='position-absolute' style={{ right: '10px', top: '10px' }} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L13 13M1 13L13 1L1 13Z" stroke="#FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    )
                }

            </div>
        )

    }

    // return <Nudge message="Some of our voices are facing issues. Choose a different voice if you cannot generate audio." dismissable />

    if (null === org) {
        return <></>
    }

    // if ([...PREVIEW_PLANS].includes(org.plan_id))
    //     return (
    //         <Nudge message="Upgrade your plan to unlock all features" dismissable  />
    //     )

    if (org.words_usage_current_month_in_per >= 100) {
        return (
            <Nudge message="You have reached your audio limit. To continue using Listen2It seamlessly, please upgrade your account." />
        )
    }

    if (org.words_usage_current_month_in_per >= 85) {
        return <Nudge message="You have reached 85% of your audio limit. To continue using Listen2It, please upgrade your account." dismissable={true} />
    }

    // if(["free-plan", "free-plan-inr"].includes(org.plan_id)){
    //     return (
    //         <Nudge message="Get a 14 day free trial with our paid plans. Cancel Anytime." dismissable={true} />
    //     )
    // }

    return (
        <></>
    )

}

export default TopBanner;
