import { API } from 'aws-amplify';
import { toast } from 'react-toastify';

export const fetchIntegrationsService = (org_id) => {
    return API.get(`main`, `/organisation/${org_id}/integration`).then(res => {
        return res.data
    }).catch(err => {
        return err
    })
}

export const fetchIntegrationService = ({ org_id, id }) => {
    return API.get(`main`, `/organisation/${org_id}/integration/${id}`).then(res => {
        return res.data
    }).catch(err => {
        return err
    })
}

export const fetchIntegrationProfilesService = ({ org_id, id }) => {
    return API.get(`main`, `/organisation/${org_id}/integration/${id}/audio_profile`).then(res => {
        return res.data
    }).catch(err => {
        return err
    })
}

export const deleteAudioProfileService = ({ org_id, integration_id, id }) => {
    API.del(`main`, `/organisation/${org_id}/integration/${integration_id}/audio_profile/${id}`, { headers: { "Content-Type": "application/json" } })
        .then((res) => {
            return res.data
        }).catch((error) => {
            return error
        })
}

export const createIntegrationService = ({ org_id, body }) => {
    return new Promise((resolve, reject) => {
        API.post(`main`, `/organisation/${org_id}/integration/default`, {
            body,
            headers: { "Content-Type": "application/json" }
        })
        .then((res) => {
            if(res.success){
                toast.success("Project created successfully")
            }
            return resolve(res)
        }).catch((error) => {
            const msg = error?.response?.data?.message || "Something went wrong"
            toast.error(msg)
            return reject(error)
        })
    })
}
