import Bugsnag from "@bugsnag/js";
import { call, put, takeEvery, select, debounce, delay } from "redux-saga/effects";
import { putContentService } from "../../services/audio_composer";
import { showSavingInDraftLabel, updateCurrentAudioComposerBlock, addAudioComposerAudioBlock, initializeAudioComposerSuccess, initializeCurrentAudioSuccess, updateComposerTTSBlocks, updateAudioComposerSections, toggleAudioComposerBlockActions, updateSelectedAudioProfile, updateShowEditorVoiceProfiles, updateVoice, updateVoices, updateLanguage, updateVoiceSettingsFilter } from "../actions";
import { APPLY_EDITOR_TITLE_SETTINGS, EXIT_EDITOR, PUT_BLOCK_AUDIO, UPDATE_AUDIO_COMPOSER_BLOCK, UPDATE_BLOCK_AUDIOS, UPDATE_SLATE_VALUE, ADD_AUDIO_COMPOSER_BLOCK, UPDATE_TITLE, UPDATE_PRONUNCIATION_LIBRARY, UPDATE_AUDIO_COMPOSER_BLOCK_MOVE_LEFT, UPDATE_AUDIO_COMPOSER_BLOCK_MOVE_RIGHT, INITIALIZE_AUDIO_COMPOSER_REQUESTED, GENERATE_COMPOSER_SETTINGS, TOGGLE_INCLUDE_TITLE, UPDATE_VOICE_FOR_ALL_BLOCKS, UPDATE_CURRENT_AUDIO_COMPOSER_BLOCK } from "../actionTypes";
import { defaultBlockValue, defaultSectionsValue, defaultSlateBlockValue } from "../../components/composer/utils";
import hash from 'object-hash';
import { Transforms } from "slate";
import { getBlockDuration, getBlockLevelPronunciationLibrary, getBlockText } from "../../utils/SlateUtils/index.js";

function* saveDraftSaga(action) {
    try {
        const sections = yield select(state => state.audio_composer.sections);
        const slate_value = yield select(state => state.audio_composer.slate_value);
        const isContentChanged = yield select(state => state.audio_composer.isContentChanged);
        const title = yield select(state => state.audio_composer.title);
        const content = yield select(state => state.edit_content.content);
        const integration = yield select(state => state.integration.integration)
        const organisation = yield select(state => state.organisation.organisation);

        if (!organisation || !integration || !organisation.SK || !integration.SK) {
            return;
        }

        const orgId = organisation.SK.split('#')[1];
        const integrationId = integration.SK.split('#')[1];



        yield put(showSavingInDraftLabel(true));

        if (!content) {

            return;

        }
        
        yield call(putContentService, {
            orgId,
            integrationId,
            contentId: content.SK.split('#')[1],
            body: {
                title,
                slate_value,
                composer_settings : sections,
                is_draft: true,
                isContentChanged
            }
        });

        yield put(showSavingInDraftLabel(false));
    } catch (e) {
        console.log(e)
        Bugsnag.notify(e);

    }

}

function* exitEditorSaga(action) {

    const history = action.payload;
    yield call(saveDraftSaga);
    yield delay(500);

    const integration = yield select(state => state.integration.integration)
    const organisation = yield select(state => state.organisation.organisation);

    if (!organisation || !integration) {
        return;
    }

    const orgId = organisation.SK.split('#')[1];
    const integrationId = integration.SK.split('#')[1];

    history.push(`/organisation/${orgId}/integration/${integrationId}/content`);
}

function* buildComposerBlocks(action) {

    // yield put(toggleCurrentAudioPlaying(false));

    const pronunciation_library = yield select(state => state.integration.pronunciationLibrary);
    const slate_value = yield select(state => state.audio_composer.slate_value);
    const block_audios = yield select(state => state.audio_composer.block_audios);
    let composer_settings = yield select(state => state.audio_composer.sections);

    if (!slate_value || slate_value.length === 0) {
        return;
    }
    
    if (action.type === GENERATE_COMPOSER_SETTINGS) {

        composer_settings = defaultSectionsValue()

        const ttsBlocks = slate_value.map((block, blockIndex) => {
            const blockLevelPronunciationLibrary = getBlockLevelPronunciationLibrary(block, pronunciation_library)
            const blockHash = hash({
                ...block,
                pronunciation_library: blockLevelPronunciationLibrary
            })
    
            const blockAudio = block_audios[blockHash];
    
            const blockDuration = blockAudio ? blockAudio.duration : getBlockDuration(block)
        
            const ttsBlock = {
                ...defaultBlockValue(),
                ends : blockDuration, // In seconds relative to 0s
                trimRight: 0,
                url : blockAudio ? blockAudio.audio_url : null,
                duration: blockDuration, // Original duration of audio
                title: getBlockText(block)
            }
            
            return ttsBlock;
        })
    
        // yield put(updateComposerTTSBlocks(ttsBlocks));

        composer_settings[0].tracks[0].blocks = ttsBlocks;

        yield put(updateAudioComposerSections(composer_settings));

        return;
    }

    if (!composer_settings) {
        return;
    }

    if (composer_settings[0].tracks[0].blocks.length === 0) {
        return;
    }

    const ttsBlocks = slate_value.map((block, blockIndex) => {
        const blockLevelPronunciationLibrary = getBlockLevelPronunciationLibrary(block, pronunciation_library)
        const blockHash = hash({
            ...block,
            pronunciation_library: blockLevelPronunciationLibrary
        })

        const blockAudio = block_audios ? block_audios[blockHash] : null;

        const blockDuration = blockAudio ? blockAudio.duration : getBlockDuration(block)

        const isBlockAvailable = !!(composer_settings && composer_settings[0] && composer_settings[0].tracks[0] && composer_settings[0].tracks[0].blocks[blockIndex])

        const defaultBlockVal = isBlockAvailable ? (composer_settings[0].tracks[0].blocks[blockIndex]) : defaultBlockValue()

        let blockEnds, blockTrimRight;

        if (blockAudio && !defaultBlockVal.url) { // block audio just generated (reset crop)
            blockEnds = blockDuration
            blockTrimRight = 0
        } else if (!blockAudio) { // no block audio (reset crop)
            blockEnds = blockDuration
            blockTrimRight = 0;
        } else if (blockAudio) { // block audio and composer audio are equal (retain crop)
            blockEnds = defaultBlockVal.ends
            blockTrimRight = defaultBlockVal.trimRight || 0
        } else { // (reset crop)
            blockEnds = blockDuration;
            blockTrimRight = 0;
        }
        
        // const blockEnds = (blockAudio && defaultBlockVal.ends) ? defaultBlockVal.ends : blockDuration

        const ttsBlock = {
            ...defaultBlockVal,
            ends : blockEnds, // In seconds relative to 0s
            trimRight: blockTrimRight,
            url : blockAudio ? blockAudio.audio_url : null,
            duration: blockDuration, // Original duration of audio
            title: getBlockText(block)
        }
        
        return ttsBlock;
    })

    yield put(updateComposerTTSBlocks(ttsBlocks))

    // composer_settings[0].tracks[0].blocks = ttsBlocks;

    // yield put(updateAudioComposerSections(composer_settings));

}

function* addAudioComposerBlockSaga(action) {
    const integration = yield select(state => state.integration.integration);
    const editor = yield select(state => state.audio_composer.editor);
    const slate_value = yield select(state => state.audio_composer.slate_value);
    let { sectionIndex, blockIndex } = action.payload;

    if(sectionIndex === 0) {
        
        let audio_profile = {
            ...integration.audio_profile,
            lang_code: integration.lang_code
        };

        if (!blockIndex) { // inseting a block at the end
            blockIndex = [slate_value.length]
        }
    
        if (blockIndex >= 1) {
            audio_profile = slate_value[blockIndex - 1].audio_profile;
        }
    
        Transforms.insertNodes(
            editor,
            defaultSlateBlockValue({
                ...audio_profile,
            }),
            {
                at: [blockIndex],
            }
        );

    } else {
        yield put(addAudioComposerAudioBlock(action.payload))
    }

}

function* postComposerBlockSwapHandler(action) {
    const { didSwap, blockIndex } = action.payload;

    if (!didSwap) {
        return;
    }

    yield delay(1)

    yield put(updateCurrentAudioComposerBlock({sectionIndex: 0, trackIndex: 0, blockIndex}))
    yield put(toggleAudioComposerBlockActions(false))
}

function* initializeAudioComposerSaga(action){
    yield put(initializeCurrentAudioSuccess())
    yield put(initializeAudioComposerSuccess())
}

function* toggleIncludeTitleSaga(action) {

    const includeTitle = action.payload;
    const title = yield select(state => state.audio_composer.title);
    const slate_value = yield select(state => state.audio_composer.slate_value);
    const editor = yield select(state => state.audio_composer.editor);
    const integration = yield select(state => state.integration.integration);

    if (!slate_value || slate_value.length === 0) {
        return;
    }

    const isTitleAlreadyPresent = slate_value[0]?.is_title;

    yield delay(1)

    if (includeTitle && !isTitleAlreadyPresent) {
        let audio_profile = {
            ...integration.audio_profile,
            lang_code: integration.lang_code
        };

		Transforms.insertNodes(
            editor,
            {
				...defaultSlateBlockValue(audio_profile, title),
				is_title: true,
			},
            {
                at: [0],
            }
        );
    }

    if (!includeTitle && isTitleAlreadyPresent) {
        Transforms.removeNodes(editor, {at: [0]})
    }

}

function* updateTitleInSlateValue() {

    const title = yield select(state => state.audio_composer.title);
    const editor = yield select(state => state.audio_composer.editor);
    const slate_value = yield select(state => state.audio_composer.slate_value);
    const integration = yield select(state => state.integration.integration);

    const isTitleAlreadyPresent = slate_value[0]?.is_title;

    if (!integration) return;

    let audio_profile = {
        ...integration.audio_profile,
        lang_code: integration.lang_code
    };

    const block = defaultSlateBlockValue(audio_profile, title);
    block.children[0].children[0].text = title;

    yield delay(1)

    if (isTitleAlreadyPresent) {
        Transforms.removeNodes(editor, {at: [0]})
        Transforms.insertNodes(
            editor,
            {
				...defaultSlateBlockValue(audio_profile, title),
				is_title: true,
			},
            {
                at: [0],
            }
        );
    }

}

function* updateVoiceForAllBlocksSaga(action) {
    const editor = yield select(state => state.audio_composer.editor);
    const slate_value = yield select(state => state.audio_composer.slate_value);

    if (!slate_value) return;

    const { isAudioProfile } = action.payload

    /**
     * @Info
     *  if the selected item is a audio profile
     */
    if (isAudioProfile) {

        const { lang_code, voice, engine, platform, volume, speed, pitch, profile_name } = action.payload;

        slate_value.forEach((_, blockIndex) => {
            Transforms.setNodes(
                editor,
                {
                    audio_profile: {
                        profile_name, pitch, speed, volume, voice, lang_code, engine, platform
                    }
                },
                {
                    at: [blockIndex]
                }
            )
        });

        return;
    }

    /**
     * @Info
     *  if the selected item is a voice and not audio profile
     */

    const {code: voice, engine, platform, langCode: lang_code } = action.payload;
    let newVoice = {
        engine,
        lang_code,
        platform,
        voice
    }

    slate_value.forEach((block, blockIndex) => {
        const oldAudioProfile = block.audio_profile;

        // Old profile was a saved profile
        // in that case, should unset audio_profile completely
        if (oldAudioProfile.profile_name) {
            Transforms.setNodes(
                editor,
                {
                    audio_profile: {
                        ...newVoice,
                        volume: 0,
                        speed: 100
                    }
                },
                {
                    at: [blockIndex]
                }
            )
        } else {
            Transforms.setNodes(
                editor,
                {
                    audio_profile: {
                        ...oldAudioProfile,
                        ...newVoice
                    }
                },
                {
                    at: [blockIndex]
                }
            )
        }
        
    })
}

function* applyEditorTitleSettingsSaga(action){
    const {includeTitle, title} = action.payload;
    const editor = yield select(state => state.audio_composer.editor);
    const slate_value = yield select(state => state.audio_composer.slate_value);
    const integration = yield select(state => state.integration.integration);

    if (!integration) return;

    if (!slate_value || slate_value.length === 0) {
        return;
    }

    const isTitleAlreadyPresent = slate_value[0]?.is_title;

    let audio_profile = {
        ...integration.audio_profile,
        lang_code: integration.lang_code
    };

    yield delay(1)

    if (isTitleAlreadyPresent) {
        Transforms.removeNodes(editor, {at: [0]})

        if(includeTitle){
            Transforms.insertNodes(
                editor,
                {
                    ...defaultSlateBlockValue(audio_profile, title),
                    is_title: true,
                },
                {
                    at: [0],
                }
            );
        }
        
    }else if (!isTitleAlreadyPresent && includeTitle) {
        let audio_profile = {
            ...integration.audio_profile,
            lang_code: integration.lang_code
        };

		Transforms.insertNodes(
            editor,
            {
				...defaultSlateBlockValue(audio_profile, title),
				is_title: true,
			},
            {
                at: [0],
            }
        );
    }
}

function* changeAudioProfileSettingsOfCurrentBlockData(action) {

    const selectedBlockPosition = action.payload;
    if (!selectedBlockPosition) {
        return;
    }

    const slateValue = yield select((state) => state.audio_composer.slate_value);
    const selectedBlock = slateValue[selectedBlockPosition.blockIndex];

    if (!selectedBlock) {
        return;
    }

    if (selectedBlock.audio_profile.profile_name) {
        yield put(updateSelectedAudioProfile(selectedBlock.audio_profile))
        yield put(updateShowEditorVoiceProfiles(true))
        yield put(updateVoice(null));
    }

    const languages = yield select((state) => state.voice.languages);
    const language = yield select((state) => state.voice.language);

    // start by disabling saved audio profile
    yield put(updateSelectedAudioProfile(null));
    yield put(updateShowEditorVoiceProfiles(false));

    const { lang_code: langCode, voice: voiceCode, engine, platform } = selectedBlock.audio_profile;

    if (!languages || !languages[langCode]) {
        return;
    }

    let currentVoices = languages[langCode].voices;
    let currentVoice = currentVoices.find((v) => v.engine === engine && v.code === voiceCode);

    // if no voice is a match for the current block, set the first voice of this language as the default voice
    if (!currentVoice) {
        currentVoice = currentVoices[0];
    }

    // set the new voice in store
    yield put(updateVoice(currentVoice));
    if (langCode !== language?.code) {
        yield put(updateVoices(currentVoices));
        yield put(updateLanguage(languages[langCode]));
    }

    yield put(updateVoice(currentVoice))
    yield put(updateVoiceSettingsFilter({ key: "isPitchDisabled", value: ("polly" === platform && "neural" === engine) }))
    yield put(updateVoiceSettingsFilter({ key: "isStyleDisabled", value: (currentVoice.styles.length === 0) }))
    yield put(updateVoiceSettingsFilter({ key: "isBreathingDisabled", value: !("polly" === platform && "standard" === engine) }))

    yield put(updateVoiceSettingsFilter({ key: "language", value: langCode }))
    yield put(updateVoiceSettingsFilter({ key: "gender", value: "all" }))
    yield put(updateVoiceSettingsFilter({ key: "quality", value: "all" }))

}

function* audioComposerSaga() {
    yield debounce(1000, [UPDATE_SLATE_VALUE, UPDATE_TITLE, UPDATE_AUDIO_COMPOSER_BLOCK, ADD_AUDIO_COMPOSER_BLOCK, APPLY_EDITOR_TITLE_SETTINGS], saveDraftSaga);
    yield takeEvery(EXIT_EDITOR, exitEditorSaga);

    yield takeEvery([
        UPDATE_SLATE_VALUE, 
        PUT_BLOCK_AUDIO, 
        UPDATE_BLOCK_AUDIOS, 
        UPDATE_PRONUNCIATION_LIBRARY,
        GENERATE_COMPOSER_SETTINGS
    ], buildComposerBlocks);

    yield takeEvery(ADD_AUDIO_COMPOSER_BLOCK, addAudioComposerBlockSaga);
    yield takeEvery([UPDATE_AUDIO_COMPOSER_BLOCK_MOVE_LEFT, UPDATE_AUDIO_COMPOSER_BLOCK_MOVE_RIGHT], postComposerBlockSwapHandler);
    yield takeEvery(INITIALIZE_AUDIO_COMPOSER_REQUESTED, initializeAudioComposerSaga);
    yield takeEvery(TOGGLE_INCLUDE_TITLE, toggleIncludeTitleSaga);
    yield takeEvery(UPDATE_TITLE, updateTitleInSlateValue);
    yield takeEvery(UPDATE_VOICE_FOR_ALL_BLOCKS, updateVoiceForAllBlocksSaga);
    yield takeEvery(APPLY_EDITOR_TITLE_SETTINGS, applyEditorTitleSettingsSaga);

    yield takeEvery(UPDATE_CURRENT_AUDIO_COMPOSER_BLOCK, changeAudioProfileSettingsOfCurrentBlockData)
}

export default audioComposerSaga;