import { API } from 'aws-amplify';
import { useMutation } from "@tanstack/react-query";
import clean from '../../utils/Clean';

interface IuseCreateProfileProps {
    onSuccess: () => void
}
interface ICreateProfileRequestProps {
    org_id: string
    id: string 
    profile: Object
}

export const useCreateProfile = ({ onSuccess }: IuseCreateProfileProps) => {
    return useMutation(({ org_id, id, profile }: ICreateProfileRequestProps) =>
        API.post(`main`, `/organisation/${org_id}/integration/${id}/audio_profile`,{body: clean(profile), headers: {"Content-Type":"application/json"}}),
        {
            onSuccess: onSuccess
        }
    )
}


