import React, { useState } from 'react';
import {Crisp} from "crisp-sdk-web"
import { useForm } from "react-hook-form";
import Spinner from '../../components/Spinner';
import { Auth, API } from 'aws-amplify';
import { toast } from 'react-toastify';
import { createBrowserHistory } from 'history';
import {Modal} from "react-bootstrap";
import Bugsnag from "@bugsnag/js";
import {fetchLocation} from '../../utils/Country'
import AuthSpritesImg from "../../assets/auth-sprites.svg"
import { goToCurrentIntegration } from '../../utils/General';
const classNames = require('classnames');

export default function SignIn(props){

    const [ isLoading, setIsLoading ] = useState(false);
    const history = createBrowserHistory();
    const [authState, setAuthState] = React.useState("signin");
    const [ username, setUsername ] = useState(null);
    const [ password, setPassword ] = useState(null);
    const loginForm = useForm();
    const confirmSignUpForm = useForm();
    const [user, setUser] = useState(null);
    const newPasswordForm = useForm();
    const [ showNewPasswordModal, setShowNewPasswordModal] = useState(false);
    const [ isSettingNewPassword, setIsSettingNewPassword ] = useState(false);

    const onSubmitLoginForm = async data => {

        const { username, password } = data;

        setUsername(username);
        setPassword(password);
        setIsLoading(true);

        Auth.signIn(username, password).then(async(response)=>{
            console.log({response : response.attributes})
            setUser(response);

            if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {

                setShowNewPasswordModal(true);
                setIsLoading(false);
                return;

            }

            props.onSetUser(response);
            await goToCurrentIntegration(history)
            
            props.onChangeAuthState("signedin");
            setIsLoading(false);

        }).catch((error)=>{

            if(error.code && 'UserNotConfirmedException' === error.code){

                return Auth.resendSignUp(username)
                .then(() => {

                    setIsLoading(false);
                    setAuthState('signUpConfirmationPending')

                }).catch(error => {

                    toast.error(error.message);
                    setIsLoading(false);

                });

            }

            toast.error(error.message);
            setIsLoading(false);

        });

    };

    const onSubmitConfirmSignUp = async data => {

        const { code } = data;

        setIsLoading(true);

        const country = await fetchLocation()

        const location = {
            country : country.country,
            region : country.region,
            city : country.city
        }

        Auth.confirmSignUp(username, code.trim()).then((response)=>{

            Auth.signIn(username, password).then((response)=>{

                props.onSetUser(response);

                Auth.currentAuthenticatedUser().then(user => {

                    const organisation= user.attributes["custom:org"];

                    API.post(`main`, `/organisation`,{body:{name: organisation, location}, headers: {"Content-Type":"application/json"}})
                        .then(()=>{

                            setIsLoading(false);
                            props.onChangeAuthState("signedin");

                        });

                });

            })

        }).catch((error)=>{

            toast.error(error.message);
            setIsLoading(false);

        });

    }

    const onResendCode = async () =>{

        Auth.resendSignUp(username)
        .then(() => {

            toast.success("Code has been resent successfully.");

        }).catch(error => {

            toast.error(error.message);

        }).finally(()=>{

            setIsLoading(false);

        });

    }

    const onSubmitNewPasswordForm = async data => {

        const { given_name, family_name, password } = data;

        setIsSettingNewPassword(true);

        Auth.completeNewPassword(
            user,
            password,
            {
                given_name,
                family_name
            }
        ).then(user => {

            props.onSetUser(user);
            history.push(`/`);
            props.onChangeAuthState("signedin");

        }).catch(e => {

            Bugsnag.notify(e);

            toast.error("Something went wrong.");

        }).finally(()=>{

            setIsSettingNewPassword(false);

        });

    };

    return(
        <div className="row my-5 my-md-0">
            <div className="col-md-6 offset-md-3">
            {
                (authState === "signin") &&
                <form onSubmit={loginForm.handleSubmit(onSubmitLoginForm)} >
                    <h4 className="form-title text-center">Login</h4>
                    <p className="text-center mb-5">Need an account? <span onClick={()=>{props.onChangeAuthState("signup")}} style={{color:'#2D79F6', cursor: 'pointer'}}>Create an account</span></p>

                    <div className="form-group">
                        <label>Email address</label>
                        <input style={{backgroundImage : `url(${AuthSpritesImg})`, backgroundPosition : "4px 0px"}} type="username" name="username" className={classNames("form-control", "auth-input", { "is-invalid": loginForm.errors.username})}  ref={loginForm.register({required: true, pattern: /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,6}$/g})}/>
                        {
                            loginForm.errors.username &&
                            <div className="invalid-feedback">
                                {
                                    ("required"===loginForm.errors.username.type) && "Email address is required."
                                }
                                {
                                    ("pattern"===loginForm.errors.username.type) && "Email address is invalid."
                                }
                            </div>
                        }
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input style={{backgroundImage : `url(${AuthSpritesImg})`, backgroundPosition : "4px -70px"}} type="password" name="password" className={classNames("form-control", "auth-input", { "is-invalid": loginForm.errors.password})}  ref={loginForm.register({required: true})}/>
                        {
                            loginForm.errors.password &&
                            <div className="invalid-feedback">
                                {
                                    ("required"===loginForm.errors.password.type) && "Password is required."
                                }
                            </div>
                        }
                    </div>
                    {
                        (isLoading)?
                        (
                            <button type="button" className="btn btn-primary btn-block btn-rounded-30 btn-dark-blue-1">
                                <Spinner color="#FFF" />
                            </button>
                        )
                        :
                        (
                            <button type="submit" className="btn btn-primary btn-block btn-rounded-30 btn-dark-blue-1">Continue</button>
                        )
                    }
                    <p style={{color:'#2D79F6', cursor: 'pointer', textAlign: 'center', marginTop: '18px'}} onClick={()=>{props.onChangeAuthState("forgotpassword")}}>Forgot Password?</p>
                </form>
            }{
                (authState === "signUpConfirmationPending") &&
                <form className="mt-5" onSubmit={confirmSignUpForm.handleSubmit(onSubmitConfirmSignUp)}>
                    <h4 className="form-title text-center">Verify your email</h4>
                    <p className="text-center"><small>We have sent you an email with a code.</small></p>
                    <div className="form-group">
                        <label>Please enter the code below</label>
                        <input style={{backgroundImage : `url(${AuthSpritesImg})`, backgroundPosition : "4px -70px"}} type="text" name="code" className={classNames("form-control", "auth-input", { "is-invalid": confirmSignUpForm.errors.code})} ref={confirmSignUpForm.register({required: true})}/>
                        {
                            confirmSignUpForm.errors.code &&
                            <div className="invalid-feedback">
                                {
                                    ("required"===confirmSignUpForm.errors.code.type) && "Code is required."
                                }
                            </div>
                        }
                    </div>
                    {
                        (isLoading)?
                        (
                            <button type="button" className="btn btn-primary btn-block btn-rounded-30 btn-dark-blue-1">
                                <Spinner color="#fff" />
                            </button>
                        )
                        :
                        (
                            <button type="submit" className="btn btn-primary btn-block btn-rounded-30 btn-dark-blue-1">Continue</button>
                        )
                    }
                    <p className="mt-1 text-center"><small>Haven't received the code? Check your spam folder or <span style={{color:'#2D79F6', cursor: 'pointer'}} onClick={()=>{onResendCode()}}>click here to resend</span> the code.</small></p>
                </form>
            }
            <Modal show={showNewPasswordModal} onHide={()=>{setShowNewPasswordModal(false)}}>
                <Modal.Body>
                    <form className="p-3" id="new-password-form" onSubmit={newPasswordForm.handleSubmit(onSubmitNewPasswordForm)} >
                        <h4 className="my-3">Complete SignUp</h4>
                        <div className="form-group">
                            <label>First Name</label>
                            <input style={{backgroundImage : `url(${AuthSpritesImg})`, backgroundPosition : "4px 0px"}} type="text" name="given_name" className={classNames("form-control", "auth-input", { "is-invalid": newPasswordForm.errors.given_name})}  ref={newPasswordForm.register({required: true})}/>
                            {
                                newPasswordForm.errors.given_name &&
                                <div className="invalid-feedback">
                                    {
                                        ("required"===newPasswordForm.errors.given_name.type) && "First Name is required."
                                    }
                                </div>
                            }
                        </div>
                        <div className="form-group">
                            <label>Last Name</label>
                            <input style={{backgroundImage : `url(${AuthSpritesImg})`, backgroundPosition : "4px 0px"}} type="text" name="family_name" className={classNames("form-control", "auth-input", { "is-invalid": newPasswordForm.errors.family_name})}  ref={newPasswordForm.register({required: true})}/>
                            {
                                newPasswordForm.errors.family_name &&
                                <div className="invalid-feedback">
                                    {
                                        ("required"===newPasswordForm.errors.family_name.type) && "Last Name is required."
                                    }
                                </div>
                            }
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input style={{backgroundImage : `url(${AuthSpritesImg})`, backgroundPosition : "4px -70px"}} type="password" name="password" className={classNames("form-control", "auth-input", { "is-invalid": newPasswordForm.errors.password})}  ref={newPasswordForm.register({required: true})} />
                            {
                                newPasswordForm.errors.password &&
                                <div className="invalid-feedback">
                                    {
                                        ("required"===newPasswordForm.errors.password.type) && "Password is required."
                                    }
                                </div>
                            }
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    {
                        (isSettingNewPassword)?
                            (
                                <button type="button" className="btn btn-primary btn-block btn-rounded-30 btn-dark-blue-1">
                                    <Spinner color="#FFF" />
                                </button>
                            )
                            :
                            (
                                <button type="submit" form="new-password-form" className="btn btn-primary btn-block btn-rounded-30 btn-dark-blue-1">Continue</button>
                            )
                    }
                </Modal.Footer>
            </Modal>
            </div>
        </div>
    );

}
