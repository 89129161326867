import React, {useState} from 'react';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import {Auth} from 'aws-amplify';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner';
import Bugsnag from "@bugsnag/js";
const classNames = require('classnames');


export default function ChangePassword(){

    const { register, errors, handleSubmit, reset } = useForm();
    const [ isLoading, setIsLoading ]  = useState(false);

    const onSubmit = data => {

        setIsLoading(true);

        Auth.currentAuthenticatedUser()
        .then(user => {
            return Auth.changePassword(user, data.current_password, data.new_password);
        }).then(() => {

            toast.success("Password changed successfully.");
            reset()

        }).catch(err => {

            toast.error(err.message);
            Bugsnag.notify(err);

        }).finally(()=>{

            setIsLoading(false);

        });

    };

    return(
        <form onSubmit={handleSubmit(onSubmit)} className="my-3 my-xl-5">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-4">
                            <span className="form-section-title mb-4 mb-md-0 d-block">Change Password</span>
                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                <label>Current Password</label>
                                <input type="password" name="current_password" className={classNames("form-control", { "is-invalid": errors.current_password})}  ref={register({required: true, minLength: 8})} />
                                {
                                    errors.current_password &&
                                    <div className="invalid-feedback">
                                        {
                                            ("required"===errors.current_password.type) && "Current password is required."
                                        }
                                        {
                                            ("minLength"===errors.current_password.type) && "Password should be at least 8 character in length."
                                        }
                                    </div>
                                }
                            </div>
                            <div className="form-group">
                                <label>New Password</label>
                                <input type="password" name="new_password" className={classNames("form-control", { "is-invalid": errors.new_password})} ref={register({required: true,  minLength: 8})} />
                                {
                                    errors.new_password &&
                                    <div className="invalid-feedback">
                                        {
                                            ("required"===errors.new_password.type) && "New password is required."
                                        }
                                         {
                                            ("minLength"===errors.new_password.type) && "Password should be at least 8 character in length."
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 text-right">
                    <Link to={`/`}><button className="btn btn-outline-primary mr-3" type="button">Cancel</button></Link>
                    {
                        (isLoading)?
                        (
                            <button className="btn btn-primary" type="button" style={{width:"172px"}}>
                                <Spinner color="#FFF" />
                            </button>
                        )
                        :
                        (
                            <button className="btn btn-primary" type="submit" style={{width:"172px"}}>Change Password</button>
                        )
                    }
                </div>
            </div>
        </form>
    );

}
