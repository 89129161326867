import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import ContentVoiceOver from './ContentVoiceOver';
import ContentAudioArticle from './ContentAudioArticle';
import EditContent from './EditContent';
import Installation from './integration/Installation';
import Player from './integration/Player';
import General from './integration/General';
import Profile from './profile/Profile';
import Organisation from './Organisation';
import Integration from './Integration';
import RouteWrapper from '../components/RouteWrapper';
import FullWidthLayout from '../components/FullWidthLayout';
import FullWidthLayoutFlex from '../components/FullWidthLayoutFlex';
import SideBarLayout from '../components/SideBarLayout';
import Overview from './analytics/Overview';
import ContentAnalytics from './analytics/ContentAnalytics';
import Billing from './Billing';
import Plans from './plans/Plans';
import ChangePlan from './plans/ChangePlan';
import Podcast from './Podcast';
import CreatePodcast from './CreatePodcast';
import EditPodcast from './EditPodcast';
import CreateContent from './CreateContent';
import WordPress from './installation/WordPress';
import OtherCMS from './installation/OtherCMS';
import Listen2ItAPI from './installation/API';
import CreateProject from './CreateProject';
import WelcomePage from './WelcomePage'
import PodcastAudios from './PodcastAudios';
import AddContentToPodcast from './AddContentToPodast';
import CancelableFullWidthLayout from '../components/CancelableFullWidthLayout';
import Error404 from '../components/error/404';
import AIVoices from './AIVoices';
import SavedProfiles from './SavedProfiles';
import { createProdCampTokenService } from '../services/prodcamp';
import { useOnURLChangeListener } from '../hooks/useOnURLChangeListener';

export default function ProtectedRoutes(){
    
    useEffect(() => {
        
        createProdCampTokenService().then(data => {
            setTimeout(() => {
                localStorage.setItem('l2it-prodcamp-token', data)
                window.ProdCamp('setToken', data)
                window.ProdCamp('initFeedbackWidget')
                window.ProdCamp('initChangelog')
            }, 500)  
        })

        return () => {
            window.ProdCamp("destroyFeedbackWidget")
            window.ProdCamp("destroyChangelog")
        }
    }, [])

    return(
        <Router>
            <Wrapper>
                <Switch>
                    <RouteWrapper exact path="/" component={Organisation} layout={FullWidthLayout} />
                    <RouteWrapper exact path="/profile" component={Profile} layout={FullWidthLayout} />
                    <RouteWrapper exact path="/organisation/:org_id/integration" component={Integration} layout={FullWidthLayout} />
                    <RouteWrapper exact path="/organisation/:org_id/plans" component={Plans} layout={FullWidthLayout}/>
                    <RouteWrapper exact path="/profile" component={Profile} layout={FullWidthLayout} />
                    <Route exact path="/organisation/:org_id/integration/:id/content/new" >
                        <CreateContent />
                    </Route>
                    <RouteWrapper exact path="/organisation/:org_id/upgrade" component={ChangePlan} layout={CancelableFullWidthLayout}/>
                    <RouteWrapper exact path="/organisation/:org_id/project/create" component={CreateProject} layout={FullWidthLayout} />
                    <RouteWrapper exact path="/organisation/:org_id/welcome" component={WelcomePage} layout={FullWidthLayoutFlex} />
                    <Switch>
                        <Route exact path="/organisation/:org_id/integration/:id/content/:content_id" >
                            <EditContent />
                        </Route>
                        <RouteWrapper exact path="/organisation/:org_id/integration/:id/billing" component={Billing} layout={SideBarLayout}/>
                        <RouteWrapper exact path="/organisation/:org_id/integration/:id/content" component={ContentVoiceOver} layout={SideBarLayout}/>
                        <RouteWrapper exact path="/organisation/:org_id/integration/:id/content-audio-articles" component={ContentAudioArticle} layout={SideBarLayout}/>
                        <RouteWrapper path="/organisation/:org_id/integration/:id/installation" component={Installation} layout={SideBarLayout} />
                        <RouteWrapper path="/organisation/:org_id/integration/:id/voice" component={AIVoices} layout={SideBarLayout} />
                        <RouteWrapper path="/organisation/:org_id/integration/:id/profiles" component={SavedProfiles} layout={SideBarLayout} />
                        <RouteWrapper path="/organisation/:org_id/integration/:id/player" component={Player} layout={SideBarLayout} />
                        <RouteWrapper path="/organisation/:org_id/integration/:id/general" component={General} layout={SideBarLayout} />
                        <RouteWrapper path="/organisation/:org_id/integration/:id/analytics_overview" component={Overview} layout={SideBarLayout} />
                        <RouteWrapper path="/organisation/:org_id/integration/:id/content_analytics" component={ContentAnalytics} layout={SideBarLayout} />

                        <RouteWrapper exact path="/organisation/:org_id/integration/:id/podcast" component={Podcast} layout={SideBarLayout}/>
                        <RouteWrapper exact path="/organisation/:org_id/integration/:id/podcast/new" component={CreatePodcast} layout={SideBarLayout}/>
                        <RouteWrapper exact path="/organisation/:org_id/integration/:id/podcast/:podcast_id" component={EditPodcast} layout={SideBarLayout}/>
                        <RouteWrapper exact path="/organisation/:org_id/integration/:id/podcast/:podcast_id/episodes" component={PodcastAudios} layout={SideBarLayout}/>
                        <RouteWrapper exact path="/organisation/:org_id/integration/:id/podcast/:podcast_id/episodes-add" component={AddContentToPodcast} layout={SideBarLayout}/>

                        <RouteWrapper exact path="/organisation/:org_id/integration/:id/wordpress" component={WordPress} layout={SideBarLayout}/>
                        <RouteWrapper exact path="/organisation/:org_id/integration/:id/cms" component={OtherCMS} layout={SideBarLayout}/>
                        <RouteWrapper exact path="/organisation/:org_id/integration/:id/api" component={Listen2ItAPI} layout={SideBarLayout}/>
                        <Route component={Error404}></Route>
                    </Switch>
                </Switch>
            </Wrapper>
        </Router>
    )
}

const Wrapper = ({ children }) => {

    useOnURLChangeListener(
        (pathname) => pathname.includes('/content/'),
        (isContentScreen) => {
            if (isContentScreen) {
                window.$crisp.push(['do', 'chat:hide']);
                if (document.getElementById('prodcamp-widgets')) {
                    document.getElementById('prodcamp-widgets').style.display = "none";
                }
            } else {
                window.$crisp.push(['do', 'chat:show']);
                if (document.getElementById('prodcamp-widgets')) {
                    document.getElementById('prodcamp-widgets').style.display = "block";
                }
            }
        }
    );

    return (
        <>
            {children}
        </>
    )
}
