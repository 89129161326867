import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import {
	Transforms, Text, Node
} from "slate";
import { Editable, ReactEditor, } from "slate-react";
import * as SlateReact from "slate-react";
import { API } from 'aws-amplify';
import { toast } from 'react-toastify';
import clean from '../utils/Clean';
import Spinner from './Spinner';
import Bugsnag from "@bugsnag/js";
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactHowler from 'react-howler';
import hash from 'object-hash';
import AudioPlayer from '../components/AudioPlayer';
import YoutubeEmbed from '../components/YoutubeEmbed'
import EditorLeftMenu from './editor/EditorLeftMenu';
import EditorHeader from './editor/EditorHeader';
import LinkIcon from "../assets/editor/import-url-link.svg"
import DownArrowWhiteIcon from '../assets/editor/down-arrow-white.svg'
import pausePreviewEditor from '../assets/editor/block-pause.svg';
import VoiceModalCloseIcon from '../assets/voice/editor-modal-close.svg'
import maleVoiceIcon from '../assets/editor/male-voice.svg';
import femaleVoiceIcon from '../assets/editor/female-voice.svg';
import blockPlayIcon from '../assets/editor/block-play.svg'
import voiceArrowRightIcon from '../assets/editor/arrow-right.svg'
import deleteBlockIcon from '../assets/editor/delete-block.svg';
import HoveringToolbar from './HoveringMenu';

import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toggleEditorVoicePickerModal, fetchIntegrationProfilesRequested, fetchIntegrationRequested, fetchLanguagesRequested, updateSlateValue, updateVoiceConfiguration, updateVoiceSettingsFilter, addAudioComposerBlock, putBlockAudio, updateTitle, fetchOrganisationRequested, updateCurrentAudioComposerBlock, toggleAudioComposerDeleteBlockModal, toggleNoCreditsModal, toggleIncludeTitle, addMixPanelEvent, updateVoice } from '../store/actions';
import VoicePickerEditor from '../modules/integration/VoicePickerEditor';
import AudioComposer from './composer/AudioComposer';
import { postContentService } from '../services/audio_composer';
import getVoiceWithoutLangCode from '../utils/GetVoiceWithoutLangCode';
import InlineChromiumBugfix from './editor/InlineChromiumFix';
import { getBlockLastPoint, getBlockLevelPronunciationLibrary, getWordsCount } from '../utils/SlateUtils/index.js';
import { EditorToolbar } from './editor/EditorToolbar';
import { insertPause, isPauseActive, unwrapPause } from '../utils/SlateUtils/PauseUtils';
import { MemoizeImage } from './editor/Modals/MemoizeImage';
import { PronunciationLibraryModal } from './editor/Modals/PronunciationLibraryModal';

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

function EditorV2({ org_id, id, url }) {

	const history = useHistory();
	const dispatch = useDispatch();
	const queryParams = useQuery();

	const [contentId, setContentId] = useState(null)

	const [isPublishing] = useState(false);

	const showNoCreditsModal = useSelector(state => state.audio_composer.showNoCreditsModal);

	const title = useSelector(state => state.audio_composer.title);
	const value = useSelector(state => state.audio_composer.slate_value);
	const editor = useSelector(state => state.audio_composer.editor);

	const currentBlock = useSelector(state => state.audio_composer.currentBlock);

	const [isEmphasisDisabled, setIsEmphasisDisabled] = useState(false);

	const player = useRef(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [audio, setAudio] = useState(null);
	const [isImportingFromURL, setIsImportingFromURL] = useState(false);
	const [showStarterCardModal, setShowStarterCardModal] = useState(false)

	const [previewAudioCache, setPreviewAudioCache] = useState({});

	const [customPauseDuration, setCustomPauseDuration] = useState(0);
	const [showCustomPauseModal, setShowCustomPauseModal] = useState(false);

	const [selectionPreviewAudio, setSelectionPreviewAudio] = useState(null);
	const [isSelectionPreviewPlaying, setIsSelectionPreviewPlaying] = useState(false);
	const [playingFragment, setPlayingFragment] = useState(null);
	const [isSelectionPreviewLoading, setIsSelectionPreviewLoading] = useState(false);

	const [fullPreviewAudio, setFullPreviewAudio] = useState(null);

	const pronunciationLibrary = useSelector(state => state.integration.pronunciationLibrary)

	const voice = useSelector((state) => state.voice.voice);
	const selectedAudioProfile = useSelector((state) => state.voice.selectedAudioProfile)
	const usage = useSelector((state) => state.organisation.usage);
	const organisation = useSelector((state) => state.organisation.organisation);
	const languages = useSelector((state) => state.voice.languages);
	const language = useSelector((state) => state.voice.language);
	const { content, showPronunciationLibraryModal: showPronunciationLibrary, showVoicePickerModal: showVoicePicker } = useSelector(state => state.edit_content)


	const [showRefetchConfirmationModal, setShowRefetchConfirmationModal] = useState(false);

	const [showConfirmExit, setShowConfirmExit] = useState(false);

	function setBlockAudioProfile(value) {

		if (editor.children && editor.children[[currentBlock.blockIndex]]) {
			Transforms.setNodes(
				editor,
				{
					audio_profile: value
				},
				{
					at: [currentBlock.blockIndex]
				}
			)
		}

	}

	function setBlockProsodySettings(value) {

		if (!currentBlock) {
			return;
		}

		if (!editor.children[currentBlock.blockIndex]) {
			return;
		}

		Transforms.setNodes(
			editor,
			{
				audio_profile: {
					...editor.children[currentBlock.blockIndex].audio_profile,
					...value
				}
			},
			{
				at: [currentBlock.blockIndex]
			}
		)

		dispatch(addMixPanelEvent({
			orgId: org_id, integrationId: id, contentId, body: {
				eventName: "CHANGE_PROSODY_SETTINGS_TTS_BLOCK",
				eventData: {
					...value
				}
			}
		}))

	}

	const insertNewBlock = (insertBlockIndex) => {
		dispatch(addAudioComposerBlock({
			sectionIndex: 0,
			trackIndex: 0,
			blockIndex: insertBlockIndex
		}))
		Transforms.select(editor, { path: [insertBlockIndex, 0, 0], offset: 0 });
		setTimeout(() => {
			dispatch(updateCurrentAudioComposerBlock({
				sectionIndex: 0,
				trackIndex: 0,
				blockIndex: insertBlockIndex,
			}));
		}, 0)
	}

	useEffect(() => {

		if (content) {
			setContentId(content.SK.split('#')[1])
		}

	}, [content])

	useEffect(() => {
		dispatch(fetchLanguagesRequested())
		dispatch(fetchOrganisationRequested(org_id));
		dispatch(fetchIntegrationRequested(org_id, id))
		dispatch(fetchIntegrationProfilesRequested(org_id, id))
	}, [org_id, id, dispatch]);

	useEffect(() => {

		if (selectedAudioProfile) {

			setIsEmphasisDisabled(selectedAudioProfile.engine === 'neural' && selectedAudioProfile.platform === 'polly')
			return;

		}

		if (voice) {

			setIsEmphasisDisabled(voice.engine === 'neural' && voice.platform === 'polly');
			dispatch(updateVoiceSettingsFilter({ key: "isPitchDisabled", value: ("polly" === voice.platform && "neural" === voice.engine) }))
			dispatch(updateVoiceSettingsFilter({ key: "isStyleDisabled", value: voice.styles.length === 0 }))
			dispatch(updateVoiceConfiguration({ key: 'style', value: null }))

		}

	}, [dispatch, voice, selectedAudioProfile])

	const savePronunciationLibrary = useCallback((value) => {

		API.put('main', `/organisation/${org_id}/integration/${id}/pronunciation_library`, {
			body: {
				pronunciation_library: value
			}, headers: { "Content-Type": "application/json" }
		}).then(resp => {
			// 
		}).catch(err => {
			// 
		})

	}, [id, org_id]);

	const decorate = useCallback(([node, path]) => {
		const ranges = []

		if (!language) return ranges;

		const libraryCopy = [...pronunciationLibrary].filter(val => val.language === language.code);

		libraryCopy.forEach((libraryValue) => {
			if (libraryValue.original && Text.isText(node)) {
				const { text } = node
				// const re = new RegExp(`\\b${libraryValue.original}\\b`, 'gi');
				// const parts = text.split(re);
				const re = new RegExp(`(?<!\\S)${libraryValue.original}((?!\\S)|(?=[.,?!]))`, 'gi');
				let parts = text.split(re);
				parts = parts.filter((part, index) => {
					if (index === 0 && part === "") {
						return true;
					}
					return part !== "";
				})

				let offset = 0

				parts.forEach((part, i) => {
					if (i !== 0) {
						ranges.push({
							anchor: { path, offset: offset - libraryValue.original.length },
							focus: { path, offset },
							highlight: true,
							original: libraryValue.original,
							replaceWith: libraryValue.replaceWith
						})
					}

					offset = offset + part.length + libraryValue.original.length
				})
			}
		})

		return ranges;
	}, [pronunciationLibrary, language]);

	const previewTTS = (element, content_id = contentId) => {

		return API.post(
			'main',
			`/organisation/${org_id}/integration/${id}/content/${content_id}/preview/v2`,
			{
				body: clean(element),
				headers: { 'Content-Type': 'application/json' }
			});

	}

	const onListenClick = async () => {

		const currentFragment = editor.getFragment()[0];
		const currentFragmentHash = hash({
			...currentFragment,
			pronunciation_library: pronunciationLibrary,
		});

		if (isSelectionPreviewPlaying && currentFragmentHash === playingFragment) {
			setIsSelectionPreviewPlaying(false);
			return;
		}

		setPlayingFragment(currentFragmentHash);

		if (selectionPreviewAudio && currentFragmentHash === playingFragment) {

			setIsSelectionPreviewPlaying(true);
			return;

		}

		setSelectionPreviewAudio(null);

		if (previewAudioCache.hasOwnProperty(currentFragmentHash)) {

			setIsSelectionPreviewPlaying(false);
			setSelectionPreviewAudio(previewAudioCache[currentFragmentHash]);
			setIsSelectionPreviewPlaying(true);
			return;

		}

		setIsSelectionPreviewPlaying(false);
		setIsSelectionPreviewLoading(true);

		try {
			const resp = await previewTTS(currentFragment);
			if (resp.message) {
				toast.error(resp.message);
				return;
			}
			if (resp.data && resp.data.message) {
				toast.error(resp.data.message);
				return;
			}
			const audio_url = resp.data.audio_url;

			setSelectionPreviewAudio(`${process.env.REACT_APP_AUDIO_BUCKET}/${audio_url}`);
			setIsSelectionPreviewPlaying(true);

			setIsSelectionPreviewLoading(false);

			setPreviewAudioCache(prev => (
				{
					...prev,
					[currentFragmentHash]: audio_url,
				}
			))
		} catch (e) {
			console.log(e)
		} finally {
			setIsSelectionPreviewLoading(false);
			setIsSelectionPreviewPlaying(false);
		}

	}

	const onStopClick = () => {
		setIsSelectionPreviewLoading(false);
		setIsSelectionPreviewPlaying(false);
	}

	const convertArticleFromURL = async () => {

		try {

			setIsImportingFromURL(true);

			const response = await API.post(
				'main',
				`/organisation/${org_id}/integration/${id}/content/import_url`,
				{
					body: {
						article_url: encodeURIComponent(url || content.url),
						content_id: contentId
					},
					headers: { "Content-Type": "application/json" }
				}
			);

			if (response.success) {
				dispatch(updateSlateValue(response.data.slate_value))
				dispatch(updateTitle(response.data.title))
				dispatch(toggleIncludeTitle(true))
			} else {
				toast.error(response.data.message)
			}

		} catch (e) {

			toast.error("Something went wrong while refetching article.")
			Bugsnag.notify(e);

		} finally {
			setIsImportingFromURL(false);
			setShowRefetchConfirmationModal(false);
		}
	}

	editor.children = value;

	useEffect(() => {

		if (queryParams.get("show_starter_card")) {
			setShowStarterCardModal(true)
		}
		if (!contentId) {
			dispatch(updateSlateValue(value))
		}

		if (value && value.length > 0) {
			const isTitleAlreadyPresent = value[0]?.is_title;

			if (isTitleAlreadyPresent) {
				dispatch(toggleIncludeTitle(true));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (!organisation || !languages || Object.keys(languages).length === 0) {
		return (
			<Spinner color="#657B90" />
		);
	}

	// Editor component
	return <React.Fragment>
		{
			audio && (
				<ReactHowler src={audio} playing={isPlaying} ref={player} onEnd={() => {

					setIsPlaying(false);
					setAudio(null);

				}} />
			)
		}
		{
			selectionPreviewAudio && (
				<ReactHowler src={selectionPreviewAudio} playing={isSelectionPreviewPlaying} onEnd={() => {
					setIsSelectionPreviewPlaying(false);
				}} />
			)
		}

		<Modal className="editor-starter-card-modal" style={{ borderRadius: "20px" }} show={showStarterCardModal} onHide={() => setShowStarterCardModal(false)}>
			<Modal.Body>
				<div className="editor-starter-card">
					<div>
						<div className="col-12 d-flex flex-column align-items-center px-0">
							<YoutubeEmbed embedId="JGfkHiopie0" />
						</div>
						<div className="col-12">
							<h4 className="text-center my-3 form-title">Welcome to Listen2It Studio</h4>
							<p className="text-center text">Spend a minute to watch our <b>starter video</b>. We take you through the easy steps that you can follow on our easy-to-use editing studio to create your first voiceover</p>
						</div>
						<div className="col-12 d-flex flex-column align-items-center">
							<p className="mb-0"><span style={{ fontSize: "12px", color: "rgb(45, 121, 246)", cursor: "pointer" }} onClick={() => setShowStarterCardModal(false)}>Skip for now</span></p>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>

		<form style={{
			height: '100vh',
			display: 'flex',
			flexDirection: 'column',
			overflow: 'hidden',
			background: '#F7F7F7'
		}}>
			<EditorHeader setShowStarterCardModal={(e) => setShowStarterCardModal(e)} org_id={org_id} contentId={contentId} id={id} title={title} value={value} isPublishing={isPublishing} />

			{(url || (content && content.url)) && (

				<ConfirmRefetchModal
					show={showRefetchConfirmationModal}
					onHide={() => setShowRefetchConfirmationModal(false)}
					url={url || content.url}
					onConfirm={() => {
						convertArticleFromURL();
					}}
					isLoading={isImportingFromURL}
				/>
			)}


			<div className="editor-main">

				<EditorLeftMenu org_id={org_id} id={id} contentId={contentId} setShowRefetchConfirmationModal={(v) => setShowRefetchConfirmationModal(v)} />

				<div style={{
					overflowY: 'scroll',
					overflowX: 'hidden',
					margin: '0px 15px 16px 0px',
					flexGrow: 1
				}} className="card">
					<div style={{ padding: 0, position: 'relative' }} className="card-body">
						<div className="row">
							<div className="col-12 d-flex flex-column">
								<div className="">
									<SlateReact.Slate
										editor={editor}
										value={value}
										onChange={(changedValue) => {

											if (_.isEqual(changedValue, value)) {
												return;
											}

											dispatch(updateSlateValue(changedValue))
										}}
									>
										<HoveringToolbar
											isEmphasisDisabled={isEmphasisDisabled}
											onListenClick={onListenClick}
											onStopClick={onStopClick}
											isSelectionPreviewPlaying={isSelectionPreviewPlaying}
											isSelectionPreviewLoading={isSelectionPreviewLoading}
											savePronunciationLibrary={savePronunciationLibrary}
											orgId={org_id}
											integrationId={id}
											contentId={contentId}
										/>

										<EditorToolbar
											org_id={org_id}
											contentId={contentId}
											id={id}
											setShowCustomPauseModal={setShowCustomPauseModal}
											setBlockProsodySettings={setBlockProsodySettings}
										/>

										<div className='px-3 pt-4' style={{ overflowY: 'auto' }}>
											<Editable
												onPaste={(event) => {
													event.preventDefault();

													const clipboardText = event.clipboardData.getData('text/plain');

													editor.insertText(clipboardText)

												}}
												decorate={decorate}
												renderElement={(props) => <Element {...props} isPlaying={isPlaying} setIsPlaying={setIsPlaying} audio={audio} setAudio={setAudio} previewAudioCache={previewAudioCache} setPreviewAudioCache={setPreviewAudioCache} contentId={contentId} setContentId={setContentId} org_id={org_id} id={id} />}
												renderLeaf={(props) => <TextComponent {...props} isEmphasisDisabled={isEmphasisDisabled} />}
												placeholder=""
												onKeyDown={(event) => {

													if (
														(event.key.toLowerCase() === 'a' && event.ctrlKey)
														|| (event.key.toLowerCase() === 'a' && event.metaKey)
													) {

														event.preventDefault();



														Transforms.setSelection(editor, {
															anchor: {
																path: [currentBlock.blockIndex, 0, 0],
																offset: 0
															},
															focus: getBlockLastPoint(
																value[currentBlock.blockIndex],
																currentBlock.blockIndex
															)
														})
													}
												}}

											/>
											{
												editor.children.length === 0 && (
													<div contentEditable={false} className="d-flex flex-row justify-content-center align-items-center m-1 add-block-container last">
														<div className='add-block-background-pipe left'>
														</div>
														<span className={`add-block-btn`} contentEditable={false} onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															insertNewBlock(value.length)
														}}>
															+
														</span>
														<div className='add-block-background-pipe right'>
														</div>
													</div>
												)
											}
										</div>
										<div style={{ height: "30px", "position": "sticky", "bottom": "0", "display": "flex", justifyContent: "space-between", alignItems: "center" }}>
											<div className="editor--words-count ml-3"><span>{value.reduce((accumulator, block) => accumulator + getWordsCount(block.children), 0)} words</span></div>
											<div className='editor--words-remaining mr-3'>
												{
													usage && (
														<>
															<span className='words-count'>{usage.words_remaining?.toLocaleString()}</span>
															<span> of </span>
															<span className='words-count'>{usage.plan_words_limit?.toLocaleString()}</span>
															<span> words remaining </span>
														</>
													)
												}
											</div>
										</div>
									</SlateReact.Slate>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>

			<AudioComposer orgId={org_id} integrationId={id} />
		</form>

		<Modal
			className="modal-voice-picker"
			show={showVoicePicker}
			onHide={() => {
				dispatch(toggleEditorVoicePickerModal(false))
			}}
		>
			<Modal.Header className='d-flex justify-content-between align-items-center' style={{ borderBottom: '1px solid #00000029', marginBottom: 16 }}>
				<div>
					<p className="mb-0" style={{ fontSize: 18, fontWeight: "bold", color: "#333B6C" }}>Select a voice</p>
					<p style={{ fontSize: 13 }} className="mb-0">Choose from 900+ realistic voice in 140+ languages and dialects</p>
				</div>
				<div style={{ cursor: "pointer" }} onClick={() => dispatch(toggleEditorVoicePickerModal(false))}>
					<img alt="close" height="20" width="20" src={VoiceModalCloseIcon} />
					<span style={{ marginLeft: 3, fontSize: 16, fontWeight: 500, color: "#999DB5" }}>Close</span>
				</div>
			</Modal.Header>
			<Modal.Body className='voice-picker-body'>
				<VoicePickerEditor
					onVoiceChange={(newVoice) => {

						if (!newVoice) {
							return;
						}

						setBlockAudioProfile({
							engine: newVoice.engine,
							lang_code: newVoice.langCode,
							platform: newVoice.platform,
							voice: newVoice.code,
							volume: 0,
							speed: 100
						})

						dispatch(addMixPanelEvent({
							orgId: org_id, integrationId: id, contentId, body: {
								eventName: "CHANGE_VOICE_TTS_BLOCK",
								eventData: {
									...newVoice
								}
							}
						}))

						setTimeout(() => {
							dispatch(updateVoice(newVoice))
							const { platform, engine, styles } = newVoice;

							dispatch(updateVoiceSettingsFilter({
								key: "isPitchDisabled",
								value: ("polly" === platform && "neural" === engine)
							}));
							dispatch(updateVoiceSettingsFilter({
								key: "isStyleDisabled", value: (styles.length === 0)
							}));
							dispatch(updateVoiceSettingsFilter({
								key: "isBreathingDisabled",
								value: !("polly" === platform && "standard" === engine)
							}));
						}, 1)

					}}
					onProfileChange={(newProfile) => {

						setBlockAudioProfile({
							...newProfile
						})

						dispatch(addMixPanelEvent({
							orgId: org_id, integrationId: id, contentId, body: {
								eventName: "CHANGE_PROFILE_TTS_BLOCK",
								eventData: {
									...newProfile
								}
							}
						}))

						setTimeout(() => {
							dispatch(toggleEditorVoicePickerModal(false))
						}, 500)

					}}
					onClose={() => dispatch(toggleEditorVoicePickerModal(false))}
				/>
			</Modal.Body>
		</Modal>
		<Modal className="modal-add-content" show={showCustomPauseModal} onHide={() => setShowCustomPauseModal(false)}>
			<Modal.Header closeButton>
				<Modal.Title>
					Add a custom pause duration
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="container">
				<input style={{ minWidth: 128 }} type="number" min={0} max={50} value={customPauseDuration} onChange={(e) => setCustomPauseDuration(e.currentTarget.value)} />
			</Modal.Body>
			<Modal.Footer>
				<button type="button" className="btn btn-outline-primary mr-3" onClick={() => setShowCustomPauseModal(false)}>Cancel</button>
				<button type="button" className="btn btn-primary" onClick={(e) => {
					e.preventDefault();
					if (isPauseActive(editor)) {
						unwrapPause(editor);
					} else {
						insertPause(editor, parseInt(customPauseDuration));
					}
					setTimeout(() => setShowCustomPauseModal(false))
				}}>
					Add
				</button>
			</Modal.Footer>
		</Modal>
		<PronunciationLibraryModal
			showPronunciationLibrary={showPronunciationLibrary}
			languages={languages}
			org_id={org_id}
			id={id}
		/>
		<ConfirmExitModal
			show={showConfirmExit}
			onHide={() => setShowConfirmExit(false)}
			onConfirm={() => {
				history.push(`/organisation/${org_id}/integration/${id}/content`)
			}}
		/>
		{
			(fullPreviewAudio) &&
			<div className="audio-player-wrapper">
				<AudioPlayer onClose={() => setFullPreviewAudio(null)} onEnd={() => setFullPreviewAudio(null)} audio={fullPreviewAudio} title={title} />
			</div>
		}
		{/* <Tip /> */}
		<Modal show={showNoCreditsModal} onHide={() => dispatch(toggleNoCreditsModal(false))}>
			<Modal.Header closeButton>
				<Modal.Title>
					No words remaining.
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>Not enough word credits left to generate this audio. Please upgrade your plan to continue.</p>
			</Modal.Body>
			<Modal.Footer>
				<button type="button" className="btn btn-outline-primary mr-3" onClick={() => dispatch(toggleNoCreditsModal(false))}>Cancel</button>
				<button type="button" className="btn btn-primary" onClick={(e) => history.push(`/organisation/${org_id}/upgrade`)}>Upgrade</button>
			</Modal.Footer>
		</Modal>
	</React.Fragment>
}

const PauseElement = ({ attributes, children, element }) => {
	const selected = SlateReact.useSelected();
	return (
		/*
			Note that this is not a true button, but a span with button-like CSS.
			True buttons are display:inline-block, but Chrome and Safari
			have a bad bug with display:inline-block inside contenteditable:
			- https://bugs.webkit.org/show_bug.cgi?id=105898
			- https://bugs.chromium.org/p/chromium/issues/detail?id=1088403
			Worse, one cannot override the display property: https://github.com/w3c/csswg-drafts/issues/3226
			The only current workaround is to emulate the appearance of a display:inline button using CSS.
		*/
		<span
			{...attributes}
			onClick={(ev) => ev.preventDefault()}
			data-duration={element.duration + 's'}
			className="pause"
			style={{
				boxShadow: selected ? "0 0 0 3px pink" : "none"
			}}
		// Margin is necessary to clearly show the cursor adjacent to the button
		>
			<InlineChromiumBugfix />
			{children}
			<InlineChromiumBugfix />
		</span>
	);
};

const ConfirmRefetchModal = ({ show, onHide, onConfirm, url, isLoading }) => {
	return (
		<Modal className="content-import-modal" show={show} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>
					Import Content
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="download-icons-row">
					<div className={`download-card`}>
						<div className='w-100 d-flex justify-content-center'>
							<img alt="" src={LinkIcon} /></div>
						<div className='w-100 text-center text'>URL</div>
					</div>
				</div>
				<div className='import-main'>
					<p className="modal-subtitle">

						<center>Re-import the URL if the content of the original URL has been changed. <br /><i>(URL cannot be changed for "Audio Articles")</i></center>

					</p>
					<div className="form-group">
						<input value={url} type="text" className="form-control" disabled />
					</div>

					<div className="w-100 d-flex justify-content-center">
						<Button onClick={onConfirm} style={{ marginRight: 15 }} className="editor-download-button" variant="primary">
							{(isLoading) ? <Spinner color="#ffffff" /> : <>
								<img alt='' height="14" style={{ marginRight: 5 }} src={DownArrowWhiteIcon} />
								<span style={{ fontSize: 14 }}>Re-Import</span></>}
						</Button>
					</div>
				</div>

			</Modal.Body>
		</Modal>
	);
}

const ConfirmExitModal = ({ show, onHide, onConfirm }) => {
	return (
		<Modal show={show} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>
					Are you sure want to close editor?
					<p className="modal-subtitle">All your unsaved changes will be lost</p>
				</Modal.Title>
			</Modal.Header>
			<Modal.Footer>
				<button type="button" className="btn btn-outline-primary mr-3" onClick={onHide}>No</button>
				<button type="button" className="btn btn-danger" onClick={onConfirm}>Yes</button>
			</Modal.Footer>
		</Modal>
	);
}

export default EditorV2;

const TextComponent = (props) => {
	const { attributes, children, leaf, isEmphasisDisabled } = props;
	if (leaf.text === "") {
		return (
			<span
				// The following is a workaround for a Chromium bug where,
				// if you have an inline at the end of a block,
				// clicking the end of a block puts the cursor inside the inline
				// instead of inside the final {text: ''} node
				// https://github.com/ianstormtaylor/slate/issues/4704#issuecomment-1006696364
				style={{ paddingLeft: "0.1px" }}
				{...attributes}
			>
				{children}
			</span>
		);
	}

	if (leaf.highlight || leaf.pronunciation || leaf.say_as || leaf.emphasis) {

		return (
			<OverlayTrigger
				placement='top'
				delay={{ show: 500, hide: 200 }}
				overlay={(props) => (
					<Tooltip {...props}>
						{
							leaf.say_as
								? (
									<>{`${leaf.interpret_as}`}</>
								)
								: leaf.emphasis && !isEmphasisDisabled
									? (
										<>Emphasis - {leaf.emphasis_strength}</>
									)
									: leaf.emphasis && isEmphasisDisabled
										? (
											<>Emphasis unavailable for selected voice. Emphasis will be discarded</>
										)
										: (
											<>{`${leaf.original} -> ${leaf.replaceWith}`}</>
										)
						}
					</Tooltip>
				)}
			>
				<span
					{...attributes}
				>
					<span className={leaf.pronunciation ? 'slate-pronunciation-node' : leaf.highlight ? 'slate-highlight-node' : leaf.emphasis && !isEmphasisDisabled ? 'slate-emphasis-node' : leaf.emphasis && isEmphasisDisabled ? 'slate-emphasis-node-disabled' : 'slate-say-as-node'}>
						{children}
					</span>
				</span>
			</OverlayTrigger>
		)

	}

	return (
		<span
			{...attributes}
		>
			{children}
		</span>
	);
};

const Element = (props) => {
	const { attributes, children, element } = props;

	switch (element.type) {
		case "pause":
			return <PauseElement {...props} />;
		case "list-item":
			return (
				<p style={{ marginBottom: 0 }} {...attributes}>
					{children}
				</p>
			);
		case "block":
			return <BlockElement {...props} />
		default:
			return (
				<p className='slate-paragraph' {...attributes}>
					{/* <InlineChromiumBugfix /> */}
					{children}
					{/* <InlineChromiumBugfix /> */}
				</p>
			);
	}
};

const BlockElement = (props) => {
	const integration = useSelector(state => state.integration.integration)
	const languages = useSelector((state) => state.voice.languages);
	const editor = useSelector(state => state.audio_composer.editor);
	const pronunciationLibrary = useSelector(state => state.integration.pronunciationLibrary)
	const block_audios = useSelector(state => state.audio_composer.block_audios);
	const published_at = useSelector(state => state.edit_content.published_at)
	const currentBlock = useSelector(state => state.audio_composer.currentBlock);
	const dispatch = useDispatch();
	const { content, showPronunciationLibraryModal: showPronunciationLibrary, showVoicePickerModal: showVoicePicker } = useSelector(state => state.edit_content)

	const { attributes, children, element } = props;

	let { is_title, audio_profile } = element;

	const wordsCount = getWordsCount(element.children);

	if (!audio_profile || !audio_profile.lang_code) {
		audio_profile = {
			lang_code: integration.lang_code,
			...integration.audio_profile
		}
	}

	let selectedVoice = languages[audio_profile.lang_code].voices.find((voice) => voice.code === audio_profile.voice && voice.engine === element.audio_profile.engine);
	const profile = audio_profile.profile_name ? audio_profile : null
	const path = ReactEditor.findPath(editor, element);
	const blockNode = Node.get(editor, path);

	const blockLevelPronunciationLibrary = getBlockLevelPronunciationLibrary(element, pronunciationLibrary)

	const blockHash = hash({
		...blockNode,
		pronunciation_library: blockLevelPronunciationLibrary
	})

	if (!profile && !selectedVoice) {
		selectedVoice = getVoiceWithoutLangCode(languages, audio_profile.voice, audio_profile.engine)
	}

	const insertNewBlock = (insertBlockIndex) => {
		dispatch(addAudioComposerBlock({
			sectionIndex: 0,
			trackIndex: 0,
			blockIndex: insertBlockIndex
		}))
		Transforms.select(editor, { path: [insertBlockIndex, 0, 0], offset: 0 });
		setTimeout(() => {
			dispatch(updateCurrentAudioComposerBlock({
				sectionIndex: 0,
				trackIndex: 0,
				blockIndex: insertBlockIndex,
			}));
		}, 0)
	}

	return (
		<div onClick={() => {

			const newCurrentBlock = {
				sectionIndex: 0,
				trackIndex: 0,
				blockIndex: path[0]
			}

			if (!_.isEqual(currentBlock, newCurrentBlock)) {
				dispatch(updateCurrentAudioComposerBlock(newCurrentBlock));
			}

			const domElement = document.getElementById(`block-paragraph-${path[0]}`)
			if (domElement) {
				domElement.classList.add('active')
			}

			if (editor.selection && editor.selection.anchor && editor.selection.anchor.path[0] === path[0]) {
				return;
			}

			const blockIndex = path[0];

			if (wordsCount === 0) return

			Transforms.select(editor, getBlockLastPoint(element, blockIndex));

		}} className='slate-block'>
			<div id={`block-paragraph-${path[0]}`} className={`d-flex flex-column paragraph-container ${path[0] === currentBlock?.blockIndex ? "active" : ""} `} {...attributes}>
				<div className='user-select-none d-flex flex-row justify-content-between' style={{
					paddingTop: 2,
					paddingBottom: 5,
					marginBottom: 10,
					borderBottom: "1px solid #E1E1E1"
				}} contentEditable={false}>
					<div style={{ gap: 10 }} className='d-flex flex-row flex-grow-1'>
						<div onClick={() => {
							dispatch(updateCurrentAudioComposerBlock({
								sectionIndex: 0,
								trackIndex: 0,
								blockIndex: path[0]
							}))
							setTimeout(() => {
								dispatch(toggleEditorVoicePickerModal(!showVoicePicker))
							})
						}} className='block-voice-container'>
							<MemoizeImage className="gender-icon" src={(selectedVoice?.gender.charAt(0).toUpperCase() === "M") ? maleVoiceIcon : femaleVoiceIcon} alt="" />
							<div>
								{profile ? `${profile.profile_name} (profile)` : `${selectedVoice?.name} (${selectedVoice?.gender.charAt(0).toUpperCase()})`}
							</div>

							<MemoizeImage alt="" className="arrow-right" src={voiceArrowRightIcon} />
						</div>
						<div className='block-words-count'>
							<span>{wordsCount} words</span>
						</div>
						{is_title ? <div className='block-is-title-label'>
							<span>Title</span>
						</div> : null}

						<EditedLabel blockHash={blockHash} block_audios={block_audios} published_at={published_at} wordsCount={wordsCount} />

					</div>
					<div className='d-flex flex-row' style={{ gap: 10 }}>

						<PreviewPlayerComponent wordsCount={wordsCount} element={element} {...props} />

						{
							!is_title && (
								<button className="delete-block" style={{
									// opacity: value.length <= 1 ? '0.3' : '1'
								}}
									// disabled={value.length <= 1} 
									onClick={(e) => {

										e.preventDefault();
										e.stopPropagation();

										// if (value.length <= 1) {
										// 	return;
										// }

										dispatch(updateCurrentAudioComposerBlock({
											sectionIndex: 0,
											trackIndex: 0,
											blockIndex: path[0],
										}));

										dispatch(toggleAudioComposerDeleteBlockModal(true));

									}}>
									<MemoizeImage className="action-icon" src={deleteBlockIcon} alt="delete block" />
									<span>Delete</span>
								</button>
							)
						}
					</div>
				</div>
				{/* <InlineChromiumBugfix /> */}


				<div style={{ zIndex: 10 }} contentEditable={!is_title} suppressContentEditableWarning={true}>
					{children}
				</div>
				{
					(wordsCount === 0)
					&& (
						<div contentEditable={false} style={{ color: '#8d8d8d', position: 'absolute', top: 47 }}>Enter your content here</div>
					)
				}
				{/* <InlineChromiumBugfix /> */}
			</div>
			<div contentEditable={false} className={`d-flex flex-row justify-content-center align-items-center my-2 add-block-container ${path[0] === editor.children.length - 1 ? 'last' : ''}`}>
				<div className='add-block-background-pipe left'>
				</div>
				<div className={`add-block-btn`} contentEditable={false} onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					insertNewBlock(path[0] + 1);
				}}>
					<span style={{
						background: "#fff",
						color: "#2D79F6",
						borderRadius: "50%",
						width: 12,
						height: 12,
						lineHeight: "14px",
						marginRight: 5
					}}>
						+
					</span>
					<span style={{ fontSize: 12 }}>Add a block</span>
				</div>

				<div className='add-block-background-pipe right'>
				</div>
			</div>

		</div>
	)
}

function EditedLabel({ wordsCount, block_audios, blockHash, published_at }) {

	if (wordsCount === 0)
		return <></>;

	if (block_audios && block_audios.hasOwnProperty(blockHash) && published_at) {

		const blockGeneratedTime = (new Date(block_audios[blockHash].created_at)).getTime();
		const contentPublishedTime = (new Date(published_at)).getTime();

		if (blockGeneratedTime <= contentPublishedTime) {
			return <></>
		}

	}

	return (
		<div className='block-words-count'>
			Edited
		</div>
	)
}

const PreviewPlayerComponent = ({ wordsCount, element, isPlaying, setIsPlaying, audio, setAudio, previewAudioCache, setPreviewAudioCache, contentId, setContentId, org_id, id, }) => {
	const pronunciationLibrary = useSelector(state => state.integration.pronunciationLibrary)
	const title = useSelector(state => state.audio_composer.title);
	const value = useSelector(state => state.audio_composer.slate_value);
	const composer_settings = useSelector(state => state.audio_composer.sections);

	const dispatch = useDispatch();

	const [isLoadingPreview, setIsLoadingPreview] = useState(false);
	const [playingElement, setPlayingElement] = useState(null);

	const currentElementHash = hash({
		...element,
		pronunciation_library: getBlockLevelPronunciationLibrary(element, pronunciationLibrary)
	});

	const DownloadParagraphButton = () => {
		return <></>
		// return (
		// 	<img className='action-icon download-paragraph-icon' onClick={async (e) => {

		// 		e.preventDefault();
		// 		const resp = await previewTTS([element], 'paragraph');
		// 		const audio_url = resp.data.audio_url;
		// 		saveAs(audio_url, `${title} - para test.mp3`)

		// 	}} src={downloadIcon} style={{ cursor: 'pointer' }} alt="preview" />
		// );
	}

	const previewTTS = (element, content_id = contentId) => {

		return API.post(
			'main',
			`/organisation/${org_id}/integration/${id}/content/${content_id}/preview/v2`,
			{
				body: clean(element),
				headers: { 'Content-Type': 'application/json' }
			});

	}

	const onClick = async (e) => {

		if (isPlaying /*  && currentElementHash === playingElement */) { // Pause the audio that is playing

			setIsPlaying(false);
			return;

		}

		setPlayingElement(currentElementHash);

		// If the audio is paused while playing
		if (audio && currentElementHash === playingElement) {

			setIsPlaying(true);
			return;

		}

		setAudio(null);

		// If the preview was already requested and the paragraph hasn't changed
		if (previewAudioCache.hasOwnProperty(currentElementHash)) {

			setIsPlaying(false);
			setAudio(`${process.env.REACT_APP_AUDIO_BUCKET}/${previewAudioCache[currentElementHash]}`);
			setIsPlaying(true);
			return;

		}

		// Requesting preview for New/Edited paragraph 
		setIsPlaying(false);
		setIsLoadingPreview(true);

		let content_id = contentId;

		if (!content_id) {
			const resp = await postContentService({
				orgId: org_id, integrationId: id, body: {
					title,
					slate_value: value,
					is_draft: true,
					composer_settings
				}
			})

			if (resp && resp.content_id) {
				content_id = resp.content_id;
				setContentId(content_id);
			}
		}

		if (!content_id) {
			return;
		}

		// dispatch(fetchContentRequested(org_id, id, content_id, true));
		// window.history.replaceState(null, title, `/organisation/${org_id}/integration/${id}/content/${content_id}`);

		const resp = await previewTTS(element, content_id);
		setIsLoadingPreview(false);
		if (resp.message) {
			toast.error(resp.message)
			return;
		}
		if (!resp.data) {
			toast.error("Something went wrong")
			return;
		}
		if (resp.data && resp.data.message) {
			toast.error(resp.data.message)
			return;
		}

		dispatch(putBlockAudio({
			hash: currentElementHash,
			value: {
				...resp.data
			}
		}))
		const audio_url = resp.data.audio_url;

		setPreviewAudioCache(prev => (
			{
				...prev,
				[currentElementHash]: audio_url,
			}
		))

		setAudio(`${process.env.REACT_APP_AUDIO_BUCKET}/${audio_url}`);
		setIsPlaying(true);

		dispatch(addMixPanelEvent({
			orgId: org_id, integrationId: id, contentId, body: {
				eventName: 'PREVIEW_TTS_BLOCK',
				eventData: {}
			}
		}))

	}

	if (wordsCount === 0) {
		return (
			<div className='preview-icon-wrapper disabled' onClick={(e) => { e.preventDefault() }}>
				<MemoizeImage className='action-icon' src={blockPlayIcon} alt="play" />
				<span>Preview</span>
			</div>
		);
	}

	if (isLoadingPreview && currentElementHash === playingElement) {
		return (
			<AudioLoadingComponent />
		);
	}

	/**
	 * @TODO Should check with currentElementIndex instead of currentElementHash
	 */
	if (isPlaying && currentElementHash === playingElement) {
		return (
			<div className='preview-icon-wrapper' onClick={onClick}>
				<MemoizeImage height="15" className='action-icon' src={pausePreviewEditor} alt="pause" />
				<DownloadParagraphButton />
				<span>Preview</span>
			</div>
		);
	}

	if (currentElementHash === playingElement && audio !== null) {

		return (
			<div className='preview-icon-wrapper' onClick={onClick}>
				<MemoizeImage className='action-icon' src={blockPlayIcon} alt="play" />
				<DownloadParagraphButton />
				<span>Preview</span>
			</div>
		);
	}

	return (
		<div className='preview-icon-wrapper' onClick={onClick}>
			<MemoizeImage className='action-icon' src={blockPlayIcon} alt="preview" />
			{/* <DownloadParagraphButton /> */}
			<span>Preview</span>
		</div>
	);
}

const AudioLoadingComponent = () => {
	return (
		<div className="preview-player-spinner">
			<div className="rect1"></div>
			<div className="rect2"></div>
			<div className="rect3"></div>
		</div>
	)
}