import React from "react";
import { useHistory, Link } from "react-router-dom";
import logo from "../assets/logo-full.png";

export default function CancelableFullWidthLayout({ children }) {
  const history = useHistory();

  return (
    <React.Fragment>
      <div className="container-fluid container-fluid--header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="header">
                <Link className="text-decoration-none" to="/">
                  <div className="d-flex align-items-center">
                    <img className="logo mr-1" src={logo} alt="logo" />
                  </div>
                </Link>
                <div
                  onClick={() => history.goBack()}
                  className="d-flex align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 1L1 11"
                      stroke="#48525B"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1 1L11 11"
                      stroke="#48525B"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="ml-2" style={{ fontSize: 18 }}>
                    Close
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {children}
    </React.Fragment>
  );
}
