import React from 'react';

export const Pipe = () => {
    return (
        <div style={{
            width: 1,
            background: 'rgb(137 163 189)',
            margin: '0 12px',
        }}></div>
    )
}