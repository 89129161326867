import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link } from "react-router-dom"
import SideBar from './SideBar';
import hamburgerIcon from '../assets/hamburger.svg';
import closeIcon from '../assets/close.svg';
import ChangeLogIcon from '../assets/menu/changelog.svg'
import Avatar from './Avatar';
import HelpCenter from './HelpCenter'
import TopBanner from "./TopBanner";
import { useSelector } from 'react-redux';
import { FREE_AND_PREVIEW_PLANS } from '../constants/constants';
import { UPGRADE_DASHBOARD } from '../constants/hotjar-events';
import { Button } from 'react-bootstrap';
import OrganisationUsage from './OrganisationUsage';
import { useGlobalStore } from '../zustand-store/useGlobalStore';
import { UpgradePlanModalV2 } from './plans/UpgradePlanModal';
const classNames = require('classnames');


export default function SideBarLayout({ children }) {
    const { id } = useParams();
    const sidebarRef = useRef()

    const [sidebarState, setSidebarState] = useState("hide");
    const organisation = useSelector(state => state.organisation.organisation);
    const toggleUpgradeModal = useGlobalStore((state) => state.toggleUpgradeModal)

    useEffect(() => {
        if (id) {
            localStorage.setItem("currentIntegration", id)
        }
    }, [id])

    return (
        <>
            <div className="app-container">

                <UpgradePlanModalV2 showCurrentPlan={true} />
                
                <div className={classNames("sidebar", { hide: "hide" === sidebarState }, { show: "show" === sidebarState })}>
                    <SideBar />
                </div>
                <div className={classNames("page", { push: "show" === sidebarState })}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <TopBanner inline />
                            </div>
                        </div>
                    </div>
                    <div className="top-nav">
                        {
                            ("hide" === sidebarState)
                                ?
                                (
                                    <img src={hamburgerIcon} className="d-lg-none" onClick={() => { setSidebarState("show") }} alt="hamburger icon" />
                                )
                                :
                                (
                                    <img src={closeIcon} className="d-lg-none" onClick={() => { setSidebarState("hide") }} alt="close icon" />
                                )
                        }
                        <div className="nav-menu">
                            <OrganisationUsage />

                            {organisation && !FREE_AND_PREVIEW_PLANS.includes(organisation.plan_id) && (
                                <Button onClick={() => {
                                    window.hj("event", UPGRADE_DASHBOARD);
                                    toggleUpgradeModal(true)
                                }} variant="outline-primary" className="upgrade-button mr-4">
                                    Upgrade
                                </Button>

                            )}
                            <div className="dropdown">
                                <div data-prodcamp-changelog className="avatar">
                                    <div className="icon">
                                        <img src={ChangeLogIcon} />
                                    </div>
                                </div>
                            </div>

                            <HelpCenter />
                            <Avatar />
                        </div>
                    </div>
                    <div className="content">
                        <div className="content-full">
                            {children}
                        </div>

                    </div>
                </div>
            </div>
        </>
    )

}