import React from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as SlateReact from "slate-react";

import { addMixPanelEvent } from "../../../store/actions";
import { insertPause, isPauseActive, unwrapPause } from "../../../utils/SlateUtils/PauseUtils";

import dropdownIcon from '../../../assets/down_chevron_primary.svg';
import { MemoizeImage } from "../Modals/MemoizeImage";

export const AddPause = ({ orgId, integrationId, contentId, onCustomPauseClick }) => {
    const dispatch = useDispatch();
    const editor = SlateReact.useSlate();

    const addPause = (e, duration) => {
        e.preventDefault();
        if (isPauseActive(editor)) {
            unwrapPause(editor);
        } else {
            insertPause(editor, duration);
            dispatch(addMixPanelEvent({
                orgId, integrationId, contentId, body: {
                    eventName: "ADD_PAUSE",
                    eventData: {
                        pause: duration
                    }
                }
            }))
        }
    }

    const CustomToggleContent = React.forwardRef(({ onClick }, ref) => (
        <button type="button" className="btn--add-pause" ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }} >
            Add pause
            <MemoizeImage className="ml-2 action-icon" width={9} height={6} src={dropdownIcon} alt="dropdown" />
        </button>
    ));

    return (
        <Dropdown as="span">
            <Dropdown.Toggle as={CustomToggleContent} />
            <Dropdown.Menu>
                <Dropdown.Item as="button" onClick={(e) => addPause(e, 0.2)}>
                    0.2s
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={(e) => addPause(e, 0.5)}>
                    0.5s
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={(e) => addPause(e, 1.0)}>
                    1s
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={(e) => addPause(e, 1.5)}>
                    1.5s
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={(e) => addPause(e, 2.0)}>
                    2s
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={(e) => {
                    e.preventDefault();
                    onCustomPauseClick();
                }}>
                    Custom pause
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );

};
