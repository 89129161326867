import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import EditorV2 from '../components/Editor';
import Spinner from '../components/Spinner';
import { fetchContentRequested, updateBlockAudios, updateSlateValue, updateCurrentAudioComposerBlock, updateTitle, generateComposerSettings, updatePublishedAt, toggleIncludeTitle, toggleIsAudioGenerating, fetchLanguagesRequested } from '../store/actions';

function EditContent(){

    const { org_id, id, content_id } = useParams();
    const dispatch = useDispatch();

    const isLoading = useSelector(state => state.edit_content.isLoading)
    const isError = useSelector(state => state.edit_content.isError)
    const content = useSelector((state) => state.edit_content.content)
    const slate_value = useSelector((state) => state.audio_composer.slate_value)
    const languages = useSelector((state) => state.voice.languages);

    const initialiseContentInStore = () => {
        dispatch(updateBlockAudios(content.block_audios || {}))

        if (!!slate_value) { // if slate value exists, content already initialised
            dispatch(updateSlateValue(content.slate_body, false))
        } else {
            dispatch(updateSlateValue(content.slate_body))
        }

        dispatch(updatePublishedAt(content.published_at))
        if (!content.composer_settings) {
            dispatch(generateComposerSettings())
        }
        dispatch(updateTitle(content.title))
        dispatch(updateCurrentAudioComposerBlock({
            sectionIndex: 0,
            trackIndex: 0,
            blockIndex: 0,
        }));
        const value = content.slate_body
        if (value && value.length > 0) {
            const isTitleAlreadyPresent = value[0]?.is_title;

            if (isTitleAlreadyPresent) {
                dispatch(toggleIncludeTitle(true));
            }
        }
    }

    useEffect(() => {

        dispatch(fetchLanguagesRequested());
        dispatch(fetchContentRequested(org_id, id, content_id));
        dispatch(toggleIsAudioGenerating(false));

    }, [org_id, id, content_id, dispatch]);

    useEffect(() => {

        if (content) {
            initialiseContentInStore()
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content, languages])

    if (isError) {
        return <></>;
    }

    return (
        !isLoading && content && slate_value
            ? <EditorV2 
                org_id={org_id} 
                id={id} 
                content_id={content_id} 
                slate_value={content.slate_body} 
                plainText={content.body}
                title_prop={content.title} 
                url={content.url}
            />
            : <Spinner /> 
    );

}

export default EditContent;
