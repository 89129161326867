import { call, put, takeEvery } from "redux-saga/effects";
import {
    FETCH_ORGANISATION_REQUESTED, FETCH_ORGANISATION_USAGE_REQUESTED,
} from "../actionTypes"

import {
    fetchOrganisationService, fetchOrganisationUsageService,
} from "../../services/organisation"

import {
    fetchOrganisationSuccess,
    fetchOrganisationFailure,
    fetchOrganisationUsageSuccess,
} from "../actions";
import Bugsnag from "@bugsnag/js";

function* fetchOrganisationSaga(action) {
    try {
        const organisation = yield call(fetchOrganisationService, action.payload);
        yield call(fetchOrhganisationUsageSaga, action);
        yield put(fetchOrganisationSuccess(organisation))
    } catch (e) {
        yield put(fetchOrganisationFailure(e));
    }
}

function* fetchOrhganisationUsageSaga(action) {
    try {
        const usage = yield call(fetchOrganisationUsageService, action.payload);
        yield put(fetchOrganisationUsageSuccess(usage))
    } catch (e) {
        console.log(e)
        Bugsnag.notify(e)
    }
}

function* organisationSaga() {
    yield takeEvery(FETCH_ORGANISATION_REQUESTED, fetchOrganisationSaga);
    yield takeEvery(FETCH_ORGANISATION_USAGE_REQUESTED, fetchOrhganisationUsageSaga);
}

export default organisationSaga;
