export const ADD_AUDIO_COMPOSER_TRACK = "ADD_AUDIO_COMPOSER_TRACK"

export const REMOVE_AUDIO_COMPOSER_TRACK = "REMOVE_AUDIO_COMPOSER_TRACK"

export const ADD_AUDIO_COMPOSER_BLOCK = "ADD_AUDIO_COMPOSER_BLOCK"

export const REMOVE_AUDIO_COMPOSER_BLOCK = "REMOVE_AUDIO_COMPOSER_BLOCK"

export const UPDATE_AUDIO_COMPOSER_BLOCK = "UPDATE_AUDIO_COMPOSER_BLOCK"

export const UPDATE_CURRENT_AUDIO_COMPOSER_BLOCK = "UPDATE_CURRENT_AUDIO_COMPOSER_BLOCK"

export const UPDATE_AUDIO_COMPOSER_SETTINGS = "UPDATE_AUDIO_COMPOSER_SETTINGS";

export const UPDATE_SLATE_VALUE = "UPDATE_SLATE_VALUE";

export const UPDATE_BLOCK_AUDIOS = "UPDATE_BLOCK_AUDIOS";

export const PUT_BLOCK_AUDIO  = "PUT_BLOCK_AUDIO";

export const TOGGLE_AUDIO_COMPOSER_AUDIO_UPLOAD_MODAL = "TOGGLE_AUDIO_COMPOSER_AUDIO_UPLOAD_MODAL"

export const TOGGLE_AUDIO_COMPOSER_GENERATE_MODAL = "TOGGLE_AUDIO_COMPOSER_GENERATE_MODAL"

export const TOGGLE_AUDIO_COMPOSER_DELETE_BLOCK_MODAL = "TOGGLE_AUDIO_COMPOSER_DELETE_BLOCK_MODAL"

export const UPDATE_AUDIO_COMPOSER_GENERATE_CURRENT_STEP = "UPDATE_AUDIO_COMPOSER_GENERATE_CURRENT_STEP"

export const UPDATE_TITLE = "UPDATE_TITLE";

export const SHOW_SAVING_IN_DRAFT_LABEL = "SHOW_SAVING_IN_DRAFT_LABEL";

export const EXIT_EDITOR = "EXIT_EDITOR";

export const UPDATE_AUDIO_COMPOSER_IS_CONTENT_CHANGED = "UPDATE_AUDIO_COMPOSER_IS_CONTENT_CHANGED"

export const UPDATE_AUDIO_COMPOSER_SECTIONS = "UPDATE_AUDIO_COMPOSER_SECTIONS"

export const TOGGLE_DOWNLOAD_FILE = "TOGGLE_DOWNLOAD_FILE";

export const UPDATE_EDITOR_DRAFT_VALUE = "UPDATE_EDITOR_DRAFT_VALUE"

export const TOGGLE_AUDIO_COMPOSER_BLOCK_ACTIONS = "TOGGLE_AUDIO_COMPOSER_BLOCK_ACTIONS"

export const UPDATE_AUDIO_COMPOSER_EXTRA_SETTINGS = "UPDATE_AUDIO_COMPOSER_EXTRA_SETTINGS"

export const UPDATE_AUDIO_COMPOSER_BLOCK_MOVE_LEFT = "UPDATE_AUDIO_COMPOSER_BLOCK_MOVE_LEFT"

export const UPDATE_AUDIO_COMPOSER_BLOCK_MOVE_RIGHT = "UPDATE_AUDIO_COMPOSER_BLOCK_MOVE_RIGHT"

export const ADD_AUDIO_COMPOSER_AUDIO_BLOCK = "ADD_AUDIO_COMPOSER_AUDIO_BLOCK"

export const UPDATE_COMPOSER_TTS_BLOCKS = "UPDATE_COMPOSER_TTS_BLOCKS"

export const INITIALIZE_AUDIO_COMPOSER_REQUESTED = "INITIALIZE_AUDIO_COMPOSER_REQUESTED"

export const INITIALIZE_AUDIO_COMPOSER_SUCCESS = "INITIALIZE_AUDIO_COMPOSER_SUCCESS"

export const GENERATE_COMPOSER_SETTINGS = 'GENERATE_COMPOSER_SETTINGS'

export const TOGGLE_NO_CREDITS_MODAL = 'TOGGLE_NO_CREDITS_MODAL'

export const TOGGLE_INCLUDE_TITLE = 'TOGGLE_INCLUDE_TITLE'

export const UPDATE_VOICE_FOR_ALL_BLOCKS = 'UPDATE_VOICE_FOR_ALL_BLOCKS'

export const TOGGLE_IS_AUDIO_GENERATING = "TOGGLE_IS_AUDIO_GENERATING"

export const SET_AUDIO_DOWNLOAD_MODE = "SET_AUDIO_DOWNLOAD_MODE"

export const TOGGLE_AUDIO_COMPOSER_PLAYER = "TOGGLE_AUDIO_COMPOSER_PLAYER"