import Bugsnag from '@bugsnag/js';
import { API } from 'aws-amplify';
import React, { ReactChild, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ReactHowler from 'react-howler';
import { useParams } from "react-router-dom";
import shallow from 'zustand/shallow'

import { PitchDropdown, SpeedSlider, StylesDropdown, VolumeSlider } from './VoiceConfigurations';
import Spinner from '../Spinner';

import { IVoice, useAIVoicesStore } from '../../zustand-store/useAIVoicesStore';
import { useSetDefaultProfile } from '../../http-hooks/integration/useSetDefaultProfile';

import clean from '../../utils/Clean';

import playPreviewIcon from '../../assets/play_preview.svg';
import pausePreviewIcon from '../../assets/pause_preview.svg';
import { useCreateProfile } from '../../http-hooks/ai-voices/useCreateProfile';
import { useVoiceConfigurationStore } from '../../zustand-store/useVoiceConfigurationStore';
import { useEditProfile } from '../../http-hooks/ai-voices/useEditProfile';
import { useSavedProfilesStore } from '../../zustand-store/useSavedProfilesStore';
import { queryClient } from '../..';

export type PageParams = {
    org_id: string;
    id: string;
};

export const SetDefaultVoiceModal = () => {

    const { org_id, id } = useParams<PageParams>();
    const mutation = useSetDefaultProfile({
        onSuccess: () => toast.success("Default profile saved successfully!")
    });
    const { selectedVoice, show, closeModal } = useAIVoicesStore((state) => ({
        selectedVoice: state.selectedVoice, 
        show: state.showSetDefaultProfileModal,
        closeModal: state.closeModal,
    }), shallow)
    const voiceConfiguration = useVoiceConfigurationStore((state) => state.voiceConfiguration)

    if (selectedVoice === null) {
        return <></>
    }

    return (
        <ConfigureVoiceModal 
            show={show}
            title={`Configure ${selectedVoice.name} (${selectedVoice.gender.charAt(0).toUpperCase()}) Voice`}
            onHide={closeModal}
            voice={selectedVoice}
            submitButtonText="Set Default"
            isSubmitting={mutation.isLoading}
            onSubmit={() => mutation.mutate({ org_id, id, profile: {
                ...voiceConfiguration,
                voice: selectedVoice.code,
                lang_code: selectedVoice.langCode,
                platform: selectedVoice.platform,
                engine: selectedVoice.engine
            }})}
        />
    )
}

export const EditCustomProfileModal = () => {

    const { org_id, id } = useParams<PageParams>();
    const editProfile = useEditProfile({
        onSuccess: () => {
            toast.success("Profile settings changed successfully");
            queryClient.refetchQueries({ queryKey: ['audio_profile'], type: 'active' })
        }
    })
    const editDefaultProfile = useSetDefaultProfile({
        onSuccess: () => {
            toast.success("Profile settings changed successfully");
            queryClient.refetchQueries({ queryKey: ['audio_profile'], type: 'active' })
        }
    })
    const { selectedProfile, show, closeModal, toggleChangeVoice } = useSavedProfilesStore((state) => ({
        selectedProfile: state.selectedProfile,
        show: state.showEditProfile,
        closeModal: state.closeModal,
        toggleChangeVoice: state.toggleChangeVoice,
    }), shallow)
    const voiceConfiguration = useVoiceConfigurationStore((state) => state.voiceConfiguration)
    const selectedVoice = useAIVoicesStore((state) => state.selectedVoice)

    if (!selectedProfile || !selectedVoice) {
        return <></>
    }

    const language = useAIVoicesStore.getState().languages[selectedVoice.langCode]

    return (
        <ConfigureVoiceModal
            show={show}
            title={`Edit Profile - ${selectedProfile.profile_name}`}
            voice={selectedVoice}
            onHide={closeModal}
            submitButtonText="Save Profile"
            isSubmitting={editProfile.isLoading || editDefaultProfile.isLoading}
            onSubmit={() => {
                if (!selectedProfile.id) {
                    editDefaultProfile.mutate({ org_id, id, profile: {
                        ...voiceConfiguration,
                        voice: selectedVoice.code,
                        lang_code: selectedVoice.langCode,
                        platform: selectedVoice.platform,
                        engine: selectedVoice.engine
                    }})
                } else {
                    editProfile.mutate({ org_id, id, profile_id: selectedProfile.id, profile: {
                        ...selectedProfile,
                        ...voiceConfiguration,
                        voice: selectedVoice.code,
                        lang_code: selectedVoice.langCode
                    }})
                }
            }}
        >
            <div style={{ width: '100%', background: '#F6F7FD' }} className='d-flex px-3 py-2 mb-3'>
                <div style={{color: '#333B6C'}} className='flex-grow-1'>
                    <span>Voice:</span>
                    <span style={{fontWeight: 600}}>{selectedVoice?.name}, {language.name} ({selectedVoice.gender.charAt(0).toUpperCase()})</span>
                </div>
                <button onClick={() => {
                    toggleChangeVoice()
                }} style={{ color: 'white', fontSize: 14, fontWeight: 500, borderRadius: 12, height: 24, background: '#007bff', border: 'none', padding: '2px 8px' }}>
                    Change Voice
                </button>
            </div>
        </ConfigureVoiceModal>
    )

}

export const CreateCustomProfileModal = () => {

    const { org_id, id } = useParams<PageParams>();

    const mutation = useCreateProfile({
        onSuccess: () => toast.success("New profile created successfully")
    });
    const { selectedVoice, show, closeModal } = useAIVoicesStore((state) => ({
        selectedVoice: state.selectedVoice, 
        show: state.showCreateCustomVoiceProfileModal, 
        closeModal: state.closeModal
    }), shallow)
    const voiceConfiguration = useVoiceConfigurationStore((state) => state.voiceConfiguration)

    const [profileName, setProfileName] = useState('')
    const [profileNameError, setProfileNameError] = useState('');

    if (selectedVoice === null) {
        return <></>
    }

    return (
        <ConfigureVoiceModal 
            show={show}
            title={`Configure ${selectedVoice.name} (${selectedVoice.gender.charAt(0).toUpperCase()}) Voice`}
            voice={selectedVoice}
            onHide={closeModal}
            submitButtonText="Create Profile"
            isSubmitting={mutation.isLoading}
            onSubmit={() => {
                if (profileName === '') {
                    setProfileNameError('Profile name cannot be empty')
                    return;
                }
                setProfileNameError('')
                mutation.mutate({ org_id, id, profile: {
                    ...voiceConfiguration,
                    profile_name: profileName,
                    voice: selectedVoice.code,
                    lang_code: selectedVoice.langCode,
                    platform: selectedVoice.platform,
                    engine: selectedVoice.engine
                }})
            }}
        >
            <div className="form-group col-12 mb-4">
                <label className="d-inline-flex align-items-center">
                    <span>Profile name</span>
                </label>
                <input type="text" className="form-control" value={profileName} onChange={(e) => {
                    setProfileName(e.target.value)
                }} />
                {
                    profileNameError && (
                        <span style={{fontSize: 13}} className="text-danger">{profileNameError}</span>
                    )
                }
            </div>
        </ConfigureVoiceModal>
    )
}

interface IConfigureVoiceModalProps {
    show: boolean
    title: string
    voice: IVoice
    onHide: () => void
    children?: ReactChild
    onSubmit: () => void
    isSubmitting: boolean
    submitButtonText: string
}

const ConfigureVoiceModal = ({children, title, voice, onHide, isSubmitting, onSubmit, submitButtonText, show} : IConfigureVoiceModalProps) => {

    return (
        <Modal show={show} onHide={() => {
            onHide()
        }} className="configure-voice-modal">
            <Modal.Header closeButton closeLabel='Close'>
                <span style={{color: '#333B6C', fontSize: 18, fontWeight: 'bold'}}>
                    {title}
                </span>
            </Modal.Header>
            <Modal.Body className='row'>
                {children}
                <div className='col-md-6'>
                    <VolumeSlider />
                    <SpeedSlider />
                    <PitchDropdown voice={voice} />
                    <StylesDropdown voice={voice} />
                </div>
                <div className="col-md-6">
                    <VoicePreviewTextArea />
                </div>
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', justifyContent: 'center'}}>
                <button disabled={isSubmitting} onClick={() => onSubmit()} className='btn btn-primary'>
                    {
                        isSubmitting
                        ? <Spinner color="#FFFFFF" />
                        : <>{submitButtonText}</>}
                </button>
            </Modal.Footer>
        </Modal>
    )
}

const VoicePreviewTextArea = () => {

    const selectedVoice = useAIVoicesStore((state) => state.selectedVoice);
    const voiceConfiguration = useVoiceConfigurationStore((state) => state.voiceConfiguration)

    const [text, setText] = useState("If you set your goals ridiculously high and it's a failure, you will fail above everyone else's success.");
    
    const [isLoadingAudioPreview, setIsLoadingAudioPreview] = useState(false);
    const [audio, setAudio] = useState<string | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const getAudioPreview = (data: Object) => {

        return API.post(`main`, `/audio/preview`,{body: clean(data), headers: {"Content-Type":"application/json"}});

    }

    const onPlayPreview = () => {

        if (!selectedVoice) {
            return;
        }

        if(text.length < 10){
            toast.error("Preview text cannot be less than 10 characters");
            return;
        }

        const isStyleAvailable = selectedVoice.styles.length > 0;
        const isPitchAvailable = selectedVoice.platform === 'polly' && selectedVoice.engine === 'neural';

        const profile = {
            voice: selectedVoice.code,
            lang_code: selectedVoice.langCode,
            platform: selectedVoice.platform,
            engine: selectedVoice.engine,
            speed: voiceConfiguration.speed,
            volume: voiceConfiguration.volume,
            ...((isStyleAvailable && voiceConfiguration.style !== 'default') && {style: voiceConfiguration.style}),
            ...((isPitchAvailable && voiceConfiguration.pitch !== 'default') && {pitch: voiceConfiguration.pitch})
        }



        let data = {slate_value: [
            {
                type: 'paragraph',
                children: [
                    { text: text },
                ]
            }
        ], ...profile};

        setIsLoadingAudioPreview(true);

        getAudioPreview(data).then((response)=>{

            setAudio(response.data.audio_url);
            setIsPlaying(true);

        }).catch((error)=>{

            toast.error("Something went wrong while generating audio preview.");
            Bugsnag.notify(error);

        }).finally(()=>{

            setIsLoadingAudioPreview(false);

        });

    }

    return (
        <>
            <div className="pb-0 mb-3">
                <p className="mb-2" style={{color : "#6A6A6A", fontWeight : 500}}>Test your voice settings</p>
                <textarea style={{resize: 'none', fontSize : 12}} className="form-control sample-text" rows={6} maxLength={200} onChange={(event) => setText(event.target.value)} defaultValue={text} />
                <p className="text-right mb-0"><small className="font-italic">{200 - text.length} characters left</small></p>
            </div>
            {
                isLoadingAudioPreview
                ? (
                    <button type="button" className="btn btn--play-preview">
                        <Spinner color="#3D4852" />
                    </button>
                )
                : (
                    (isPlaying)?
                        (
                            <button type="button" className="btn btn--play-preview" onClick={()=>{setIsPlaying(false)}} ><img className="mr-2" src={pausePreviewIcon} alt="play icon"/> Pause Preview</button>
                        )
                        :
                        (
                            <button type="button" className="btn btn--play-preview" onClick={onPlayPreview} ><img className="mr-2" src={playPreviewIcon} alt="play icon"/> Play Preview</button>
                        )
                )
            }
            {
                (audio !== null && audio !== undefined) &&
                <ReactHowler src={audio} playing={isPlaying} onEnd={()=>{setIsPlaying(false)}} />
            }
        </>
    )
}
