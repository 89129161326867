import { API } from 'aws-amplify';
import { useQuery } from "@tanstack/react-query";

export const fetchLanguages = () => {
    return API.get('main', `/voices`, {headers: {'Content-Type': "application/json"}});
}

export const useLanguages = (onSuccess: (data: any) => void) => {
    return useQuery(['languages'], fetchLanguages, {onSuccess: onSuccess, refetchOnWindowFocus: false})
}
