import { API } from 'aws-amplify';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, Link, useHistory } from "react-router-dom";
import Bugsnag from '@bugsnag/browser';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Spinner from '../components/Spinner';
import only from '../utils/Only';

import PODCAST_CATEGORIES from '../constants/podcast_categories.json';

import logoIcon from '../assets/logo.svg';
import leftArrowIcon from '../assets/arrow-left.svg';
import copyIcon from '../assets/copy.svg';
import useBeforeUnload from '../hooks/useBeforeUnload';
import ColorPicker from '../components/ColorPicker';

function CreatePodcast() {

    const { org_id, id, podcast_id } = useParams();
    const history = useHistory();
    const [podcast, setPodcast] = useState(null);
    const { register, reset, errors, handleSubmit, formState: { isDirty: formIsDirty } } = useForm();
    const [category, setCategory] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [posterFile, setPosterFile] = useState(null);
    const [selectedTab, setSelectedTab] = useState('distribution');

    const getPodcast = () => {
        return API.get('main', `/organisation/${org_id}/integration/${id}/podcast/${podcast_id}`);
    };

    useBeforeUnload({
        when: formIsDirty,
        message: 'Are you sure you want to leave?',
    })

    useEffect(() => {
        setIsLoading(true);
        getPodcast().then((response) => {

            const data = { ...response.data }

            setPodcast(data);

            reset(data);

            setCategory(data.category)

        }).finally(() => {
            setIsLoading(false);
        });
    }, [podcast_id]);

    const onCategoryChange = (event) => {
        setCategory(event.target.value)
    }

    const onSubmit = async (data) => {

        data = only(data, ['title', 'description', 'author', 'email', 'category', 'sub_category', 'is_explicit', 'is_automatic', 'is_published', 'poster_path'])

        setIsUpdating(true);

        try {

            if (posterFile !== null) {
                const signedResponse = await API.get(`main`, `/podast/cover/signed-url`, {
                    queryStringParameters: {
                        file_name: posterFile.name,
                        content_type: posterFile.type
                    }
                });

                const requestOptions = {
                    method: 'PUT',
                    body: posterFile,
                    redirect: 'follow'
                };

                const uploadURL = signedResponse.data.signed_url;
                const url = signedResponse.data.url;

                await fetch(uploadURL, requestOptions);

                data.poster_path = url;
            } else {

                data.poster_path = podcast.poster_path;

            }

            await API.put('main', `/organisation/${org_id}/integration/${id}/podcast/${podcast_id}`, { body: data, headers: { 'Content-Type': 'application/json' } });

            toast.success('New show settings saved!');
            history.push(`/organisation/${org_id}/integration/${id}/podcast`);

        } catch (error) {

            toast.error("Something went wrong while saving the Podcast show settings.")
            Bugsnag.notify(error);

        } finally {
            setIsUpdating(false);
        }
    };

    const PodcastPosterImagePicker = () => {
        return (
            <>
                <div className="poster-container d-flex align-items-center justify-content-center mx-auto mx-xl-0 position-relative mb-3">
                    <input type="file" id="poster" onChange={(event) => { setPosterFile(event.target.files[0]) }} />
                    <label htmlFor="poster" className="position-absolute">
                        {
                            (posterFile !== null)
                                ? (
                                    <img className="poster-image" alt="poster" src={URL.createObjectURL(posterFile)} />
                                )
                                : (podcast && podcast.poster_path)
                                    ? (
                                        <img className="poster-image" alt="poster" src={podcast.poster_path} />
                                    )
                                    : (
                                        <img width={64} alt="poster" src={logoIcon} />
                                    )
                        }
                    </label>
                </div>
            </>
        );
    };

    const HostingSettings = function ({ playerSettings, pageSettings }) {

        const { register, getValues, setValue, reset, handleSubmit, watch } = useForm();
        const [logoFile, setLogoFile] = useState(null);
        const [isUpdating, setIsUpdating] = useState(false);

        const watchAll = watch();
    
        useEffect(() => {
    
            reset({
                player_color: playerSettings.player_color,
                player_text_color: playerSettings.text_color,
                player_background_color: playerSettings.background_color,
                page_background_color: pageSettings.background_color,
                download_button: pageSettings.download_button,
                subscribe_button: pageSettings.subscribe_button,
                twitter_share: pageSettings.twitter_share,
                facebook_share: pageSettings.facebook_share,
                remove_branding: pageSettings.remove_branding,
            })
    
        }, [pageSettings, playerSettings]);
    
        const onUpdate = async (data) => {
    
            setIsUpdating(true);
    
            try {
    
                if (logoFile !== null) {
                    const signedResponse = await API.get(`main`, `/podast/logo/signed-url`, {
                        queryStringParameters: {
                            file_name: logoFile.name,
                            content_type: logoFile.type
                        }
                    });
    
                    const requestOptions = {
                        method: 'PUT',
                        body: logoFile,
                        redirect: 'follow'
                    };
    
                    const uploadURL = signedResponse.data.signed_url;
                    const url = signedResponse.data.url;
    
                    await fetch(uploadURL, requestOptions);
    
                    data.logo = url;
                } else {
    
                    data.logo = pageSettings.logo;
    
                }
    
                await API.put('main', `/organisation/${org_id}/integration/${id}/podcast/${podcast_id}/page`, { body: {
                    player_settings: {
                        player_color: data.player_color,
                        text_color: data.player_text_color,
                        background_color: data.player_background_color,
                    },
                    page_settings: {
                        background_color: data.page_background_color,
                        logo: data.logo,
                        facebook_share: data.facebook_share,
                        twitter_share: data.twitter_share,
                        download_button: data.download_button,
                        subscribe_button: data.subscribe_button,
                        remove_branding: data.remove_branding,
                    },
                }, headers: { 'Content-Type': 'application/json' } });
    
                toast.success('Podcast show settings changed successfully!');
                history.push(`/organisation/${org_id}/integration/${id}/podcast`);
    
            } catch (error) {
    
                toast.error("Something went wrong while saving the Podcast show settings.")
                Bugsnag.notify(error);
    
            } finally {
                setIsUpdating(false);
            }
    
        }
        
        return (
            <form className="col-xl-8" onSubmit={handleSubmit(onUpdate)}>

                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4">
                                <span className="form-section-title" style={{textTransform:"capitalize"}}>Page Settings</span>
                            </div>
                            <div className="col-md-8">
                                <div className="form-section-title mb-2">Logo</div>
                                <div className="logo-container d-flex align-items-center justify-content-center mx-xl-0 position-relative mb-3">
                                    <input type="file" id="poster" onChange={(event) => { setLogoFile(event.target.files[0]) }} />
                                    <label htmlFor="poster" className="position-absolute">
                                        {
                                            (logoFile !== null)
                                                ? (
                                                    <img className="logo-image" alt="poster" src={URL.createObjectURL(logoFile)} />
                                                )
                                                : (pageSettings.logo)
                                                    ? (
                                                        <img className="" alt="poster" width={160} style={{ objectFit: 'cover' }} src={pageSettings.logo} />
                                                    )
                                                    : (
                                                        <img width={64} alt="poster" src={logoIcon} />
                                                    )
                                        }
                                    </label>
                                </div>
                                <div className="form-group">
                                    <label>Background Color</label>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <ColorPicker defaultColor={getValues('page_background_color') ?? pageSettings.background_color} onChange={(color)=>{setValue('page_background_color',color);}}/>
                                            </span>
                                        </div>
                                        <input type="text" className="form-control" name="page_background_color" ref={register}/>
                                    </div>
                                </div>
                                <div className="form-check my-3 mt-5">
                                    <input className="form-check-input" type="checkbox" name="download_button" ref={register} />
                                    <label className="form-check-label">
                                        Download audio button
                                    </label>
                                </div>
                                <div className="form-check my-3">
                                    <input className="form-check-input" type="checkbox" name="facebook_share" ref={register} />
                                    <label className="form-check-label">
                                        Facebook Sharing
                                    </label>
                                </div>
                                <div className="form-check my-3">
                                    <input className="form-check-input" type="checkbox" name="twitter_share" ref={register} />
                                    <label className="form-check-label">
                                        Twitter Sharing
                                    </label>
                                </div>
                                <div className="form-check my-3">
                                    <input className="form-check-input" type="checkbox" name="remove_branding" ref={register} />
                                    <label className="form-check-label">
                                        Remove Listen2It Branding
                                    </label>
                                </div>
                                <div className="form-check my-3">
                                    <input className="form-check-input" type="checkbox" name="subscribe_button" ref={register} />
                                    <label className="form-check-label">
                                        Subscribe button (RSS Feed)
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mt-4">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4">
                                <span className="form-section-title" style={{textTransform:"capitalize"}}>Player Settings</span>
                            </div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label>Player Color</label>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <ColorPicker defaultColor={getValues('player_color') ?? playerSettings.player_color} onChange={(color)=>{setValue('player_color',color);}}/>
                                            </span>
                                        </div>
                                        <input type="text" className="form-control" name="player_color" ref={register}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Player Text Color</label>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <ColorPicker defaultColor={getValues('player_text_color') ?? playerSettings.text_color} onChange={(color)=>{setValue('player_text_color',color);}}/>
                                            </span>
                                        </div>
                                        <input type="text" className="form-control" name="player_text_color" ref={register} />
                                    </div>
                                </div>
                                <div className="form-group"> 
                                    <label>Player Background Color</label>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <ColorPicker defaultColor={getValues('player_background_color') ?? playerSettings.background_color} onChange={(color)=>{setValue('player_background_color',color);}}/>
                                            </span>
                                        </div>
                                        <input type="text" className="form-control" name="player_background_color" ref={register} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div className="row my-4">
                    <div className="col-12 text-right">
                        <Link to={`/organisation/${org_id}/integration/${id}/podcast`}><button className="btn btn-outline-primary mr-3" type="button">Cancel</button></Link>
                        {
                            (isUpdating) ?
                                (
                                    <button className="btn btn-primary" style={{ width: '145px' }} type="button">
                                        <Spinner color="#fff" />
                                    </button>
                                )
                                :
                                (
                                    <button className="btn btn-primary" style={{ width: '145px' }} type="submit">Save Changes</button>
                                )
                        }
                    </div>
                </div>
            </form>
        );
    }

    return (
        <>
            <div className="d-flex" style={{ gap: 4, color: 'var(--blue)', cursor: 'pointer' }} onClick={() => history.push(`/organisation/${org_id}/integration/${id}/podcast`)}>
                <img src={leftArrowIcon} width={18} />
                Back to podcast shows
            </div>
            <h3 className="page-title">Podcast Settings</h3>
            <p className="page-subtitle">Configure your podcast settings as per your requirements.</p>
            <ul className="nav nav-tabs mb-4">
                <li class="nav-item">
                    <a onClick={() => setSelectedTab('distribution')} className={classNames("nav-link ", { "active": selectedTab === 'distribution' })} aria-current="page" href="#">General</a>
                </li>
                <li class="nav-item">
                    <a onClick={() => setSelectedTab('hosting')} className={classNames("nav-link ", { "active": selectedTab === 'hosting' })} aria-current="page" href="#">Hosting Page</a>
                </li>
            </ul>
            {
                (selectedTab === 'distribution') && (podcast)
                    && (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row flex-xl-row-reverse justify-content-xl-end">
                                {
                                    (isLoading)
                                        ?
                                        (
                                            <div className="m-3">
                                                <Spinner color="#3d4852" />
                                            </div>
                                        )
                                        :
                                        (
                                            <>
                                                <div className="col-xl-8">
                                                    <div className="card mb-4">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <span className="form-section-title mb-4 mb-md-0 d-block">Podcast RSS URL</span>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <div className="form-group">
                                                                        <div className="input-group input-group--search">
                                                                            <input type="text" className="form-control border-right-0" value={`${process.env.REACT_APP_PODCAST_RSS_BASE_URL}/${org_id}/${id}/${podcast_id}`} readOnly />
                                                                            <div className="input-group-append border-left-0">
                                                                                <span className="input-group-text">
                                                                                    <CopyToClipboard text={`${process.env.REACT_APP_PODCAST_RSS_BASE_URL}/${org_id}/${id}/${podcast_id}`} onCopy={() => toast.success("Copied to clipboard")}>
                                                                                        <img src={copyIcon} style={{ cursor: 'pointer' }} alt="search icon" />
                                                                                    </CopyToClipboard>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <span className="form-section-title mb-4 mb-md-0 d-block">General</span>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <div className="form-group">
                                                                        <label>Podcast Title*</label>
                                                                        <input type="text" name="title" className={classNames("form-control", { "is-invalid": errors.title })} ref={register({ required: true, minLength: 3 })} />
                                                                        {
                                                                            errors.title &&
                                                                            <div className="invalid-feedback">
                                                                                {
                                                                                    ("required" === errors.title.type) && "Podcast title field is required."
                                                                                }
                                                                                {
                                                                                    ("minLength" === errors.title.type) && "Podcast Title field should be at least 3 character long."
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Podcast Description*</label>
                                                                        <textarea className="form-control" name="description" rows="3" ref={register({ required: true })} />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Podcast Author*</label>
                                                                        <input type="text" name="author" className={classNames("form-control", { "is-invalid": errors.author })} ref={register({ required: true, minLength: 3 })} />
                                                                        {
                                                                            errors.author &&
                                                                            <div className="invalid-feedback">
                                                                                {
                                                                                    ("required" === errors.author.type) && "Author Name field is required."
                                                                                }
                                                                                {
                                                                                    ("minLength" === errors.author.type) && "Author Name field should be at least 3 character long."
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Author email*</label>
                                                                        <input type="email" name="email" className={classNames("form-control", { "is-invalid": errors.author })} ref={register({ required: true, pattern: /^\S+@\S+\.\S+$/i })} />
                                                                        {
                                                                            errors.email &&
                                                                            <div className="invalid-feedback">
                                                                                {
                                                                                    ("required" === errors.email.type) && "Author email field is required."
                                                                                }
                                                                                {
                                                                                    ("pattern" === errors.email.type) && "Author email field should be a valid email address"
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Category*</label>
                                                                        <select className={classNames("custom-select", { "is-invalid": errors.category })} name="category" ref={register({ required: true })} onChange={onCategoryChange}>
                                                                            {
                                                                                Object.keys(PODCAST_CATEGORIES).map((category, i) => {
                                                                                    return (
                                                                                        <option value={category}>{category}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                        {
                                                                            errors.category &&
                                                                            <div className="invalid-feedback">
                                                                                {
                                                                                    ("required" === errors.category.type) && "Category field is required."
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Sub-Category</label>
                                                                        <select className={classNames("custom-select", { "is-invalid": errors.sub_category })} name="sub_category" ref={register({})} disabled={category === null || PODCAST_CATEGORIES[category].length === 0}>
                                                                            {
                                                                                category !== null &&
                                                                                PODCAST_CATEGORIES[category].map((sub_category, i) => {
                                                                                    return (
                                                                                        <option value={sub_category}>{sub_category}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Language</label>
                                                                        <select className={classNames("custom-select")} name="lang_code" disabled value={"English"}>
                                                                            <option value="English">English</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="form-check my-3">
                                                                        <input name="is_explicit" className="form-check-input" type="checkbox" ref={register()} onChange={() => { }} />
                                                                        <label>
                                                                            Contains explicit content
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card my-4 ">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-md-4 mb-3 mb-md-0">
                                                                    <span className="form-section-title mb-0 mb-xl-2 d-block">Podcast Image*</span>
                                                                    {
                                                                        errors.is_automatic &&
                                                                        <div className="invalid-feedback d-block">
                                                                            {
                                                                                ("required" === errors.is_automatic.type) && "Distribution field is required."
                                                                            }
                                                                        </div>
                                                                    }
                                                                    <span className="form-section-sub-title d-block">The image should be square and atleast 1400x1400 pixels in size. We support images in jpg, png, gif and tiff formats up to a maximum of 4MB in size.</span>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <PodcastPosterImagePicker />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card my-4 ">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <span className="form-section-title mb-4 mb-md-0 d-block">Status</span>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <div class="custom-control custom-switch">
                                                                        <input type="checkbox" class="custom-control-input" id="is_published" name="is_published" ref={register()} />
                                                                        <label class="custom-control-label" for="is_published">Publish this Podcast?</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card my-4 ">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-md-4 mb-3 mb-md-0">
                                                                    <span className="form-section-title mb-0 mb-xl-2 d-block">Automatic Publishing</span>
                                                                    {
                                                                        errors.is_automatic &&
                                                                        <div className="invalid-feedback d-block">
                                                                            {
                                                                                ("required" === errors.is_automatic.type) && "Distribution field is required."
                                                                            }
                                                                        </div>
                                                                    }
                                                                    <span className="form-section-sub-title d-block">All your audios will be automatically added (as soon as they are created) as podcast episodes and published if this is enabled. You can still remove them later if you want to.</span>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <div class="custom-control custom-switch">
                                                                        <input disabled={true} type="checkbox" class="custom-control-input" id="is_automatic" name="is_automatic" ref={register()} />
                                                                        <label class="custom-control-label" for="is_automatic">Automatically publish all my audios as episodes in this show</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="row my-4">
                                                        <div className="col-12 text-right">
                                                            <Link to={`/organisation/${org_id}/integration/${id}/podcast`}><button className="btn btn-outline-primary mr-3" type="button">Cancel</button></Link>
                                                            {
                                                                (isUpdating) ?
                                                                    (
                                                                        <button className="btn btn-primary" style={{ width: '145px' }} type="button">
                                                                            <Spinner color="#fff" />
                                                                        </button>
                                                                    )
                                                                    :
                                                                    (
                                                                        <button className="btn btn-primary" style={{ width: '145px' }} type="submit">Save Changes</button>
                                                                    )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                }
                            </div>
                        </form>
                    )
            }
            {
                ('hosting' === selectedTab) && (podcast)
                    && (
                        <HostingSettings playerSettings={podcast.player_settings} pageSettings={podcast.page_settings} />
                    )
            }
        </>
    );
}

export default CreatePodcast;
