import { withHistory } from "slate-history";
import { withReact } from "slate-react";
import { createEditor, Transforms } from "slate";
import { defaultTrackValue, defaultBlockValue, TIME_PIXEL_CONVERSION } from "../../components/composer/utils";
import {
    ADD_AUDIO_COMPOSER_TRACK,
    REMOVE_AUDIO_COMPOSER_TRACK,
    ADD_AUDIO_COMPOSER_AUDIO_BLOCK,
    REMOVE_AUDIO_COMPOSER_BLOCK,
    UPDATE_AUDIO_COMPOSER_BLOCK,
    UPDATE_CURRENT_AUDIO_COMPOSER_BLOCK,
    UPDATE_AUDIO_COMPOSER_SETTINGS,
    UPDATE_SLATE_VALUE,
    UPDATE_BLOCK_AUDIOS,
    PUT_BLOCK_AUDIO,
    TOGGLE_AUDIO_COMPOSER_AUDIO_UPLOAD_MODAL,
    TOGGLE_AUDIO_COMPOSER_GENERATE_MODAL,
    TOGGLE_AUDIO_COMPOSER_DELETE_BLOCK_MODAL,
    UPDATE_AUDIO_COMPOSER_GENERATE_CURRENT_STEP,
    UPDATE_TITLE,
    SHOW_SAVING_IN_DRAFT_LABEL,
    EXIT_EDITOR,
    UPDATE_AUDIO_COMPOSER_IS_CONTENT_CHANGED,
    UPDATE_AUDIO_COMPOSER_SECTIONS,
    TOGGLE_DOWNLOAD_FILE,
    TOGGLE_AUDIO_COMPOSER_BLOCK_ACTIONS,
    UPDATE_AUDIO_COMPOSER_EXTRA_SETTINGS,
    UPDATE_AUDIO_COMPOSER_BLOCK_MOVE_RIGHT,
    UPDATE_AUDIO_COMPOSER_BLOCK_MOVE_LEFT,
    INITIALIZE_AUDIO_COMPOSER_SUCCESS,
    UPDATE_COMPOSER_TTS_BLOCKS,
    GENERATE_COMPOSER_SETTINGS,
    TOGGLE_NO_CREDITS_MODAL,
    TOGGLE_INCLUDE_TITLE,
    TOGGLE_IS_AUDIO_GENERATING,
    SET_AUDIO_DOWNLOAD_MODE,
    APPLY_EDITOR_TITLE_SETTINGS,
    TOGGLE_AUDIO_COMPOSER_PLAYER,
} from "../actionTypes";
import { withBlockLevelCharactersLimit, withInlines } from "../../utils/SlatePlugins";

const initialState = {
    // sections : defaultSectionsValue(),
    sections : null,
    currentBlock : null,
    isLoading : false,
    title: 'Untitled audio',
    showSavingInDraftLabel: false,
    slate_value: null,
    block_audios: {},
    editor: withBlockLevelCharactersLimit(withInlines(withHistory(withReact(createEditor())))),
    audioTrackUploadModalShow : false,
    audioGenerateModalShow : false,
    deleteBlockModalShow : false,
    audioGenerateCurrentStep : 0,
    isContentChanged : false,
    contentURL: null,
    shouldDownloadFile: false,
    audioDownloadMode: 0,
    isBlockActionsActive : false,
    extraSettings : {
        zoomLevel : TIME_PIXEL_CONVERSION
    },
    isInitialized : false,
    showNoCreditsModal: false,
    includeTitle: false,
    isAudioGenerating : false,
    isComposerActive : true,
};
  
export default function integrationReducer(state = initialState, action) {
    const { type } = action;
  
    switch (type) {
        case TOGGLE_AUDIO_COMPOSER_PLAYER: {
            return {
                ...state, 
                isComposerActive : action.payload
            }
        }

        case APPLY_EDITOR_TITLE_SETTINGS: {
            return {
                ...state,
                includeTitle : action.payload.includeTitle,
                title : action.payload.title
            }
        }

        case TOGGLE_IS_AUDIO_GENERATING: {
            return {
                ...state,
                isAudioGenerating : action.payload
            }
        }

        case TOGGLE_NO_CREDITS_MODAL: {
            return {
                ...state,
                showNoCreditsModal: action.payload
            }
        }

        case UPDATE_AUDIO_COMPOSER_IS_CONTENT_CHANGED: {
            
            return {
                ...state,
                isContentChanged : action.payload
            }
        }

        case ADD_AUDIO_COMPOSER_TRACK: {
            let sections = [...state.sections]
            sections[action.payload.sectionIndex].tracks.push(defaultTrackValue())
            
            return {
                ...state,
                sections,
            }
        }

        case REMOVE_AUDIO_COMPOSER_TRACK: {
            let sections = [...state.sections]
            sections[action.payload.sectionIndex].tracks.splice(action.payload.trackIndex)
            
            return {
                ...state,
                sections,
            }
        }

        case UPDATE_AUDIO_COMPOSER_SECTIONS: {
            return {
                ...state,
                sections: action.payload,
            }
        }

        case UPDATE_COMPOSER_TTS_BLOCKS: {
            if (!state.sections) return {...state}
            let sections = [...state.sections]
            sections[0].tracks[0].blocks = action.payload;

            return {
                ...state,
                sections: [...sections],
            }
        }

        case ADD_AUDIO_COMPOSER_AUDIO_BLOCK: {
            const sectionIndex = 1
            const { trackIndex, data } = action.payload
            
            let sections = [...state.sections]
            sections[sectionIndex].tracks[trackIndex].blocks.push({ ...defaultBlockValue(), ...data }) 

            return {
                ...state,
                sections: sections,
                isContentChanged: true
            }
        }

        case REMOVE_AUDIO_COMPOSER_BLOCK: {
            const { sectionIndex, trackIndex, blockIndex } = action.payload

            if(sectionIndex === 0){
                Transforms.removeNodes(state.editor, {at: [blockIndex]})
                return state
            }
            
            let sections = [...state.sections]
            sections[sectionIndex].tracks[trackIndex].blocks.splice(blockIndex, 1)
            
            return {
                ...state,
                sections: sections,
                isContentChanged : true,
                deleteBlockModalShow : false,
                // currentBlock: null
            }
        }

        case UPDATE_CURRENT_AUDIO_COMPOSER_BLOCK : {
            return {
                ...state,
                currentBlock : action.payload,
            }
        }

        case UPDATE_AUDIO_COMPOSER_BLOCK: {
            const {sectionIndex, trackIndex, blockIndex} = action.payload
            let sections = [...state.sections]
            const newBlock = {...sections[sectionIndex].tracks[trackIndex].blocks[blockIndex], ...action.payload.data}
            sections[sectionIndex].tracks[trackIndex].blocks[blockIndex] = newBlock

            let newState = {
                ...state,
                sections,
                isContentChanged : true
            }

            return newState
        }

        case UPDATE_AUDIO_COMPOSER_SETTINGS: {

            return {
                ...state,
                sections: action.payload,
                //isContentChanged : true
            }
        }

        case UPDATE_SLATE_VALUE: {
            let slate_value = action.payload;
            const contentChanged = action.contentChanged;

            return {
                ...state,
                slate_value,
                isContentChanged : contentChanged
            }
        }

        case UPDATE_BLOCK_AUDIOS: {
            return {
                ...state,
                block_audios: action.payload,
                //isContentChanged : true
            }
        }

        case PUT_BLOCK_AUDIO: {
            const { hash, value } = action.payload;
            return {
                ...state,
                block_audios: {
                    ...state.block_audios,
                    [hash]: value
                },
                isContentChanged : true
            }
        }

        case TOGGLE_AUDIO_COMPOSER_AUDIO_UPLOAD_MODAL: {
            return {
                ...state,
                audioTrackUploadModalShow : action.payload
            }
        }

        case TOGGLE_AUDIO_COMPOSER_GENERATE_MODAL : {
            return {
                ...state,
                audioGenerateModalShow : action.payload
            }
        }

        case TOGGLE_AUDIO_COMPOSER_DELETE_BLOCK_MODAL : {
            return {
                ...state,
                deleteBlockModalShow : action.payload
            }
        }

        case UPDATE_AUDIO_COMPOSER_GENERATE_CURRENT_STEP : {
            return {
                ...state,
                audioGenerateCurrentStep : action.payload
            }
        }

        case UPDATE_TITLE: {
            return {
                ...state,
                title: action.payload
            }
        }

        case SHOW_SAVING_IN_DRAFT_LABEL: {
            return {
                ...state,
                showSavingInDraftLabel: action.payload
            }
        }

        case EXIT_EDITOR: {
            return {
                ...state,
            }
        }

        case TOGGLE_DOWNLOAD_FILE: {
            return {
                ...state,
                shouldDownloadFile: action.payload
            }
        }

        case SET_AUDIO_DOWNLOAD_MODE: {
            return {
                ...state,
                audioDownloadMode: action.payload
            }
        }

        case TOGGLE_AUDIO_COMPOSER_BLOCK_ACTIONS: {
            return {
                ...state,
                isBlockActionsActive : action.payload
            }
        }

        case UPDATE_AUDIO_COMPOSER_EXTRA_SETTINGS : {
            return {
                ...state,
                extraSettings : {
                    ...state.extraSettings,
                    ...action.payload
                }
            }
        }

        case UPDATE_AUDIO_COMPOSER_BLOCK_MOVE_RIGHT: {

            const newState = {
                ...state
            }

            const blockIndex = action.payload;
            if (blockIndex === state.slate_value.length - 1) {
                return newState;
            }

            Transforms.moveNodes(state.editor, {
                at: [blockIndex],
                to: [blockIndex + 1]
            })

            action.payload = {
                didSwap: true,
                blockIndex: blockIndex + 1
            };

            return state
        }

        case UPDATE_AUDIO_COMPOSER_BLOCK_MOVE_LEFT: {
            
            const newState = {
                ...state
            }

            const blockIndex = action.payload;
            if (blockIndex === 0) {
                return newState;
            }

            Transforms.moveNodes(state.editor, {
                at: [blockIndex],
                to: [blockIndex - 1]
            })

            action.payload = {
                didSwap: true,
                blockIndex: blockIndex - 1
            };

            return state;
        }

        case INITIALIZE_AUDIO_COMPOSER_SUCCESS: {
            return {
                ...state,
                extraSettings : {
                    zoomLevel : TIME_PIXEL_CONVERSION
                },
                isInitialized : true,
                isBlockActionsActive : false
            }
        }

        case GENERATE_COMPOSER_SETTINGS: {
            return {
                ...state
            }
        }

        case TOGGLE_INCLUDE_TITLE: {
            return {
                ...state,
                includeTitle: action.payload
            }
        }

      default:
        return state;
    }
}
