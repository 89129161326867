export const FETCH_CONTENT_REQUESTED = "FETCH_CONTENT_REQUESTED"

export const FETCH_CONTENT_BLOCKS_REQUESTED = "FETCH_CONTENT_BLOCKS_REQUESTED";

export const FETCH_CONTENT_SUCCESS = "FETCH_CONTENT_SUCCESS"

export const FETCH_CONTENT_FAILURE = "FETCH_CONTENT_FAILURE"

export const SET_CONTENT = "SET_CONTENT"

export const UPDATE_PUBLISHED_AT = "UPDATE_PUBLISHED_AT"

export const SET_CONTENT_ID = "SET_CONTENT_ID"

export const PLAY_TTS_BLOCK_PREVIEW = "PLAY_TTS_BLOCK_PREVIEW"

export const STOP_TTS_BLOCK_PREVIEW = "STOP_TTS_BLOCK_PREVIEW"

export const TOGGLE_PRONUNCIATION_LIBRARY_MODAL = "TOGGLE_PRONUNCIATION_LIBRARY_MODAL"

export const TOGGLE_DOWNLOAD_CONTENT_MODAL = "TOGGLE_DOWNLOAD_CONTENT_MODAL"

export const APPLY_EDITOR_TITLE_SETTINGS = "APPLY_EDITOR_TITLE_SETTINGS"

export const TOGGLE_EDITOR_VOICE_PICKER_MODAL = "TOGGLE_EDITOR_VOICE_PICKER_MODAL"

export const TOGGLE_EDITOR_ADD_FROM_URL_MODAL = "TOGGLE_EDITOR_ADD_FROM_URL_MODAL"