import { API } from 'aws-amplify';
import { useQueries } from "@tanstack/react-query";

export const fetchAnalyticsOverview = (org_id: string, integration_id: string, start_date: string, end_date: string) => {
    return API.get('main', `/organisation/${org_id}/integration/${integration_id}/analytics?start_date=${start_date}&end_date=${end_date}`, {headers: {'Content-Type': "application/json"}});
}

export const fetchAnalyticsNewAndRepeatedListeners = (org_id: string, integration_id: string, start_date: string, end_date: string) => {
    return API.get('main', `/organisation/${org_id}/integration/${integration_id}/analytics/new_repeated_listeners?start_date=${start_date}&end_date=${end_date}`, {headers: {'Content-Type': "application/json"}});
}

export const fetchAnalyticsTrends = (org_id: string, integration_id: string, start_date: string, end_date: string) => {
    return API.get('main', `/organisation/${org_id}/integration/${integration_id}/analytics/trends?start_date=${start_date}&end_date=${end_date}`, {headers: {'Content-Type': "application/json"}});
}

export const fetchAnalyticsBrowsers = (org_id: string, integration_id: string, start_date: string, end_date: string) => {
    return API.get('main', `/organisation/${org_id}/integration/${integration_id}/analytics/browsers?start_date=${start_date}&end_date=${end_date}`, {headers: {'Content-Type': "application/json"}});
}

export const fetchAnalyticsCountries = (org_id: string, integration_id: string, start_date: string, end_date: string) => {
    return API.get('main', `/organisation/${org_id}/integration/${integration_id}/analytics/country?start_date=${start_date}&end_date=${end_date}`, {headers: {'Content-Type': "application/json"}});
}

export const fetchAnalyticsOperatingSystems = (org_id: string, integration_id: string, start_date: string, end_date: string) => {
    return API.get('main', `/organisation/${org_id}/integration/${integration_id}/analytics/operating_systems?start_date=${start_date}&end_date=${end_date}`, {headers: {'Content-Type': "application/json"}});
}

export const fetchAnalyticsDevices = (org_id: string, integration_id: string, start_date: string, end_date: string) => {
    return API.get('main', `/organisation/${org_id}/integration/${integration_id}/analytics/devices?start_date=${start_date}&end_date=${end_date}`, {headers: {'Content-Type': "application/json"}});
}

interface IuseAnalyticsOverviewProps {
    org_id: string
    integration_id: string
    start_date: string
    end_date: string
}

export const useAnalyticsOverview = ({org_id, integration_id, start_date, end_date}: IuseAnalyticsOverviewProps) => {
    return useQueries({
        queries: [
            { 
                queryKey: ['analytics', 'overview', org_id, integration_id, start_date, end_date],
                queryFn: () => fetchAnalyticsOverview(org_id, integration_id, start_date, end_date)
            },
            { 
                queryKey: ['analytics', 'new_repeated_listeners', org_id, integration_id, start_date, end_date],
                queryFn: () => fetchAnalyticsNewAndRepeatedListeners(org_id, integration_id, start_date, end_date)
            },
            { 
                queryKey: ['analytics', 'trends', org_id, integration_id, start_date, end_date],
                queryFn: () => fetchAnalyticsTrends(org_id, integration_id, start_date, end_date)
            },
            { 
                queryKey: ['analytics', 'browsers', org_id, integration_id, start_date, end_date],
                queryFn: () => fetchAnalyticsBrowsers(org_id, integration_id, start_date, end_date)
            },
            { 
                queryKey: ['analytics', 'countries', org_id, integration_id, start_date, end_date],
                queryFn: () => fetchAnalyticsCountries(org_id, integration_id, start_date, end_date)
            },
            { 
                queryKey: ['analytics', 'operating_systems', org_id, integration_id, start_date, end_date],
                queryFn: () => fetchAnalyticsOperatingSystems(org_id, integration_id, start_date, end_date)
            },
            { 
                queryKey: ['analytics', 'devices', org_id, integration_id, start_date, end_date],
                queryFn: () => fetchAnalyticsDevices(org_id, integration_id, start_date, end_date)
            }
        ]
    })
}
