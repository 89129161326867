import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ImportFromURL from '../../components/ImportFromURL';
import {toggleEditorVoicePickerModal, toggleEditorAddFromURLModal, togglePronunciationLibraryModal, toggleAudioComposerAudioUploadModal} from "../../store/actions"

// Images
import AIVoicesIcon from '../../assets/editor/ai-voices.svg'
import ImportURLIcon from '../../assets/editor/import-url.svg'
import AddMusicIcon from '../../assets/editor/add-music.svg'
import PronunciaionLibEditorImg from '../../assets/pronunciation-lib-editor.svg'

export default ({ org_id, id, contentId, setShowRefetchConfirmationModal }) => {

	// const history = useHistory();
	const dispatch = useDispatch()

	const { showVoicePickerModal : showVoicePicker, content } = useSelector(state => state.edit_content)

	return useMemo(() => (
		<>
			<div className="editor-left-menu">
				<div onClick={() => dispatch(toggleEditorVoicePickerModal(!showVoicePicker))} className="editor-left-menu--item">
					<img src={AIVoicesIcon} />
					<span>AI Voices</span>
				</div>

				{(content && content.url) && (

					<div onClick={() => setShowRefetchConfirmationModal(true)} className="editor-left-menu--item">
						<img src={ImportURLIcon} />
						<span>Refetch</span>
					</div>

				)}
				
				{(content && !content.url) && (
					<div onClick={() => dispatch(toggleEditorAddFromURLModal(true))} className="editor-left-menu--item">
						<span style={{top : 6, right : 15, position : "absolute", textAlign: "center", background: "#2D79F6", borderRadius: 5, color: "#fff", fontSize : 8, width: 30, height: 12, lineHeight : "9px", display : "flex", justifyContent : "center", alignItems: "center"}}>Beta</span>
						<img src={ImportURLIcon} />
						<span>Import</span>
					</div>
				)}

				<div onClick={() => dispatch(toggleAudioComposerAudioUploadModal(true))} className="editor-left-menu--item">
					<img src={AddMusicIcon} />
					<span>Add Music</span>
				</div>

				<div onClick={() => dispatch(togglePronunciationLibraryModal(true))} className="editor-left-menu--item">
					<img src={PronunciaionLibEditorImg} />
					<span>Pronunciation</span>
				</div>
			</div>

			<ImportFromURL orgId={org_id} integrationId={id} contentId={contentId} />
		</>

	), [org_id, id, contentId, setShowRefetchConfirmationModal, showVoicePicker, content])
}