import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { FREE_AND_PREVIEW_PLANS } from '../constants/constants';
import { UPGRADE_DASHBOARD } from '../constants/hotjar-events';
import { fetchOrganisationUsageRequested } from '../store/actions';
import upgradeBtnRightArrowIcon from "../assets/menu/upgrade-btn-right-arrow.svg"
import { useGlobalStore } from '../zustand-store/useGlobalStore';

const OrganisationUsage = () => {

    const wordCreditsRef = useRef()
    const wordCreditsModalRef = useRef()
    const [isModalShow, setIsModalShow] = useState(false)
    const [modalX, setModalX] = useState(0)
    const orgUsage = useSelector(state => state.organisation.usage);
    const organisation = useSelector((state) => state.organisation.organisation);
    const toggleUpgradeModal = useGlobalStore((state) => state.toggleUpgradeModal)

    const dispatch = useDispatch();

    const { org_id } = useParams();
    const getRenewalDateString = (timestamp) => {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ]
        const date = new Date(timestamp);
        return `${date.getDate()}-${monthNames[date.getMonth()]}-${date.getFullYear()}`;
    }

    const toggleModal = () => {
        setModalX(wordCreditsRef.current.getBoundingClientRect()['x'])
        setIsModalShow(!isModalShow)
    }

    useEffect(() => {
        if (orgUsage === null) {
            dispatch(fetchOrganisationUsageRequested(org_id))
        }
    }, [orgUsage, org_id, dispatch])

    return (
        <>
            {
                (organisation && orgUsage && orgUsage.subscription_status) ? ((![...FREE_AND_PREVIEW_PLANS].includes(organisation.plan_id)) ? (<>
                    <span ref={wordCreditsRef} onClick={() => toggleModal()} className="editor--words-remaining mr-3">
                        <span className="words-count">{(orgUsage.words_remaining).toLocaleString() + " "}</span> words remaining
                    </span>
                    {
                        isModalShow && (
                            <div ref={wordCreditsModalRef} style={{ left: `calc(${modalX}px - 225px)` }} className="org-words-remaining-modal">
                                <div className='row'>
                                    <div className='col-12 text-center mb-1'>
                                        <span className="highlight">{(orgUsage.words_remaining).toLocaleString()}</span> of {(orgUsage.plan_words_limit).toLocaleString()} words remaining
                                    </div>
                                    <div className='col-12 text-center'>
                                        {
                                            organisation && ![...FREE_AND_PREVIEW_PLANS].includes(organisation.plan_id)
                                            && (
                                                <span className='mt-4'>
                                                    {orgUsage.subscription_status === 'in_trial' ? 'Trial expires' : 'Renews'} <span className="highlight">{getRenewalDateString(orgUsage.renews_on)}</span>
                                                </span>
                                            )
                                        }
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12 mt-2'>

                                        <Button onClick={() => {
                                            window.hj("event", UPGRADE_DASHBOARD);
                                            toggleUpgradeModal(true)
                                        }} variant="outline-primary" className="upgrade-button">
                                            Get More Words
                                        </Button>

                                    </div>
                                </div>
                            </div>
                        )
                    }
                </>) : (<span onClick={() => {
                    window.hj("event", UPGRADE_DASHBOARD);
                    toggleUpgradeModal(true)
                }} className="editor--words-remaining-preview mr-3">{(orgUsage.words_remaining).toLocaleString() + " "} words left | <span style={{ cursor: "pointer", textDecoration: "underline" }}>Upgrade For More <img alt="right-arrow" src={upgradeBtnRightArrowIcon} /></span></span>)) : null
            }
        </>
    )
}

export default OrganisationUsage;