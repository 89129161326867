import {
    FETCH_INTEGRATION_REQUESTED,
    FETCH_INTEGRATION_SUCCESS,
    FETCH_INTEGRATION_FAILURE,
    CREATE_INTEGRATION_REQUESTED,
    CREATE_INTEGRATION_SUCCESS,
    CREATE_INTEGRATION_FAILURE,
    FETCH_INTEGRATION_PROFILES_REQUESTED,
    FETCH_INTEGRATION_PROFILES_SUCCESS,
    FETCH_INTEGRATION_PROFILES_FAILURE,
    DELETE_AUDIO_PROFILE_REQUESTED,
    DELETE_AUDIO_PROFILE_SUCCESS,
    DELETE_AUDIO_PROFILE_FAILURE,
    SET_DEFAULT_AUDIO_PROFILE,
    UPDATE_PRONUNCIATION_LIBRARY
} from "../actionTypes"

export const fetchIntegrationRequested = (org_id, id) => {
    return {
        type: FETCH_INTEGRATION_REQUESTED,
        payload: {org_id, id},
    };
};

export const fetchIntegrationSuccess = (data) => {
    return {
        type: FETCH_INTEGRATION_SUCCESS,
        payload: data,
    };
};

export const fetchIntegrationFailure = (data) => {
    return {
        type: FETCH_INTEGRATION_FAILURE,
        payload: data,
    };
};

export const createIntegrationRequested = (org_id, body) => {
    return {
        type: CREATE_INTEGRATION_REQUESTED,
        payload: {org_id, body},
    };
};

export const createIntegrationSuccess = (data) => {
    return {
        type: CREATE_INTEGRATION_SUCCESS,
        payload: data,
    };
};

export const createIntegrationFailure = (data) => {
    return {
        type: CREATE_INTEGRATION_FAILURE,
        payload: data,
    };
};

export const fetchIntegrationProfilesRequested = (org_id, id) => {
    return {
        type: FETCH_INTEGRATION_PROFILES_REQUESTED,
        payload: {org_id, id},
    };
};

export const fetchIntegrationProfilesSuccess = (data) => {
    return {
        type: FETCH_INTEGRATION_PROFILES_SUCCESS,
        payload: data,
    };
};

export const fetchIntegrationProfilesFailure = (data) => {
    return {
        type: FETCH_INTEGRATION_PROFILES_FAILURE,
        payload: data,
    };
};

export const deleteAudioProfileRequested = (org_id, integration_id, id) => {
    return {
        type: DELETE_AUDIO_PROFILE_REQUESTED,
        payload: {
            org_id, integration_id, id
        },
    };
}

export const deleteAudioProfileSuccess = (id) => {
    return {
        type: DELETE_AUDIO_PROFILE_SUCCESS,
        payload: {
            id
        },
    };
}

export const deleteAudioProfileFailure = (id, data) => {
    return {
        type: DELETE_AUDIO_PROFILE_FAILURE,
        payload: {
            id, data
        },
    };
}

export const updateDefaultAudioProfile = (data) => {
    return {
        type : SET_DEFAULT_AUDIO_PROFILE,
        payload : data
    }
}

export const updatePronunciationLibrary = (data) => {
    return {
        type: UPDATE_PRONUNCIATION_LIBRARY,
        payload: data,
    }
}