import create from 'zustand';

interface IPreGenAudioStore {
    src: string | null
    isPlaying: boolean
    togglePlayPause: (src: string) => void
    stop: () => void
}

export const usePreGenAudioPlayer = create<IPreGenAudioStore>()((set) => ({
    src: null,
    isPlaying: false,
    togglePlayPause: (src: string) => set((state) => {
        if (state.src === src) {
            return {isPlaying: !state.isPlaying}
        }
        return {
            src,
            isPlaying: true
        }
    }),
    stop: () => set(() => ({ 
        src: null, 
        isPlaying: false 
    })),
}))