import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { API } from "aws-amplify";

import { PageParams } from "../ai-voices/ConfigureVoiceModal";
import Info from "./PlanInfo";
import Spinner from "../Spinner";

import PLANS from "../../constants/plans.json";
import { useOrganisation } from "../../http-hooks/organisation/useOrganisation";
import { toast } from "react-toastify";
import { useGlobalStore } from "../../zustand-store/useGlobalStore";
import { addMixPanelEvent } from "../../store/actions";
import { useDispatch } from "react-redux";

interface IPlansModalProps {
  showCurrentPlan?: boolean
}

export const UpgradePlanModalV2 = ({ showCurrentPlan }: IPlansModalProps) => {

  const dispatch = useDispatch()
  const { org_id } = useParams<PageParams>();
  const { data, isLoading, error } = useOrganisation({ org_id });
  const [loadingPlanIndex, setLoadingPlanIndex] = useState(-1);

  const show = useGlobalStore((state) => state.showUpgradeModal);
  const toggle = useGlobalStore((state) => state.toggleUpgradeModal);
  const title = useGlobalStore((state) => state.modalTitle);

  useEffect(() => {
    if(show){
      dispatch(addMixPanelEvent({
        orgId: org_id, body: {
          eventName: "PAYMENT_VIEW_PLANS",
          eventData: {}
        }
      }))
    }
  }, [show, dispatch, org_id])

  function onHide() {
    toggle(false)
  }

  if (isLoading) {
    return (
      <Spinner color="#3D4852" />
    );
  }

  if (error) {
    toast.error("Something went wrong!");
    onHide();
    return <></>
  }

  const org = data.data;
  console.log({ org })
  const currentPlan = getPlan(org.plan_id);

  const upgrade = async (plan: any) => {

    try {

      let planId = '';

      if (org.plan_id.indexOf('usd') !== -1) {
        planId = plan.plan_id_usd;
      } else {
        planId = plan.plan_id_inr;
      }

      const url = window.location.href.split('?')[0];

      const response = await API.get('main', `/organisation/${org_id}/create-upgrade-session?planId=${planId}&redirectURL=${url}&cancelURL=${url}`, { headers: { "Content-Type": "application/json" } });

      if (!response.success) {
        toast.error("Something went wrong in checkout!");
      }

      window.open(response.data.url, "_self")

    } catch (e) {
      console.log(e);
      toast.error("Something went wrong in checkout!");
    } finally {
      setLoadingPlanIndex(-1)
    }

  }

  let plans = PLANS;
  console.log("upgrade", plans, currentPlan)
  if (currentPlan) {
    if (showCurrentPlan) {
      plans = getCurrentAndHigherPlans(org.plan_id)
    } else {
      plans = getHigherPlans(org.plan_id)
    }
  }

  plans = [
    ...plans,
    PLANS[PLANS.length - 1]
  ]

  plans = plans.filter(item => item.is_active).splice(0, 4)
  
  return (
    <Modal className="plans-modal" show={show} onHide={onHide}>
      <Modal.Header style={{ padding: "25px 32px" }} closeButton>
        <h3 className="modal-title">
          {title}
        </h3>
      </Modal.Header>
      <Modal.Body style={{
        width: "100%",
        overflowY: "auto",
      }} className="py-0">
        <div className="row">
          {plans.map((plan: any, index) => {
            return (
              <div key={`plan-${index}`} className={`col-12 col-md-6 col-lg-3 px-2 plan-card`}>
                <div
                  className={classNames("card", "card-pricing-summary")}
                >
                  <div className={`card-body card-body-${(index === plans.length - 1) ? '3' : index}`}>
                    <p className="pricing-summary-title">
                      {plan.plan_title}
                    </p>
                    <p className="pricing-summary-sub-title">
                      {plan.plan_sub_title}
                    </p>
                    <div className="pricing-summary-price">
                      <span>{plan.price}</span>
                      <p style={{ fontSize: 16, fontWeight: 500, }}>{plan.price_subtitle}</p>
                      {
                        plan.cta 
                        ? <a href={plan.cta.link}><Button>{plan.cta.text}</Button></a> 
                        : loadingPlanIndex === index
                          ? (
                            <button className="btn btn-outline-primary">
                              <Spinner color="#3D4852" />
                            </button>
                          )
                          : (
                            isCurrentPlan(org.plan_id, plan)
                              ? (
                                <button onClick={() => {
                                  removeModalFromURL();
                                  onHide();
                                }} className="btn btn-outline-secondary">
                                  Continue
                                </button>
                              )
                              : (
                                <button onClick={() => {
                                  setLoadingPlanIndex(index);
                                  upgrade(plan);
                                }} className="btn btn-primary btn-upgrade">
                                  Upgrade
                                </button>
                              )
                          )
                      }
                    </div>

                    <hr className="pricing-points-divider" />
                    {plan.subtitle1 ? <p style={{ fontSize: 14 }} className="fw-bold mb-1">{plan.subtitle1}</p> : null}
                    <ul className="pricing-summary-points">
                      {plan.summary_features.map((feature: any, findex : Number) => (
                        <li key={`plan-${index}-feature-${findex}`}><Info text={feature.text} helpText={feature.help_text} /></li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="row justify-content-end mb-2">
          <a href="https://getlisten2it.com/pricing">
            <button className="btn btn-link" style={{ color: '#2D79F6', fontWeight: 500, fontSize: 16 }}>
              See all plans and features
            </button>
          </a>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const removeModalFromURL = () => {
  window.history.replaceState(null, "", window.location.href.split('?')[0])
}

const isCurrentPlan = (planId: string, plan: any) => {
  return (
    plan.plan_id_inr === planId
    || plan.plan_id_inr_annual === planId
    || plan.plan_id_usd === planId
    || plan.plan_id_usd_annual === planId
  )
}

const getPlan = (planId: string) => {
  return PLANS.find((item) => {
    return (
      item.plan_id_inr === planId
      || item.plan_id_inr_annual === planId
      || item.plan_id_usd === planId
      || item.plan_id_usd_annual === planId
    )
  })
}

const getHigherPlans = (planId: string) => {
  let index = PLANS.findIndex((item) => {
    return (
      item.plan_id_inr === planId
      || item.plan_id_inr_annual === planId
      || item.plan_id_usd === planId
      || item.plan_id_usd_annual === planId
    )
  });

  index = index + 1;

  return PLANS.slice(index)
}

const getCurrentAndHigherPlans = (planId: string) => {
  const index = PLANS.findIndex((item) => {
    return (
      item.plan_id_inr === planId
      || item.plan_id_inr_annual === planId
      || item.plan_id_usd === planId
      || item.plan_id_usd_annual === planId
    )
  });

  if (index === -1) {
    return PLANS
  }

  return PLANS.slice(index)
}