import { API } from 'aws-amplify';
import { useQuery } from "@tanstack/react-query";

export const fetchSubscription = (org_id: string) => {
    return API.get('main', `/organisation/${org_id}/subscription`, {headers: {'Content-Type': "application/json"}});
}

interface IuseSubscriptionProps {
    org_id: string
}

export const useSubscription = ({org_id}: IuseSubscriptionProps) => {
    return useQuery(['organisation', 'subscription', org_id], () => fetchSubscription(org_id))
}
