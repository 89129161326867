import React, {useState} from 'react';
import { useForm } from "react-hook-form";
import Spinner from '../../components/Spinner';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import Bugsnag from "@bugsnag/js";
import AuthSpritesImg from "../../assets/auth-sprites.svg"
const classNames = require('classnames');

export default function ForgotPassword(props){

    const [ isLoading, setIsLoading ] = useState(false);
    const forgotPasswordForm = useForm();
    const resetPasswordForm = useForm();
    const [authState, setAuthState] = React.useState("forgotpassword");
    const [ username, setUsername ] = useState(null);
    const [ verificationCode, setVerificationCode ] = useState("");

    const onSubmitForgotPasswordForm = async data => {

        const { username } = data;
        setUsername(username);
        setIsLoading(true);

        Auth.forgotPassword(username)
        .then(() => {

            setAuthState("verifycode")

        }).catch(error => {

            toast.error(error.message);
            Bugsnag.notify(error);

        }).finally(()=>{

            setIsLoading(false);

        });

    };

    const onResendCode = async () =>{

        Auth.forgotPassword(username)
        .then(() => {

            toast.success("Code has been resent successfully.");

        }).catch(error => {

            toast.error(error.message);
            Bugsnag.notify(error);

        }).finally(()=>{

            setIsLoading(false);

        });
    }

    const onSubmitVerificationCodeForm = async data => {
        const { verificationCode } = data;
        setVerificationCode(verificationCode);
        setAuthState("resetpassword")
    };

    const onSubmitResetPasswordForm = async data => {

        const { password, password1 } = data;
        if(password !== password1){
            return toast.error("Both passwords do not match");
        }

        setIsLoading(true);
        Auth.forgotPasswordSubmit(username, verificationCode, password)
        .then(() => {
            toast.success("Password has been reset successfully.");
            props.onChangeAuthState("signin");
        })
        .catch(error => {
            toast.error(error.message);
            Bugsnag.notify(error);
            setAuthState("forgotpassword")
        }).finally(()=>{
            setIsLoading(false)
        });


    }

    return(
        <div className="row my-5 my-md-0">
            <div className="col-md-6 offset-md-3">
                {
                    (authState === "forgotpassword") &&
                    <form onSubmit={forgotPasswordForm.handleSubmit(onSubmitForgotPasswordForm)} >
                        <h4 className="form-title text-center">Recover Password</h4>
                        <p className="text-center"><small>Please enter your registered email address and we'll send you a code which you can use to reset your password</small></p>
                        <div className="form-group">
                            <label>Email address</label>
                            <input style={{backgroundImage : `url(${AuthSpritesImg})`, backgroundPosition : "4px -35px"}} type="username" name="username" className={classNames("form-control", "auth-input", { "is-invalid": forgotPasswordForm.errors.username})}  ref={forgotPasswordForm.register({required: true, pattern: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g})}/>
                            {
                                forgotPasswordForm.errors.username &&
                                <div className="invalid-feedback">
                                    {
                                        ("required"===forgotPasswordForm.errors.username.type) && "Email address is required."
                                    }
                                    {
                                        ("pattern"===forgotPasswordForm.errors.username.type) && "Email address is invalid."
                                    }
                                </div>
                            }
                        </div>
                        {
                            (isLoading)?
                            (
                                <button type="button" className="btn btn-primary btn-block btn-rounded-30 btn-dark-blue-1">
                                    <Spinner color="#fff" />
                                </button>
                            )
                            :
                            (
                                <button type="submit" className="btn btn-primary btn-block btn-rounded-30 btn-dark-blue-1">Send Code</button>
                            )
                        }
                        <p style={{color:'#2D79F6', cursor: 'pointer', textAlign: 'center', marginTop: '18px'}} onClick={()=>{props.onChangeAuthState("signin")}}>SignIn</p>
                    </form>
                }
                {
                    (authState === "verifycode") &&
                    <form className="mt-5" onSubmit={resetPasswordForm.handleSubmit(onSubmitVerificationCodeForm)}>
                        <h4 className="form-title text-center">Enter Verification Code</h4>
                        <p className="text-center"><small>Please enter the verification code sent on your email</small></p>
                        <div className="form-group">
                            <label>Please enter the code below</label>
                            <input style={{backgroundImage : `url(${AuthSpritesImg})`, backgroundPosition : "4px -70px"}} type="text" name="verificationCode" className={classNames("form-control", "auth-input", { "is-invalid": resetPasswordForm.errors.code})} ref={resetPasswordForm.register({required: true})}/>
                            {
                                resetPasswordForm.errors.code &&
                                <div className="invalid-feedback">
                                    {
                                        ("required"===resetPasswordForm.errors.code.type) && "Code is required."
                                    }
                                </div>
                            }
                        </div>
                        <button type="submit" className="btn btn-primary btn-block btn-rounded-30 btn-dark-blue-1">Proceed</button>
                        <p className="text-center mt-1"><small>Haven't received the code? Check your spam folder or <span style={{color:'#2D79F6', cursor: 'pointer'}} onClick={()=>{onResendCode()}}>click here to resend</span> the code.</small></p>
                    </form>
                }
                {
                    (authState === "resetpassword") &&
                    <form className="mt-5" onSubmit={resetPasswordForm.handleSubmit(onSubmitResetPasswordForm)}>
                        <h4 className="form-title text-center">Reset Password</h4>
                        <p className="text-center"><small>Set a new password below</small></p>
                        <div className="form-group">
                            <label>Password(min 8 characters)</label>
                            <input style={{backgroundImage : `url(${AuthSpritesImg})`, backgroundPosition : "4px -70px"}} type="password" name="password" className={classNames("form-control", "auth-input", { "is-invalid": resetPasswordForm.errors.password})} ref={resetPasswordForm.register({required: true, minLength:8})} />
                            {
                                resetPasswordForm.errors.password &&
                                <div className="invalid-feedback">
                                    {
                                        ("required"===resetPasswordForm.errors.password.type) && "Password is required."
                                    }
                                    {
                                        ("minLength"===resetPasswordForm.errors.password.type) && "Password should be at least 8 characters long."
                                    }
                                </div>
                            }
                        </div>
                        <div className="form-group">
                            <label>Retype Password</label>
                            <input style={{backgroundImage : `url(${AuthSpritesImg})`, backgroundPosition : "4px -70px"}} type="password" name="password1" className={classNames("form-control", "auth-input", { "is-invalid": resetPasswordForm.errors.password})} ref={resetPasswordForm.register({required: true, minLength:8})} />
                            {
                                resetPasswordForm.errors.password1 &&
                                <div className="invalid-feedback">
                                    {
                                        ("required"===resetPasswordForm.errors.password1.type) && "Password is required."
                                    }
                                    {
                                        ("minLength"===resetPasswordForm.errors.password1.type) && "Password should be at least 8 characters long."
                                    }
                                </div>
                            }
                        </div>
                        {
                            (isLoading)?
                            (
                                <button type="button" className="btn btn-primary btn-block btn-rounded-30 btn-dark-blue-1">
                                    <Spinner color="#fff" />
                                </button>
                            )
                            :
                            (
                                <button type="submit" className="btn btn-primary btn-block btn-rounded-30 btn-dark-blue-1">Reset Password</button>
                            )
                        }
                        <p className="mt-1"><small>Haven't received the code? Check your spam folder or <span style={{color:'#2D79F6', cursor: 'pointer'}} onClick={()=>{onResendCode()}}>click here to resend.</span></small></p>
                    </form>
                }
            </div>
        </div>
    );

}
