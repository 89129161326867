import { API } from "aws-amplify";
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import {
    toggleAudioComposerGenerateModal,
    updateAudioComposerGenerateCurrentStep,
    updateCurrentAudio,
    updateAudioComposerIsContentChanged,
    fetchOrganisationUsageRequested,
    toggleCurrentAudioPlaying,
    toggleDownloadFile,
    fetchContentRequested,
    toggleNoCreditsModal,
    updateBlockAudios,
    updatePublishedAt,
} from "../../store/actions";
import Spinner from '../Spinner';

function GenerateAudioModal({ orgId, integrationId }) {

    const dispatch = useDispatch()
    const { content } = useSelector((state) => state.edit_content)
    const { isContentChanged, sections, shouldDownloadFile, title, slate_value, audioGenerateModalShow: show, audioGenerateCurrentStep } = useSelector(state => state.audio_composer)

    const [totalCreditsToDeduct, setTotalCreditsToDeduct] = useState(null)
    const [isLoading, setIsLoading] = useState(false);

    const saveContent = () => {
        return API.post('main', `/organisation/${orgId}/integration/${integrationId}/content/v2`, {
            headers: { "Content-Type": "application/json" },
            body: {
                title: title,
                slate_value: slate_value,
                composer_settings: sections,
                is_draft: true,
            }
        })
    }

    const getDeductableCredits = async () => {

        let contentId;

        try {
            if (!content) {
                const resp = await saveContent();
                if (!resp.success) {
                    dispatch(toggleNoCreditsModal(true));
                    hideModal();
                    return;
                }
                contentId = resp.data.content_id;
                window.history.replaceState(null, title, `/organisation/${orgId}/integration/${integrationId}/content/${contentId}`);
                dispatch(fetchContentRequested(orgId, integrationId, contentId));
            } else {
                contentId = content.SK.split('#')[1];
            }
        } catch (e) {
            console.log('error', e)
            toast.error('Something went wrong')
        }

        return API.post('main', `/organisation/${orgId}/integration/${integrationId}/content/${contentId}/credits`, {
            body: {
                slate_value: slate_value,
            }
        });

    }

    const generateAudio = () => {
        const contentId = content.SK.split('#')[1];
        API.put('main', `/organisation/${orgId}/integration/${integrationId}/content/${contentId}/composer-output`, {
            headers: { "Content-Type": "application/json" },
            body: {
                settings: sections
            }
        }).then(data => {
            const audioURL = `${process.env.REACT_APP_AUDIO_BUCKET}/${data.url}`;
            dispatch(updateCurrentAudio(audioURL))
            dispatch(toggleCurrentAudioPlaying(true))

            if (shouldDownloadFile) {
                saveAs(audioURL, `${title.substr(0, 255)}.mp3`);
                dispatch(toggleDownloadFile(false))
            }

            dispatch(updateAudioComposerIsContentChanged(false))

        }).catch(err => {
            if (err.response && err.response.data && err.response.data.invalidFile) return toast.error(err.response.data.invalidFile)
        }).finally(() => {
            hideModal()
        })
    }

    const generateTTS = () => {
        const contentId = content.SK.split('#')[1];
        return API.put('main', `/organisation/${orgId}/integration/${integrationId}/content/${contentId}/v2`, {
            headers: { "Content-Type": "application/json" },
            body: {
                title: title,
                slate_value: slate_value,
                composer_settings: sections,
                is_draft: false,
                isContentChanged,
                includeMusic: true,
            }
        })
    }

    const hideModal = () => {
        // Setting Current Step back to 0
        dispatch(updateAudioComposerGenerateCurrentStep(0))
        // Closing the modal
        dispatch(toggleAudioComposerGenerateModal(false))
    }

    useEffect(() => {
        if (audioGenerateCurrentStep === 1) {
            generateTTS().then(resp => {
                if (!resp.success) {
                    dispatch(toggleNoCreditsModal(true));
                    hideModal()
                    return;
                }
                dispatch(fetchOrganisationUsageRequested(orgId));
                dispatch(updateBlockAudios(resp.data))
                dispatch(updatePublishedAt());
                // Changing current step to 2 after 3 seconds
                dispatch(updateAudioComposerGenerateCurrentStep(2))
                // Calling the API to generate final audio
                generateAudio()
            })
        }
    }, [audioGenerateCurrentStep, dispatch, generateAudio, generateTTS, orgId, hideModal])

    useEffect(() => {
        if (show) {
            setIsLoading(true)
            getDeductableCredits()
                .then(resp => {
                    if (!resp.success) {
                        dispatch(toggleNoCreditsModal(true));
                        hideModal();
                        return;
                    }
                    const credits = resp.data.credits;
                    setTotalCreditsToDeduct(credits);
                    if (credits === 0)
                        dispatch(updateAudioComposerGenerateCurrentStep(1));
                })
                .catch(e => {
                    console.log('error', e)
                })
                .finally(() => setIsLoading(false))
        }
    }, [show, getDeductableCredits, hideModal])

    return (
        <Modal show={show} onHide={() => hideModal()}>
            <Modal.Header closeButton>
                <Modal.Title>Generate Audio</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    isLoading ? (
                        <>
                            <div className='mb-3'>
                                <Spinner color="#115CD9" />
                            </div>
                        </>
                    ) :
                        (
                            !audioGenerateCurrentStep ? <p>You will consume {totalCreditsToDeduct} words for the content</p> : (
                                (audioGenerateCurrentStep === 1) ? <p>
                                    Generating TTS Audios for Blocks
                                </p> : <p>
                                    Generating Final Audio Output
                                </p>
                            )
                        )
                }
            </Modal.Body>
            {
                audioGenerateCurrentStep || isLoading
                    ? null
                    : (
                        <Modal.Footer>
                            <button type="button" className="btn btn-outline-primary mr-3" onClick={() => hideModal()}>Cancel</button>
                            <button onClick={() => dispatch(updateAudioComposerGenerateCurrentStep(1))} type="button" className="btn btn-primary">Proceed</button>
                        </Modal.Footer>
                    )
            }
        </Modal>
    );
}

export default GenerateAudioModal;