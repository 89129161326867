import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo-full.png";
import SadSVG from "../../assets/error-404.svg"

export default function Error404() {

  return (
    <React.Fragment>
      <div className="container-fluid container-fluid--header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="header">
                <Link className="text-decoration-none" to="/">
                  <div className="d-flex align-items-center">
                    <img className="logo mr-1" src={logo} alt="logo" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid error-404">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-8">
                    <h1 className="text-center error-404-img"><img src={SadSVG} /></h1>
                    <h1 className="text-center error-404-title">404</h1>
                    <h2 className="text-center">Page Not Found</h2>
                    <p className="text-center">The page you were looking for could not be found. It might have been removed, renamed, or did not exist in the first place.</p>
                </div>
            </div>
        </div>
      </div>
    </React.Fragment>
  );
}