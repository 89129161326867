import React, { memo } from 'react';

export const MemoizeImage = memo(({src, width, height, className, alt}) => {
    return (
        <img className={className} width={width} height={height} src={src} alt={alt} />
    )
}, (prev, next) => {
    if (prev.src !== next.src) {
        return true;
    }
    return false;
});