import { useEffect } from "react"
import { useHistory } from "react-router-dom"

export const useOnURLChangeListener = (predicate, onChange) => {
    const history = useHistory()

    useEffect(() => {
        onChange(predicate(window.location.pathname))
        return history.listen((location) => {
            console.log("history", `You changed the page to: ${location.pathname}`)
            onChange(predicate(location.pathname))
        })
    },[history, predicate, onChange])

    return;
}