import React, { useState, useEffect } from 'react';
import ReactHowler from 'react-howler';
import playPreviewIcon from '../assets/play_preview.svg';
import pausePreviewIcon from '../assets/pause_preview.svg';
import { API } from 'aws-amplify';
import clean from '../utils/Clean';
import Spinner from './Spinner';
import { toast } from 'react-toastify';
import Bugsnag from "@bugsnag/js";
import { DEFAULT_LANG_CODE } from '../constants/constants';

export default function AudioPreview({profile}){

    const [ isLoadingAudioPreview, setIsLoadingAudioPreview ] = useState(false);
    const [text, setText] = useState("");
    const [ languages, setLanguages ] = useState(null);
    const [charRemaining, setCharRemaining] = useState(200);
    const [audio, setAudio] = useState(null);
    const [reFetchPreview, setReFetchPreview] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);

    const getAudioPreview = (data) => {

        return API.post(`main`, `/audio/preview`,{body: clean(data), headers: {"Content-Type":"application/json"}});

    }

    const getLanguages = () => {

        return API.get(`main`, `/voices`,{headers: {"Content-Type":"application/json"}});

    }

    const onPlayPreview = () => {

        if(!reFetchPreview){
            setIsPlaying(true);
            return;
        }

        if(text.length < 10){
            toast.error("Preview text cannot be less than 10 characters");
            return;
        }

        let data = {slate_value: [
            {
                type: 'paragraph',
                children: [
                    { text: text },
                ]
            }
        ], ...profile};

        if(!data.hasOwnProperty("voice") || data.voice.length === 0){
            toast.error("Select Voice to listen to the preview.");
            return;
        }

        setIsLoadingAudioPreview(true);

        getAudioPreview(data).then((response)=>{

            setAudio(response.data.audio_url);
            setReFetchPreview(false);
            setIsPlaying(true);

        }).catch((error)=>{

            toast.error("Something went wrong while generating audio preview.");
            Bugsnag.notify(error);

        }).finally(()=>{

            setIsLoadingAudioPreview(false);

        });

    }

    useEffect(()=>{

        setReFetchPreview(true);

    },[text, profile]);

    useEffect(()=>{

        if(!languages){
            return;
        }

        let sampleString = (languages[profile.lang_code])?languages[profile.lang_code].quote:languages[DEFAULT_LANG_CODE].quote;
        setText(sampleString);
        setCharRemaining(200 - sampleString.length);

    },[languages]);

    useEffect(()=>{

        getLanguages().then((response)=>{

            setLanguages(JSON.parse(response.data));

        })

    },[]);

    return(
        <>
            <h5>Test your audio settings</h5>
            <div className="audio-preview">
                <div className="pb-0">
                    <textarea className="form-control sample-text" rows="6" maxLength="200" onChange={(event)=>{setText(event.target.value);setCharRemaining(200 - event.target.value.length)}} defaultValue={text} />
                    <p className="text-right mb-0"><small className="font-italic">{charRemaining} characters left</small></p>
                </div>
                <div className="card-footer d-flex justify-content-center">
                    {/*
                        (audio) &&
                        <button onClick={()=>{saveAs(audio, 'preview.mp3')}} type="button" className="btn btn--download-preview mr-md-3 mb-2 mb-md-0"><img className="mr-2" src={downloadIcon} alt="download icon" />Download Preview</button>
                     */}
                    {
                        (isLoadingAudioPreview)
                        ?
                        (
                            <button type="button" className="btn btn--play-preview">
                                <Spinner color="#3D4852" />
                            </button>
                        )
                        :
                        (
                            (isPlaying)?
                            (
                                <button type="button" className="btn btn--play-preview" onClick={()=>{setIsPlaying(false)}} ><img className="mr-2" src={pausePreviewIcon} alt="play icon"/> Pause Preview</button>
                            )
                            :
                            (
                                <button disabled={profile.lang_code === undefined || profile.lang_code === null} type="button" className="btn btn--play-preview" onClick={onPlayPreview} ><img className="mr-2" src={playPreviewIcon} alt="play icon"/> Play Preview</button>
                            )
                        )
                    }
                    {
                        (audio) &&
                        <ReactHowler src={audio} playing={isPlaying} onEnd={()=>{setIsPlaying(false)}} />
                    }
                </div>
            </div>
        </>
    );
}
