import { API } from "aws-amplify";
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import classNames from 'classnames'

import Spinner from './Spinner';
import encodeQueryData from '../utils/Query';

import playIcon from '../assets/play.svg';
import searchIcon from '../assets/search.svg';
import AudioPlayer from './AudioPlayer';
import PodcastEpisodeSettings from './PodcastEpisodeSettings';

function AddAudioToPodcastModal({ show, onHide, orgId, integrationId, podcastId, showDetails, onSuccess }) {

    const [contents, setContents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isFetchingMore, setIsFetchingMore] = useState(false);
    const [showEpisodeSettings, setShowEpisodeSettings] = useState(false);
    const [selectedEpisode, setSelectedEpisode] = useState(null);
    const [nextPageToken, setNextPageToken] = useState(null);
    const [searchResults, setSearchResults] = useState(null);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [audio, setAudio] = useState(null);
    const [audioTitle, setAudioTitle] = useState("Press play to listen to this.");

    const getContents = (nextPageToken = null) => {
        let url = `/organisation/${orgId}/integration/${integrationId}/content`;

        let params = {};

        if (nextPageToken) {

            params["next_page_token"] = nextPageToken;

        }

        if (Object.keys(params).length > 0) {

            url = url + "?" + encodeQueryData(params);

        }

        return API.get(`main`, url);
    };

    const getNextPage = () => {

        setIsFetchingMore(true);

        getContents(nextPageToken).then((response) => {
            setContents([...contents, ...response.data]);
            setIsFetchingMore(false);
            if (response.hasOwnProperty("next_page_token")) {
                setNextPageToken(response.next_page_token)
            }
        });
    };

    const searchContent = (keyword) => {
        API.get(`main`, `/organisation/${orgId}/integration/${integrationId}/search?keyword=${keyword}`, { headers: { "Content-Type": "application/json" } }).then((response) => {
            setSearchResults(response.data.hits.hits);
        });
    }

    useEffect(() => {

        getContents()
            .then(response => {
                setContents(response.data);
                if (response.hasOwnProperty("next_page_token")) {
                    setNextPageToken(response.next_page_token)
                }
            })
            .catch(error => {
                toast.error("Something went wrong!");
            })
            .finally(() => setLoading(false));

    }, []);

    const SearchResults = () => {
        return (
            <React.Fragment>
                <div className="content-filter">
                    <span className="mr-2">Showing search results for <strong>{searchKeyword}</strong></span>
                    <span className="clear-content-filter" onClick={() => { setSearchKeyword(""); setSearchResults(null) }}>Clear</span>
                </div>
                {
                    searchResults.length > 0 ? (
                        <div className="list-group list-group-flush list-group--content">
                            {
                                searchResults.map((item, index) => {
                                    return (
                                        <li onClick={() => {

                                            setSelectedEpisode({
                                                ...item,
                                                content_id: item._source.SK.S.split("#")[1],
                                                author: showDetails.author,
                                                category: showDetails.category,
                                                is_published: true
                                            });
                                            onHide();
                                            setShowEpisodeSettings(true);

                                        }} style={{ cursor: 'pointer' }} className="list-group-item list-group-item-action" key={`content-${index}`}>
                                            <h4>{item._source.title.S}</h4>
                                            {
                                                (null !== item._source.published_at.S)
                                                && (
                                                    <span className="publish_date">Published on {moment(item._source.published_at.S).format("MMM D, YYYY")}</span>
                                                )
                                            }
                                            <span className="dot-separator" />
                                            {
                                                item._source.audio_duration && (
                                                    <span className="audio-duration">{moment.duration(item._source.audio_duration.S, 'seconds').minutes()} min listen</span>
                                                )
                                            }
                                            <div className="actions">
                                                {
                                                    item._source.published_at.S
                                                    && (
                                                        <img src={playIcon} onClick={(e) => {

                                                            // e.stopPropagation();
                                                            setAudio(item._source.full_audio_url.S);
                                                            setAudioTitle(item._source.title.S);

                                                        }} className="play-icon" alt="play icon" />
                                                    )
                                                }
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </div>
                    ) :
                        (
                            <p style={{ marginTop: '32px' }}>No content found in this keyword. Try a different keyword.</p>
                        )
                }
            </React.Fragment>
        )
    }

    const Contents = () => {
        return (
            <React.Fragment>
                <div className="list-group list-group-flush list-group--content">
                    {
                        contents.map((item, index) => {
                            return (
                                <li onClick={() => {

                                    setSelectedEpisode({
                                        ...item,
                                        content_id: item.SK.split("#")[1],
                                        author: showDetails.author,
                                        category: showDetails.category,
                                        is_published: true
                                    });
                                    onHide();
                                    setShowEpisodeSettings(true);

                                }} style={{ cursor: 'pointer' }} className="list-group-item list-group-item-action" key={`content-${index}`}>
                                    <h4>{item.title}</h4>
                                    {
                                        (null !== item.published_at)
                                        && (
                                            <span className="publish_date">Published on {moment(item.published_at).format("MMM D, YYYY")}</span>
                                        )
                                    }
                                    <span className="dot-separator" />
                                    {
                                        item.audio_duration && (
                                            <span className="audio-duration">{moment.duration(item.audio_duration, 'seconds').minutes()} min listen</span>
                                        )
                                    }
                                    <div className="actions">
                                        {
                                            item.published_at
                                            && (
                                                <img src={playIcon} onClick={(e) => {

                                                    // e.stopPropagation();
                                                    setAudio(item.full_audio_url);
                                                    setAudioTitle(item.title);

                                                }} className="play-icon" alt="play icon" />
                                            )
                                        }
                                    </div>
                                </li>
                            )
                        })
                    }
                </div>
                {
                    (isFetchingMore) ?
                        (
                            <button className={classNames("btn--fetch-more", "mt-3")}>
                                <Spinner color="#657B90" />
                            </button>
                        )
                        :
                        (
                            <button className={classNames("btn--fetch-more", "mt-3", "mb-5", { "d-none": null == nextPageToken })} onClick={() => { getNextPage() }}>Fetch More</button>
                        )
                }
            </React.Fragment>
        )
    }

    return (
        <>
            <PodcastEpisodeSettings
                show={showEpisodeSettings}
                onHide={() => setShowEpisodeSettings(false)}
                orgId={orgId}
                integrationId={integrationId}
                podcastId={podcastId}
                selectedEpisode={selectedEpisode}
                isNew={true}
                onSuccess={onSuccess}
            />

            <Modal className="modal-add-content" show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Add Audios
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="container">
                    <div className="content-search">
                        <div className="input-group input-group--search">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <img src={searchIcon} alt="search icon" />
                                </span>
                            </div>
                            <input type="text" className="form-control" placeholder="Search" value={searchKeyword} onChange={(event) => { setSearchKeyword(event.target.value) }} onKeyDown={(event) => { if ("Enter" === event.key) { searchContent(event.target.value) } }} />
                        </div>
                    </div>
                    {
                        loading
                            ? <></>
                            : (searchResults)
                                ? (
                                    <SearchResults />
                                )
                                : <Contents />
                    }
                </Modal.Body>
            </Modal>
            {
                (audio) &&
                <div className="audio-player-wrapper">
                    <AudioPlayer audio={audio} title={audioTitle} />
                </div>
            }
        </>
    );
}

export default AddAudioToPodcastModal;
