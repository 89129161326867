import {trackMixpanelService} from "../../services/mixpanel"
import {
    ADD_AUDIO_COMPOSER_BLOCK, 
    REMOVE_AUDIO_COMPOSER_BLOCK, 
    EXIT_EDITOR,
    UPDATE_AUDIO_COMPOSER_BLOCK_MOVE_LEFT,
    UPDATE_AUDIO_COMPOSER_BLOCK_MOVE_RIGHT,
    ADD_AUDIO_COMPOSER_AUDIO_BLOCK
} from "../actionTypes"

// Mixpanel middleware for editor
const mixpanelMiddleware = store => next => action => {
    if([
        ADD_AUDIO_COMPOSER_BLOCK, 
        REMOVE_AUDIO_COMPOSER_BLOCK, 
        EXIT_EDITOR,
        UPDATE_AUDIO_COMPOSER_BLOCK_MOVE_LEFT,
        UPDATE_AUDIO_COMPOSER_BLOCK_MOVE_RIGHT,
        ADD_AUDIO_COMPOSER_AUDIO_BLOCK
    ].includes(action.type)){
        const eventName = action.type
        const eventData = { val : action.payload }
        const storeState = store.getState()
        const integrationData = storeState.integration.integration
        const organisationData = storeState.organisation.organisation
        const contentData = storeState.edit_content.content
        if(integrationData && organisationData && contentData){
            const integrationId = integrationData.SK.split("#")[1]
            const orgId = organisationData.SK.split("#")[1]
            const contentId = contentData.SK.split("#")[1]
            trackMixpanelService({orgId, integrationId, contentId, body : {eventName, eventData}})
        }
    }

    next(action);
}

export default mixpanelMiddleware