import { API } from 'aws-amplify';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { togglePronunciationLibraryModal, updatePronunciationLibrary } from '../../../store/actions';

export const PronunciationLibraryModal = ({
	showPronunciationLibrary,
	languages,
	org_id,
	id,
}) => {

	const dispatch = useDispatch();
	const [original, setOriginal] = useState('');
	const [replaceWith, setReplaceWith] = useState('');
	const [language, setLanguage] = useState('');

	const pronunciationLibrary = useSelector(state => state.integration.pronunciationLibrary)

	const savePronunciationLibrary = useCallback((value) => {

		API.put('main', `/organisation/${org_id}/integration/${id}/pronunciation_library`, {
			body: {
				pronunciation_library: value
			}, headers: { "Content-Type": "application/json" }
		}).then(resp => {
			// 
		}).catch(err => {
			// 
		})

	}, [id, org_id]);

	const onEdit = (value) => {

		const copy = [...value];
		const sanitised = copy.filter((value) => (value.original && value.replaceWith && value.language) && value);
		_.debounce(() => {
			savePronunciationLibrary(sanitised)
		}, 1500)();

	}

	const onSubmit = () => {

		if (original === '' || replaceWith === '' || language === '') {
			toast.info('All fields are mandatory!')
			return;
		}

		for (let i = 0; i < pronunciationLibrary.length; i++) {
			const value = pronunciationLibrary[i];
			if (value.original === original && value.language === language) {
				toast.error("This text exists for this language, please delete the same from pronunciation library before adding again.");
				return;
			}
		}

		const copy = [...pronunciationLibrary, {
			original,
			replaceWith,
			language
		}];

		setOriginal('');
		setReplaceWith('');
		setLanguage('');

		dispatch(updatePronunciationLibrary(copy))
		savePronunciationLibrary(copy);

	}

	return (
		<Modal className='modal-pronunciation-library' show={showPronunciationLibrary} onHide={() => dispatch(togglePronunciationLibraryModal(false))}>
			<Modal.Header closeButton>
				<Modal.Title>
					Pronunciation Library
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<div className='new-pronunciation-container'>
						<h5>Create new pronunciation</h5>
						<div className='pronunciation-library-fields'>
							<div className='form-field'>
								<label>Original <sup className="text-danger">*</sup></label>
								<input value={original} onChange={(e) => setOriginal(e.target.value)} type="text" placeholder='Type text' />
							</div>
							<div className='form-field'>
								<label>Replace with <sup className="text-danger">*</sup></label>
								<input value={replaceWith} onChange={(e) => setReplaceWith(e.target.value)} type="text" placeholder='Type text' />
							</div>
							<div className='form-field'>
								<label>Audio Language <sup className="text-danger">*</sup></label>
								<select value={language} onChange={(e) => setLanguage(e.target.value)} name="lang_code" >
									<option value="">-- Choose --</option>
									{
										languages && Object.keys(languages).sort().map((lang) => {

											return (
												<option value={languages[lang].code} key={`general-lang-${languages[lang].code}`}>{languages[lang].name}</option>
											)

										})
									}
								</select>
							</div>
							<button onClick={onSubmit} className='btn btn-primary'>Add</button>
						</div>
					</div>
					<div className='pronunciation-list'>
						<h5>Pronunciation Library</h5>
						<div>
							{pronunciationLibrary.map((pronunciationValue, index) => {
								return (
									<div key={index} className='pronunciation-library-fields'>
										<div className='form-field'>
											<label>Original <sup className="text-danger">*</sup></label>
											<input value={pronunciationValue.original} onChange={(e) => {

												let copy = [...pronunciationLibrary];
												copy[index].original = e.target.value;
												dispatch(updatePronunciationLibrary(copy))
												onEdit(copy);

											}} type="text" placeholder='Type text' />
										</div>
										<div className='form-field'>
											<label>Replace with <sup className="text-danger">*</sup></label>
											<input value={pronunciationValue.replaceWith} onChange={(e) => {

												let copy = [...pronunciationLibrary];
												copy[index].replaceWith = e.target.value;
												dispatch(updatePronunciationLibrary(copy))
												onEdit(copy);

											}} type="text" placeholder='Type text' />
										</div>
										<div className='form-field'>
											<label>Audio Language <sup className="text-danger">*</sup></label>
											<select value={pronunciationValue.language} onChange={(e) => {

												let copy = [...pronunciationLibrary];
												copy[index].language = e.target.value;
												dispatch(updatePronunciationLibrary(copy))
												onEdit(copy);

											}} name="lang_code" >
												<option value="">-- Choose --</option>
												{
													languages && Object.keys(languages).sort().map((lang) => {

														return (
															<option value={languages[lang].code} key={`general-lang-${languages[lang].code}`}>{languages[lang].name}</option>
														)

													})
												}
											</select>
										</div>
										<button onClick={() => {

											const copy = [...pronunciationLibrary];
											copy.splice(index, 1);
											dispatch(updatePronunciationLibrary(copy))
											onEdit(copy);

										}} className='btn btn-danger'>Delete</button>
									</div>
								)
							})}
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}