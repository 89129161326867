import React from 'react';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { saveAs } from 'file-saver';
import { API } from 'aws-amplify';
import { toast } from 'react-toastify';
import Bugsnag from "@bugsnag/js";
import { Link } from "react-router-dom";
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';

import formatAudioFileDuration from '../../utils/AudioDuration';

import embedCodeIcon from '../../assets/code-content.svg';
import playIcon from '../../assets/play-content.svg';
import externalLinkIcon from '../../assets/share-content.svg';
import editIcon from '../../assets/edit-content.svg';
import downloadIcon from '../../assets/download-alt.svg';
import hyperlinkIcon from '../../assets/url-content.svg'
import { useSelector } from 'react-redux';
import { useGlobalStore } from '../../zustand-store/useGlobalStore';

const classNames = require('classnames');

function isDraft({ full_audio_url }) {

    if (`${full_audio_url}`.includes('/null')) {
        return true;
    }

    return false;

}

const AudioStatusBadge = ({ isAudioDisabled, fullAudioStatus, publishedAt }) => {
    if (isAudioDisabled) {
        return (
            <span className="badge badge-danger mr-3">Disabled</span>
        );
    }
    if (fullAudioStatus === 'IN PROGRESS') {
        return (
            <span className="badge badge-primary mr-3">In Progress</span>
        );
    }

    // published_at is non-existent for older records created from widget.
    if (publishedAt === undefined || publishedAt) {
        return (
            <span className="badge badge-success mr-3">Published</span>
        );
    }

    return (
        <span className="badge badge-secondary mr-3">In Draft</span>
    );
}

const ContentItem = ({ item, index, org_id, id, setAudio, setAudioTitle, setShowUploadAudio, setSelectedAudio, setShowEmbedCode, setEmbedCode, setShowPodcast, setContentId, disableAudio, enableAudio, CustomToggle, discardDraft, deleteAudio }) => {

    const history = useHistory();
    const organisation = useSelector((state) => state.organisation.organisation);
    const toggleUpgradeModal = useGlobalStore((state) => state.toggleUpgradeModal)
    const contentId = item.SK.split("#")[1]

    const cloneContent = () => {
        API.post('main', `/organisation/${org_id}/integration/${id}/content/clone`, {
            body: {
                content_to_clone: contentId,
            },
            headers: { "Content-Type": "application/json" }
        }).then((response) => {

            if (response.success) {
                history.push(`/organisation/${org_id}/integration/${id}/content/${response.data.content_id}`)
            }

        }).catch((error) => {

            toast.error("Something went wrong while cloning content.")
            Bugsnag.notify(error);

        })
    }

    return (
        <li className="list-group-item list-group-item-action">
            <h4>
                <Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/organisation/${org_id}/integration/${id}/content/${contentId}`}>
                    {item.title}
                </Link>
                {
                    (!(item.body) && !(item.slate_body)) && (
                        <span style={{ fontSize: 12, fontWeight: 'normal' }} className="badge badge-info ml-2">
                            Audio Upload
                        </span>
                    )
                }
            </h4>
            <AudioStatusBadge publishedAt={item.published_at} fullAudioStatus={item.full_audio_status} isAudioDisabled={item.is_audio_disabled} />
            {
                (null !== item.created_at)
                && (
                    <span className="publish_date">{moment(item.created_at).format("MMM D, YYYY")}</span>
                )
            }
            <span className="dot-separator" />
            {
                item.audio_duration && (
                    <span className="audio-duration">{formatAudioFileDuration(item.audio_duration)}</span>
                )
            }
            <div className="actions">
                {
                    (null !== item.published_at && item.full_audio_url) && (
                        <OverlayTrigger placement="bottom" overlay={
                            <Tooltip>Play</Tooltip>
                        }>
                            <img title="Play" src={playIcon} onClick={() => {

                                if ("IN PROGRESS" === item.full_audio_status) return;

                                setAudio(item.full_audio_url);
                                setAudioTitle(item.title);

                            }} className={classNames('icon', 'play-icon', { 'icon-disabled': "IN PROGRESS" === item.full_audio_status })} alt="play icon" />
                        </OverlayTrigger>
                    )
                }
                {
                    item.body || item.slate_body ? (
                        <Link to={`/organisation/${org_id}/integration/${id}/content/${contentId}`}>
                            <OverlayTrigger placement="bottom" overlay={
                                <Tooltip>Edit</Tooltip>
                            }>
                                <img title="Edit" src={editIcon} className="icon edit-icon" alt="edit icon" />
                            </OverlayTrigger>
                        </Link>


                    ) : (
                        <OverlayTrigger placement="bottom" overlay={
                            <Tooltip>Edit</Tooltip>
                        }>
                            <img title="Edit" src={editIcon} className="icon edit-icon" alt="edit icon" onClick={() => {
                                setShowUploadAudio(true);
                                setSelectedAudio(item);
                            }} />
                        </OverlayTrigger>

                    )
                }
                {
                    (null !== item.published_at) && (
                        <OverlayTrigger placement="bottom" overlay={
                            <Tooltip>Download</Tooltip>
                        }>
                            <img title="Download" onClick={() => {

                                if (!organisation?.gates?.can_download_audio) {
                                    toggleUpgradeModal(true, {title: "Upgrade to get unlimited downloads, more words and more features."});
                                    return;
                                }

                                API
                                    .get('main', `/organisation/${org_id}/integration/${id}/content/${contentId}/download`)
                                    .then((resp) => {
                                        if (!resp.success) {
                                            toggleUpgradeModal(true, {title: resp.message});
                                            return;
                                        }
                                        if ("IN PROGRESS" === item.full_audio_status) return;
                                        saveAs(item.full_audio_url, `${item.title.substr(0, 255)}.mp3`);
                                    })
                                    .catch((error) => {
                                        Bugsnag.notify(error)
                                        toast.error("Something went wrong.")
                                    })

                            }} src={downloadIcon} alt="download" className={classNames('icon', 'download-icon', { 'icon-disabled': "IN PROGRESS" === item.full_audio_status })} />
                        </OverlayTrigger>

                    )
                }
                {
                    (null !== item.published_at) && (
                        <OverlayTrigger placement="bottom" overlay={
                            <Tooltip>Embed</Tooltip>
                        }>
                            <img title="Embed" alt="" src={embedCodeIcon} style={{ height: 14 }} className={classNames('icon', { 'icon-disabled': "IN PROGRESS" === item.full_audio_status })} onClick={() => {

                                if ("IN PROGRESS" === item.full_audio_status) return;

                                setShowEmbedCode(true);
                                setEmbedCode(`<iframe
id="l2it-widget-iframe"
src="${process.env.REACT_APP_WIDGET_BASE_URL}/inline.html?org_id=${org_id}&integration_id=${id}&${item.url ? `url=${encodeURIComponent(item.url)}` : `content_id=${contentId}`}&has_paywall=false"
style="border:none;width:100%;height:80px;"
>
</iframe>`)
                            }} />
                        </OverlayTrigger>

                    )
                }
                {
                    (null !== item.published_at) && (
                        <a onClick={(e) => {

                            if ("IN PROGRESS" === item.full_audio_status) {
                                e.preventDefault()
                            }

                        }} href={`${process.env.REACT_APP_WIDGET_BASE_URL}/inline.html?org_id=${org_id}&integration_id=${id}&${item.url ? `url=${encodeURIComponent(item.url)}` : `content_id=${contentId}`}`} target="_blank" rel="noreferrer noopener">
                            <OverlayTrigger placement="bottom" overlay={
                                <Tooltip>Share</Tooltip>
                            }>

                                <img title="Share Link" style={{ height: 16 }} src={externalLinkIcon} className={classNames('icon', 'link-icon', { 'icon-disabled': "IN PROGRESS" === item.full_audio_status })} alt="link icon" />
                            </OverlayTrigger>
                        </a>


                    )
                }
                {
                    item.url && (
                        <Link to={{ pathname: item.url }} target="_blank">
                            <OverlayTrigger placement="bottom" overlay={
                                <Tooltip>URL</Tooltip>
                            }>

                                <img title="Content URL" style={{ height: 16 }} src={hyperlinkIcon} className={classNames('icon', 'url-icon')} alt="url icon" />

                            </OverlayTrigger>
                        </Link>

                    )
                }
                <Dropdown as="span">
                            <Dropdown.Toggle as={CustomToggle} />
                            <Dropdown.Menu>
                                <Dropdown.Item as="div" onClick={() => cloneContent()}>
                                    Clone
                                </Dropdown.Item>
                                {
                                    null === item.published_at
                                        ? <>
                                            {/* <Dropdown.Item as="div" onClick={ () => publishAudio(item.SK.split("#")[1], index) }>
                                    Generate Audio
                                </Dropdown.Item> */}
                                            {
                                                isDraft(item) && (
                                                    <Dropdown.Item as="div" onClick={() => {
                                                        discardDraft(contentId, index);
                                                    }}>
                                                        Discard draft
                                                    </Dropdown.Item>
                                                )
                                            }
                                        </>
                                        : <>
                                            {/* {
                                (item.body || item.slate_body) && // presence of body denotes that this content is not an uploaded file
                                    <Dropdown.Item as="div" onClick={()=>{regenerateAudio(item.SK.split("#")[1], index)}}>
                                        Regenerate Audio
                                    </Dropdown.Item>
                                } */}
                                            {
                                                (item.is_audio_disabled)
                                                    ?
                                                    (
                                                        <Dropdown.Item as="div" onClick={() => { enableAudio(contentId, index) }}>
                                                            Enable Audio
                                                        </Dropdown.Item>
                                                    )
                                                    :
                                                    (
                                                        item.url && (
                                                            <Dropdown.Item as="div" onClick={() => { disableAudio(contentId, index) }}>
                                                                Disable Player Widget
                                                            </Dropdown.Item>
                                                        )
                                                    )
                                            }
                                            <Dropdown.Item as="div" onClick={() => {
                                                setShowPodcast(true);
                                                setContentId(contentId);
                                            }}>
                                                Add to podcast feed
                                            </Dropdown.Item>
                                            <Dropdown.Item as="div" onClick={() => {
                                                deleteAudio(contentId, index);
                                            }}>
                                                Delete
                                            </Dropdown.Item>
                                        </>
                                }
                            </Dropdown.Menu>
                        </Dropdown>

            </div>
        </li>
    )
}

export default ContentItem