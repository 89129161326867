import React from 'react';
const classNames = require('classnames');

export default function PlayerPreview({type, color, position, title, text_color, background_color}){


    return(
        <div className="card card--player-preview">
            <div className="card-header">
                &nbsp;
            </div>
            <div className="card-body pb-5">
            <div className="block" style={{height: "45px"}} />
                <PlayerMockup type={type} color={color} position={position} title={title} text_color={text_color} background_color={background_color} />
                <div className={classNames("block",{"mt-3": type !== "inline"})} style={{height: "16px", width: "90%"}} />
                <div className="block mt-2" style={{height: "16px", width: "80%"}} />
                <div className="block mt-2" style={{height: "16px", width: "70%"}} />
                <div className={classNames("block", "mt-2", {"mb-5": type === "static"})} style={{height: "16px", width: "60%"}} />
            </div>
        </div>
    )

}

function PlayerMockup({type, color, position, title, text_color, background_color}){

    if("floating" === type){

        let style = {position: "absolute"};

        if("bottom-right" === position){

            style = { ... style, bottom: "10px", right: "10px" };

        } else if("bottom-left" === position){

            style = { ... style, bottom: "10px", left: "10px" };

        } else if("top-left" === position){

            style = { ... style, top: "10px", left: "10px" };

        } else if("top-right" === position){

            style = { ... style, top: "10px", right: "10px" };

        } else{

            style = { ... style, bottom: "10px", right: "10px" };

        }

        return(
            <div className="" style={style}>
                <PlayIcon fill={color} />
            </div>
        )

    }

    if("static" === type){

        return(
            <div className="" style={{position: "absolute", bottom: "0px", left: "0px", width: "100%", padding: "1.25rem"}}>
                <div style={{backgroundColor : background_color ? background_color : "#f4f4f4", borderRadius : "5px"}} className="d-flex align-items-baseline p-2">
                    <div>
                        <PlayIcon fill={color} />
                    </div>
                    <div className="ml-3 w-100">
                        <p className="mb-1" style={{color: text_color}}><small>{title}</small></p>
                        <div style={{width: "100%", height: "5px", background:"#DBDDDF"}} />
                    </div>
                </div>
            </div>
        )

    }

    return(
        <div style={{margin:"10px 0"}}>
            <div style={{backgroundColor : background_color ? background_color : "#f4f4f4", borderRadius : "5px"}} className="d-flex align-items-baseline p-2">
                <div>
                    <PlayIcon fill={color} />
                </div>
                <div className="ml-3 w-100">
                    <p className="mb-1" style={{color: text_color}}><small>{title}</small></p>
                    <div style={{width: "100%", height: "5px", background:"#DBDDDF"}} />
                </div>
            </div>
        </div>
    )

}

function PlayIcon({fill}){
    return(
        <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="23.5" cy="23.5" r="23.5" fill={fill} />
            <path d="M34 24L19 32.6603V15.3397L34 24Z" fill="white"/>
        </svg>
    );
}
