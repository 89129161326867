import { BLOCK_MAX_CHARACTERS_LENGTH } from "../constants/constants";
import { Node } from "slate";
import { getCharactersCount } from "./SlateUtils/index.js";

export const withInlines = (editor) => {
	const { insertData, insertText, isInline, isVoid } = editor;

	editor.isInline = (element) =>
		["pause", "custom"].includes(element.type) || isInline(element);

	editor.isVoid = (element) => {
		return element.type === "pause" ? true : isVoid(element);
	};

	editor.insertText = (text) => {
		insertText(text);
	};

	editor.insertData = (data) => {
		insertData(data);
	};

	return editor;
};

export const withBlockLevelCharactersLimit = (editor) => {
	const { insertText } = editor;

    editor.insertText = (text) => {

		try {
			const blockIndex = editor.selection.anchor.path[0];
			const block = Node.get(editor, [blockIndex]);
			const charactersCount = getCharactersCount(block.children);
			const remainingCharactersCount = BLOCK_MAX_CHARACTERS_LENGTH - charactersCount;

			if (getCharactersCount(block.children) + text.length >= BLOCK_MAX_CHARACTERS_LENGTH) {
				console.log("Can't add more text");
			}

			insertText(text.substring(0, remainingCharactersCount));
		} catch (e) {
			insertText(text);
		}

    };
    return editor;
} 