import React, { useEffect, useState } from 'react';
import {Crisp} from "crisp-sdk-web"
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import helpIcon from '../../assets/content/empty-state-help.svg'

function PodcastsEmptyState() {

    const history = useHistory();
    const { org_id, id } = useParams();
    const {integration, isLoading} = useSelector(state => state.integration)

    const Shimmer = () => {

        return (
            <div className="list-group list-group-flush list-group--content">
                {
                    _.range(4).map((index) => {

                        return (
                            <li className="list-group-item list-group-item-action" key={`content-${index}`}>
                                <div className="content-title-shimmer shine" />
                                <div className="content-meta-shimmer shine" />
                            </li>
                        )

                    })
                }
            </div>
        )
    }

    return (
        <div className="">
            {
                isLoading
                    ? (
                        <Shimmer />
                    )
                    : (
                        <>
                            <div style={{borderRadius: 5, background: "#F6F7FD", height: 40}}></div>
                            <div className="mt-5 mb-5">
                                <h5 className="mb-4" style={{ fontSize: 18, fontWeight: "bold" }}>Easiest way to showcase your audios as podcast.</h5>
                                <h5 className="mb-4" style={{ fontSize: 18 }}>Easily embed an entire collection of audio articles, voiceovers or a audio series on a beautiful on-brand podcast page. Host standalone, embed on your site or publish it across popular podcasting apps.</h5>
                                <div>{integration?.gates?.can_create_audio ? <Button className="mr-2" onClick={() => history.push(`/organisation/${org_id}/integration/${id}/podcast/new`)} variant="primary">New Podcast</Button> : null}</div>
                            </div>

                            <div style={{borderRadius: 5, background: "#F6F7FD", height: 140}} className="d-flex flex-column justify-content-center align-items-center">
                                <img className="mb-2" src={helpIcon} width="20" />
                                <p><b>Having trouble publishing your podcast?</b></p>
                                <Button className="mr-2" onClick={() => Crisp.chat.open()} variant="primary">Contact Us</Button>
                            </div>
                        
                        </>
                    )
            }
        </div>
    );
}

export default PodcastsEmptyState;
