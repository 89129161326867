import { API } from "aws-amplify";
import Bugsnag from '@bugsnag/browser';
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Spinner from '../Spinner';
import uploadIcon from '../../assets/upload-alt.svg';
import { useForm } from 'react-hook-form';
import clean from '../../utils/Clean';


function ExtraAudioUploadModal({ show, onHide, onSuccess = () => { }, orgId, integrationId, selectedAudio, audioKey }) {
    const [isUploadingAudio, setIsUploadingAudio] = useState(false);
    const [audioFile, setAudioFile] = useState(null);
    const { handleSubmit } = useForm();


    const onUploadAudio = async (data) => {

        if (audioFile === null) {
            toast.error('Select an audio file to upload!')
            return;
        }

        setIsUploadingAudio(true);

        try {

            const signedResponse = await API.get('main', `/organisation/${orgId}/integration/${integrationId}/audio/signed-url`, {
                queryStringParameters: {
                    file_name: audioFile.name,
                    content_type: audioFile.type,
                    file_name_prefix: audioKey.split("_")[0]
                }
            });

            const requestOptions = {
                method: 'PUT',
                body: audioFile,
                redirect: 'follow'
            };

            const uploadURL = signedResponse.data.signed_url;
            const url = signedResponse.data.url;

            await fetch(uploadURL, requestOptions);

            if (selectedAudio) { // existing audio - EDIT/PUT
                await API.put('main', `/organisation/${orgId}/integration/${integrationId}/extraaudio/upload`, {
                    body: clean({
                        key: audioKey,
                        url: url,
                    }),
                    headers: { "Content-Type": "application/json" }
                })

            } else { // new audio - NEW/POST
                await API.post('main', `/organisation/${orgId}/integration/${integrationId}/extraaudio/upload`, {
                    body: clean({
                        key: audioKey,
                        url: url,
                    }),
                    headers: { "Content-Type": "application/json" }
                })

            }

            onSuccess();

        } catch (e) {

            toast.error("Something went wrong.")
            Bugsnag.notify(e);

        } finally {

            setIsUploadingAudio(false);
            onHide()

        }
    }

    useEffect(() => {

        if (!show) {
            setAudioFile(null);
        }

    }, [show])

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Upload Audio file
                    <p className="modal-subtitle">Only mp3 files are allowed</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="upload-audio-dropbox d-flex flex-column justify-content-center">
                    <input name="audio-file" type="file" accept=".mp3" onChange={(event) => setAudioFile(event.target.files[0])} />
                    <label htmlFor="audio-file" className="d-flex flex-column align-items-center">
                        <img alt="" src={uploadIcon} />
                        <p>Drag and drop or browse to choose a file</p>
                        {
                            audioFile && (
                                <p>({audioFile.name})</p>
                            )
                        }
                    </label>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-outline-primary mr-3" onClick={onHide}>Cancel</button>
                {
                    (isUploadingAudio) ?
                        (
                            <button type="button" className="btn btn-primary text-center">
                                <Spinner color="#FFF" />
                            </button>
                        )
                        :
                        (
                            <button onClick={handleSubmit(onUploadAudio)} type="button" className="btn btn-primary">Upload</button>
                        )
                }
            </Modal.Footer>
        </Modal>
    );
}

export default ExtraAudioUploadModal;
