export default function getVoiceWithoutLangCode(languages, voiceCode, engine) {
    for (let langCode in languages) {
        const language = languages[langCode];
        for (let i = 0; i < language.voices.length; i++) {
            const voice = language.voices[i];
            if (voice.code === voiceCode && voice.engine === engine) {
                return voice;
            }
        }
    }
}