export const BLOCK_MAX_CHARACTERS_LENGTH = 2000;
export const PREVIEW_PLANS = ['preview-plan-inr-2', 'preview-plan-usd'];
export const FREE_PLANS = ['free-plan', 'free-plan-inr']
export const FREE_AND_PREVIEW_PLANS = [...PREVIEW_PLANS, ...FREE_PLANS]
export const FREE_AND_PREVIEW_DOWNLOAD_COUNT = 3
export const DEFAULT_LANG_CODE = "en-US";
export const DEFAULT_VOICE_CONFIGURATION = {
    volume: 0,
    speed: 100,
    pitch: '',
    style: '',
}