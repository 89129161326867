import React from 'react';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';
import Ripple from '../../components/Ripple';

export default function Trend({ label, metric }){

    const COLORS = [ '#1F78B4', '#B2DF8A', '#33A02C', '#FB9A99', '#A6CEE3' ];

    // const mapNulls = (metric) => {

    //     if("country" === metric){

    //         return "Unknown";

    //     } else if ("devices" === metric){

    //         return "Desktop & Others";

    //     } else {

    //         return "";

    //     }

    // }

    const { data: queryData, isLoading} = metric;

    if (isLoading) {
        return (
            <div style={{width: '200px', height: '200px', textAlign: 'center', paddingTop:'100px'}}>
                <Ripple />
            </div>
        )
    }

    const data = queryData.data;

    return(
        <div className="pie-chart">
            {
                (
                    (0 === data.length)?
                    (
                        <div style={{width: '200px', height: '200px', textAlign: 'center', paddingTop:'100px'}}>
                            <span>NO DATA</span>
                        </div>
                    )
                    :
                    (
                        <PieChart width={200} height={200}>
                            <Pie
                                data={data}
                                cx={95}
                                cy={100}
                                innerRadius={70}
                                outerRadius={90}
                                fill="#8884d8"
                                paddingAngle={2}
                            >
                                {
                                    data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
                                }
                            </Pie>
                            <Tooltip />
                        </PieChart>
                    )
                )
            }
            <p className="mt-5"><small>{label}</small></p>
            <div className="mt-3">
                {
                    data.map((d, index)=>{
                        return(
                            <div className="d-flex justify-content-between">
                                <div className="my-1">
                                    <div style={{width:'16px', height:'16px', borderRadius:'6px', backgroundColor: COLORS[index % COLORS.length], display:'inline-block', verticalAlign:'middle'}}></div>
                                    <span style={{marginLeft:'10px', verticalAlign:'middle'}}>
                                        {
                                            (d.name) ? d.name : ""
                                        }
                                        {/* mapNulls(metric) */}
                                    </span>
                                </div>
                                <div>{d.value}</div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
