import create from 'zustand'
import { DEFAULT_LANG_CODE, DEFAULT_VOICE_CONFIGURATION } from '../constants/constants'
import { useVoiceConfigurationStore } from './useVoiceConfigurationStore'
export interface IVoice {
	audio: string
	code: string
	engine: string
	gender: string
	langCode: string
	name: string
	platform: string
	previewText: string
	styles: Array<string>
}
interface ILanguage {
	code: string
	name: string
	quote: string
	voices: Array<IVoice>
}

export interface IVoiceConfiguration {
	volume: number
	speed: number
	pitch: string
	style: string
}

export enum VoiceConfigurationOptions {
	volume = 'volume',
	speed = 'speed',
	pitch = 'pitch',
	style = 'style'
}

type TLanguage = {
	[key: string]: ILanguage;
};

interface AIVoicesState {
	languages: TLanguage
	voices: Array<IVoice>
	selectedLanguage: ILanguage | null
	selectedVoice: IVoice | null
	filters: {
		gender: string
		quality: string
		style: boolean
	},
	expandedCardIndex: number | null
	showSetDefaultProfileModal: boolean
	showCreateCustomVoiceProfileModal: boolean
	setLanguages: (languages: TLanguage) => void
	setVoices: (voices: Array<IVoice>) => void
	setSelectedLanguage: (language: ILanguage) => void
	setSelectedVoice: (voide: IVoice | null) => void
	resetFilters: () => void
	setGender: (gender: string) => void
	setQuality: (quality: string) => void
	setStyle: (style: boolean) => void
	setExpandedVoice: (expandedCardIndex: number | null) => void
	toggleCreateCustomVoiceProfileModal: () => void
	toggleSetDefaultProfile: () => void
	closeModal: () => void
}

export const useAIVoicesStore = create<AIVoicesState>()((set) => ({
	languages: {},
	voices: [],
	selectedLanguage: null,
	selectedVoice: null,
	filters: {
		gender: 'all',
		quality: 'all',
		style: false,
	},
	expandedCardIndex: null,
	showSetDefaultProfileModal: false,
	showCreateCustomVoiceProfileModal: false,
	setLanguages: (languages) => set((state) => {

		if (!!state.selectedLanguage) {
			return {languages: languages}
		}

		const language = languages[DEFAULT_LANG_CODE]

		return ({ 
			languages: languages,
			selectedLanguage: language,
			voices: sortVoicesInPriority(language.voices),
			expandedCardIndex: null
		})
	}),
	resetFilters: () => set((state) => (
		{ 
			voices: sortVoicesInPriority(state.selectedLanguage?.voices), 
			filters: {
				gender: 'all',
				quality: 'all',
				style: false,
			},
			expandedCardIndex: null
		}
	)),
	setGender: (gender: string) => set((state) => {
		const newFilters = {
			...state.filters,
			gender: gender
		}
		return (
			{ 
				filters: {...newFilters},
				voices: getFilteredVoices(state.selectedLanguage?.voices, newFilters),
				expandedCardIndex: null
			}
		)
	}),
	setQuality: (quality: string) => set((state) => {
		const newFilters = {
			...state.filters,
			quality: quality
		}
		return (
			{ 
				filters: {...newFilters},
				voices: getFilteredVoices(state.selectedLanguage?.voices, newFilters),
				expandedCardIndex: null
			}
		)
	}),
	setStyle: (style: boolean) => set((state) => {
		const newFilters = {
			...state.filters,
			style: style
		}
		return (
			{ 
				filters: {...newFilters},
				voices: getFilteredVoices(state.selectedLanguage?.voices, newFilters),
				expandedCardIndex: null
			}
		)
	}),
	setVoices: (voices) => set(() => ({ voices: sortVoicesInPriority(voices) })),
	setSelectedLanguage: (language) => set(() => ({ selectedLanguage: language, voices: sortVoicesInPriority(language.voices) })),
	setSelectedVoice: (voice) => set(() => {

		const defaultConfiguration = DEFAULT_VOICE_CONFIGURATION as IVoiceConfiguration;
		useVoiceConfigurationStore.setState({
			voiceConfiguration: defaultConfiguration
		})

		return ({ 
			selectedVoice: voice, 
		})
	}),
	setExpandedVoice: (expandedCardIndex) => set(() => ({ expandedCardIndex: expandedCardIndex })),
	toggleCreateCustomVoiceProfileModal: () => set((state) => ({ 
		showCreateCustomVoiceProfileModal: !state.showCreateCustomVoiceProfileModal 
	})),
	toggleSetDefaultProfile: () => set((state) => ({ 
		showSetDefaultProfileModal: !state.showSetDefaultProfileModal 
	})),
	closeModal: () => set(() => ({showCreateCustomVoiceProfileModal: false, showSetDefaultProfileModal: false}))
}))

function getFilteredVoices(voicesInput: Array<IVoice> = [], filters: {
	gender: string
	quality: string
	style: boolean
}) {
	let copy = [...voicesInput]
	if (filters.gender !== 'all') {
		copy = copy.filter((voice) => voice.gender === filters.gender)
	}
	if (filters.quality !== 'all') {
		copy = copy.filter((voice) => voice.engine === filters.quality)
	}
	if (filters.style) {
		copy = copy.filter((voice) => voice.styles.length > 0)
	}
	console.log(copy)
	return sortVoicesInPriority(copy);
}

function sortVoicesInPriority(voicesInput: Array<IVoice> = []) {
	return voicesInput.sort((a, b) => {
		// sort for styles
		if (a.styles.length > 0 || b.styles.length > 0) {
			return b.styles.length - a.styles.length
		}
		// default sort for voice quality
		// if engine is neural, its string length is 6
		// if engine is standard, its string length is 8
		// leverage this to run the sort
		return a.engine.length - b.engine.length;
	})
}
