import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import SingleVoiceLabelEditor from "./SingleVoiceLabelEditor"
import SingleVoiceDetails from "./SingleVoiceDetails"
import { setIsDefaultProfile, updateSelectedAudioProfile, updateVoice } from '../../store/actions';
import { toast } from 'react-toastify';

export default function ShowVoicesAccordion({ voices, onVoiceChange, selectedVoice, audioControls }) {
    const dispatch = useDispatch()
    const org = useSelector((state) => state.organisation.organisation)

    const handleVoiceChange = (voice) => {
        const isDisabled = org && !org.gates.can_select_premium_voices && (voice.engine === "neural")
        if (isDisabled) return toast.error("Please upgrade your plan to select premium audios");
        const newVoice = voices.find(v => (v.code === voice.code) && (v.engine === voice.engine))

        if (onVoiceChange) {
            onVoiceChange(newVoice)
        } else {
            dispatch(updateVoice(newVoice))
            dispatch(updateSelectedAudioProfile(null))
            dispatch(setIsDefaultProfile(false))
        }
    }

    return (
        voices.map((voice, voiceIndex) => (<div className="col-12 col-md-6">
                <Accordion>
                    <VoiceListItem
                        handleVoiceChange={handleVoiceChange}
                        selectedVoice={selectedVoice}
                        voice={voice}
                        voiceIndex={voiceIndex}
                        audioControls={audioControls}
                    />
                </Accordion>
            </div>)
        )
    )
};

const VoiceListItem = ({ selectedVoice, voiceIndex, voice, handleVoiceChange, audioControls }) => {
    const isSelected = selectedVoice
        && (selectedVoice.code === voice.code)
        && (selectedVoice.engine === voice.engine)

    return <div key={`voice-${voiceIndex}`} className='mb-2'>
        <div className="d-flex">
            <Card className="voice-tile-editor" style={{ 'flexGrow': 1, 'border': (isSelected ? '2px solid rgb(116 129 144)' : null) }}>
                <Card.Header>
                    <SingleVoiceLabelEditor
                        isSelected={isSelected}
                        onSelect={() => handleVoiceChange(voice)}
                        className="voice-name-label"
                        eventKey={`AV-${voiceIndex}`}
                        voice={voice}
                        audioControls={audioControls}
                    />
                </Card.Header>
                <Accordion.Collapse eventKey={`AV-${voiceIndex}`}>
                    <Card.Body>
                        <SingleVoiceDetails voice={voice} />
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </div>
    </div>
}