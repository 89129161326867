import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {initializeAudioComposerRequested} from "../../store/actions"

import { 
    updateAudioComposerExtraSettings,
    toggleAudioComposerPlayer
} from "../../store/actions";

import ComposerAudioPlayer from "./ComposerAudioPlayer"
import zoomPlusIcon from "../../assets/editor/zoom-plus.svg"
import zoomMinusIcon from "../../assets/editor/zoom-minus.svg"
import ComposerDownArrowImg from "../../assets/editor/composer-down-arrow.svg"
import ComposerUpArrowImg from "../../assets/editor/composer-up-arrow.svg"
// import infoIcon from "../../assets/editor/info.svg"

function ComposerHeader({orgId, integrationId}) {
    const dispatch = useDispatch()
    const {extraSettings, isComposerActive} = useSelector(state => state.audio_composer)

    useEffect(() => {
        dispatch(initializeAudioComposerRequested())
    }, [])

    
    return useMemo(() => { return (
        <>
        <div className="audio-composer-header">
            
            <div onClick={(e) => dispatch(toggleAudioComposerPlayer(!isComposerActive))} className="composer-slide-toggle">
                <img src={isComposerActive ? ComposerDownArrowImg : ComposerUpArrowImg} />
                <img style={{marginTop : -3}} src={isComposerActive ? ComposerDownArrowImg : ComposerUpArrowImg} />
            </div>

            <div className="composer-preview-settings">
                <ComposerAudioPlayer orgId={orgId} integrationId={integrationId} />   
            </div>
            
            <div className="composer-zoom-settings">
                <img title="Zoom in" width="18" src={zoomMinusIcon} />
                <input title="Zoom in/Zoom out composer" type="range" min="0" max="100" className="form-control-range audio-composer-zoom-range" value={extraSettings.zoomLevel} onChange={e => dispatch(updateAudioComposerExtraSettings({ zoomLevel : parseInt(e.target.value)}))}/>
                <img title="Zoom out" width="18" src={zoomPlusIcon} />
            </div>
        </div>
        </>
    ) }, [isComposerActive, extraSettings])
}

export default ComposerHeader