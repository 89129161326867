import React, { useEffect, useRef, useState } from 'react'
import { API } from "aws-amplify";
import Bugsnag from "@bugsnag/js";
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Spinner from '../Spinner';
import { returnTimeLabel } from "./utils"
import {
    updateCurrentAudio,
    toggleCurrentAudioPlaying,
    updateCurrentAudioSeekValue,
    updateAudioComposerIsContentChanged,
    updateCurrentAudioDuration,
    toggleIsAudioGenerating,
    toggleDownloadContentModal,
    fetchOrganisationUsageRequested,
    stopComposerPlayer,
    fetchContentBlocksRequested
} from "../../store/actions"
// Images
import ComposerPlayIcon from "../../assets/editor/composer-play-white.svg"
import ComposerPauseIcon from "../../assets/editor/composer-pause-white.svg"
import ComposerPreviousIcon from "../../assets/editor/composer-previous.svg"
import ReactHowler from 'react-howler';
import { useBuildAudioSocket } from '../../hooks/useBuildAudioSocket';
import { useGlobalStore } from '../../zustand-store/useGlobalStore';

const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        Consumes word credits
    </Tooltip>
);

function ComposerAudioPlayer({ orgId, integrationId }) {
    const dispatch = useDispatch();
    const toggleUpgradeModal = useGlobalStore((state) => state.toggleUpgradeModal)

    const { isContentChanged: audioComposerIsContentChanged, slate_value, sections, title, isContentChanged, isAudioGenerating } = useSelector(state => state.audio_composer);
    const { currentAudio: audio, isCurrentAudioPlaying: isPlaying, playerSeek, currentAudioDuration, isSRTLoading } = useSelector(state => state.content)
    const { content } = useSelector((state) => state.edit_content)

    const [toolTipShow, setToolTipShow] = useState(false)

    const player = useRef(null)
    const playerInterval = useRef(null)

    const generateTTS = (socketConnectionId) => {
        const contentId = content.SK.split('#')[1];
        API.put('main', `/organisation/${orgId}/integration/${integrationId}/content/${contentId}/v2`, {
            headers: { "Content-Type": "application/json" },
            body: {
                socketConnectionId: socketConnectionId,
                title: title,
                slate_value: slate_value,
                composer_settings: sections,
                is_draft: false,
                isContentChanged,
                includeMusic: true,
            }
        }).then((resp) => {

            if (!resp.success) {
                if (resp.failure_type && resp.failure_type === 'NO_ENOUGH_CREDITS') {
                    toggleUpgradeModal(true, { title: resp.message });
                    return;
                } else if (resp.failure_type && resp.failure_type === 'CONTENT_TOO_LONG') {
                    toast.error(resp.message);
                    return;
                }
            }
        }).catch((error) => {
            Bugsnag.notify(error);
            toast.error('Something went wrong');
        }).finally(() => {
            dispatch(toggleIsAudioGenerating(false))
        })
    }

    const startSeeker = () => {
        playerInterval.current = setInterval(() => {
            if (audio && player && player.current !== null) {
                try {
                    const seek = player.current.seek();
                    if (!(isNaN(seek))) dispatch(updateCurrentAudioSeekValue({ seek }))
                } catch (error) {
                    console.log(player?.current)
                    Bugsnag.notify(error);
                }
            }

        }, 100);
    }

    const stopSeeker = () => {
        clearInterval(playerInterval.current);
        playerInterval.current = null
    }

    const playAudio = () => {
        dispatch(toggleCurrentAudioPlaying(true))
        startSeeker()
    }

    const pauseAudio = () => {
        dispatch(toggleCurrentAudioPlaying(false))
        stopSeeker()
    }

    const stopAudio = () => {
        dispatch(stopComposerPlayer())
    }

    const playFullAudio = (message) => {
        const contentId = content.SK.split('#')[1];
        dispatch(toggleIsAudioGenerating(false))
        const audioURL = `${process.env.REACT_APP_AUDIO_BUCKET}/${message.audio_url}`;
        dispatch(updateCurrentAudio(audioURL))
        dispatch(updateCurrentAudioDuration(message.duration))
        dispatch(updateAudioComposerIsContentChanged(false))
        dispatch(toggleDownloadContentModal(false))
        playAudio()

        dispatch(fetchContentBlocksRequested(orgId, integrationId, contentId, true));
    }


    const [connect, disconnect, sendMessage] = useBuildAudioSocket(
        () => sendMessage("socket", "get credentials"),
        (message) => {
            console.log("socket", { message })
            if (message.progress && message.progress === 100) {
                disconnect();
                playFullAudio(message);
                dispatch(fetchOrganisationUsageRequested(orgId));
            } else if (message.connectionId) {
                generateTTS(message.connectionId)
            } else if (message.error) {
                toast.error("Something went wrong!")
            }
        },
        () => console.log("socket closed")
    );

    const handlePlayAudio = () => {
        console.log("Play button clicked, current state of Playing - ", isPlaying)
        if (isPlaying) {
            console.log("Before calling pause function")
            pauseAudio()
        } else {
            console.log("Before calling play function")
            // For new generation
            if (!audio || audioComposerIsContentChanged) {
                console.log("New audio to be generated")
                setToolTipShow(false)
                // Setting the seek time to 0
                stopAudio()
                dispatch(toggleIsAudioGenerating(true))
                connect();
                return
            }

            if (!player.current) return

            console.log("Before Resume audio")
            // For existing audio, just resume
            playAudio()
        }
    }

    const manageOverlayShow = (show) => {
        setToolTipShow((audioComposerIsContentChanged && !isPlaying && !((isAudioGenerating || isSRTLoading))) ? show : false)
    }

    useEffect(() => {

        if (player && player.current && !isPlaying) {
            player.current.seek(playerSeek);
        }

    }, [playerSeek, isPlaying])

    // useEffect(() => {

    //     if (audio && isPlaying && player.current) {
    //         console.log("Before Start Seeker", { audio, isPlaying, c: player.current })
    //         startSeeker()
    //     } else {
    //         console.log("Before Stop Seeker", { audio, isPlaying, c: player.current })
    //         stopSeeker()
    //     }

    // }, [audio, isPlaying, player])

    useEffect(() => {
        // Destroying the interval on destroy
        return () => clearInterval(playerInterval.current);
    }, [])

    return (
        <>
            {
                (audio) ? <ReactHowler html5={true} src={audio} ref={player} playing={isPlaying} onEnd={stopAudio}
                /> : null

            }

            <img alt="" style={{ cursor: "pointer" }} onClick={() => stopAudio()} src={ComposerPreviousIcon} height="15" />


            {(isAudioGenerating || isSRTLoading) ?
                <Button style={{ pointerEvents: "none" }} onClick={() => handlePlayAudio()} variant="outline-primary" className="composer-preview is-generating"><span style={{ marginTop: 2, marginRight: 5 }}><Spinner color="#333B6C" /></span><span>Generating</span></Button> :
                <OverlayTrigger
                    placement="bottom"
                    overlay={renderTooltip}
                    onToggle={manageOverlayShow}
                    show={toolTipShow}
                >
                    <Button onClick={() => handlePlayAudio()} variant="primary" className="composer-preview">
                        <img src={isPlaying ? ComposerPauseIcon : ComposerPlayIcon} height="20" alt="" />
                        <span>{isPlaying ? "Pause" : (audioComposerIsContentChanged ? "Build" : "Play")}</span>
                    </Button>
                </OverlayTrigger>

            }

            <div className="composer-time">
                <span className="current">{returnTimeLabel(playerSeek)}</span>
                <span className="total"> / {returnTimeLabel(currentAudioDuration)}</span>
            </div>
        </>
    )
}

export default ComposerAudioPlayer