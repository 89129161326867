import React, { useState, useRef, useEffect } from 'react';
import ReactHowler from 'react-howler';

import closeIcon from '../assets/close.svg';

export default function AudioPlayer({audio, title, onClose, onEnd}){

    const player = useRef(null);
    const [isPlaying, setIsPlaying ] = useState(false);
    const [timer, setTimer] = useState("00:00")
    const [progress, setProgress] = useState(0);

    const formatTime = (secs, duration) => {

        const timeLeft = duration - secs;

        const minutes = Math.floor(timeLeft / 60) || 0;

        const seconds = (timeLeft - minutes * 60) || 0;

        return '-' + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;

    };

    useEffect(() => {

        const interval = setInterval(() => {

            if(isPlaying){

                const seek = player.current.seek();

                setTimer(formatTime(Math.round(seek), Math.round(player.current.duration())))
                setProgress(seek);

            }

        }, 250 );

        return () => clearInterval(interval);

      }, [isPlaying, player]);

      useEffect(() => {
          setIsPlaying(true)
      }, [audio])

    return (
        <div className="audio-player">
            <img alt='close' className="close-icon" src={closeIcon} onClick={() => onClose()} />
            <div className="control" onClick={()=>{setIsPlaying(!isPlaying)}}>
                {
                    (isPlaying)?
                    (
                        <PauseIcon />
                    )
                    :
                    (
                        <PlayIcon />
                    )
                }
            </div>
            <div className="middle">
                <p className="title">{title}</p>
                <input
                    type="range" 
                    value={progress}
                    onChange={(e) => {
                        const value = e.target.value;

                        setProgress(value)
                        player.current.seek(value);
                    }}
                    max={player.current ? player.current.duration() : 0}
                />
                <div className="timer">
                    {timer}
                </div>
            </div>
            <div className="tail" />
            <ReactHowler
                html5={true} 
                src={audio} 
                playing={isPlaying} 
                ref={player} 
                onEnd={() => {
                    setIsPlaying(false)
                    if (onEnd)onEnd()
                }} 
            />
        </div>
    )

}

function PlayIcon(){
    return(
        <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="23.5" cy="23.5" r="23.5" fill="#3F7BEE" />
            <path d="M34 24L19 32.6603V15.3397L34 24Z" fill="white"/>
        </svg>
    );
}

function PauseIcon(){
    return(
        <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="23.5" cy="23.5" r="23.5" fill="#2D79F6"/>
            <path d="M20 16V32M27 16V32" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
