import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import shallow from 'zustand/shallow';

import { EditCustomProfileModal, PageParams } from '../components/ai-voices/ConfigureVoiceModal';
import { IconButton } from '../components/IconButton';
import Spinner from '../components/Spinner';
import { Pipe } from '../components/editor/Pipe';
import { usePreGenAudioPlayer } from '../zustand-store/usePreGenAudioPlayerStore'
import { useSavedProfiles } from '../http-hooks/saved-profiles/useSavedProfiles';
import PlayIcon from '../assets/voice/tile-play.svg'
import PauseIcon from '../assets/voice/tile-pause.svg'
import DropdownIcon from '../assets/down_chevron.svg';
import EditIcon from '../assets/voice/edit.svg';
import { keyable, useSavedProfilesStore } from '../zustand-store/useSavedProfilesStore';
import { useAIVoicesStore } from '../zustand-store/useAIVoicesStore';
import { useLanguages } from '../http-hooks/ai-voices/useLanguages';
import { useVoiceConfigurationStore, VoiceConfigurationOptions } from '../zustand-store/useVoiceConfigurationStore';
import { ChangeVoiceModal } from '../components/ai-voices/ChangeVoiceModal';

export default function SavedProfiles() {

    return (
        <div>
            <h3 className="page-title">Saved Profiles</h3>
            <SavedProfilesList />
            <EditCustomProfileModal />
            <ChangeVoiceModal />
        </div>
    )
}

const SavedProfilesList = () => {

    const { org_id, id } = useParams<PageParams>();
    const setLanguages = useAIVoicesStore(state => state.setLanguages)
    const languages = useLanguages((data) => setLanguages(JSON.parse(data.data)));
    const { isLoading, isError, data } = useSavedProfiles({ org_id, id });
    
    if (isLoading || languages.isLoading) {
        return (
            <Spinner color="#115CD9" />
        )
    }

    if (isError) {
        return <></>
    }

    const profiles = data.data

    return (
        <div style={{maxWidth: 600}}>
            {
                profiles.map((profile: any, index: number) => (
                    <SavedProfileListItem key={index} profile={profile} index={index} />
                ))
            }
        </div>
    )
}

interface ISavedProfileListItemProps {
    profile: any
    index: number
}

const SavedProfileListItem = ({ profile, index }: ISavedProfileListItemProps) => {

    const { expandedCardIndex, setExpandedProfile, setSelectedProfile, toggleEditProfile } = useSavedProfilesStore((state) => ({
        expandedCardIndex: state.expandedCardIndex, 
        setExpandedProfile: state.setExpandedProfile, 
        setSelectedProfile: state.setSelectedProfile,
        toggleEditProfile: state.toggleEditProfile,
    }), shallow);
    const setVoiceConfiguration = useVoiceConfigurationStore((state) => state.setVoiceConfiguration)
    const profileDetailsRef = useRef<HTMLDivElement>(null);
    
    const languages = useAIVoicesStore((state) => state.languages);
    const language = languages[profile.lang_code];
    const voice = language.voices.find(item => item.code === profile.voice && item.engine === profile.engine)

    return (
        <div style={{gap: 24}} className='d-flex flex-row align-items-center px-1 my-3'>
            <div style={{border: '1px solid #70707073', borderRadius: 6, flexGrow: 1, padding: '6px 10px' }}>
                <div className='d-flex justify-content-between'>
                    <div className='d-flex' style={{ gap: 8, fontSize : 13, fontWeight : "bold" }}>
                        <PreviewIcon audioSrc={voice?.audio || ""} />
                        <div style={{ gap: 4 }} className='d-flex align-items-center'>
                            <span>
                                {profile.profile_name}
                            </span>
                        </div>
                    </div>
                    <IconButton style={{transform: expandedCardIndex === index ? 'rotate(180deg)' : 'rotate(0deg)'}} onClick={() => {
                        if (expandedCardIndex === index) {
                            setExpandedProfile(null)
                        } else {
                            setExpandedProfile(index)
                        }
                    }} src={DropdownIcon} alt="drop-down"  />
                </div>
                <div ref={profileDetailsRef} style={{overflow: 'hidden', transition: 'all 0.5s ease', height: expandedCardIndex === index ? profileDetailsRef.current?.scrollHeight : 0}}>
                    <ProfileDetails profile={profile} />
                </div>
            </div>
            <div className='d-flex flex-shrink-0'>
                <span onClick={() => {
                    setSelectedProfile(profile)
                    toggleEditProfile()
                    
                    setVoiceConfiguration(VoiceConfigurationOptions.volume, parseInt(profile.volume))
                    setVoiceConfiguration(VoiceConfigurationOptions.speed, parseInt(profile.speed))

                    if (profile.pitch) {
                        setVoiceConfiguration(VoiceConfigurationOptions.pitch, profile.pitch)
                    } else {
                        setVoiceConfiguration(VoiceConfigurationOptions.pitch, 'default')
                    }

                    if (profile.style) {
                        setVoiceConfiguration(VoiceConfigurationOptions.style, profile.style)
                    } else {
                        setVoiceConfiguration(VoiceConfigurationOptions.style, 'default')
                    }


                }} style={{color: '#2D79F6', fontSize: 14, fontWeight: 600, cursor: 'pointer'}}>Edit Profile</span>
                <Pipe />
                <IconButton width={16} height={16} src={EditIcon} alt="Create voiceover" />
            </div>
        </div>
    )
}

interface IProfileDetailsProps {
    profile: keyable
}

const ProfileDetails = ({ profile }: IProfileDetailsProps) => {

    const languages = useAIVoicesStore((state) => state.languages);
    const language = languages[profile.lang_code];
    const voice = language.voices.find(item => item.code === profile.voice && item.engine === profile.engine)

    return (
        <div className='audio-profile-card-details row' style={{padding: 20}}>
            <div className='col-6 d-flex flex-column'>
                <label>Language</label>
                <span>{language.name}</span>
            </div>
            <div className='col-6 d-flex flex-column'>
                <label>Voice</label>
                <span className="voice">{voice?.name}</span>
            </div>
        </div>
    )
}

const PreviewIcon = ({ audioSrc }: IPreviewIconProps) => {

    const { src, isPlaying, togglePlayPause } = usePreGenAudioPlayer();

    return (
        <button onClick={() => togglePlayPause(getAudioURL(audioSrc))} style={{
            background: 'rgb(227, 227, 227)',
            boxShadow: 'none',
            border: 'none',
            borderRadius: 6,
            width: 28,
            height: 28
        }}>
            {
                isPlaying && src === getAudioURL(audioSrc)
                    ? (
                        <img height={14} width={14} src={PauseIcon} alt="pause-icon" />
                    )
                    : (
                        <img height={14} width={14} src={PlayIcon} alt="play-icon" />
                    )
            }
        </button>
    )
}

interface IPreviewIconProps {
    audioSrc: string
}

const getAudioURL = (audio: string) => {
    return `${process.env.REACT_APP_AUDIO_PREVIEW_URL}/${audio}`
}
