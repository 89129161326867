import React from 'react';
import { useAIVoicesStore } from '../../zustand-store/useAIVoicesStore';

export default function LeftPane() {

    const {languages, selectedLanguage, setSelectedLanguage, filters, resetFilters, setGender, setQuality, setStyle} = useAIVoicesStore(state => state)

    return (
        <div style={{padding: "24px 12px", background: "#F6F7FD"}} className="filter-box flex-grow-1 flex-column">
            <div className="form-group">
                <div className='d-flex flex-row justify-content-between align-items-center mb-2'>
                    <span className="filter-heading"><b>Language</b></span>
                </div>
                <select value={selectedLanguage?.code} className="custom-select" name="lang_code" onChange={(e) => {
                    setSelectedLanguage(languages[e.target.value])
                }} >
                    {
                        Object.keys(languages).map((lang) => {
                            return (
                                <option value={languages[lang].code} key={`step-one-lang-${languages[lang].code}`}>{languages[lang].name}</option>
                            )

                        })
                    }
                </select>
            </div>

            <div style={{marginTop: 24}} className="form-group">
                <div className='d-flex flex-row justify-content-between align-items-center'>
                    <span className="filter-heading"><b>Filters</b></span>
                    <span onClick={() => resetFilters()} style={{ fontSize: 14, lineHeight: 2 }} className='btn btn-link'>
                        Reset
                    </span>
                </div>
                <select onChange={(e) => setGender(e.target.value )} value={filters.gender} className="custom-select" name="gender" >
                    <option value="all">All</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                </select>
            </div>

            <div className="form-group">
                <select onChange={(e) => setQuality(e.target.value)} value={filters.quality} className="custom-select" name="quality" >
                    <option value="all">All</option>
                    <option value="neural">Premium</option>
                    <option value="standard">Standard</option>
                </select>
            </div>

            <div className="form-group">
                <input
                    type="checkbox"
                    onChange={
                        (e) => setStyle(e.target.checked)
                    }
                    checked={filters.style}
                    name="style"
                />
                <span style={{ color: "#333B6C", fontSize: 14, marginLeft: 4 }}>Voice Styles</span>
            </div>
        </div>
    )
}
