import React from 'react';
import Content from "./Content"

function ContentAudioArticle(){

    return(
        <React.Fragment>
            <h3 className="page-title">All Audio Articles</h3>
            <Content contentType="audio_article" />
        </React.Fragment>
    );
}

export default ContentAudioArticle;