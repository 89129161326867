import { API } from 'aws-amplify';
import { useQuery } from "@tanstack/react-query";

export const fetchOrganisation = (org_id: string) => {
    return API.get('main', `/organisation/${org_id}`, {headers: {'Content-Type': "application/json"}});
}

interface IuseOrganisationProps {
    org_id: string
}

export const useOrganisation = ({org_id}: IuseOrganisationProps) => {
    return useQuery(['organisation', org_id], () => fetchOrganisation(org_id))
}
