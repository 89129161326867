export const TIME_PIXEL_CONVERSION = 60
export const BLOCK_SIZE_IN_SECONDS = 10

export const defaultSectionsValue = () => {
    return [{ type : "TTS", tracks : [defaultTTSTrackValue()] }, { type : "AUDIO", tracks : [defaultTrackValue()] }]
}

export const defaultSlateBlockValue = (audio_profile, text = '') => {
	return (
		{
			type: "block",
			audio_profile: {
				...audio_profile
			},
			children: [
				{
					type: 'paragraph',
					children: [
						{
							text: text
						}
					]
				}
			]
		}
	)
}

export const defaultTTSTrackValue = () => {
    return {
        type : "TRACK",
        blocks : [defaultBlockValue()]
    }
}

export const defaultTrackValue = () => {
    return {
        type : "TRACK",
        blocks : []
    }
}

export const roundSingleDig = (val) => {
    return Math.round(val*10)/10
}

export const getConversionValue = (val) => {
    return val*0.2
}

export const defaultBlockValue = () => {
    const defaultDuration = 5 + parseInt(Math.random()*15)
    return {
        title : "Block",
        type : "BLOCK",
        starts : 0, // In seconds relative to 0s
        trimRight: 0,
        ends : defaultDuration, // In seconds relative to 0s
        url : null,
        delay : 0,
        duration: defaultDuration, // Original duration of audio
		fadeInDuration : 0,
		fadeOutDuration : 0,
		volume : 100
    }
}

export const secondsToPixels = (s, zoomLevel=TIME_PIXEL_CONVERSION) => {
    const conversionValue = getConversionValue(zoomLevel)
    s = Math.round(s*10)/10 // Rounding to nearest single decimal place
    return `${s*conversionValue}`
}

export const pixelsToSeconds = (p, zoomLevel=TIME_PIXEL_CONVERSION) => {
    const conversionValue = getConversionValue(zoomLevel)
	let s = Math.round(p)/conversionValue
	s = Math.round(s*10)/10 // Rounding to nearest single decimal place
    return s
}

export const getAudioDurationOfTempAudio = async(url) => {
    const au = document.createElement('audio');
    au.src = `${process.env.REACT_APP_AUDIO_BUCKET}/${url}`;
    return new Promise((resolve) => {
        au.addEventListener('loadedmetadata', function(){
            const duration = au.duration;
            resolve(duration)
        },false);
    })
    
}

export const returnTimeLabel = (totalTimeInSeconds) => {
    totalTimeInSeconds = Math.round(totalTimeInSeconds*10)/10
    const timeInMinutes = Math.round(Math.floor(totalTimeInSeconds/60)).toString()
    const timeInSeconds = (Math.round((totalTimeInSeconds - (timeInMinutes*60))*10)/10).toString()
    return `${timeInMinutes.padStart(2, '0')}:${timeInSeconds.padStart(2, '0')}`
}