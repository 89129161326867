import Bugsnag from "@bugsnag/js";
import { call, debounce } from "redux-saga/effects";

import {
    ADD_MIXPANEL_EVENT,
} from "../actionTypes"

import {
    trackMixpanelService
} from "../../services/mixpanel"

import {
    
} from "../actions";

function* addMixpanelEventSaga(action){
    try {
        yield call(trackMixpanelService, action.payload);
    } catch (e) {
        Bugsnag.notify(e);
    }
}

function* mixpanelSaga() {
    yield debounce(500, [ADD_MIXPANEL_EVENT], addMixpanelEventSaga);
}

export default mixpanelSaga;
