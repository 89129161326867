
export default class CustomURL{

    constructor(base) {

        this.base = base;
        this.searchParams = [];

    }

    appendSearchParam(key, value){

        this.searchParams.push({
            key,
            value
        })

    }

    joinSearchParam(){

        return this.searchParams.map((param)=>{
            return param.key + '=' + param.value;
        }).join('&');

    }

    href(){

        if(this.searchParams.length === 0){

            return this.base;

        }

        return this.base + '?' + this.joinSearchParam()

    }

}
