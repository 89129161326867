import React from 'react';
import {Crisp} from "crisp-sdk-web"
import { useHistory, useParams } from 'react-router-dom';
import wordpressIcon from '../../assets/content/empty-state-wordpress-icon.svg'
import cmsIcon from '../../assets/content/empty-state-other-cms.svg'
import rightArrowIcon from '../../assets/content/empty-state-right-arrow.svg'
import helpIcon from '../../assets/content/empty-state-help.svg'
import _ from 'lodash';
import { Button } from 'react-bootstrap';

function AudiosEmptyState({ orgId, integrationId }) {

    const history = useHistory();
    const { org_id, id } = useParams();
    const PUBLISH_AUDIO_ITEMS = [
        {
            icon: wordpressIcon,
            label: 'WordPress',
            sublabel: 'Using Listen2It WordPress plugin',
            action: () => {
                history.push(`/organisation/${orgId}/integration/${integrationId}/wordpress`)
            }
        },
        {
            icon: cmsIcon,
            label: 'Other CMS or Blog',
            sublabel: 'Using Listen2It Universal Embed Code',
            action: () => {
                history.push(`/organisation/${orgId}/integration/${integrationId}/cms`)
            }
        },
    ];

    const CardItem = ({ count, icon, label, sublabel, onClick }) => {
        return (
            <div style={{ marginLeft: 15, marginRight: 15, }} key={count} onClick={onClick} className="col card--add-audio">
                <img alt="add-audio" width={32} src={icon} />
                <div className="flex-grow-1">
                    <p className="mb-0 font-weight-bold" style={{ fontSize: 18 }}>{label}</p>
                    <p className="mb-0" style={{ fontSize: 14 }}>{sublabel}</p>
                </div>
                <img alt="add-audio" width={25} src={rightArrowIcon} />
            </div>
        );
    }

    return (
        <div className="">
            <div className="mt-5 mb-4">
                <h5 className="mb-4" style={{ fontSize: 18, fontWeight: "bold" }}>Start publishing audio versions of content automatically. </h5>
                <h5 style={{ fontSize: 18 }}>Choose from the list of supported content platforms to view integration instructions. Integrate Listen2It within minutes to get started.</h5>
            </div>

            <div className="row flex-column mb-5">
                {
                    PUBLISH_AUDIO_ITEMS.map((item, count) => (
                        <CardItem key={count} count={count} icon={item.icon} label={item.label} sublabel={item.sublabel} onClick={item.action} />
                    ))
                }
            </div>

            <div style={{ borderRadius: 5, background: "#F6F7FD", height: 140 }} className="d-flex flex-column justify-content-center align-items-center">
                <img className="mb-2" src={helpIcon} width="20" />
                <p><b>Having trouble publishing your podcast?</b></p>
                <Button className="mr-2" onClick={() => Crisp.chat.open()} variant="primary">Contact Us</Button>
            </div>
        </div>
    );
}

export default AudiosEmptyState;
