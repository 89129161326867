import React from 'react';
import EditProfile from './EditProfile';
import ChangePassword from './ChangePassword';


function Profile(){
   
    return(
        <React.Fragment>
            <div className="container-fluid">
                <div className="container">
                    <div className="row my-3 my-xl-5">
                        <div className="col-12">
                            <h3>Your Profile</h3>
                            <p>Edit your basic profile information</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <EditProfile />
                            <ChangePassword />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Profile;