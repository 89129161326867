import Bugsnag from "@bugsnag/js";
import React from "react";
import { Dropdown } from "react-bootstrap";

import dropdownIcon from '../../../assets/down_chevron_primary.svg';
import { MemoizeImage } from "../Modals/MemoizeImage";

export const StylePicker = ({ value, onChange, styles, disabled }) => {

    const CustomToggleContent = React.forwardRef(({ onClick }, ref) => (
        <button style={{ fontWeight: 600, opacity: disabled ? 0.65 : 1 }} disabled={disabled} type="button" className="btn--pitch" ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }} >
            {!value ? 'default' : value}
            <MemoizeImage className="ml-2 action-icon" width={9} height={6} src={dropdownIcon} alt="dropdown" />
        </button>
    ));

    return (
        <Dropdown as="span">
            <Dropdown.Toggle as={CustomToggleContent} />
            <Dropdown.Menu>
                <Dropdown.Item as="span" onClick={() => onChange('default')} >
                    Default
                </Dropdown.Item>
                {
                    styles.map((style, index) => {

                        try {
                            style = style.replace('[', '')
                            style = style.replace(']', '')
                        } catch (e) {
                            console.log(style)
                            Bugsnag.notify(e)
                        }

                        return (
                            <Dropdown.Item key={index} as="span" onClick={() => onChange(style)} >
                                {style.substring(0, 1).toUpperCase() + style.substring(1)}
                            </Dropdown.Item>
                        )
                    })
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}