import {
    FETCH_LANGUAGES_REQUESTED,
    FETCH_LANGUAGES_SUCCESS,
    FETCH_LANGUAGES_FAILURE,
    UPDATE_VOICE_SETTINGS_FILTER,
    UPDATE_VOICES,
    UPDATE_VOICE,
    UPDATE_SHOW_DELETE_CONFIRMATION_MODAL,
    UPDATE_SHOW_UPDATE_CONFIRMATION_MODAL,
    SET_SHOW_SAVE_AUDIO_PROFILE_MODAL,
    UPDATE_AUDIO_PROFILE_REQUESTED,
    UPDATE_AUDIO_PROFILE_SUCCESS,
    UPDATE_AUDIO_PROFILE_FAILURE,
    UPDATE_SHOW_EDITOR_VOICE_PROFILES,
    UPDATE_VOICE_CONFIGURATION,
    UPDATE_LANGUAGE,
    UPDATE_SELECTED_AUDIO_PROFILE,
    SET_IS_DEFAULT_PROFILE
} from "../actionTypes"

export const fetchLanguagesRequested = () => {
    return {
        type: FETCH_LANGUAGES_REQUESTED,
    };
};

export const fetchLanguagesSuccess = (data) => {
    return {
        type: FETCH_LANGUAGES_SUCCESS,
        payload: data,
    };
};

export const fetchLanguagesFailure = (data) => {
    return {
        type: FETCH_LANGUAGES_FAILURE,
        payload: data,
    };
};

export const updateVoiceSettingsFilter = (data) => {
    return {
        type: UPDATE_VOICE_SETTINGS_FILTER,
        payload: data,
    };
};

export const updateVoices = (data) => {
    return {
        type: UPDATE_VOICES,
        payload: data,
    };
};

export const updateVoice = (data) => {
    return {
        type: UPDATE_VOICE,
        payload: data,
    };
};

export const updateSelectedAudioProfile = (data) => {
    return {
        type: UPDATE_SELECTED_AUDIO_PROFILE,
        payload: data,
    };
};

export const updateLanguage = (data) => {
    return {
        type: UPDATE_LANGUAGE,
        payload: data,
    };
};

export const updateVoiceConfiguration = (data) => {
    return {
        type: UPDATE_VOICE_CONFIGURATION,
        payload: data,
    };
};

export const updateShowDeleteConfirmation = (data) => {
    return {
        type: UPDATE_SHOW_DELETE_CONFIRMATION_MODAL,
        payload: data,
    };
};

export const updateShowUpdateConfirmation = (data) => {
    return {
        type: UPDATE_SHOW_UPDATE_CONFIRMATION_MODAL,
        payload: data,
    };
};

export const updateAudioProfileRequested = (data) => {
    return {
        type: UPDATE_AUDIO_PROFILE_REQUESTED,
        payload: data,
    };
};

export const updateAudioProfileSuccess = (data) => {
    return {
        type: UPDATE_AUDIO_PROFILE_SUCCESS,
        payload: data,
    };
};

export const updateAudioProfileFailure = (data) => {
    return {
        type: UPDATE_AUDIO_PROFILE_FAILURE,
        payload: data,
    };
};

export const updateShowEditorVoiceProfiles = (data) => {
    return {
        type: UPDATE_SHOW_EDITOR_VOICE_PROFILES,
        payload: data,
    }
}
export const updateShowSaveAudioProfileModal = (data) => {
    return {
        type: SET_SHOW_SAVE_AUDIO_PROFILE_MODAL,
        payload: data,
    };
};

export const setIsDefaultProfile = (data) => {
    return {
        type: SET_IS_DEFAULT_PROFILE,
        payload: data,
    }
}
