import React from 'react'; 
import shallow from 'zustand/shallow';
import { useVoiceConfigurationStore } from '../../zustand-store/useVoiceConfigurationStore';
import { IVoice, VoiceConfigurationOptions } from "../../zustand-store/useAIVoicesStore"

export const VolumeSlider = () => {
    
    const { setVoiceConfiguration, voiceConfiguration: {volume} } = useVoiceConfigurationStore((state) => ({
        voiceConfiguration: state.voiceConfiguration,
        setVoiceConfiguration: state.setVoiceConfiguration
    }), shallow)


    return (
        <div className="form-group">
            <label className="d-inline-flex align-items-center">
                <span>Volume</span>
                <span style={{background : "#333B6C"}} className="badge badge-secondary ml-1">{volume}</span>
            </label>
            <input type="range" className="form-control-range" min="-6" max="6" step="0.5" defaultValue="0" name="volume" value={volume} onChange={(e) => {
                setVoiceConfiguration(VoiceConfigurationOptions.volume, e.target.valueAsNumber)
            }} />
        </div>
    )
}

export const SpeedSlider = () => {

    const { setVoiceConfiguration, voiceConfiguration: {speed} } = useVoiceConfigurationStore((state) => ({
        voiceConfiguration: state.voiceConfiguration,
        setVoiceConfiguration: state.setVoiceConfiguration
    }), shallow)

    return (
        <div className="form-group">
            <label className="d-inline-flex align-items-center"><span>Speed</span><span style={{background : "#333B6C"}} className="badge badge-secondary ml-1">{speed}</span></label>
            <input type="range" className="form-control-range" min="20" max="200" step="1" defaultValue="100" name="speed" onChange={(e) => {
                setVoiceConfiguration(VoiceConfigurationOptions.speed, e.target.valueAsNumber)
            }} />
        </div>
    )
}

interface IPitchDropdownProps {
    voice: IVoice
}

export const PitchDropdown = ({ voice }: IPitchDropdownProps) => {

    const { setVoiceConfiguration, voiceConfiguration: {pitch} } = useVoiceConfigurationStore((state) => ({
        voiceConfiguration: state.voiceConfiguration,
        setVoiceConfiguration: state.setVoiceConfiguration
    }), shallow)

    if (voice === null) {
        return <></>
    }

    if (voice.platform === 'polly' && voice.engine === 'neural') {
        return <></>
    }
    
    return (
        <div className="form-group mt-3">
            <label>Pitch</label>
            <select className="custom-select" name="pitch" value={pitch} onChange={(e) => {
                setVoiceConfiguration(VoiceConfigurationOptions.pitch, e.target.value);
            }}>
                <option value="">Default</option>
                <option value="x-low">x-low</option>
                <option value="low">low</option>
                <option value="medium">medium</option>
                <option value="high">high</option>
                <option value="x-high">x-high</option>
            </select>
        </div>
    )
}

interface IStylesDropdownProps {
    voice: IVoice
}

export const StylesDropdown = ({ voice }: IStylesDropdownProps) => {

    const { voiceConfiguration: {style}, setVoiceConfiguration } = useVoiceConfigurationStore((state) => ({
        voiceConfiguration: state.voiceConfiguration,
        setVoiceConfiguration: state.setVoiceConfiguration, 
    }), shallow)

    if (voice === null) {
        return <></>
    }

    if (voice.styles.length === 0) {
        return <></>
    }

    return (
        <div className="form-group mt-3">
            <label>Styles</label>
            <select value={style} className="custom-select" name="style" onChange={(e) => {
                setVoiceConfiguration(VoiceConfigurationOptions.style, e.target.value)
            }}>
                <option value="">Default</option>
                {
                    voice?.styles.map((style) => {
                        return <option value={style} key={style}>{style}</option>
                    })
                }
            </select>
        </div>
    )
}