import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { exitEditor, addMixPanelEvent, toggleEditorTitleSettingsModal, toggleDownloadContentModal, setAudioDownloadMode, updateCurrentAudioSeekValue, toggleCurrentAudioPlaying, toggleDownloadFile, toggleIsAudioGenerating, generateSRTRequested } from "../../store/actions"
import UpdateEditorTitleModal from './Modals/UpdateEditorTitleModal';
import Spinner from '../Spinner';
import EditorHelpCenter from './EditorHelpCenter';
import { getWordsCount } from '../../utils/SlateUtils/index.js';
import { PREVIEW_PLANS } from '../../constants/constants';

// Images
import downloadVoiceIconModal from '../../assets/editor/download-voice.svg'
import downloadCaptionsIconModal from '../../assets/editor/download-captions.svg'
import downloadMusicIconModal from '../../assets/editor/download-music.svg'
import EditorArrowLeftImg from '../../assets/arrow-left-editor.svg'
import EditPencilEditorImg from '../../assets/edit-pencil-editor.svg'
import CloudDoneEditorImg from '../../assets/cloud-done-editor.svg'
import DownArrowWhiteIcon from '../../assets/editor/down-arrow-white.svg'
import { UPGRADE_EDITOR } from '../../constants/hotjar-events';
import { useBuildAudioSocket } from '../../hooks/useBuildAudioSocket';
import { API } from 'aws-amplify';
import { UpgradePlanModalV2 } from '../plans/UpgradePlanModal';
import { useGlobalStore } from '../../zustand-store/useGlobalStore';

export default ({ title, value, org_id, id, contentId, setShowStarterCardModal }) => {
	const renderTooltip = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Consumes word credits
		</Tooltip>
	);

	const history = useHistory();
	const dispatch = useDispatch()

	const toggleUpgradeModal = useGlobalStore((state) => state.toggleUpgradeModal)

	const [downloadContentType, setDownloadContentType] = useState(null)
	const [toolTipShow, setToolTipShow] = useState(false)

	const { showSavingInDraftLabel, isContentChanged: audioComposerIsContentChanged, slate_value, sections: composer_settings, isAudioGenerating } = useSelector(state => state.audio_composer);
	const { isToggleTitleEditorModalShow, isSRTLoading, isSRTFailure } = useSelector((state) => state.content);
	const { showDownloadContentModal: showDownloadModal } = useSelector(state => state.edit_content)
	const { organisation } = useSelector((state) => state.organisation);
	const { content } = useSelector((state) => state.edit_content);

	const downloadAudioFile = (message) => {
		const audioURL = `${process.env.REACT_APP_AUDIO_CDN}/${message.audio_url}`;
		saveAs(audioURL, `${title.substr(0, 255)}.mp3`);
		dispatch(toggleDownloadFile(false))
		dispatch(toggleIsAudioGenerating(false))
	};

	const generateAudio = (socketConnectionId) => {
		const contentId = content.SK.split('#')[1];
		API.put('main', `/organisation/${org_id}/integration/${id}/content/${contentId}/download`, {
			headers: { "Content-Type": "application/json" },
			body: {
				socketConnectionId: socketConnectionId,
				title: title,
				slate_value: slate_value,
				composer_settings: composer_settings,
				is_draft: false,
				isContentChanged: audioComposerIsContentChanged,
				includeMusic: downloadContentType === 0 ? false : true
			}
		}).then((resp) => {
			if (!resp.success) {
				toggleUpgradeModal(true, {title: resp.message});
				dispatch(toggleIsAudioGenerating(false))
			}
		}).catch(() => {
			toast.error("Something went wrong!")
			dispatch(toggleIsAudioGenerating(false))
		})
	};

	const [connect, disconnect, sendMessage] = useBuildAudioSocket(
		() => sendMessage("socket", "get credentials"),
		(message) => {
			console.log("socket", { message })
			if (message.progress && message.progress === 100) {
				downloadAudioFile(message);
				disconnect();
			} else if (message.connectionId) {
				generateAudio(message.connectionId)
			}
		},
		() => console.log("socket closed")
	);

	const downloadContent = (e) => {
		if (downloadContentType === 0) {
			onDownload(e)
		} else if (downloadContentType === 1) {
			onDownload(e)
		} else if (downloadContentType === 2) {
			onSRTGenerate()
		}
	}

	const onDownload = (e) => {

		e.preventDefault();

		if (!organisation.gates.can_download_audio) {
			toggleUpgradeModal(true, {title: "Upgrade to get unlimited downloads, more words and more features."});
			dispatch(addMixPanelEvent({
				orgId: org_id, integrationId: id, contentId, body: {
					eventName: "PREVIEW_PLAN_DOWNLOAD_AUDIO",
					eventData: {
						plan_id: organisation.plan_id
					}
				}
			}))
			return;
		}

		connect();
		// Setting the seek time to 0
		dispatch(setAudioDownloadMode(downloadContentType))
		dispatch(updateCurrentAudioSeekValue({ seek: 0 }))
		dispatch(toggleCurrentAudioPlaying(false))
		dispatch(toggleDownloadFile(true))
		dispatch(toggleIsAudioGenerating(true))

		return;
	}

	const onSRTGenerate = () => {

		if (!organisation.gates.can_download_srt) {
			toggleUpgradeModal(true);
			return;
		}

		if (audioComposerIsContentChanged) return toast.error("Please generate the audio again before downloading SRT File")

		dispatch(generateSRTRequested({
			org_id,
			id,
			contentId,
			body: {
				slate_value: value,
				composer_settings
			}
		}))
	}

	const manageOverlayShow = (show) => {
		setToolTipShow((audioComposerIsContentChanged) ? show : false)
	}

	useEffect(() => {
		if (isSRTFailure) {
			toast.error("Subtitles generation failed")
		}
	}, [isSRTFailure])

	return useMemo(() => (
		<>
			{isToggleTitleEditorModalShow ? <UpdateEditorTitleModal /> : null}

			<UpgradePlanModalV2 showCurrentPlan={true} />

			<Modal className="editor-download-modal" style={{ borderRadius: "20px" }} show={showDownloadModal} onHide={() => dispatch(toggleDownloadContentModal(false))}>
				<Modal.Header closeButton>
					<Modal.Title>
						Configure Download
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="download-icons-row">
						<div onClick={() => setDownloadContentType(0)} className={`download-card ${downloadContentType === 0 ? "is-selected" : ""}`}>
							<div className='w-100 d-flex justify-content-center'>
								<img alt="" src={downloadVoiceIconModal} />
							</div>
							<div className='w-100 text-center text'>Voice Only</div>
						</div>
						<div onClick={() => setDownloadContentType(1)} className={`download-card ${downloadContentType === 1 ? "is-selected" : ""}`}>
							<div className='w-100 d-flex justify-content-center'>
								<img alt="" src={downloadVoiceIconModal} />
								<img alt="" src={downloadMusicIconModal} />
							</div>
							<div className='w-100 text-center text'>Voice + Music</div>
						</div>
						<div onClick={() => setDownloadContentType(2)} className={`download-card ${downloadContentType === 2 ? "is-selected" : ""}`}>
							<div className='w-100 d-flex justify-content-center'>
								<img alt="" src={downloadCaptionsIconModal} /></div>
							<div className='w-100 text-center text'>Captions</div>
						</div>
					</div>
					<div className="row mb-3 justify-content-center">
						<Button style={{ marginRight: 15 }} className="editor-download-button" disabled={((getWordsCount(value) === 0) || (downloadContentType == null))} onClick={(e) => downloadContent(e)} variant="primary">
							{(isAudioGenerating || isSRTLoading) ? <Spinner color="#ffffff" /> : <>
								<img alt='' height="14" style={{ marginRight: 5 }} src={DownArrowWhiteIcon} />
								<span style={{ fontSize: 14 }}>Download</span></>}
						</Button>
					</div>
				</Modal.Body>
			</Modal>

			<div className="editor-header">
				<div onClick={() => {
					dispatch(exitEditor(history));
				}} style={{ width: "90px", cursor: "pointer" }} className="editor-header-block">
					<span style={{ cursor: "pointer" }}>
						<img alt="" className="block-img" width="10" src={EditorArrowLeftImg} />
					</span>
					<span className="block-text">Home</span>
				</div>

				<div style={{ overflow: "hidden", whiteSpace: "nowrap" }} className="editor-header-block no-right-border flex-grow-1">
					<span onClick={() => dispatch(toggleEditorTitleSettingsModal(true))} className="block-text-title">{title} <img alt="" className="block-img" width="15" src={EditPencilEditorImg} /></span>
					{/* <span>
						
					</span> */}
				</div>

				<div className="editor-header-block">
					<span style={{ marginLeft: "auto" }}>
						{
							showSavingInDraftLabel
								? <Spinner color="#4D547E" />
								: <img alt="" title="Draft Saved" className="block-img" width="28" src={CloudDoneEditorImg} />
						}
					</span>
				</div>

				<div className="editor-header-block no-right-border">
					{
						(organisation && [...PREVIEW_PLANS].includes(organisation.plan_id))
						&& (
							<Button onClick={() => {
								window.hj("event", UPGRADE_EDITOR);
								toggleUpgradeModal(true)
							}} style={{ marginRight: 15 }} className="editor-download-button" disabled={getWordsCount(value) === 0} variant="outline-primary">
								<span style={{ fontSize: 14 }}>Upgrade</span>
							</Button>

						)
					}

					{/* Showing Tooltip only when content is changed */}
					{
						<OverlayTrigger
							placement="bottom"
							overlay={renderTooltip}
							onToggle={manageOverlayShow}
							show={toolTipShow}
						>
							<Button style={{ marginRight: 15, whiteSpace: 'nowrap' }} className="editor-download-button" disabled={getWordsCount(value) === 0} onClick={() => { dispatch(toggleDownloadContentModal(true)) }} variant="primary">
								<img alt="" height="14" style={{ marginRight: 5 }} src={DownArrowWhiteIcon} />
								<span style={{ fontSize: 14 }}>Download</span>
							</Button>
						</OverlayTrigger>
					}


					<EditorHelpCenter setShowStarterCardModal={setShowStarterCardModal} />
				</div>
			</div>
		</>

	// eslint-disable-next-line react-hooks/exhaustive-deps
	), [toolTipShow, downloadContentType, organisation, content, showDownloadModal, isToggleTitleEditorModalShow, isSRTLoading, isSRTFailure, showSavingInDraftLabel, audioComposerIsContentChanged, slate_value, isAudioGenerating, composer_settings])
}