import React, { useState, useEffect } from 'react';
import Highlight from 'react-highlight.js';
import { useParams } from "react-router-dom";
import copyIcon from '../../assets/copy.svg';
import ideaBuldIcon from '../../assets/idea-bulb.svg';
import Spinner from '../../components/Spinner';
import { API } from "aws-amplify";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import HavingTroubleCard from './HavingTroubleCard';
import APIKeysSection from './APIKeysSection';

import wordpress from '../../assets/wordpress.svg';
import ghost from '../../assets/ghost.svg';
import webflow from '../../assets/webflow.svg';
import blogger from '../../assets/blogger.svg';
import wix from '../../assets/wix.svg';
import squarespace from '../../assets/squarespace.svg';
import weebly from '../../assets/weebly.svg';
import joomla from '../../assets/joomla.svg';
import hubspot from '../../assets/hubspot.svg';

const STACKS = [
    {
        logo: wordpress,
        brand: 'Wordpress',
        link: 'how-to-add-audio-to-your-wordpress-blog-posts'
    },
    {
        logo: ghost,
        brand: 'Ghost',
        link: 'how-to-add-audio-to-your-ghost-blog-post'
    },
    {
        logo: webflow,
        brand: 'Webflow',
        link: 'how-to-add-audio-to-your-webflow-blog-posts'
    },
    {
        logo: blogger,
        brand: 'Blogger',
        link: 'how-to-add-audio-to-your-blogger-blog-post'
    },
    {
        logo: wix,
        brand: 'Wix',
        link: 'how-to-add-audio-to-your-wix-blog-post'
    },
    {
        logo: squarespace,
        brand: 'Squarespace',
        link: 'how-to-add-audio-to-your-squarespace-blog-posts'
    },
    {
        logo: weebly,
        brand: 'Weebly',
        link: 'how-to-add-audio-to-your-weebly-blog-posts-using-listen2it'
    },
    {
        logo: joomla,
        brand: 'Joomla',
        link: 'how-to-add-audio-to-your-joomla-blog-posts-using-listen2it'
    },
    {
        logo: hubspot,
        brand: 'Hubspot',
        link: 'how-to-add-audio-to-your-hubspot-blog-posts-using-listen2it'
    },
];

function OtherCMS() {

    const { org_id, id } = useParams();

    const [integration, setIntegration] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getIntegration = (org_id, id) => {
        return API.get(`main`, `/organisation/${org_id}/integration/${id}`);
    }

    useEffect(() => {
        getIntegration(org_id, id).then(response => {

            const integration = response.data;

            setIntegration(integration);
            setIsLoading(false)

        });
    }, []);

    const EMBED_CODE = `
        <div id="l2it-audio-player"></div>                    
        <script>
            function initListen2It() {
                Listen2It.init({
                    url: window.location.href,
                    org_id: '${org_id}',
                    integration_id: '${id}',
                    container: '#l2it-audio-player'
                });
            }
            function initialize(i,t){var n;i.getElementById(t)?initListen2It():((n=i.createElement("script")).id=t,n.async=!0,n.src="${process.env.REACT_APP_WIDGET_URL}",n.onload=initListen2It,i.head.appendChild(n))}function initiateCall(){initialize(document,"listen2it-widget")}window.addEventListener?window.addEventListener("load",initiateCall,!1):window.attachEvent("load",initiateCall,!1);
        </script>
    `;

    return (
        <React.Fragment>
            <div>
                <h3 className="page-title">Integrate your content platform</h3>
                <p className="page-subtitle">Connect your website or app with Listen2It to automatically publish audio versions of your articles</p>
                {
                    (isLoading) ?
                        (
                            <div className="my-1">
                                <Spinner color="#3d4852" />
                            </div>
                        )
                        :
                        (
                            <>
                                <p className="mt-5 mb-1 font-weight-bold">What platform is your site/app on?</p>
                                <p>Choose a platform to view detailed installation instructions for adding Listen2It to your website and/or app</p>
                                <span className="mt-4" style={{ fontSize: 14, borderBottom: '1px dashed #C4C4C4' }}>Do you have multiple websites and/or apps? Learn how to integrate Listen2It across them.</span>
                                <div className="mt-4 mx-0 row installation-grid">
                                    {STACKS.map((stack) => (
                                        <a style={{ textDecoration: 'none', color: 'inherit' }} href={`${process.env.REACT_APP_ZOHO_KB_BASE_URL}/portal/en/kb/articles/${stack.link}`} target="_blank" rel="noopener noreferrer">
                                            <div className="platform-tile bg-white d-flex flex-column justify-content-center align-items-center">
                                                <img className="row" src={stack.logo} alt={stack.brand} />
                                                <div className="row text-center">{stack.brand}</div>
                                            </div>
                                        </a>
                                    ))}
                                    <a style={{ textDecoration: 'none', color: 'inherit' }} href={`${process.env.REACT_APP_ZOHO_KB_BASE_URL}/portal/en/kb/articles/rest-api`} target="_blank" rel="noopener noreferrer">
                                        <div className="platform-tile bg-white d-flex flex-column justify-content-center align-items-center">
                                            <span className="font-weight-bold" style={{ fontSize: 18, color: 'black' }}>{'{REST:API}'}</span>
                                            <div className="row text-center">REST API</div>
                                        </div>
                                    </a>
                                </div>
                                <APIKeysSection orgId={org_id} id={id} integration={integration} />
                                <div className="mt-5">
                                    <div className="mb-4 d-flex align-items-center" style={{ backgroundColor: '#E8EFFD', borderRadius: 6, padding: '6px 10px', color: '#3D4852', fontWeight: 600, fontSize: 14, maxWidth: 'fit-content' }}>
                                        <img className="mr-3" src={ideaBuldIcon} alt="" />
                                        Don’t see your platform listed? Install manually with the Universal Code below.
                                    </div>
                                    <p className="mt-3 mb-1 font-weight-bold">Universal Embed Code</p>
                                    <p>
                                        Add the following script to your website’s HTML code as per below:
                                        <ol>
                                            <li>For Inline Player: Add it where you would like the player to appear.</li>
                                            <li>For Floating and Static Player: Add it before {'</body>'} tag in your HTML code.</li>
                                        </ol>
                                        {`\n`}
                                        Add the script to every page you would like to add Listen2It to.
                                    </p>
                                    <p></p>
                                    <div className="well mb-5 position-relative">
                                        <CopyToClipboard text={EMBED_CODE} onCopy={() => toast.success("Copied to clipboard")}>
                                            <div
                                                className="position-absolute row justify-content-center"
                                                style={{
                                                    right: 32,
                                                    top: 16,
                                                    gap: 4,
                                                    borderRadius: 6,
                                                    padding: '2px 16px',
                                                    background: '#E6EFFE',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <img src={copyIcon} style={{ cursor: 'pointer' }} alt="search icon" />
                                                <span>Copy</span>
                                            </div>
                                        </CopyToClipboard>
                                        <Highlight className="pt-3 pt-sm-0" language="javascript">
                                            {EMBED_CODE}
                                        </Highlight>
                                    </div>
                                </div>
                                <HavingTroubleCard />
                            </>
                        )
                }
            </div>
        </React.Fragment>
    );
}

export default OtherCMS;
