import React, { useState, useEffect } from 'react';
import {Crisp} from "crisp-sdk-web"
import {useDispatch} from 'react-redux'
import { useParams } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from "react-router-dom";
import {Auth} from 'aws-amplify';
import Bugsnag from "@bugsnag/js";
import {fetchOrganisationRequested} from "../store/actions"

function Avatar(){
    const { org_id } = useParams();
    const dispatch = useDispatch()

    const [ attr, setAttr ] = useState({});
    const [ isLoading, setIsLoading ] = useState(true);

    useEffect(()=>{

        if (!org_id) return;

        dispatch(fetchOrganisationRequested(org_id))
        Auth.currentAuthenticatedUser({
            bypassCache: true
        }).then(user => {
            
            setAttr(user.attributes);

        }).catch(err => {

            Bugsnag.notify(err)

        }).finally(()=>{

            setIsLoading(false);

        });

        

    },[org_id, dispatch]);

    const CustomToggle = React.forwardRef(({ onClick }, ref) => (
        <div className="avatar" ref={ref} onClick={(e) => { e.preventDefault(); onClick(e);}}>
            {
                (isLoading)
                ?
                (
                    <div className="avatar-shimmer shine" />
                )
                :
                (
                    <div className="icon">
                        {
                            (attr?.hasOwnProperty("custom:avatar"))?
                            (
                                <div className="custom-avatar" style={{backgroundImage:`url(${encodeURI(attr["custom:avatar"])})`}}>
                                </div>
                            )
                            : (attr?.hasOwnProperty('given_name'))
                                ? (
                                    <span>{`${attr.given_name.charAt(0)}`}</span>
                                )
                                : (
                                    <></>
                                )

                        }
                    </div>
                )
            }
        </div>
    ));

    return(
        <Dropdown>
            <Dropdown.Toggle as={CustomToggle} />
            <Dropdown.Menu>
                <Dropdown.Header className="pb-0">Signed in as</Dropdown.Header>
                { attr && <Dropdown.ItemText>{attr.email}</Dropdown.ItemText> }

                <Dropdown.Divider />

                <Dropdown.Item as="div"><Link to="/profile">Profile</Link></Dropdown.Item>
                
                <Dropdown.Divider />
                
                <Dropdown.Item as="div" onClick={
                    async ()=>{
                        Crisp.session.reset();
                        await Auth.signOut();
                        window.location.replace(window.location.origin);
                    }
                }>Logout</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )

}

export default Avatar;
