import React from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';

function VoiceoversEmptyState() {
    const history = useHistory();
    const { org_id, id } = useParams();
    
    const {integration, isLoading} = useSelector(state => state.integration)

    const Shimmer = () => {

        return (
            <div className="list-group list-group-flush list-group--content">
                {
                    _.range(4).map((index) => {

                        return (
                            <li className="list-group-item list-group-item-action" key={`content-${index}`}>
                                <div className="content-title-shimmer shine" />
                                <div className="content-meta-shimmer shine" />
                            </li>
                        )

                    })
                }
            </div>
        )
    }

    return (
        <div className="">
            {
                isLoading
                    ? (
                        <Shimmer />
                    )
                    : (
                        <>
                            <div className="mt-5 mb-4">
                                <h5 style={{fontSize: 18, fontWeight: "bold"}}>This is where you can manage your audios, and access essential tools and settings. Create your first AI voiceover audio to get started</h5>
                            </div>  
                            <div >
                            {integration?.gates?.can_create_audio ? <Button className="mr-2" onClick={() => history.push(`/organisation/${org_id}/integration/${id}/content/new`)} variant="primary">Create Audio</Button> : null}
                            <Link target="_blank" to={{pathname : "https://support.getlisten2it.com/portal/en/kb"}}><Button variant="outline-primary">Learn to use Listen2It</Button></Link>
                            </div>  
                        </>
                    )
            }
        </div>
    );
}

export default VoiceoversEmptyState;
