import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Highlight from 'react-highlight.js';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import copyIcon from '../assets/copy-alt.svg';
import moreIcon from '../assets/more.svg';
import OnboardingSuccessImg from "../assets/onboarding-setup-success.png"
import PartyingFace from "../assets/welcome-partying-face.svg"
import calendarIcon from '../assets/calendar.svg';
import searchIcon from '../assets/search.svg';
import { DateRangePicker } from 'react-date-range';
import { subDays } from 'date-fns';
import Modal from 'react-bootstrap/Modal'
import { API } from 'aws-amplify';
import moment from 'moment';
import { NavLink, useParams, useHistory, useLocation } from "react-router-dom";
import _ from 'lodash';
import AudioPlayer from '../components/AudioPlayer';
import { toast } from 'react-toastify';
import Spinner from '../components/Spinner';
import Bugsnag from "@bugsnag/js";
import VoiceoversEmptyState from './audio/VoiceoversEmptyState';
import AudiosEmptyState from './audio/AudiosEmptyState';
import ContentItem from '../components/content/ContentItem'
// Redux 
import { fetchContentsRequested, fetchContentsSearchRequested, fetchContentsSuccess } from "../store/actions"
import { useGlobalStore } from '../zustand-store/useGlobalStore';

const classNames = require('classnames');

function Content({ contentType, setShowUploadAudio, setSelectedAudio }) {

    const { org_id, id } = useParams();
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation();

    const toggle = useGlobalStore((state) => state.toggleUpgradeModal);

    const [isContentChannelsLoading, setContentChannelsLoading] = useState(true);
    const [isAddingContentToChannels, setIsAddingContentToChannels] = useState(false);
    const [podcasts, setPodcasts] = useState([]);
    const [contentPodcasts, setContentPodcasts] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [audio, setAudio] = useState(null);
    const [audioTitle, setAudioTitle] = useState("Press play to listen to this.")
    const [showEmbedCode, setShowEmbedCode] = useState(false);
    const [embedCode, setEmbedCode] = useState('');

    const [timePeriod, setTimePeriod] = useState([
        {
            startDate: subDays(new Date(), 7),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const [isDateFilterApplied, setIsDateFilterApplied] = useState(false)

    const [show, setShow] = useState(false);
    const [showPodcast, setShowPodcast] = useState(false);


    const [contentId, setContentId] = useState(null);

    const content = useSelector((state) => state.content.contents)
    const isLoading = useSelector((state) => state.content.isLoading)
    const isFetchingMore = useSelector((state) => state.content.isFetchingMore)
    const nextPageToken = useSelector(state => state.content.nextPageToken)
    const isSearchResults = useSelector(state => state.content.isSearchResults)

    const getNextPage = () => {

        dispatch(fetchContentsRequested({ org_id, id, filter: { nextPageToken, contentType, startDate: null, endDate: null, isMore: true } }))

    }

    const getManualPodcastChannels = () => {
        return API.get('main', `/organisation/${org_id}/integration/${id}/podcast?manual`);
    };

    const getPodcastChannelsByContentId = () => {
        return API.get('main', `/organisation/${org_id}/integration/${id}/content/${contentId}/podcasts`);
    };

    const enableAudio = (content_id, index) => {

        let contents = [...content];
        let currentContent = contents[index];

        API.put(`main`, `/organisation/${org_id}/integration/${id}/content/${content_id}/enable_audio`, { headers: { "Content-Type": "application/json" } })
            .then((response) => {

                toast.success('Audio has been enabled successfully.');
                currentContent.is_audio_disabled = false;
                contents[index] = currentContent;
                dispatch(fetchContentsSuccess({ contents }))

            }).catch((error) => {

                toast.error("Something went wrong while enabling the audio.");
                Bugsnag.notify(error);

            });

    }

    const discardDraft = (content_id, index) => {

        let currentContent = content[index];

        API.del('main', `/organisation/${org_id}/integration/${id}/draft_content/${content_id}`, { headers: { "Content-Type": "application/json" } }).then((response) => {

            toast.success('Draft has been discarded successfully.');
            let contents = content.filter(val => {
                if (val.SK === currentContent.SK) return false;
                return true;
            })
            dispatch(fetchContentsSuccess({ contents }))

        }).catch((error) => {

            toast.error("Something went wrong while discarding the draft.");
            Bugsnag.notify(error);

        })

    }

    const deleteAudio = (content_id, index) => {

        let currentContent = content[index];

        API.del('main', `/organisation/${org_id}/integration/${id}/content/${content_id}`, { headers: { "Content-Type": "application/json" } }).then((response) => {

            toast.success('Audio has been deleted successfully.');
            let contents = content.filter(val => {
                if (val.SK === currentContent.SK) return false;
                return true;
            })
            dispatch(fetchContentsSuccess({ contents }))

        }).catch((error) => {

            toast.error("Something went wrong while deleting the audio.");
            Bugsnag.notify(error);

        })

    }

    const clearSearchResults = () => {
        setSearchKeyword("");
        fetchAndPopulateContents()
    }

    const searchContent = (keyword) => {

        dispatch(fetchContentsSearchRequested({ org_id, id, keyword }))

    }

    const disableAudio = (content_id, index) => {

        let contents = [...content];
        let currentContent = contents[index];

        API.put(`main`, `/organisation/${org_id}/integration/${id}/content/${content_id}/disable_audio`, { headers: { "Content-Type": "application/json" } })
            .then(() => {

                toast.success('Audio has been disabled successfully.');
                currentContent.is_audio_disabled = true;
                contents[index] = currentContent;
                dispatch(fetchContentsSuccess({ contents }))

            }).catch((error) => {

                toast.error("Something went wrong while disabling the audio.");
                Bugsnag.notify(error);

            });

    }

    const CustomToggle = React.forwardRef(({ onClick }, ref) => (
        <img src={moreIcon} className="more-icon" alt="more icon" ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }} />
    ));

    const Shimmer = () => {

        return (
            <div className="list-group list-group-flush list-group--content">
                {
                    _.range(4).map((index) => {

                        return (
                            <li className="list-group-item list-group-item-action" key={`content-${index}`}>
                                <div className="content-title-shimmer shine" />
                                <div className="content-meta-shimmer shine" />
                            </li>
                        )

                    })
                }
            </div>
        )
    }

    const AppliedFilter = () => {

        return (
            (isDateFilterApplied) &&
            <div className="content-filter">
                <span className="mr-2">Showing content from {moment(timePeriod[0].startDate).format('MMM Do YY')} to {moment(timePeriod[0].endDate).format('MMM Do YY')}</span>
                <span className="clear-content-filter" onClick={() => { setIsDateFilterApplied(false) }}>Clear</span>
            </div>
        )
    }

    const Content = () => {

        return (
            <React.Fragment>
                {isSearchResults &&
                    <>
                        <div className="content-filter">
                            <span className="mr-2">Showing search results for <strong>{searchKeyword}</strong></span>
                            <span className="clear-content-filter" onClick={() => clearSearchResults()}>Clear</span>
                        </div>

                        {(!content || !content.length) && <p style={{ marginTop: '32px' }}>No content found in this keyword. Try a different keyword.</p>}
                    </>
                }

                <div className="list-group list-group-flush list-group--content">
                    {
                        content && content.map((item, index) => {
                            return (
                                <ContentItem
                                    key={`content-${index}`}
                                    item={item}
                                    index={index}
                                    org_id={org_id}
                                    id={id}
                                    setAudio={(e) => setAudio(e)}
                                    setAudioTitle={(e) => setAudioTitle(e)}
                                    setShowUploadAudio={(e) => setShowUploadAudio(e)}
                                    setSelectedAudio={(e) => setSelectedAudio(e)}
                                    setShowEmbedCode={(e) => setShowEmbedCode(e)}
                                    setEmbedCode={(e) => setEmbedCode(e)}
                                    setShowPodcast={(e) => setShowPodcast(e)}
                                    setContentId={(e) => setContentId(e)}
                                    disableAudio={(e, i) => disableAudio(e, i)}
                                    enableAudio={(e, i) => enableAudio(e, i)}
                                    discardDraft={(e, i) => { discardDraft(e, i) }}
                                    deleteAudio={(e, i) => { deleteAudio(e, i) }}
                                    CustomToggle={CustomToggle}

                                />
                            )

                        })
                    }
                </div>

                {
                    !isSearchResults &&
                    ((isFetchingMore) ?
                        (
                            <button className={classNames("btn--fetch-more", "mt-3")}>
                                <Spinner color="#657B90" />
                            </button>
                        )
                        :
                        (
                            <button className={classNames("btn--fetch-more", "mt-3", { "d-none": null == nextPageToken })} onClick={() => { getNextPage() }}>Fetch More</button>
                        ))
                }

            </React.Fragment>
        )

    }

    const fetchAndPopulateContents = () => {

        setIsDateFilterApplied(false);

        // Getting contents
        dispatch(fetchContentsRequested({ org_id, id, filter: { nextPageToken: null, contentType, startDate: null, endDate: null } }))
    }

    useEffect(() => {

        const shouldShowUpgradeModal = (!!(new URLSearchParams(location.search).get('show_upgrade_modal')))
        if (shouldShowUpgradeModal) {
            toggle(true);
        }

    }, [location.search, toggle])

    useEffect(() => {

        let startDate = null;
        let endDate = null;

        if (true === isDateFilterApplied) {

            startDate = moment(timePeriod[0].startDate).format();
            endDate = moment(timePeriod[0].endDate).endOf('day').format();

        }

        dispatch(fetchContentsRequested({ org_id, id, filter: { nextPageToken: null, contentType, startDate, endDate } }))

        getManualPodcastChannels().then((response) => {
            setPodcasts(response.data);
        })

    }, [timePeriod, isDateFilterApplied]);

    useEffect(() => {

        fetchAndPopulateContents();

    }, [org_id, id]);

    useEffect(() => {
        setContentChannelsLoading(true);
        getPodcastChannelsByContentId()
            .then((response) => {
                const result = response.data.map((n) => {
                    return n.podcast_id;
                })
                setContentPodcasts(result);
            })
            .finally(() => {
                setContentChannelsLoading(false);
            });
    }, [contentId]);

    const onAddContentToChannel = (podcastId) => {
        setIsAddingContentToChannels(true);
        API.post('main', `/organisation/${org_id}/integration/${id}/podcast/${podcastId}/content/${contentId}`, {
            headers: { 'Content-Type': 'application/json' }
        }).then(() => {
            toast.success('Content added to the show successfully!');
        }).catch((error) => {

            toast.error("Something went wrong while creating the Podcast the show.")
            Bugsnag.notify(error);

        }).finally(() => {
            setIsAddingContentToChannels(false);
        })
    };

    const onRemoveContentFromChannel = (podcastId) => {
        setIsAddingContentToChannels(true);
        API.del('main', `/organisation/${org_id}/integration/${id}/podcast/${podcastId}/episode/${contentId}`, {
            headers: { 'Content-Type': 'application/json' }
        }).then(() => {
            toast.success('Audio removed from the shows successfully!');
        }).catch((error) => {

            toast.error("Something went wrong while removing the audio from the Podcast show.")
            Bugsnag.notify(error);

        }).finally(() => {
            setIsAddingContentToChannels(false);
        })
    };

    const disableOnBoardingSuccessCard = () => {
        history.replace({
            search: "",
        })
    }

    return (
        <React.Fragment>

            <div className="d-flex w-100 flex-wrap filters px-3">
                <div className="content-search d-flex align-items-center">
                    <div className="input-group input-group--search">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <img width="15" src={searchIcon} alt="search icon" />
                            </span>
                        </div>
                        <input type="text" className="form-control" placeholder="Search" value={searchKeyword} onChange={(event) => { setSearchKeyword(event.target.value) }} onKeyDown={(event) => { if ("Enter" === event.key) { searchContent(event.target.value) } }} />
                    </div>
                </div>

                <div className="date-filter d-flex align-items-center">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <img src={calendarIcon} alt="calendar icon" />
                        </span>
                        <button type="button" className="btn--date-filter w-100 d-none d-md-inline-block" onClick={() => { setShow(true) }}>
                            <span>Filter by date</span>
                        </button>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={() => { setShow(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Pick a date range</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DateRangePicker
                        onChange={item => { setTimePeriod([item.selection]); setIsDateFilterApplied(true) }}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        ranges={timePeriod}
                        maxDate={new Date()}
                        direction="vertical"
                    />
                </Modal.Body>
            </Modal>
            <Modal show={showPodcast} onHide={() => { setShowPodcast(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Select podcast feed
                        <p className="modal-subtitle">Only podcast with "Manual" distribution are listed.</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {
                            (isContentChannelsLoading || isAddingContentToChannels)
                                ? (
                                    <Spinner color="#657B90" />
                                ) : (
                                    (podcasts.length > 0)
                                        ? (
                                            podcasts.map((item, item_i) => {
                                                return (
                                                    <div key={`podcast-${item_i}`} className="form-check my-3">
                                                        <input className="form-check-input" type="checkbox" checked={contentPodcasts.indexOf(item.id) >= 0} onChange={(event) => {
                                                            let copy = [...contentPodcasts];
                                                            if (event.currentTarget.checked) {

                                                                copy.push(item.id);
                                                                setContentPodcasts(copy);
                                                                onAddContentToChannel(item.id);

                                                            } else {

                                                                copy = copy.filter(n => n !== item.id);
                                                                setContentPodcasts(copy);
                                                                onRemoveContentFromChannel(item.id);

                                                            }
                                                        }} />
                                                        <label className="form-check-label">
                                                            {item.title}
                                                        </label>
                                                    </div>
                                                )
                                            })
                                        ) : (
                                            <div className="mb-3">
                                                <p>There are no podcast shows. Start by creating one</p>
                                                <NavLink to={`/organisation/${org_id}/integration/${id}/podcast/new`} activeClassName="active">
                                                    <button className="btn btn-primary">Create new Podcast</button>
                                                </NavLink>
                                            </div>
                                        )
                                )
                        }
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className="modal-embed-code" show={showEmbedCode} onHide={() => setShowEmbedCode(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Embed Code
                        <p style={{ fontSize: 16, fontWeight: 400, margin: 0 }}>Add the following line to your website’s HTML code as per below.</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CopyToClipboard text={embedCode} onCopy={() => toast.success("Copied to clipboard")}>
                        <div
                            className="position-absolute row justify-content-center"
                            style={{
                                right: 48,
                                top: 16,
                                gap: 4,
                                borderRadius: 6,
                                padding: '2px 16px',
                                background: '#E6EFFE',
                                cursor: 'pointer',
                            }}
                        >
                            <img src={copyIcon} style={{ cursor: 'pointer' }} alt="search icon" />
                            <span>Copy</span>
                        </div>
                    </CopyToClipboard>
                    <Highlight as="div" className="pt-3 pt-sm-0" language="html">
                        {embedCode}
                    </Highlight>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary mr-3" onClick={() => setShowEmbedCode(false)}>Close</button>
                </Modal.Footer>
            </Modal>

            {
                (isLoading)
                    ?
                    (
                        <Shimmer />
                    )
                    :
                    (
                        ((content && content.length > 0) || isSearchResults)
                            ?
                            (
                                <React.Fragment>
                                    <AppliedFilter />
                                    <Content />
                                </React.Fragment>
                            )
                            :
                            (
                                (isDateFilterApplied)
                                    ?
                                    (
                                        <React.Fragment>
                                            <AppliedFilter />
                                            <p style={{ marginTop: '32px' }}>No content found in this date range. Try adjusting the date range.</p>
                                        </React.Fragment>
                                    )
                                    :
                                    (
                                        (contentType === "voice_over") ? <VoiceoversEmptyState orgId={org_id} integrationId={id} /> : ((contentType == "audio_article") ? <AudiosEmptyState orgId={org_id} integrationId={id} /> : null)
                                    )
                            )
                    )
            }

            {
                (audio) &&
                <div className="audio-player-wrapper">
                    <AudioPlayer onClose={() => setAudio(null)} audio={audio} title={audioTitle} />
                </div>
            }
        </React.Fragment>
    );
}

export default Content;