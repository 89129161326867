import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Spinner from '../../components/Spinner';
import { API } from "aws-amplify";
import HavingTroubleCard from './HavingTroubleCard';
import APIKeysSection from './APIKeysSection';

function WordPress() {

    const { org_id, id } = useParams();

    const [integration, setIntegration] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getIntegration = (org_id, id) => {
        return API.get(`main`, `/organisation/${org_id}/integration/${id}`);
    }

    useEffect(() => {
        getIntegration(org_id, id).then(response => {

            const integration = response.data;

            setIntegration(integration);
            setIsLoading(false)

        });
    }, []);

    return (
        <React.Fragment>
            <div>
                <h3 className="page-title">WordPress Integration</h3>
                <p className="page-subtitle">Connect your WordPress website with Listen2It</p>
                {
                    (isLoading) ?
                        (
                            <div className="my-1">
                                <Spinner color="#3d4852" />
                            </div>
                        )
                        :
                        (
                            <>
                                <div className="card">
                                    <div className="card-body">
                                        <p>
                                            With our WordPress plugin you can integrate Listen2It in your website or blog. All your new posts will be automatically converted to audio as per your saved voice settings. All new posts will automatically show the integrated player allowing your audience to listen to your content
                                        </p>
                                        <ul className="mt-4" style={{
                                            listStyleType: 'none',
                                            paddingLeft: 0,
                                            marginBottom: 0,
                                        }}>
                                            <li style={{ marginBottom: 8 }}>
                                                <a href="https://support.getlisten2it.com/portal/en/kb/articles/how-to-add-audio-to-your-wordpress-blog-posts">How to setup Listen2It on your WordPress website or blog?</a>
                                            </li>
                                            <li>
                                                <a href="https://wordpress.org/plugins/listen2it/">Download the Listen2It WordPress plugin</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <APIKeysSection orgId={org_id} id={id} integration={integration} />
                                <HavingTroubleCard />
                            </>
                        )
                }
            </div>
        </React.Fragment>
    );
}

export default WordPress;
