import React from 'react';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import copyIcon from '../../assets/copy.svg';

function APIKeysSection({ orgId, integration }) {

    return (
        <div className="card my-5">
            <div className="card-body">
                <div className="form-group">
                    <label>Organisation ID</label>
                    <div className="input-group input-group--search">
                        <input type="text" className="form-control border-right-0" value={orgId} readOnly />
                        <div className="input-group-append">
                            <span className="input-group-text">
                                <CopyToClipboard text={orgId} onCopy={() => toast.success("Copied to clipboard")}>
                                    <img src={copyIcon} style={{ cursor: 'pointer' }} alt="search icon" />
                                </CopyToClipboard>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label>Project ID</label>
                    <div className="input-group input-group--search">
                        <input type="text" className="form-control border-right-0" value={integration.id} readOnly />
                        <div className="input-group-append">
                            <span className="input-group-text">
                                <CopyToClipboard text={integration.id} onCopy={() => toast.success("Copied to clipboard")}>
                                    <img src={copyIcon} style={{ cursor: 'pointer' }} alt="search icon" />
                                </CopyToClipboard>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label>API Key</label>
                    <div className="input-group input-group--search">
                        <input type="text" className="form-control border-right-0" value={integration.api_key} readOnly />
                        <div className="input-group-append border-left-0">
                            <span className="input-group-text">
                                <CopyToClipboard text={integration.api_key} onCopy={() => toast.success("Copied to clipboard")}>
                                    <img src={copyIcon} style={{ cursor: 'pointer' }} alt="search icon" />
                                </CopyToClipboard>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default APIKeysSection;
