export const FETCH_INTEGRATION_REQUESTED = "FETCH_INTEGRATION_REQUESTED"

export const FETCH_INTEGRATION_SUCCESS = "FETCH_INTEGRATION_SUCCESS"

export const FETCH_INTEGRATION_FAILURE = "FETCH_INTEGRATION_FAILURE"

export const CREATE_INTEGRATION_REQUESTED = "CREATE_INTEGRATION_REQUESTED"

export const CREATE_INTEGRATION_SUCCESS = "CREATE_INTEGRATION_SUCCESS"

export const CREATE_INTEGRATION_FAILURE = "CREATE_INTEGRATION_FAILURE"

export const FETCH_INTEGRATION_PROFILES_REQUESTED = "FETCH_INTEGRATION_PROFILES_REQUESTED"

export const FETCH_INTEGRATION_PROFILES_SUCCESS = "FETCH_INTEGRATION_PROFILES_SUCCESS"

export const FETCH_INTEGRATION_PROFILES_FAILURE = "FETCH_INTEGRATION_PROFILES_FAILURE"

export const DELETE_AUDIO_PROFILE_REQUESTED = "DELETE_AUDIO_PROFILE_REQUESTED"

export const DELETE_AUDIO_PROFILE_SUCCESS = "DELETE_AUDIO_PROFILE_SUCCESS"

export const DELETE_AUDIO_PROFILE_FAILURE = "DELETE_AUDIO_PROFILE_FAILURE"

export const SET_DEFAULT_AUDIO_PROFILE = "SET_DEFAULT_AUDIO_PROFILE"

export const UPDATE_PRONUNCIATION_LIBRARY = "UPDATE_PRONUNCIATION_LIBRARY"