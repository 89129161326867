import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { API } from "aws-amplify";
import Spinner from "../components/Spinner";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import Bugsnag from "@bugsnag/js";
import moment from "moment"
import { DEFAULT_LANG_CODE, FREE_AND_PREVIEW_PLANS } from "../constants/constants";
import { useSelector } from "react-redux";
import { useGlobalStore } from "../zustand-store/useGlobalStore";
import { useSubscription } from "../http-hooks/organisation/useSubscription";


const displayCurrency = (country_code) => {
    return country_code == "INR" ? "₹" : country_code
}

const displayAmount = (country_code, amount) => {
    const localStringCode = (country_code === "INR") ? "en-IN" : DEFAULT_LANG_CODE
    return `${displayCurrency(country_code)}${(amount / 100).toLocaleString(localStringCode)}`
}

const formatSubscriptionStatus = (status) => {
    return status.split("_").map(word => {
        let new_word = word[0].toUpperCase() + word.substring(1, word.length)
        return new_word
    }).join(" ")
}

const planIdToString = (string) => {
    if(FREE_AND_PREVIEW_PLANS.includes(string)){
        return "free-preview"
    }
    return string.replace(/inr|jan|usd|2022/g, "").replace(/  +/g, ' ');
}

const dashCaseToSentenceCase = (string) => {
    return (
        string.charAt(0).toUpperCase() + string.substr(1).split("-").join(" ")
    );
};


    // const onOpenPortal = (sectionType) => {
    //     const Chargebee = window.Chargebee;
    //     let chargebeeInstance = Chargebee.getInstance();

    //     chargebeeInstance.setPortalSession(function () {
    //         return API.get(`main`, `/organisation/${org_id}/create-portal-session`);
    //     });

    //     let cbPortal = chargebeeInstance.createChargebeePortal();

    //     let cbSectionType;

    //     switch (sectionType) {
    //         case "BILLING_HISTORY":
    //             cbSectionType = Chargebee.getPortalSections().BILLING_HISTORY;
    //             break;
    //         case "PAYMENT_SOURCES":
    //             cbSectionType = Chargebee.getPortalSections().PAYMENT_SOURCES;
    //             break;
    //         case "EDIT_BILLING_ADDRESS":
    //             cbSectionType = Chargebee.getPortalSections().EDIT_BILLING_ADDRESS;
    //             break;
    //         default:
    //             cbSectionType = Chargebee.getPortalSections().ACCOUNT_DETAILS;
    //     }

    //     if (["EDIT_BILLING_ADDRESS"].includes(sectionType)) {
    //         cbPortal.open(
    //             {
    //                 close: function () {
    //                     chargebeeInstance.logout();
    //                 },
    //             },
    //             {
    //                 sectionType: cbSectionType,
    //             },
    //         );
    //     } else {
    //         cbPortal.openSection(
    //             {
    //                 sectionType: cbSectionType,
    //             },
    //             {
    //                 close: function () {
    //                     chargebeeInstance.logout();
    //                 },
    //             }
    //         );
    //     }

    // };

export default function Billing() {
    const { org_id } = useParams();
    const history = useHistory();
    const { organisation: org, usage: orgUsage } = useSelector(state => state.organisation);
    
    const {isLoading, data} = useSubscription({ org_id });

    const [show, setShow] = useState(false);
    const [isCancellingSubscription, setIsCancellingSubscription] = useState(false);
    const [cancelReason, setCancelReason] = useState("")
    const [cancelSuggestion, setCancelSuggestion] = useState("")
    const toggleUpgradeModal = useGlobalStore((state) => state.toggleUpgradeModal)

    const onOpenPortal = async () => {
        const response = await API.get('main', `/organisation/${org_id}/create-portal-session?redirectURL=${window.location.href}`);
        window.open(response.access_url, "_self");
    }

    if (isLoading) {
        return (
            <Spinner color="#657B90" />
        );
    }

    const subscription = data.data;
    let lastPayment = null;
    if (subscription && subscription.invoices.list.length) {
        lastPayment = subscription.invoices.list[0].invoice;
    }

    const cancelSubscription = () => {
        if (!cancelReason.length) return toast.error('Please add a cancellation reason before proceeding');

        setIsCancellingSubscription(true);

        API.post('main', `/organisation/${org_id}/cancel-subscription`, { body: { cancelReason, cancelSuggestion }, headers: { 'Content-Type': 'application/json' } }).then((resp) => {

            setIsCancellingSubscription(false);
            toast.success('Your subscription is cancelled successfully');
            history.go(0);

        }).catch(err => {

            setIsCancellingSubscription(false);
            toast.error('Something went wrong');
            Bugsnag.notify(err);

        })
    }

    if (!org || !subscription) {
        return (
            <Spinner color="#657B90" />
        );
    }
        
    if (!org.plan_id) {
        history.push(`/organisation/${org_id}/plans`)
        return <></>
    }

    return (
        <div className="container-fluid billing-page">
            <>
                <Modal show={show} onHide={() => setShow(false)}>
                    <Modal.Body>
                        <div className="row mt-4">
                            <div className="col-12">
                                <h1 style={{ fontSize: '3.5rem' }} className="text-center"><span role="img" aria-label="sad-emoji">😢</span></h1>
                            </div>
                            <div className="col-12">
                                <h5 className="text-center">We are sorry to see you go...</h5>
                            </div>
                            <div className="col-12">
                                <p className="text-center mb-4">Before you cancel, please let us know the reason you are leaving. Every bit of feedback helps!</p>
                            </div>
                        </div>
                        <div className="form-group">
                            <textarea placeholder="Please tell us why are you cancelling? *" value={cancelReason} onChange={(e) => setCancelReason(e.target.value)} className="form-control" name="cancel_reason"></textarea>
                        </div>
                        <div className="form-group">
                            <textarea placeholder="Any suggestions for us? (Optional)" value={cancelSuggestion} onChange={(e) => setCancelSuggestion(e.target.value)} className="form-control" name="cancel_suggestion"></textarea>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center">
                        <p className="text-center mb-4"><small>On confirmation, your subscription would be cancelled at the end of current billed period and would not be renewed further.</small></p>
                        <div className="row">
                            <div className="col-12">
                                <button type="button" className="btn btn-outline-primary mr-3" onClick={() => setShow(false)} disabled={isCancellingSubscription}>Don't Cancel</button>
                                {
                                    (isCancellingSubscription) ?
                                        (
                                            <button type="button" className="btn btn-danger text-center">
                                                <Spinner color="#FFF" />
                                            </button>
                                        )
                                        :
                                        (
                                            <button type="button" className="btn btn-danger" disabled={!cancelReason.length} onClick={cancelSubscription}>Confirm Cancellation</button>
                                        )
                                }
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
                <h3 className="page-title">Plan and Billing</h3>
                <div className="d-flex flex-column">

                    <div className="w-100 d-flex">
                        <div className="d-flex billing-container">
                            <div className="billing-summary">
                                <p className="mb-4"><strong>Your Current Plan</strong></p>
                                {/* <p className="plan-name-heading">{`${plans[org.plan_id].plan_name}`}</p> */}
                                <p className="plan-name-heading">{dashCaseToSentenceCase(planIdToString(org.plan_id))}</p>
                                {
                                    orgUsage && orgUsage.plan_words_limit && (
                                        <p className="plan-words mb-4">{orgUsage.plan_words_limit.toLocaleString()} Words {!FREE_AND_PREVIEW_PLANS.includes(org.plan_id) ? "/ Month" : null}</p>
                                    )
                                }
                                <p className="mb-4"><span className="plan-value">{subscription.mrr / 100}</span> <span className="plan-month">{subscription.currency_code} / Month</span></p>
                                <Button className="mb-2" onClick={() => toggleUpgradeModal(true)} >
                                    {
                                        subscription.status === 'non_renewing'
                                        ? 'Resume Plan'
                                        : 'Upgrade Plan'
                                    }
                                </Button>
                                <a href="https://getlisten2it.com/pricing">View Details</a>
                            </div>
                            <div className="billing-details">
                                <p style={{ borderBottom: "1px solid #DFDFDF", paddingBottom: 15 }} className="mb-0"><strong>Billing Details</strong></p>
                                <div className="detail-row">
                                    <small>Subscription Status</small>
                                    <p className="mb-0"><strong>{formatSubscriptionStatus(subscription.status)}</strong></p>
                                </div>
                                {
                                    !FREE_AND_PREVIEW_PLANS.includes(org.plan_id) ? (
                                        <>
                                            <div className="detail-row">
                                                <small>Next Payment</small>
                                                <p className="mb-0"><strong>{moment.unix(subscription.next_billing_at).format("MMM D, YYYY")} ({displayAmount(subscription.currency_code, subscription.mrr ? subscription.mrr : subscription.plan_amount)})</strong></p>
                                            </div>
                                            <div className="detail-row">
                                                <small>Last Payment</small>
                                                <p className="mb-0"><strong>{moment.unix(lastPayment.date).format("MMM D, YYYY")} ({displayAmount(lastPayment.currency_code, lastPayment.amount_paid)})</strong></p>
                                            </div></>
                                    ) : null
                                }

                                <div style={{ marginTop: 10 }} className="w-100">
                                    <p className="mb-2"><a onClick={() => {
                                        onOpenPortal("BILLING_HISTORY");
                                    }} href="#">Invoice History {'>'}</a></p>
                                    <p className="mb-2"><a onClick={() => {
                                        onOpenPortal("PAYMENT_SOURCES");
                                    }} href="#">Change Payment Method {'>'}</a></p>
                                    <p className="mb-2"><a onClick={() => {
                                        onOpenPortal("EDIT_BILLING_ADDRESS");
                                    }} href="#">Change Billing Address {'>'}</a></p>
                                </div>

                            </div>
                        </div>
                    </div>

                    {
                        subscription.status !== 'non_renewing'
                        && (
                            <p className="mt-1 cancel-subscription" onClick={() => setShow(true)}>Cancel Subscription {'>'}</p>
                        )
                    }
                </div>
            </>
        </div>
    );
}