import create from 'zustand';
import { DEFAULT_VOICE_CONFIGURATION } from '../constants/constants';

export interface IVoiceConfiguration {
	volume: number
	speed: number
	pitch: string
	style: string
}

export enum VoiceConfigurationOptions {
	volume = 'volume',
	speed = 'speed',
	pitch = 'pitch',
	style = 'style'
}

interface IVoiceConfigurationStore {
	voiceConfiguration: IVoiceConfiguration
	setVoiceConfiguration: (key: VoiceConfigurationOptions, value: string | number) => void
}

export const useVoiceConfigurationStore = create<IVoiceConfigurationStore>()((set) => ({
	voiceConfiguration: DEFAULT_VOICE_CONFIGURATION,
    setVoiceConfiguration: (key: VoiceConfigurationOptions, value: string | number) => set((state) => ({ 
		voiceConfiguration: {
			...state.voiceConfiguration, 
			[key]: value
		}
	})),
}))