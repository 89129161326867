import React, { useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Bugsnag, { NotifiableError } from '@bugsnag/js'
import shallow from 'zustand/shallow'

import { Pipe } from '../editor/Pipe'
import { IconButton } from '../IconButton'
import { SelectVoiceTooltip } from '../voice/SingleVoiceLabelEditor'

import { usePreGenAudioPlayer } from '../../zustand-store/usePreGenAudioPlayerStore'
import { IVoice, useAIVoicesStore } from '../../zustand-store/useAIVoicesStore'

import PlayIcon from '../../assets/voice/tile-play.svg'
import PauseIcon from '../../assets/voice/tile-pause.svg'
import EditIcon from '../../assets/voice/edit.svg';
import CreateProfile from '../../assets/voice/create-profile.svg';
import DropdownIcon from '../../assets/down_chevron.svg';
import { PageParams } from './ConfigureVoiceModal';

interface IVoicesProps {
    isPicker: boolean
}

export const Voices = ({ isPicker }: IVoicesProps) => {
    const { selectedLanguage, voices } = useAIVoicesStore((state) => ({
        selectedLanguage: state.selectedLanguage,
        voices: state.voices
    }), shallow);

    return (
        <div style={{marginTop : 24}}>
            <span style={{ fontSize: 16, fontWeight: 600, color: '#333B6C' }}>{selectedLanguage?.name}</span>
            {
                voices.length === 0
                    ? (
                        <div className="mt-2">
                            No voices matching the filters could be found.
                        </div>
                    )
                    : (
                        <div
                            className='align-items-start align-content-start'
                            style={{
                                ...(!isPicker && { maxWidth: 600 }),
                                padding: '4px 0',
                                marginTop: 12,
                                height: "calc(100vh - 300px)",
                                overflowY: 'scroll'
                            }}
                        >
                            {
                                voices.map((voice: IVoice, index: number) => (
                                    <VoiceListItem key={index} index={index} voice={voice} isPicker={isPicker} />
                                ))
                            }
                        </div>
                    )
            }

        </div>
    )
}

interface IVoiceListItemProps {
    voice: IVoice
    index: number
    isPicker?: boolean
    onChange?: () => void
}

const VoiceListItem = ({ voice, index, isPicker = false, onChange }: IVoiceListItemProps) => {

    const history = useHistory();
    const { org_id, id } = useParams<PageParams>()

    const { expandedCardIndex, selectedVoice, setExpandedVoice, setSelectedVoice, toggleCreateCustomVoiceProfileModal, toggleSetDefaultProfile } = useAIVoicesStore((state) => ({
        expandedCardIndex: state.expandedCardIndex,
        setExpandedVoice: state.setExpandedVoice,
        setSelectedVoice: state.setSelectedVoice,
        toggleCreateCustomVoiceProfileModal: state.toggleCreateCustomVoiceProfileModal,
        toggleSetDefaultProfile: state.toggleSetDefaultProfile,
        selectedVoice: state.selectedVoice
    }), shallow);
    const voiceDetailsRef = useRef<HTMLDivElement>(null);

    return (
        <div style={{ gap: 24, width: '100%' }} className={`mb-3 d-flex flex-row align-items-center ${isPicker ? 'col-md-6' : ''}`}>
            <div style={{ border: '1px solid #70707073', borderRadius: 6, flexGrow: 1, padding: '6px 10px' }}>
                <div className='d-flex justify-content-between'>
                    <div className='d-flex' style={{ gap: 8 }}>
                        <PreviewIcon audioSrc={voice.audio} />
                        <div style={{ gap: 4, fontSize: 13, fontWeight: 600 }} className='d-flex align-items-center'>
                            {
                                isPicker
                                && (
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 50, hide: 400 }}
                                        overlay={SelectVoiceTooltip}
                                    >
                                        <input style={{ width: 20, height: 20, cursor: 'pointer' }} type='checkbox' checked={selectedVoice?.code === voice.code && selectedVoice.engine === voice.engine} onChange={() => setSelectedVoice(voice)} />
                                    </OverlayTrigger>
                                )
                            }
                            <span>
                                {voice.name}
                            </span>
                            <span>
                                ({voice.gender.charAt(0).toUpperCase()})
                            </span>
                        </div>
                        <div style={{ gap: 4 }} className='d-flex align-items-center'>
                            {
                                voice.engine === 'neural' && (
                                    <Badge variant="warning">Premium</Badge>
                                )
                            }
                            {
                                voice.styles.length > 0 && (
                                    <Badge style={{ background: "#333A6C" }} variant="primary">Styles</Badge>
                                )
                            }
                        </div>
                    </div>
                    <div style={{ gap: 4 }} className='d-flex'>

                        <IconButton style={{ transform: expandedCardIndex === index ? 'rotate(180deg)' : 'rotate(0deg)' }} onClick={() => {
                            if (expandedCardIndex === index) {
                                setExpandedVoice(null)
                            } else {
                                setExpandedVoice(index)
                            }
                        }} src={DropdownIcon} alt="drop-down" />
                    </div>
                </div>
                <div ref={voiceDetailsRef} style={{ overflow: 'hidden', transition: 'all 0.5s ease', height: expandedCardIndex === index ? voiceDetailsRef.current?.scrollHeight : 0 }}>
                    <VoiceDetails voice={voice} />
                </div>
            </div>
            {
                !isPicker && (
                    <div className='d-flex flex-shrink-0'>
                        <span onClick={() => {
                            setSelectedVoice(voice)
                            toggleSetDefaultProfile()
                        }} style={{ color: '#2D79F6', fontSize: 14, lineHeight: "25px", fontWeight: 600, cursor: 'pointer' }}>Set Default</span>
                        <Pipe />
                        <IconButton onClick={() => {
                            setSelectedVoice(voice);
                            toggleCreateCustomVoiceProfileModal();
                        }} width={16} height={16} src={CreateProfile} alt="profile" />
                        <Pipe />

                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="voice-over-tooltip">Create Voiceover</Tooltip>
                            }
                        >
                            <IconButton onClick={() => {
                                history.push({
                                    pathname: `/organisation/${org_id}/integration/${id}/content/new`,
                                    state: {
                                        voice: voice.code
                                    }
                                })
                            }} width={16} height={16} src={EditIcon} alt="Create voiceover" />
                        </OverlayTrigger>

                    </div>
                )
            }
        </div>
    )
}

interface IVoiceDetailsProps {
    voice: IVoice
}

const VoiceDetails = ({ voice }: IVoiceDetailsProps) => {
    const isPitchDisabled = voice.platform === 'polly' && voice.engine === 'neural'
    return voice ? (
        <div className='row mt-2'>
            {voice.styles.length ? <div className='col-12 mb-4'>
                <p className='mb-2 font-weight-bold'>Voice Styles</p>
                {voice.styles.map((voiceStyle, voiceStyleIndex) => {

                    try {
                        voiceStyle = voiceStyle.replace('[', '')
                        voiceStyle = voiceStyle.replace(']', '')
                    } catch (e) {
                        Bugsnag.notify(e as NotifiableError)
                    }

                    return <React.Fragment key={voiceStyleIndex}>
                        <Badge
                            variant="primary"
                            key={`style-${voiceStyle}-${voiceStyleIndex}`}
                        >
                            {voiceStyle.charAt(0).toUpperCase() + voiceStyle.substring(1)}
                        </Badge>
                        {' '}
                    </React.Fragment>
                })}
            </div> : null}

            <div className='col-12 mb-4'>
                <p className='mb-2 font-weight-bold'>Supported Formats</p>
                <Badge variant="warning">MP3</Badge>{' '}
                <Badge variant="warning">WAV</Badge>{' '}
            </div>

            <div className='col-12 mb-4'>
                <p className='mb-2 font-weight-bold'>Supported Settings</p>
                <Badge variant="success">Volume</Badge>{' '}
                {
                    !isPitchDisabled && (
                        <>
                            <Badge variant="success">Pitch</Badge>
                            &nbsp;
                        </>
                    )
                }
                <Badge variant="success">Speed</Badge>{' '}
            </div>

        </div>
    ) : <></>
}

const PreviewIcon = ({ audioSrc }: IPreviewIconProps) => {

    const { src, isPlaying, togglePlayPause } = usePreGenAudioPlayer();

    return (
        <button onClick={() => togglePlayPause(getAudioURL(audioSrc))} style={{
            background: 'rgb(227, 227, 227)',
            boxShadow: 'none',
            border: 'none',
            borderRadius: 6,
            width: 28,
            height: 28
        }}>
            {
                isPlaying && src === getAudioURL(audioSrc)
                    ? (
                        <img height={14} width={14} src={PauseIcon} alt="pause-icon" />
                    )
                    : (
                        <img height={14} width={14} src={PlayIcon} alt="play-icon" />
                    )
            }
        </button>
    )
}

interface IPreviewIconProps {
    audioSrc: string
}

const getAudioURL = (audio: string) => {
    return `${process.env.REACT_APP_AUDIO_PREVIEW_URL}/${audio}`
}
