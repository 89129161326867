import { API } from "aws-amplify"
import { toast } from "react-toastify"

export const fetchContentService = ({ org_id, id, content_id }) => {
    return new Promise((resolve, reject) => {
        API.get(`main`, `/organisation/${org_id}/integration/${id}/content/${content_id}`).then(response => {
            if(!response.success){
                toast.error("Cannot fetch content")
                throw response;
            }
            return resolve(response)
        }).catch(err => {
            return reject(err)
        })
    })
}