import React from 'react';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Badge from 'react-bootstrap/Badge'

import playIcon from '../../assets/voice/tile-play.svg';
import pauseIcon from '../../assets/voice/tile-pause.svg';
import downChevron from '../../assets/down_chevron.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function SingleVoiceLabelEditor({eventKey, voice, isSelected, onSelect, audioControls}){

    const decoratedOnClick = useAccordionToggle(eventKey);

    return (
        <div className='row'>
            <div style={{display: "flex", "justifyContent": "space-between"}} className='col-12 d-flex align-items-center single-voice-label'>
                <div className='d-flex align-items-center'>
                    <OverlayTrigger
                        placement="top"
						delay={{ show: 50, hide: 400 }}
						overlay={SelectVoiceTooltip}
                    >
                        {/**
                         * Sometimes after selecting a different voice, the old voice and the new voice both are checked.
                         * https://app.birdeatsbug.com/workspaces/86dce122-80ab-4549-979f-f562f287a65d/collections/a79b0569-9a54-4bb0-95fc-3de73e38b82e/sessions/MwBys4T19Z49VlX0JCD848kfrpHxa6RxnjAyaFr28kuE
                         * https://insights.hotjar.com/r?site=3015581&recording=17525427445&startTime=191000&token=1337fe45adb2008775a351076d10901b
                         * 
                         * BUG not reproducible
                         * Trying out explicit true false values for checked attribute to see if it will be fixed. 
                         * Issue can also be withing HotJar only. It is not able to reflect this change in recording but actually it worked fine.
                         */}
                        <input style={{width: 20, height: 20, cursor: 'pointer'}} type='checkbox' checked={isSelected ? true : false} onChange={onSelect} />
                    </OverlayTrigger>
                    <span className='mx-2 label-voice d-flex'>{`${voice.name}(${voice.gender.charAt(0).toUpperCase()})`}</span>
                    <VoiceBadges voice={voice} />
                </div>
                <div className='d-flex align-items-center'>
                    <PlayerIconButton audioControls={audioControls} voice={voice} />
                    <label onClick={decoratedOnClick} style={{cursor: 'pointer'}} className='form-check-label'>
                        <span><img style={{height : "7px"}} src={downChevron} alt="expand icon" /></span>
                    </label>
                </div>
            </div>
        </div>
        
    );
};

export const SelectVoiceTooltip = (props) => {
    return (
        <Tooltip id="button-tooltip" {...props}>
            Select voice
        </Tooltip>
    )
}

export const PlayerButtonTooltip = (props) => {
    return (
        <Tooltip id="button-tooltip" {...props}>
            Play
        </Tooltip>
    )
}

const PlayerIconButton = ({voice, audioControls}) => {

    const { audio, setAudio, isPlaying, setIsPlaying } = audioControls;
    const voiceSample = getAudioURL(voice.audio);

    return (
        <>
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 50, hide: 400 }}
                overlay={PlayerButtonTooltip}
            >
                {
                    (isPlaying && audio === voiceSample) 
                        ? <span className="play-icon" onClick={() => {
                            setIsPlaying(false)
                        }} >
                            <img src={pauseIcon} alt="pause icon" />
                        </span>
                        : <span className="play-icon" onClick={() => {
                            setAudio(voiceSample)
                            setIsPlaying(true)
                        } } >
                            <img src={playIcon} alt="play icon" />
                        </span>
                }
            </OverlayTrigger>
        </>
    )
}

const VoiceBadges = ({voice}) => {
    return (
        <>
            {
                (voice.engine === "neural") && <Badge variant="warning">Premium</Badge>
            }
            &nbsp;
            {
                (voice.styles && voice.styles.length) ? <Badge variant="primary">Styles</Badge> : null
            }
        </>
    )
}

const getAudioURL = (audio) => {
    return `${process.env.REACT_APP_AUDIO_PREVIEW_URL}/${audio}`
}
