import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from "react-router-dom";

function HelpCenter(){
    
    const CustomToggle = React.forwardRef(({ onClick }, ref) => (
        <div className="avatar" ref={ref} onClick={(e) => { e.preventDefault(); onClick(e);}}>
            {
                <div className="icon">                 
                    <span>?</span>                                      
                </div>
            }
        </div>
    ));

    return(
        <Dropdown className="mx-2">
            <Dropdown.Toggle as={CustomToggle} />
            <Dropdown.Menu>
            	<Dropdown.Header>Help Center</Dropdown.Header>
                <Dropdown.Item as="div"><Link to={{ pathname : "https://support.getlisten2it.com/portal/en/kb" }} target="_blank">Knowledge Base</Link></Dropdown.Item>
            	
            	<Dropdown.Divider />

            	<Dropdown.Header>Help us Improve</Dropdown.Header>
                <Dropdown.Item as="div"><Link to={{ pathname : "https://listen2it.upvoty.com/b/features/" }} target="_blank">Feature Requests</Link></Dropdown.Item>
                
                <Dropdown.Divider />

            	<Dropdown.Header>Legal</Dropdown.Header>
            	<Dropdown.Item as="div"><Link to={{ pathname : "https://www.getlisten2it.com/terms" }} target="_blank">Terms of use</Link></Dropdown.Item>
                <Dropdown.Item as="div"><Link to={{ pathname : "https://www.getlisten2it.com/privacy" }} target="_blank">Privacy Policy</Link></Dropdown.Item>
                
            </Dropdown.Menu>
        </Dropdown>
    )

}

export default HelpCenter;
