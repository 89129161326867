import {
    FETCH_INTEGRATION_REQUESTED,
    FETCH_INTEGRATION_SUCCESS,
    FETCH_INTEGRATION_FAILURE,
    FETCH_INTEGRATION_PROFILES_REQUESTED,
    FETCH_INTEGRATION_PROFILES_SUCCESS,
    FETCH_INTEGRATION_PROFILES_FAILURE,
    DELETE_AUDIO_PROFILE_REQUESTED,
    DELETE_AUDIO_PROFILE_SUCCESS,
    DELETE_AUDIO_PROFILE_FAILURE,
    SET_DEFAULT_AUDIO_PROFILE,
    UPDATE_PRONUNCIATION_LIBRARY,
    CREATE_INTEGRATION_FAILURE,
    CREATE_INTEGRATION_REQUESTED,
    CREATE_INTEGRATION_SUCCESS,
} from "../actionTypes"

const initialState = {
    integration: null,
    newIntegration: null,
    profiles: [],
    isLoading: false,
    isNewIntegrationLoading : false,
    isDeletingProfile: false,
    isError: false,
    pronunciationLibrary: [],
};

export default function integrationReducer(state = initialState, action) {
    const { type } = action;

    switch (type) {
        case FETCH_INTEGRATION_SUCCESS:
            return {
                ...state,
                integration: action.payload,
                pronunciationLibrary: action.payload.pronunciation_library || [],
                isLoading: false,
                isError: false
            };

        case FETCH_INTEGRATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true
            };

        case FETCH_INTEGRATION_REQUESTED:
            return {
                ...state,
                isLoading: true
            };

        case CREATE_INTEGRATION_SUCCESS:
            console.log(CREATE_INTEGRATION_SUCCESS, "reducer")
            return {
                ...state,
                newIntegration: action.payload,
                isNewIntegrationLoading: false,
                isError: false
            };

        case CREATE_INTEGRATION_FAILURE:
            return {
                ...state,
                isNewIntegrationLoading: false,
                isError: true
            };

        case CREATE_INTEGRATION_REQUESTED:
            return {
                ...state,
                newIntegration : null,
                isNewIntegrationLoading: true,
                isError : false
            };

        case FETCH_INTEGRATION_PROFILES_SUCCESS:
            return {
                ...state,
                profiles: action.payload,
                isLoading: false,
            };

        case FETCH_INTEGRATION_PROFILES_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true
            };

        case FETCH_INTEGRATION_PROFILES_REQUESTED:
            return {
                ...state,
            };

        case DELETE_AUDIO_PROFILE_REQUESTED:
            return {
                ...state,
                isDeletingProfile: true
            }

        case DELETE_AUDIO_PROFILE_SUCCESS:
            return {
                ...state,
                isDeletingProfile: false,
                profiles: state.profiles.filter(p => p.id !== action.payload.id)
            }

        case DELETE_AUDIO_PROFILE_FAILURE:
            return {
                ...state,
                isDeletingProfile: false
            }

        case UPDATE_PRONUNCIATION_LIBRARY:
            return {
                ...state,
                pronunciationLibrary: action.payload
            }

        case SET_DEFAULT_AUDIO_PROFILE:
            return {
                ...state,
                integration: {
                    ...state.integration,
                    audio_profile: action.payload
                }
            }

        default:
            return state;
    }
}
