import { all } from "redux-saga/effects";
import contentSaga from "./content";
import voiceSaga from "./voice";
import integrationSaga from "./integration";
import organisationSaga from "./organisation";
import editContentSaga from "./edit_content";
import audioComposerSaga from "./audio_composer";
import mixpanelSaga from "./mixpanel";

export default function* rootSaga() {
  yield all([
    contentSaga(),
    voiceSaga(),
    integrationSaga(),
    organisationSaga(),
    editContentSaga(),
    audioComposerSaga(),
    mixpanelSaga()
  ]);
}