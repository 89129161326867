import React from 'react'
import Block from './Block'
import { useDispatch } from 'react-redux'

// Redux
import {addAudioComposerBlock, toggleAudioComposerAudioUploadModal} from "../../store/actions"
function Track({sectionIndex, trackIndex, track, orgId, integrationId}) {
    const dispatch = useDispatch()
    const addNewBlock = () => {
        if(sectionIndex === 1){
            dispatch(toggleAudioComposerAudioUploadModal(true))
        }else{
            dispatch(addAudioComposerBlock({sectionIndex, trackIndex}))
        }
        
    }
    
    return (
      <div className="audio-composer-track">
          {track.blocks.map((block, blockIndex) => {
              return <Block key={`block-${blockIndex}`} sectionIndex={sectionIndex} trackIndex={trackIndex} blockIndex={blockIndex} blockProp={block} orgId={orgId} integrationId={integrationId} />
          })}
          <div className="audio-composer-block" onClick={() => addNewBlock()}>+</div>
      </div>
    )
}

export default Track