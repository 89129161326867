import {
    FETCH_CONTENTS_REQUESTED,
    FETCH_CONTENTS_SUCCESS,
    FETCH_CONTENTS_FAILURE,
    FETCH_CONTENTS_SEARCH_REQUESTED,
    FETCH_CONTENTS_SEARCH_SUCCESS,
    SET_CURRENT_AUDIO,
    TOGGLE_CURRENT_AUDIO_PLAYING,
    UPDATE_CURRENT_AUDIO_SEEK_VALUE,
    INITIALIZE_CURRENT_AUDIO_SUCCESS,
    GENERATE_SRT_REQUESTED,
    GENERATE_SRT_SUCCESS,
    GENERATE_SRT_FAILURE,
    PING_SRT_REQUESTED,
    PING_SRT_SUCCESS,
    PING_SRT_FAILURE,
    TOGGLE_EDITOR_TITLE_SETTINGS_MODAL,
    SET_CURRENT_AUDIO_DURATION,
    STOP_COMPOSER_PLAYER
} from "../actionTypes"

export const stopComposerPlayer = (data={}) => {
    return {
        type : STOP_COMPOSER_PLAYER,
        payload : data
    }
}

export const fetchContentsRequested = (data) => {
    return {
        type: FETCH_CONTENTS_REQUESTED,
        payload: data,
    };
};

export const fetchContentsSuccess = (data) => {
    return {
        type: FETCH_CONTENTS_SUCCESS,
        payload: data,
    };
};

export const fetchContentsFailure = (data) => {
    return {
        type: FETCH_CONTENTS_FAILURE,
        payload: data,
    };
};

export const fetchContentsSearchRequested = (data) => {
    return {
        type: FETCH_CONTENTS_SEARCH_REQUESTED,
        payload: data,
    };
};

export const fetchContentsSearchSuccess = (data) => {
    return {
        type: FETCH_CONTENTS_SEARCH_SUCCESS,
        payload: data,
    };
};

export const updateCurrentAudio = (data) => {
    return {
        type: SET_CURRENT_AUDIO,
        payload: data,
    };
};

export const updateCurrentAudioDuration = (data) => {
    return {
        type: SET_CURRENT_AUDIO_DURATION,
        payload: data,
    };
}

export const toggleCurrentAudioPlaying = (data) => {
    return {
        type : TOGGLE_CURRENT_AUDIO_PLAYING,
        payload : data
    }
}

export const updateCurrentAudioSeekValue = (data) => {
    return {
        type : UPDATE_CURRENT_AUDIO_SEEK_VALUE,
        payload : data
    }
}

export const initializeCurrentAudioSuccess = () => {
    return {
        type : INITIALIZE_CURRENT_AUDIO_SUCCESS,
        payload : {}
    }
}

export const generateSRTRequested = (data) => {
    return {
        type : GENERATE_SRT_REQUESTED,
        payload : data
    }
}

export const generateSRTSuccess = (data) => {
    return {
        type : GENERATE_SRT_SUCCESS,
        payload : data
    }
}

export const generateSRTFailure = (data) => {
    return {
        type : GENERATE_SRT_FAILURE,
        payload : data
    }
}

export const pingSRTRequested = (data) => {
    return {
        type : PING_SRT_REQUESTED,
        payload : data
    }
}

export const pingSRTSuccess = (data) => {
    return {
        type : PING_SRT_SUCCESS,
        payload : data
    }
}

export const pingSRTFailure = (data) => {
    return {
        type : PING_SRT_FAILURE,
        payload : data
    }
}

export const toggleEditorTitleSettingsModal = (data) => {
    return {
        type : TOGGLE_EDITOR_TITLE_SETTINGS_MODAL,
        payload : data
    }
}