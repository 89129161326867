import React, { useState, useEffect, useRef } from 'react';
import { SketchPicker } from 'react-color';

export default function ColorPicker({ defaultColor, onChange}){

    const [ showPicker, setShowPicker ] = useState(false);
    const [currentColor, setCurrentColor] = useState(defaultColor)
    const node = useRef();

    const handleColorChange = (color) => {
        setCurrentColor(color)
        onChange(color)
    }
    const handleClick = (e)=>{

            if (node.current.contains(e.target)) {

                setShowPicker(true)
                return;

            }
         
            setShowPicker(false);

    }

    useEffect(() => {
       
        document.addEventListener("mousedown", handleClick);
        
        return () => {

          document.removeEventListener("mousedown", handleClick);

        };

      }, []);

    useEffect(() => {
        setCurrentColor(defaultColor)
    }, [defaultColor])

    return (
        <span className="color-preview" style={{cursor: 'pointer', backgroundColor: currentColor, position: 'relative', border:'1px solid #ced4da'}} ref={node}>
            {
                showPicker &&
                <div style={{position: 'absolute', left: 0, top:'25px', zIndex: 2}}>
                    <SketchPicker color={currentColor} onChange={(color) => { handleColorChange(color.hex); }}/>
                </div>
            }
        </span>
    )
};