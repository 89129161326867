/* eslint-disable no-useless-escape */
import { Node } from "slate";

const MIN_COMPOSER_TTS_BLOCK_DURATION = 1.0;

export const getWordsCount = nodes => {

	const serializedString = nodes.map(n => Node.string(n)).join(' ');
	return count_words(serializedString)
	// return serializedString.split(' ').filter(val => val !== '').length;

}

export const getCharactersCount = (nodes, shouldTrim = true) => {

	const serializedString = nodes.map(n => Node.string(n)).join('');
	if (!shouldTrim) {
		return serializedString.length;
	}
	return serializedString.trim().length;

}

export const getBlockText = block => {
    return block.children.map(n => Node.string(n)).join(' ');
}

export const blockIncludes = (block, string) => {
	const plainText = getBlockText(block);

	return plainText.toLowerCase().includes(
		string.toLowerCase()
	);
}

export const getBlockDuration = block => {
	const estimatedDuration = Math.round(getWordsCount(block.children) / 2.5)
    return estimatedDuration > MIN_COMPOSER_TTS_BLOCK_DURATION ? estimatedDuration : MIN_COMPOSER_TTS_BLOCK_DURATION;
}

export const getBlockLastPoint = (block, blockIndex) => {

	if (!block || !block.children) {
		return {
			path: [blockIndex, 0, 0],
			offset: 0
		}
	}

	const paragraphs = block.children;
	const paragraphIndex = paragraphs.length - 1;

	const leafNodes = paragraphs[paragraphIndex].children;
	const leafNodeIndex = leafNodes.length - 1;

	const leafNode = leafNodes[leafNodeIndex];

	return {
		path: [blockIndex, paragraphIndex, leafNodeIndex],
		offset: leafNode.text ? leafNode.text.length : 0
	}

}

export const getBlockLevelPronunciationLibrary = (block, pronunciationLibrary) => {
	const { audio_profile } = block;
	return pronunciationLibrary.filter((value) => {
		const { original, language } = value;
		
		return (
			blockIncludes(block, original) 
			&& audio_profile.lang_code === language
		)
	})
}

function count_words(string)
{
    const r1 = new RegExp('[\u3000-\u4DFF]','g');
    const r2 = new RegExp('[\u4E00-\u9FFF]','g');
    const r3 = new RegExp('[\u0E00-\u0E7F]','g');

    string = string.replace(r1,' {PNK} ');
    string = string.replace(r2,' {CJK} ');
    string = string.replace(r3,' {THI} ');

    //string = string.replace(/(<([^>]+)>)/ig,”") ;
    string = string.replace(/(\(|\)|\*|\||\+|\”|\’|_|;|:|,|\.|\?)/ig," ") ;
    string = string.replace(/\s+/ig," ");
    //string = string.replace(/_+/ig," ");
    var a = string.split(/[\s+|\\|\/]/g);

    var count = 0;
    var pnkCounter = 0;
    var thiCounter = 0;

    for (var i = 0; i < a.length; i++) {
        if (a[i] === '{PNK}') {
			pnkCounter++;
        } else if(a[i] === '{THI}') {
			thiCounter++;
        } else if (a[i].length>0) {
			count++;
        }
    }
    count += Math.ceil(pnkCounter/3) + Math.ceil(thiCounter/4);
    return count;
}
