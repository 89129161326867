import {
    FETCH_CONTENTS_REQUESTED,
    FETCH_CONTENTS_SUCCESS,
    FETCH_CONTENTS_FAILURE,
    FETCH_CONTENTS_SEARCH_REQUESTED,
    FETCH_CONTENTS_SEARCH_SUCCESS,
    SET_CURRENT_AUDIO,
    SET_CURRENT_AUDIO_DURATION,
    TOGGLE_CURRENT_AUDIO_PLAYING,
    UPDATE_CURRENT_AUDIO_SEEK_VALUE,
    INITIALIZE_CURRENT_AUDIO_SUCCESS,
    GENERATE_SRT_REQUESTED,
    GENERATE_SRT_SUCCESS,
    GENERATE_SRT_FAILURE,
    PING_SRT_REQUESTED,
    PING_SRT_SUCCESS,
    PING_SRT_FAILURE,
    TOGGLE_EDITOR_TITLE_SETTINGS_MODAL,
    STOP_COMPOSER_PLAYER
} from "../actionTypes"
  
const initialState = {
    contents : [],
    isSearchResults : false,
    isLoading : false,
    isSRTLoading : false,
    isFetchingMore : false,
    next_page_token : null,
    currentAudio : null,
    currentAudioDuration : 0,
    isCurrentAudioPlaying : false,
    playerSeek : 0,
    isSRTFailure : false,
    srtUniqueId : null,
    srtUrl : null,
    currentAudioTitle : "Press play to listen to this.",
    isToggleTitleEditorModalShow : false
};
  
export default function ContentReducer(state = initialState, action) {
    const { type } = action;
  
    switch (type) {

      case STOP_COMPOSER_PLAYER : {
        return {
            ...state,
            playerSeek : action.payload.seek || 0,
            isCurrentAudioPlaying : false
        }
      }

      case TOGGLE_EDITOR_TITLE_SETTINGS_MODAL: 
        return {
          ...state,
          isToggleTitleEditorModalShow : action.payload
        }
        
      case FETCH_CONTENTS_REQUESTED:
        let isLoading = true
        let isFetchingMore = false

        if(action.payload.filter.isMore){
          isLoading = false
          isFetchingMore = true
        }
        return {
          ...state,
          isLoading,
          isFetchingMore
        };

      case FETCH_CONTENTS_SEARCH_REQUESTED:

        return {
          ...state,
          isLoading : true,
        };
  
      case FETCH_CONTENTS_SUCCESS:
        let contents = action.payload.contents
        
        if(action.payload.filter && action.payload.filter.isMore){
          contents = [...state.contents, ...contents]
        }
        let newState = {
          ...state,
          contents,
          isLoading : false,
          isFetchingMore : false,
          isSearchResults : false
        };

        newState["nextPageToken"] = action.payload.nextPageToken

        return newState

      case FETCH_CONTENTS_SEARCH_SUCCESS:

        return {
          ...state,
          contents : action.payload.contents,
          isLoading : false,
          isFetchingMore : false,
          isSearchResults : true
        };

      case SET_CURRENT_AUDIO:
        return {
          ...state,
          currentAudio : action.payload
        }

      case SET_CURRENT_AUDIO_DURATION:
        return {
          ...state,
          currentAudioDuration : action.payload
        }

      case UPDATE_CURRENT_AUDIO_SEEK_VALUE: {
        let newState = {
          ...state,
          playerSeek : action.payload.seek,
        }

        if(action.payload.nextPlayingState){
          newState['isCurrentAudioPlaying'] = true
        }
        
        return newState
      }

      case TOGGLE_CURRENT_AUDIO_PLAYING:
        return {
          ...state,
          isCurrentAudioPlaying : action.payload
        }

      case INITIALIZE_CURRENT_AUDIO_SUCCESS:
        return {
          ...state,
          playerSeek : 0,
          isCurrentAudioPlaying : false,
        }

      case GENERATE_SRT_REQUESTED:
        return {
          ...state,
          isSRTLoading : true,
          srtUrl : null
        }

      case GENERATE_SRT_SUCCESS:
        return {
          ...state,
          srtUniqueId : action.payload.srtUniqueId 
        }

      case GENERATE_SRT_FAILURE:
        return {
          ...state,
          isSRTLoading : false,
          srtUrl : null
        }

      case PING_SRT_REQUESTED:
        return {
          ...state,
          isSRTLoading : true,
          srtUrl : null,
          isSRTFailure : false
        }

      case PING_SRT_SUCCESS:
        return {
          ...state,
          isSRTLoading : false,
          srtUrl : action.payload.url,
        }

      case PING_SRT_FAILURE:
        return {
          ...state,
          isSRTLoading : false,
          srtUrl : null,
          isSRTFailure : true
        }
  
      default:
        return state;
    }
}
  