import { API } from 'aws-amplify';

export const trackMixpanelService = (data) => {
    
    return API.post(
        'main',
        `/trackmixpanel`, 
        {
            body : data,
            headers: { "Content-Type":"application/json" }
        }
    ).then(resp => {

        return resp.data

    }).catch((error)=>{

        console.log('error', error)
            
        return error

    });
}