import {
	Editor, Range, Transforms, Element as SlateElement
} from "slate";
export const insertPause = (editor, duration) => {
	if (editor.selection) {
		wrapPause(editor, duration);
	}
};

export const unwrapPause = (editor) => {
	Transforms.unwrapNodes(editor, {
		match: (n) =>
			!Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "pause"
	});
};

export const wrapPause = (editor, duration) => {
	if (isPauseActive(editor)) {
		unwrapPause(editor);
	}

	const { selection } = editor;
	const isCollapsed = selection && Range.isCollapsed(selection);
	const pause = {
		type: "pause",
		duration: duration,
		children: isCollapsed ? [{ text: "" }] : []
	};

	if (isCollapsed) {
		Transforms.insertNodes(editor, pause);
	} else {
		Transforms.wrapNodes(editor, pause, { split: true });
		Transforms.collapse(editor, { edge: "end" });
	}
};

export const isPauseActive = (editor) => {
	const [pause] = Editor.nodes(editor, {
		match: (n) =>
			!Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "pause"
	});
	return !!pause;
};