import { combineReducers } from "redux";
import content from "./content";
import voice from "./voice";
import integration from "./integration";
import organisation from "./organisation";
import edit_content from "./edit_content";
import audio_composer from "./audio_composer";

export default combineReducers({
  content,
  voice,
  integration,
  organisation,
  edit_content,
  audio_composer
});