import {
    FETCH_ORGANISATION_REQUESTED,
    FETCH_ORGANISATION_SUCCESS,
    FETCH_ORGANISATION_FAILURE,
    FETCH_ORGANISATION_USAGE_SUCCESS,
    FETCH_ORGANISATION_USAGE_REQUESTED,
    UPDATE_SIDE_BAR_PAGE_TYPE
} from "../actionTypes"

export const fetchOrganisationRequested = (org_id) => {
    return {
        type: FETCH_ORGANISATION_REQUESTED,
        payload: {org_id},
    };
};

export const fetchOrganisationSuccess = (data) => {
    return {
        type: FETCH_ORGANISATION_SUCCESS,
        payload: data,
    };
};

export const fetchOrganisationUsageSuccess = (data) => {
    return {
        type: FETCH_ORGANISATION_USAGE_SUCCESS,
        payload: data,
    };
};

export const fetchOrganisationFailure = (data) => {
    return {
        type: FETCH_ORGANISATION_FAILURE,
        payload: data,
    };
};

export const fetchOrganisationUsageRequested = (org_id) => {
    return {
        type: FETCH_ORGANISATION_USAGE_REQUESTED,
        payload: {org_id},
    };
};

export const updateSideBarPageType = (data) => {
    return {
        type : UPDATE_SIDE_BAR_PAGE_TYPE,
        payload : data
    }
}