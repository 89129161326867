import React from 'react';
import { useSelector } from 'react-redux'
import Badge from 'react-bootstrap/Badge'
import Bugsnag from '@bugsnag/js';

export default function SingleVoiceDetails({voice}){
    const isPitchDisabled = useSelector((state) => state.voice.filters.isPitchDisabled)
    const isStyleDisabled = useSelector((state) => state.voice.filters.isStyleDisabled)
    const isBreathingDisabled = useSelector((state) => state.voice.filters.isBreathingDisabled)
    
    return (
        <div className='row'>
            {voice.styles.length ? <div className='col-12 mb-4'>
                <p className='mb-2 font-weight-bold'>Voice Styles</p>
                {voice.styles.map((voiceStyle, voiceStyleIndex) => {
                    
                    try {
                        voiceStyle = voiceStyle.replace('[', '')
                        voiceStyle = voiceStyle.replace(']', '')
                    } catch (e) {
                        console.log(voiceStyle)
                        Bugsnag.notify(e)
                    }

                    return <React.Fragment key={voiceStyleIndex}>
                        <Badge 
                            variant="primary" 
                            key={`style-${voiceStyle}-${voiceStyleIndex}`}
                        >
                            {voiceStyle.charAt(0).toUpperCase() + voiceStyle.substring(1)}
                        </Badge>
                        {' '}
                    </React.Fragment>
                })}
            </div> : null }

            <div className='col-12 mb-4'>
                <p className='mb-2 font-weight-bold'>Supported Formats</p>
                <Badge variant="warning">MP3</Badge>{' '}
                <Badge variant="warning">WAV</Badge>{' '}
            </div>
            
            <div className='col-12 mb-4'>
                <p className='mb-2 font-weight-bold'>Supported Settings</p>
                { !isStyleDisabled && <><Badge variant="success">Style</Badge>&nbsp;</> }
                <Badge variant="success">Volume</Badge>{' '}
                { !isPitchDisabled && <><Badge variant="success">Pitch</Badge>&nbsp;</> }
                <Badge variant="success">Speed</Badge>{' '}
                { !isBreathingDisabled && <><Badge variant="success">Breathing</Badge>&nbsp;</> }
            </div>

        </div>
        
    );
};