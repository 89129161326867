import create from 'zustand';

const UPGRADE_MODAL_TITLE = "Upgrade to get more features, words and unlimited downloads";

interface IGlobalStore {
    orgId: string
    integrationId: string
    showUpgradeModal: boolean
    modalTitle: string
    setOrgId: (orgId: string) => void
    setIntegrationId: (integrationId: string) => void
    toggleUpgradeModal: (value: boolean, options?: any) => void
}

export const useGlobalStore = create<IGlobalStore>()((set) => ({
    orgId: '',
    integrationId: '',
    showUpgradeModal: false,
    modalTitle: UPGRADE_MODAL_TITLE,
    setOrgId: (orgId) => set(() => ({ orgId })),
    setIntegrationId: (integrationId) => set(() => ({ integrationId })),
    toggleUpgradeModal: (show, options) => set((state) => {
        let modalTitle = UPGRADE_MODAL_TITLE;
        if (options && options.title) {
            modalTitle = options.title
        }
        return ({ showUpgradeModal: show, modalTitle: modalTitle })
    })
}))