import React from 'react';

export default function Ripple(){

    return(
        <div className="lds-ripple">
            <div/>
            <div/>
        </div>
    )

}
