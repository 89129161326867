import { API } from "aws-amplify";
import { toast } from 'react-toastify';
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import ReactHowler from 'react-howler';

import Spinner from '../Spinner';

import {
    updateAudioComposerBlock,
    toggleAudioComposerDeleteBlockModal,
    updateAudioComposerBlockMoveLeft,
    updateAudioComposerBlockMoveRight,
    toggleAudioComposerBlockActions,
    addMixPanelEvent
} from "../../store/actions"

// Images
import trashIcon from "../../assets/delete-block-composer.svg"
import moveLeftIcon from "../../assets/composer/actions-block/shift-left.svg"
import moveRightIcon from "../../assets/composer/actions-block/shift-right.svg"
import ComposerPreviewImg from "../../assets/composer/actions-block/play.svg"
import ComposerPauseImg from "../../assets/composer/actions-block/pause.svg"
import { ConditionalWrapper } from '../ConditionalWrapper';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ActionsBlock = ({ block, currentActionsBlockPosition, orgId, integrationId }) => {
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false)
    const [isContentChanged, setIsContentChanged] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)
    const [audio, setAudio] = useState(false)

    const { blockIndex, trackIndex, sectionIndex } = useSelector(state => state.audio_composer.currentBlock)
    const value = useSelector(state => state.audio_composer.slate_value);
    const content = useSelector((state) => state.edit_content.content)

    const actionsBlockRef = useRef(null)

    const handleRemoveBlock = () => {
        if (sectionIndex == 0 && value.length <= 1) return;
        dispatch(toggleAudioComposerDeleteBlockModal(true))
    }

    const generateAudio = () => {
        setIsLoading(() => true)
        const contentId = content.SK.split('#')[1];
        API.put('main', `/organisation/${orgId}/integration/${integrationId}/content/${contentId}/composer-output-block`, {
            headers: { "Content-Type": "application/json" },
            body: {
                block
            }
        }).then(data => {
            const audioURL = `${process.env.REACT_APP_TEMP_AUDIO_BASE_URL}/${data.url}`;
            setAudio(audioURL)
            setIsPlaying(true)
            setIsContentChanged(false)
        }).catch(err => {
            if (err.response && err.response.data && err.response.data.invalidFile) return toast.error(err.response.data.invalidFile)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const handlePlayPause = () => {
        // Throw error message if URL doesnt exist for a block
        if (!block.url) {
            const errorMsg = (sectionIndex == 1) ? "Please remove and re-add the block" : "Please regenerate the audio for this block"
            return toast.error(errorMsg)
        }
        if (isContentChanged) {
            return generateAudio()
        }

        const contentId = content.SK.split('#')[1];
        dispatch(addMixPanelEvent({
            orgId, integrationId, contentId, body: {
                eventName: "UPDATE_AUDIO_COMPOSER_BLOCK_PREVIEW",
                eventData: {
                    isPlaying: !isPlaying
                }
            }
        }))
        setIsPlaying(!isPlaying)

    }

    const handleFadeInChange = (val) => {
        const contentId = content.SK.split('#')[1];
        const fadeInDuration = ((!val.length || isNaN(val)) ? 0 : parseInt(val))
        dispatch(updateAudioComposerBlock({ sectionIndex, trackIndex, blockIndex, data: { fadeInDuration } }))
        dispatch(addMixPanelEvent({
            orgId, integrationId, contentId, body: {
                eventName: "UPDATE_AUDIO_COMPOSER_FADE_IN_DURATION",
                eventData: {
                    fadeInDuration
                }
            }
        }))
    }

    const handleFadeOutChange = (val) => {
        const contentId = content.SK.split('#')[1];
        const fadeOutDuration = ((!val.length || isNaN(val)) ? 0 : parseInt(val))
        dispatch(updateAudioComposerBlock({ sectionIndex, trackIndex, blockIndex, data: { fadeOutDuration } }))
        dispatch(addMixPanelEvent({
            orgId, integrationId, contentId, body: {
                eventName: "UPDATE_AUDIO_COMPOSER_FADE_OUT_DURATION",
                eventData: {
                    fadeOutDuration
                }
            }
        }))
    }

    const handleVolumeChange = (val) => {
        const contentId = content.SK.split('#')[1];
        const volume = ((!val.length || isNaN(val)) ? 0 : parseInt(val))
        dispatch(updateAudioComposerBlock({ sectionIndex, trackIndex, blockIndex, data: { volume } }))
        dispatch(addMixPanelEvent({
            orgId, integrationId, contentId, body: {
                eventName: "UPDATE_AUDIO_COMPOSER_VOLUME",
                eventData: {
                    volume
                }
            }
        }))
    }

    useEffect(() => {
        const clickDocument = (e) => {
            if (actionsBlockRef.current) {
                const { x, y, width, height } = actionsBlockRef.current.getBoundingClientRect()
                if ((e.clientX < x) || (e.clientX > (x + width)) || (e.clientY < y) || (e.clientY > (y + height))) {
                    dispatch(toggleAudioComposerBlockActions(false))
                }
            }
        }
        document.addEventListener("click", clickDocument)

        return function cleanup() {
            document.removeEventListener("click", clickDocument)
        }
    }, [])

    useEffect(() => {
        setIsContentChanged(() => true)
    }, [block])

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Build audio first
        </Tooltip>
    );

    return (
        <>
            {
                (audio) ? <ReactHowler html5={true} src={audio} playing={isPlaying} onEnd={() => {
                    setIsPlaying(false);
                }}
                /> : null

            }
            <div style={{ left: `${currentActionsBlockPosition.x}px`, top: `calc(${currentActionsBlockPosition.y}px - 250px)` }} className="actions-block" ref={actionsBlockRef}>
                <div className='actions-block-group'>
                    <ConditionalWrapper
                        condition={!block.url}
                        wrapper={(children) => {
                            return (
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 50, hide: 400 }}
                                    overlay={renderTooltip}
                                >
                                    {children}
                                </OverlayTrigger>
                            )
                        }}
                    >
                        <button onClick={(e) => {
                            e.preventDefault();
                            handlePlayPause()
                        }} className={`btn btn-primary  d-flex justify-content-center align-items-center gap-1 ${!block.url && 'disabled'}`} style={{ maxHeight: 30, padding: "2px 8px", fontSize: 14, width: '100%' }}>
                            {
                                isLoading
                                    ? <Spinner color="#fff" />
                                    : (
                                        <>
                                            <img width={14} height={14} alt="play-icon" src={isPlaying ? ComposerPauseImg : ComposerPreviewImg} />
                                            <span>Preview</span>
                                        </>
                                    )
                            }
                        </button>
                    </ConditionalWrapper>
                </div>
                <div className='actions-block-group'>
                    <div onClick={() => dispatch(updateAudioComposerBlockMoveLeft(blockIndex))} className="actions-block-row">
                        Shift Left
                        <span><img title="Shift Left" className="block-icon" src={moveLeftIcon} width="20" height="20" /></span>
                    </div>
                    <div onClick={() => dispatch(updateAudioComposerBlockMoveRight(blockIndex))} className="actions-block-row">
                        Shift Right
                        <span><img title="Shift Right" className="block-icon" src={moveRightIcon} width="20" height="20" /></span>
                    </div>
                </div>
                <div className='actions-block-group'>
                    <div className="actions-block-row">
                        Fade In
                        <span>
                            <input title="Fade In Duration (in seconds)" className="block-action-input" value={block.fadeInDuration} onChange={e => handleFadeInChange(e.target.value)} />
                            s
                        </span>
                    </div>
                    <div className="actions-block-row">
                        Fade Out
                        <span>
                            <input title="Fade Out Duration (in seconds)" className="block-action-input" value={block.fadeOutDuration} onChange={e => handleFadeOutChange(e.target.value)} />
                            s
                        </span>
                    </div>
                </div>
                <div className='actions-block-group'>
                    <div className="actions-block-row d-block">
                        Volume ({block.volume}/100)
                        <input type="range" min="0" max="100" value={block.volume || 100} className="form-control-range" id="formControlRange" onChange={e => handleVolumeChange(e.target.value)} />
                    </div>
                </div>
                <div style={{ opacity: (sectionIndex == 0 && value.length <= 1) ? '0.3' : '1' }} title={(sectionIndex == 0 && value.length <= 1) ? "Cannot remove first block" : ""} onClick={handleRemoveBlock} className="actions-block-row">
                    Remove
                    <span><img title="Remove Block" className="block-icon" src={trashIcon} width="20" height="20" /></span>
                </div>
            </div>
        </>
    )
}

export default ActionsBlock