import React, {useRef, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  updateCurrentAudioComposerBlock, 
  updateAudioComposerBlock,
  toggleAudioComposerBlockActions,
  addMixPanelEvent
} from "../../store/actions"
import { pixelsToSeconds, secondsToPixels, roundSingleDig } from './utils'
import ActionsBlock from './ActionsBlock'
// Images
import upArrow from "../../assets/chevron-up-white.svg"
import downArrow from "../../assets/chevron-down-white.svg"
import audioGraph from "../../assets/audio-graph.png"
import { useSize } from '../../hooks/useSize'
const objectHash = require("object-hash")
const path = require("path")

function Block({sectionIndex, trackIndex, blockIndex, blockProp, orgId, integrationId}) {
  const dispatch = useDispatch()
  const isDragging = useRef(false)
  const isDraggingBlock = useRef(false)
  const currentBlockLeftPinRef = useRef(null)
  const currentBlockRightPinRef = useRef(null)
  const blockContainerRef = useRef(null)
  const blockDragMouseXPosition = useRef(0)
  const blockInitialStart = useRef(blockProp.starts)
  const blockRefData = useRef(blockProp)

  const [currentActionsBlockPosition, setCurrentActionsBlockPosition] = useState({x : null, y : null})

  const currentBlockProperties = useSelector(state => state.audio_composer.currentBlock)
  const isBlockActionsActive = useSelector(state => state.audio_composer.isBlockActionsActive)
  const {extraSettings} = useSelector(state => state.audio_composer)
  const content = useSelector((state) => state.edit_content.content)
  const [block, setBlock] = useState(blockProp)

  const blockSize = useSize(blockContainerRef);

  const checkIfCurrentBlock = () => {
    if(!currentBlockProperties) return false

    return ((currentBlockProperties.sectionIndex === sectionIndex) && (currentBlockProperties.trackIndex === trackIndex) && (currentBlockProperties.blockIndex === blockIndex))
  }

  const handleToggleActionsBlock = (e) => {
    e.persist()
    e.stopPropagation() // Stopping propagation to document.click listener inside ActionsBlock
    setCurrentActionsBlockPosition(() => {
      return {
        x : e.clientX,
        y : e.clientY
      }
    })
    dispatch(toggleAudioComposerBlockActions(!isBlockActionsActive))
  }

  const updateBlockIfNew = () => {    
    // Updating the block only store block values doesnt match the local block value
    if(objectHash(blockProp) !== objectHash(blockRefData.current)){
      const contentId = content.SK.split('#')[1];
      dispatch(updateAudioComposerBlock({
        sectionIndex, trackIndex, blockIndex, data : { ...blockRefData.current }
      }))
      dispatch(addMixPanelEvent({
        orgId, integrationId, contentId, body : {
            eventName : "UPDATE_AUDIO_COMPOSER_BLOCK",
            eventData : { sectionIndex, trackIndex, blockIndex, ...blockRefData.current }
        }
    }))
    }
  }

  // Mouse up for block 
  const mouseUpBlock = (e) => {
    blockDragMouseXPosition.current = e.clientX
    isDraggingBlock.current = false
    updateBlockIfNew()
  }

  // Move move for block if actions block is inactive
  const mouseMoveBlock = (e) => {
    if(isDraggingBlock.current && !isBlockActionsActive){
      const newX = e.clientX;
      const movedX = newX - blockDragMouseXPosition.current;

      let delaySeconds = pixelsToSeconds(movedX)
      delaySeconds = delaySeconds > 0 ? delaySeconds : 0;

      blockRefData.current = {...blockRefData.current, delay : delaySeconds}
      setBlock((prev) => {
        return { ...prev, delay : delaySeconds }
      })
    }

  }

  // Mouse down for block 
  const mouseDownBlock = (e) => {
    e.stopPropagation()
    isDraggingBlock.current = true
    blockDragMouseXPosition.current = e.clientX - secondsToPixels(blockRefData.current.delay)
  }

  // Adding event for mouse down Left Trimmer
  const mouseDownLeft = (e) => {
    e.stopPropagation()
    isDragging.current = "L"  
    blockInitialStart.current = blockRefData.current.starts
  }

  // Adding event for mouse down Right Trimmer
  const mouseDownRight = (e) => {
    e.stopPropagation()
    isDragging.current = "R"  
  }

  // Adding event for mouse up Right Trimmer
  const mouseUpRight = (e) => {
    e.stopPropagation()
    updateBlockIfNew()
    console.log("RIGHT TIMM")
  }

  // Adding event for mouse up Left Trimmer
  const mouseUpLeft = (e) => {
    e.stopPropagation()
    updateBlockIfNew()
  }

  useEffect(() => {
    if(blockContainerRef.current && checkIfCurrentBlock()){
      // Setting left and right pin
      currentBlockLeftPinRef.current = blockContainerRef.current.querySelector(".left-pin")
      currentBlockRightPinRef.current = blockContainerRef.current.querySelector(".right-pin")
        // Adding event for mouse move
        const mouseMove = (e) => {
          e.stopPropagation()
          // e.preventDefault()
          if(isDragging.current === "R"){
            // NewX will be the new width as left coord is 0
            let newX = e.clientX - blockContainerRef.current.getBoundingClientRect().x
            if(newX < 0){
              newX = 0
            }

            // Converting width to seconds
            let updatedSeconds = roundSingleDig(blockRefData.current.starts + pixelsToSeconds(newX, extraSettings.zoomLevel))

            if(updatedSeconds > block.duration) return

            // Setting the updated duration
            setBlock((prev) => {
              return {...prev, ends : updatedSeconds, trimRight: block.duration - updatedSeconds}
            })
            blockRefData.current = {
              ...blockRefData.current, ends : updatedSeconds, trimRight: block.duration - updatedSeconds
            }
          }

          if(isDragging.current === "L"){
            // NewX will be the new width as left coord is 0
            let newX = e.clientX - blockContainerRef.current.getBoundingClientRect().x
          
            // Converting width to seconds
            let updatedSeconds = roundSingleDig(blockInitialStart.current + pixelsToSeconds(newX, extraSettings.zoomLevel))
            
            if(updatedSeconds < 0) return
            if(updatedSeconds >= block.duration) return

            // Setting the updated duration
            setBlock((prev) => {
              return {...prev, starts : updatedSeconds}
            })
            blockRefData.current = {
              ...blockRefData.current, starts : updatedSeconds
            }
          }
        }

        // Adding global event for mouse up
        const mouseUpDocument = (e) => {
          if(isDragging.current || isDraggingBlock.current){
            // updateBlockIfNew()
          }
          isDragging.current = false
          isDraggingBlock.current = false
        }

        document.addEventListener("mouseup", mouseUpDocument)
        document.addEventListener("mousemove", mouseMove)
        
        return function cleanup(){
          document.removeEventListener("mouseup", mouseUpDocument)
          document.removeEventListener("mousemove", mouseMove)
          
        }
    }
  }, [currentBlockProperties])

  useEffect(() => {
    setBlock(blockProp)
    blockRefData.current = blockProp
  }, [blockProp, blockIndex])

  return (
    <>
    <div onMouseUp={mouseUpBlock} onMouseDown={mouseDownBlock} onMouseMove={mouseMoveBlock} ref={blockContainerRef} style={{marginLeft : `${secondsToPixels(block.delay, extraSettings.zoomLevel)}px`, 'width' : `${secondsToPixels(block.duration - block.starts - block.trimRight, extraSettings.zoomLevel)}px`}} className={`audio-composer-block ${!block.url ? "calculated" : ""} ${checkIfCurrentBlock() ? "current" : ""}`} title={`${!block.url ? "The duration of this block is estimated and not actual" : ""}`}>
        <div onClick={(e) => { dispatch(updateCurrentAudioComposerBlock({sectionIndex, trackIndex, blockIndex})); handleToggleActionsBlock(e); }} className='text audio-composer-unselectable'>{(sectionIndex === 0) ? block.title : path.basename(block.url)}</div>        
        {
          (checkIfCurrentBlock()) ? (
            <>
            <ActionsPopupButton 
              handleToggleActionsBlock={handleToggleActionsBlock} 
              blockSize={blockSize} 
              isBlockActionsActive={isBlockActionsActive} 
            />
            <div style={{'width' : `${secondsToPixels(block.duration - block.starts - block.trimRight, extraSettings.zoomLevel)}px`}} className="current-audio-composer-block">
              { isDragging.current ? (<span className="left-pin pin-current-duration audio-composer-unselectable">{block.starts}s</span>) : null }
              { isDragging.current ? (<span className="right-pin pin-current-duration audio-composer-unselectable">{block.duration - block.starts - block.trimRight}s</span>) : null }
              
              <span onMouseDown={(e) => mouseDownLeft(e)} onMouseUp={(e) => mouseUpLeft(e)} ref={currentBlockLeftPinRef} className="left-pin trim-pin"></span>
              <div style={{'backgroundImage' : `url('${audioGraph}')`}} className="audio-graph"></div>
              <span onMouseDown={(e) => mouseDownRight(e)} onMouseUp={(e) => mouseUpRight(e)} ref={currentBlockRightPinRef} className="right-pin trim-pin"></span>
            </div>
            { isBlockActionsActive ? (
                <ActionsBlock block={block} currentActionsBlockPosition={currentActionsBlockPosition} orgId={orgId} integrationId={integrationId} />
              ) : null 
            }
            </>
          ) : null
        }
    </div>
    </>
  )
}

const ActionsPopupButton = ({ handleToggleActionsBlock, blockSize, isBlockActionsActive }) => {

  if (!blockSize) return <></>

  if (blockSize.width < 24) {
    return <></>
  }

  return (
    <span onClick={(e) => handleToggleActionsBlock(e)} className='toggle-block-actions audio-composer-unselectable'>
      <img alt="settings" title="Toggle Block Details" src={isBlockActionsActive ? downArrow : upArrow} />
    </span>
  )
}

export default Block