import { API } from "aws-amplify";
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import classNames from 'classnames';
import Spinner from '../components/Spinner';
// import { INTEGRATION_CREATED } from '../constants/gtag-events';
import { toast } from 'react-toastify';
import Bugsnag from '@bugsnag/browser';
import AudioArticlesImg from "../assets/welcome-audio-articles.svg"
import PodcastImg from "../assets/welcome-podcast.svg"
import VoiceOverImg from "../assets/welcome-realistic-voiceover.svg"
import TTSImg from "../assets/welcome-tts.svg"
import PartyingFace from "../assets/welcome-partying-face.svg"
import OnboardingSuccessImg from "../assets/onboarding-setup-success.png"

const WelcomePage = () => {

    const { org_id } = useParams();
    const history = useHistory();

    const [org, setOrg] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isSettingPreferences, setIsSettingPreferences] = useState(false)
    const [isAccountReady, setIsAccountReady] = useState(false)
    const [integrationId, setIntegrationId] = useState(null)

    const displayCards = [
        { title: "Realistic Voiceovers", img: VoiceOverImg, value: "VOICEOVER" },
        { title: "Audio<br/>Articles", img: AudioArticlesImg, value: "AUDIO_ARTICLES" },
        { title: "Text to Speech<br/>via API", img: TTSImg, value: "TTSAPI" },
        { title: "Podcast with no recording", img: PodcastImg, value: "PODCAST" }
    ]

    const updatePreference = (use_case) => {
        setIsSettingPreferences(true);
        window.hj('identify', org_id, {
            'Use Case': use_case
        });
        API.post(`main`, `/organisation/setup`, {
            body: {
                org_id,
                use_case
            }, headers: { "Content-Type": "application/json" }
        })
            .then((response) => {

                setIsAccountReady(true)
                setIntegrationId(response.data.integration_id)

            }).catch((error) => {
                const errorMsg = (error.response && error.response.data && error.response.data.message) || "Something went wrong while creating the integration."
                toast.error(errorMsg)
                Bugsnag.notify(error);

            }).finally(() => {

                setIsSettingPreferences(false);

            });
    };

    const navigateToEmptyScreen = () => {
        history.push(`/organisation/${org_id}/integration/${integrationId}/content`)
    }

    const navigateToUpgradeScreen = () => {
        history.push(`/organisation/${org_id}/upgrade`)
    }

    const getOrganisations = (org_id) => {
        return API.get(`main`, `/organisation/${org_id}`);
    }

    useEffect(() => {
        getOrganisations(org_id).then((response) => {
            setOrg(response.data);
        }).finally(() => setIsLoading(false))
    }, [org_id]);

    if (isLoading || isSettingPreferences) {
        return (
            <div className="container">
                <div className="container-fluid mt-5 text-center">
                    {isSettingPreferences ? <h4 className="form-title">Please give us a moment to setup your account.</h4> : null}
                </div>
                <div className="container-fluid mt-5 text-center">
                    <Spinner color="#657B90" />
                </div>
            </div>
        );
    }

    if (isAccountReady) {
        return (
            <div className="container d-flex flex-column align-items-center">
                <div className="row d-flex justify-content-center">
                    <div className="col-8">
                        <div className="card-onboarding-success">
                            <img src={OnboardingSuccessImg} />
                            <div className="onboarding-content container-fluid pt-5 px-3 pb-3 text-center">
                                <h4 className="form-title">Your free account is ready <span><img style={{ width: "35px" }} src={PartyingFace} /></span></h4>
                                <p className="text">You have access to the very best features, all premium voices, unlimited previews along with 1000 words. Go ahead! start creating lifelike audio.</p>
                                <div className="row mt-4">
                                    <div className="col-12 justify-content-center">
                                        {/* <button onClick={navigateToUpgradeScreen} className="onboarding-button onboarding-button-outline">View all plans</button> */}
                                        <button onClick={navigateToEmptyScreen} className="onboarding-button onboarding-button-filled">Get started</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

    return (
        <div className='flex-grow-1 welcome--page'>
            <div className="container-fluid">
                <div className="container">
                    <div className="row my-5">
                        <div className="col-sm-12">
                            <p className='text-center subheading'>For best experience, tell us how you plan to use Listen2It</p>
                            <h3 className='text-center form-title'><b>What are you trying to create?</b></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="container my-5">
                    <div className="row my-5">
                        <div className="col-12 d-flex justify-content-center flex-wrap">
                            {displayCards.map((card, cardIndex) => {
                                return (<div key={`card-${cardIndex}`} onClick={() => updatePreference(card.value)} className='planning-use-card'>
                                    <div className='w-100 d-flex justify-content-center'><img src={card.img} /></div>
                                    <div className='w-100 text-center text' dangerouslySetInnerHTML={{ __html: card.title }}></div>
                                </div>)
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WelcomePage;
