import React, { useCallback, useEffect, useState } from "react";
import {Crisp} from "crisp-sdk-web"
import { useHistory, useParams, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import Info from '../../components/Info';
import PLANS from "../../constants/plans.json";
import Spinner from "../../components/Spinner";
import { SELECT_PLAN, VISITED_UPGRADE_PLAN } from "../../constants/hotjar-events";
import { useOrganisation } from "../../http-hooks/organisation/useOrganisation";
import { useSubscription } from "../../http-hooks/organisation/useSubscription";
import { addMixPanelEvent } from "../../store/actions";
import { useDispatch } from "react-redux";
import { returnCurrentIntegrationOfOrg } from "../../utils/General";

const classNames = require("classnames");

const plans = PLANS.filter(item => item.is_active).slice(1);

export default function ChangePlan() {
  const { org_id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch()

  const {isLoading, isError, data} = useOrganisation({org_id});

  const [isAnnualBillingCycle, setIsAnnualBillingCycle] = useState(false);

  useEffect(() => {
    window.hj("event", VISITED_UPGRADE_PLAN);
    dispatch(addMixPanelEvent({
			orgId: org_id, body: {
				eventName: "PAYMENT_VIEW_PRICING_PAGE",
				eventData: {
				}
			}
		}))
  }, []);

  if (isError) {
    return <></>
  }

  if (isLoading) {
    return (
      <div className="my-1 text-center">
        <Spinner color="#3d4852" />
      </div>
    )
  }

  const org = data.data;

  if (!org.plan_id) {
    history.push(`/organisation/${org_id}/plans`)
    return <></>
  }

  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <div className="row my-5">
            <div className="col-12">
              <h3 className="text-center">Choose plan</h3>
            </div>
            <div className="mt-2 col-12 row justify-content-center billing-cycle-switch">
              <h6 className="">Billing cycle: </h6>
              <span className={!isAnnualBillingCycle && 'selected-billing-cycle'}>Monthly</span>
              <div class="custom-control custom-switch ml-2">
                <input onChange={(e) => {
                  setIsAnnualBillingCycle(e.target.checked)
                }} type="checkbox" class="custom-control-input" id="is_published" name="is_published" checked={isAnnualBillingCycle} />
                <label class={`custom-control-label ${isAnnualBillingCycle && 'selected-billing-cycle'}`} for="is_published">Annual (Pay one month less)</label>
              </div>
            </div>
          </div>
          <PlansList org={org} isAnnualBillingCycle={isAnnualBillingCycle} />
        </div>
      </div>
    </>
  );
}

const PlansList = ({ org, isAnnualBillingCycle }) => {

  const { org_id } = useParams();
  const history = useHistory();
  const { search } = useLocation();
	const queryString = new URLSearchParams(search)
	const plan_id = queryString.get("plan_id")
  const {isLoading, data} = useSubscription({ org_id });
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false)

  const openUpgradeCard = useCallback(async (planId) => {

    try {
      const integrationId = await returnCurrentIntegrationOfOrg(org_id)
      const successURL = window.location.origin + `/organisation/${org_id}/integration/${integrationId}/billing`;
      // const successURL = `${}`
      console.log({successURL, integrationId})
      const failureURL = window.location.href
      const response = await API.get(
        `main`,
        `/organisation/${org_id}/create-upgrade-session?planId=${planId}&redirectURL=${successURL}&cancelURL=${failureURL}`,
        { headers: { "Content-Type": "application/json" } }
      );

      if (!response.success) {
        toast.error("Something went wrong in checkout!");
      }

      window.open(response.data.url, "_self")

    } catch (e) {
      console.log(e);
      toast.error("Something went wrong in checkout!");
    }
  }, [org_id])

  const onSelectPlan = useCallback((plan, subscription) => {
    window.hj("event", SELECT_PLAN);
    if (subscription.plan_id.indexOf('usd') !== -1) {
      openUpgradeCard(isAnnualBillingCycle ? plan.plan_id_usd_annual : plan.plan_id_usd);
    } else {
      openUpgradeCard(isAnnualBillingCycle ? plan.plan_id_inr_annual : plan.plan_id_inr);
    }
  }, [isAnnualBillingCycle, openUpgradeCard])

  useEffect(() => {
    if(plan_id && !isCheckoutLoading){
      setIsCheckoutLoading(true)
    }

    if(plan_id && data?.data){
      const planQuery = plans.find(p => {
        return (plan_id === p.plan_id_inr ||
          plan_id === p.plan_id_usd ||
          plan_id === p.plan_id_inr_annual ||
          plan_id === p.plan_id_usd_annual)
      })
      if(planQuery){
        onSelectPlan(planQuery, data.data)
      }else{
        setIsCheckoutLoading(false)
      }
    }
  }, [data, plan_id])

  if (isLoading || isCheckoutLoading) {
    return (
      <div className="my-1 text-center">
        <Spinner color="#3d4852" />
      </div>
    )
  }

  const subscription = data.data;
  let currentPlan;

  if (!subscription || !subscription.plan_id) {
    history.push(`/organisation/${org_id}/plans`)
    return <></>
  }

  if (org.subscription_status !== 'cancelled') {
    currentPlan = plans.filter((plan) => {
      return (
        plan.plan_id_usd === org.plan_id ||
        plan.plan_id_inr === org.plan_id ||
        plan.plan_id_usd_annual === org.plan_id ||
        plan.plan_id_inr_annual === org.plan_id
      );
    })[0];
  }

  return (
    <>
      <div className="row mt-5">
        {plans.map((plan) => {
          return (
            <div className="col-12 col-lg-8 col-xl-4 m-auto px-3">
              <div
                className={classNames("card", "card-pricing-summary", "upgrade-plan-page")}
              >
                <div className="card-body">
                  <p className="pricing-summary-title">
                    {plan.plan_title}
                  </p>
                  <p className="pricing-summary-sub-title">
                    {plan.plan_sub_title}
                  </p>
                  <div className="pricing-summary-price">
                    <span>${isAnnualBillingCycle ? plan.annual_plan_monthly_price : plan.price}</span>
                    <span id="pricing-summary-per-month">/month</span>
                  </div>
                  {isAnnualBillingCycle ? (
                    <div className="pricing-summary-annual-text">
                      <span>$ {plan.annual_price} Billed Annually</span>
                    </div>
                  ) : (
                    <div className="pricing-summary-annual-text">
                      <span>Billed Monthly</span>
                    </div>
                  )}
                  
                  {(subscription && subscription.status !== 'cancelled') && (org.plan_id === plan.plan_id_inr ||
                  org.plan_id === plan.plan_id_usd ||
                  org.plan_id === plan.plan_id_inr_annual ||
                  org.plan_id === plan.plan_id_usd_annual) ? (
                    <div className="d-grid gap-2">
                      <button
                        type="button"
                        disabled
                        className={`btn btn-outline-primary btn-block pricing-summary-cta`}
                      >
                        Current Plan
                      </button>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        onSelectPlan(plan, subscription);
                      }}
                      className="d-grid gap-2"
                    >
                      <span
                        className={`btn btn-outline-primary btn-block pricing-summary-cta`}
                      >
                        {currentPlan
                          ? (((plan.price > currentPlan.price) || (isAnnualBillingCycle && !org?.plan_id?.includes("annual")))
                            ? "Upgrade"
                            : "Downgrade")
                          : "Choose Plan"}
                      </span>
                    </div>
                  )}
                  <ul className="pricing-summary-points">
                    <li>{plan.words_count.toLocaleString()} Words / Month</li>
                  </ul>
                  <hr className="pricing-points-divider" />
                  <p className="fw-bold mb-3">Key Features</p>
                  <ul className="pricing-summary-points">
                    {plan.features.map((feature) => (
                      <li>{feature.text} {feature.help_text ? <Info text={feature.help_text} /> : null }</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="row my-5 align-items-center justify-content-center">
        <div className="col-md-9 text-center">
          <span style={{ fontSize: "24px", color: "#3D4852" }}>
            Do not fit into any of the plans? Chat with us
            <br /> and get an{" "}
            <span style={{ color: "#2D79F6" }}>Enterprise Plan</span>
          </span>
        </div>
        <div className="col-md-3">
          <button
            type="button"
            onClick={(e) => {
              Crisp.chat.open()
            }}
            className="btn btn-primary btn-block mt-3 mt-sm-0"
          >
            Talk to us
          </button>
        </div>
      </div>
    </>
  )
}
