import Bugsnag from "@bugsnag/js";
import { toast } from 'react-toastify';
import { call, put, takeEvery, select } from "redux-saga/effects";

import {
    FETCH_LANGUAGES_REQUESTED,
    UPDATE_AUDIO_PROFILE_REQUESTED
} from "../actionTypes"

import {
    fetchLanguagesService,
    updateAudioProfileService
} from "../../services/voice"

import {
    fetchLanguagesSuccess,
    fetchLanguagesFailure,
    updateAudioProfileSuccess,
    updateAudioProfileFailure,
    updateShowUpdateConfirmation,
    fetchIntegrationProfilesSuccess,
    updateDefaultAudioProfile
} from "../actions";

function* fetchLanguagesSaga(action) {
    try {
        const languages = yield call(fetchLanguagesService, action.payload);
        yield put(fetchLanguagesSuccess(languages))
    } catch (e) {
        yield put(fetchLanguagesFailure(e));
        Bugsnag.notify(e);
    }
}

function* updateAudioProfileSaga(action){
    try {
        const profile = yield call(updateAudioProfileService, action.payload);
        yield put(updateAudioProfileSuccess(profile))
        yield put(updateShowUpdateConfirmation(false))
        if(!action.payload.id){
            yield put(updateDefaultAudioProfile(action.payload.body))
        }
        
        // Updating the updated profile in frontend
        let newProfiles = yield select(state => state.integration.profiles)
        
        if(action.payload.id){
            const existing_profile = newProfiles.find(p => p.id === action.payload.id)
            let newProfile = Object.assign({}, existing_profile, action.payload.body)
            
            newProfiles = [newProfile, ...newProfiles.filter(p => p.id !== action.payload.id)]
        }

        yield put(fetchIntegrationProfilesSuccess(newProfiles))
        
        toast.success('Audio Profile has been updated successfully!');
    } catch (e) {
        yield put(updateAudioProfileFailure(e));
        yield put(updateShowUpdateConfirmation(false))
        toast.error("Something went wrong while saving the Audio Profile.");
        Bugsnag.notify(e);
    }
}

function* voiceSaga() {
    yield takeEvery(FETCH_LANGUAGES_REQUESTED, fetchLanguagesSaga);
    yield takeEvery(UPDATE_AUDIO_PROFILE_REQUESTED, updateAudioProfileSaga);
}

export default voiceSaga;
