import { API } from 'aws-amplify';
import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams, useHistory, useLocation } from "react-router-dom";
import Spinner from '../components/Spinner';
import { useGlobalStore } from '../zustand-store/useGlobalStore';

function CreateContent() {

	const { org_id, id } = useParams();
	const history = useHistory();
	const location = useLocation();

	const toggleUpgradeModal = useGlobalStore((state) => state.toggleUpgradeModal)

	const saveContent = useCallback(async () => {

        try {

			const resp = await API.post('main', `/organisation/${org_id}/integration/${id}/content/v2`, {
				headers: { "Content-Type": "application/json" },
				body : {
					title: "Untitled audio",
					slate_value: [],
					composer_settings: [],
					is_draft: true,
					...(location?.state?.voice && {voice: location.state.voice})
				}
			});

			if (!resp.success && resp.failure_type && resp.failure_type === 'NO_ENOUGH_AUDIOS') {
				history.replace(`/organisation/${org_id}/integration/${id}/content`);
				toggleUpgradeModal(true, {title: resp.message});
				return;
			}
	
			if (!resp.success) {
				toast.error("Something went wrong!!")
				history.replace(`/organisation/${org_id}/integration/${id}/content`);
				return;
			}
	
			history.replace(`/organisation/${org_id}/integration/${id}/content/${resp.data.content_id}`)

		} catch (e) {

			console.log(e);
			toast.error("Something went wrong!!")
			history.replace(`/organisation/${org_id}/integration/${id}/content`);
			
		}

    }, [org_id, id, history, location, toggleUpgradeModal])

	// set default slate value when new content is being created
	useEffect(() => {
		
		saveContent()

	}, [saveContent])

	return <Spinner color="#657B90" />
};

export default CreateContent;
