export const FETCH_CONTENTS_REQUESTED = "FETCH_CONTENTS_REQUESTED"

export const FETCH_CONTENTS_SUCCESS = "FETCH_CONTENTS_SUCCESS"

export const FETCH_CONTENTS_FAILURE = "FETCH_CONTENTS_FAILURE"

export const FETCH_CONTENTS_SEARCH_REQUESTED = "FETCH_CONTENTS_SEARCH_REQUESTED"

export const FETCH_CONTENTS_SEARCH_SUCCESS = "FETCH_CONTENTS_SEARCH_SUCCESS"

export const SET_CURRENT_AUDIO = "SET_CURRENT_AUDIO"

export const TOGGLE_CURRENT_AUDIO_PLAYING = "TOGGLE_CURRENT_AUDIO_PLAYING"

export const UPDATE_CURRENT_AUDIO_SEEK_VALUE = "UPDATE_CURRENT_AUDIO_SEEK_VALUE"

export const INITIALIZE_CURRENT_AUDIO_SUCCESS = "INITIALIZE_CURRENT_AUDIO_SUCCESS"

export const GENERATE_SRT_REQUESTED = "GENERATE_SRT_REQUESTED"

export const GENERATE_SRT_SUCCESS = "GENERATE_SRT_SUCCESS"

export const GENERATE_SRT_FAILURE = "GENERATE_SRT_FAILURE"

export const PING_SRT_REQUESTED = "PING_SRT_REQUESTED"

export const PING_SRT_SUCCESS = "PING_SRT_SUCCESS"

export const PING_SRT_FAILURE = "PING_SRT_FAILURE"

export const TOGGLE_EDITOR_TITLE_SETTINGS_MODAL = "TOGGLE_EDITOR_TITLE_SETTINGS_MODAL"

export const SET_CURRENT_AUDIO_DURATION = "SET_CURRENT_AUDIO_DURATION"

export const STOP_COMPOSER_PLAYER = "STOP_COMPOSER_PLAYER"