import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { API } from 'aws-amplify';
import { toast } from 'react-toastify';
import clean from '../../utils/Clean';
import { Modal } from 'react-bootstrap';
import downChevron from '../../assets/down_chevron.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import ExtraAudiosUpload from '../../components/integration/ExtraAudiosUpload';
import AudioPlayer from '../../components/AudioPlayer';
import PlayerPreview from '../../components/PlayerPreview';
import Spinner from '../../components/Spinner';
import ColorPicker from '../../components/ColorPicker';
import Bugsnag from "@bugsnag/js";
const classNames = require('classnames');

function Player(){

    const { org_id, id } = useParams();
    const [integration, setIntegration] = useState(null);
    const [audio, setAudio] = useState(null)
    const [organization, setOrganization] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isSavingDraft, setIsSavingDraft] = useState(false);
    const { register, watch, reset, getValues, setValue, errors, handleSubmit } = useForm();
    const [ languages, setLanguages ] = useState(null);
    const [ playerType, setPlayerType ] = useState("inline");
    const [ playerPosition, setPlayerPosition ] = useState("default");
    const watchAll = watch();
    const [show, setShow] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [savedSettings, setSavedSettings] = useState([]);
    const [draftName, setDraftName] = useState("");
    const [draftNameError, setDraftNameError] = useState(null);
    const [isFetchingPublishedSetting, setIsFetchingPublishedSetting] = useState(false);
    const [currentDraftName, setCurrentDraftName] = useState("")
    const [deleteDraftId, setDeleteDraftId] = useState(null)
    const [isDeletingDraft, setIsDeletingDraft] = useState(false)

    const draftNameRef = useRef(null)

    const openSaveDraftModal = () => {
        setShow(true)
    }

    const handleSaveDraftEnterPress = (event) => {
        if(event.key === 'Enter'){
            onSaveDraft()
        }
    }

    const onSubmit = data => {

        data = clean(data);
        data = {...integration.audio_player_settings, ...data, player_position : playerPosition, player_type : playerType}

        setIsUpdating(true);

        API.put(`main`, `/organisation/${org_id}/integration/${id}/audio_player_settings`,{body: clean(data), headers: {"Content-Type":"application/json"}}).then((response)=>{

            toast.success('Audio Player settings have been saved successfully!');

        }).catch((error)=>{

            toast.error("Something went wrong while saving the Audio Player settings.");
            Bugsnag.notify(error);

        }).finally(()=>{

            setIsUpdating(false);
            setShowConfirmation(false);

        });

    };

    const onPlayerTypeChange = (event) => {
        const newPlayerType = event.target.value
        setPlayerType(newPlayerType)
        if(newPlayerType === "floating"){
            setPlayerPosition("bottom-right")
        }else{
            setPlayerPosition("default")   
        }

    }

    const getIntegration = (org_id, id) => {

        return API.get(`main`, `/organisation/${org_id}/integration/${id}`);

    }

    const getOrganization = (org_id) => {

        return API.get(`main`, `/organisation/${org_id}`);

    }

    const getSettings = (id) => {

        API.get(`main`, `/organisation/${org_id}/integration/${id}/player_setting`).then((response)=>{

            setSavedSettings(response.data);

        });

    }

    const onSaveDraft = () => {

        if(draftName.length === 0){
            setDraftNameError("Name is required");
            return;
        }

        let data = clean(getValues());
        data.setting_name = draftName;

        setIsSavingDraft(true);

        API.post(`main`, `/organisation/${org_id}/integration/${id}/player_setting`,{body: clean(data), headers: {"Content-Type":"application/json"}})
            .then((response)=>{

            toast.success("Player settings have been saved as draft");
            getSettings(id);
            setShow(false);
            setDraftName("");

        }).catch((error)=>{

            toast.error("Something went wrong while saving the draft.");
            Bugsnag.error(error);

        }).finally(()=>{

            setIsSavingDraft(false);

        });

    }

    const onDeleteDraft = () => {
        const setting_id = deleteDraftId

        setIsDeletingDraft(true)
        API.del(`main`, `/organisation/${org_id}/integration/${id}/player_setting/${setting_id}`, { headers: {"Content-Type":"application/json"}})
            .then(()=>{

                setSavedSettings(savedSettings.filter(item => item.id !== setting_id));
                toast.success("Draft has been deleted.");

            }).catch((error)=>{

                toast.error("Something went wrong in deleting the draft.");
                Bugsnag.notify(error);

            }).finally(() => {
                setIsDeletingDraft(false)
                setDeleteDraftId(null)
            });

    }

    const onResetPublishSetting = () => {
        setCurrentDraftName("")
        setIsFetchingPublishedSetting(true);

        getIntegration(org_id, id).then((response)=>{

            setIntegration(response.data);
            reset(response.data.audio_player_settings);
            setPlayerType(response.data.audio_player_settings.player_type);

            toast.success("Successfully restored the last published setting.");

        }).catch((error)=>{

            toast.error("Something went wrong while restoring the published settings.")
            Bugsnag.notify(error);

        }).finally(()=>{

            setIsFetchingPublishedSetting(false)

        });
    }

    const restoreFromDraft = (setting) => {

        reset(setting);

        setCurrentDraftName(setting.setting_name)
        toast.success("Restored player setting from draft.");

    }

    const handleDeleteDraft = (id) => {
        setDeleteDraftId(id)
    }

    const getLanguages = () => {

        return API.get(`main`, `/voices`,{headers: {"Content-Type":"application/json"}});

    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <button type="button" className="btn--restore-voice my-3" ref={ref} onClick={(e) => { e.preventDefault(); onClick(e);}}>
            <span className="mr-5"> {children}</span>
            <img src={downChevron} className="down-chevron" alt="down chevron" />
        </button>
    ));

    useEffect(() => {
        if(show){
            draftNameRef.current.focus()
        }
    }, [show])

    useEffect(()=>{

        if(!languages){

            return;

        }

        getOrganization(org_id, id).then((response)=>{

            setOrganization(response.data)
    
        })

        getIntegration(org_id, id).then((response)=>{

            setIntegration(response.data);
            reset(response.data.audio_player_settings);
            setPlayerType(response.data.audio_player_settings.player_type);
            setPlayerPosition(response.data.audio_player_settings.player_position);

        }).finally(()=>{

            setIsLoading(false);

        });

        getSettings(id);

    },[languages]);

    useEffect(()=>{

        getLanguages().then((response)=>{

            setLanguages(JSON.parse(response.data));

        })

    },[org_id, id]);

    return(
        <React.Fragment>
            <h3 className="page-title">Audio Player</h3>
            <p className="page-subtitle">Configure how the audio player appears on your website or blog when you intergrate Listen2It</p>
            {
                ( savedSettings.length > 0 ) &&
                <>
                <Dropdown>
                    <Dropdown.Toggle as={CustomToggle}>
                        { currentDraftName.length ? currentDraftName : "Saved Drafts" }
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {
                            savedSettings.map((setting)=>{

                                return(
                                    <Dropdown.Item as="div">
                                       <div className="w-100 d-flex justify-content-between align-items-center">
                                       <span className="text-truncate" style={{maxWidth:'100px'}} onClick={()=>{restoreFromDraft(setting)}}>{setting.setting_name}</span>
                                        <svg  className="ml-2" style={{cursor: 'pointer'}} height="18px" onClick={()=>{handleDeleteDraft(setting.id)}} fill="none" stroke="#E94B30" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                        </svg>
                                       </div>
                                    </Dropdown.Item>
                                )

                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
                {
                    (isFetchingPublishedSetting)?
                    (
                        <div className="d-inline-block ml-sm-3">
                            <Spinner color="#3d4852" />
                        </div>
                    )
                    :
                    (
                        <span onClick={()=>{onResetPublishSetting()}} className="d-inline-block ml-sm-3 mb-3 mb-sm-0 text-muted" style={{borderBottom: '1px dashed #C8CED6', cursor: 'pointer'}}>Restore to published settings</span>
                    )
                }
                </>
            }
            {
                (isLoading)?
                (
                    <div className="my-1">
                        <Spinner color="#3d4852" />
                    </div>
                )
                :
                (
                    <div className="row">
                        <div className="col-xl-6 order-2 order-xl-1">
                            <form>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <span className="form-section-title">Basic Settings</span>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="form-group">
                                                    <label>Audio Player Type</label>
                                                    <select className={classNames("custom-select", { "is-invalid": errors.player_type})} name="player_type" ref={register({required: true})} onChange={onPlayerTypeChange}>
                                                        <option value="inline">Inline</option>
                                                        <option value="static">Static</option>
                                                        <option value="floating">Floating</option>
                                                    </select>
                                                    {
                                                        errors.player_type &&
                                                        <div className="invalid-feedback">
                                                            {
                                                                ("required"===errors.player_type.type) && "Language selection is required."
                                                            }
                                                        </div>
                                                    }
                                                    {
                                                        ("inline" === playerType) &&
                                                        <small className="form-text text-muted">The player will appear embedded in the content of the page.</small>
                                                    }
                                                    {

                                                        ("static" === playerType) &&
                                                        <small className="form-text text-muted">The player will appear always on top stuck to the bottom edge of the screen.</small>
                                                    }
                                                    {

                                                        ("floating" === playerType) &&
                                                        <small className="form-text text-muted">The player will appear as a minimized button at selected position.</small>
                                                    }
                                                </div>
                                                <div className={classNames("form-group", {"d-none": "floating" !== playerType})}>
                                                    <label>Player Position</label>
                                                    <select value={playerPosition} onChange={(e) => setPlayerPosition(e.target.value)} className={classNames("custom-select", { "is-invalid": errors.player_position})} name="player_position">
                                                        <option value="bottom-right">Bottom Right</option>
                                                        <option value="bottom-left">Bottom Left</option>
                                                    </select>
                                                    {
                                                        errors.player_position &&
                                                        <div className="invalid-feedback">
                                                            {
                                                                ("required"===errors.player_position.type) && "Language selection is required."
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mt-4">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <span className="form-section-title" style={{textTransform:"capitalize"}}>Player Settings</span>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="form-group">
                                                    <label>Player Color</label>
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <ColorPicker defaultColor={getValues('color')} onChange={(color)=>{setValue('color',color);}}/>
                                                            </span>
                                                        </div>
                                                        <input type="text" className="form-control" name="color" ref={register}/>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Player Text Color</label>
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <ColorPicker defaultColor={getValues('text_color')} onChange={(color)=>{setValue('text_color',color);}}/>
                                                            </span>
                                                        </div>
                                                        <input type="text" className="form-control" name="text_color" ref={register} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Player Background Color</label>
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <ColorPicker defaultColor={getValues('background_color')} onChange={(color)=>{setValue('background_color',color);}}/>
                                                            </span>
                                                        </div>
                                                        <input type="text" className="form-control" name="background_color" ref={register} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Player Title</label>
                                                    <input type="text" className="form-control" name="title" ref={register} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mt-4">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <span className="form-section-title">Other Settings</span>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="form-group d-flex flex-row justify-content-start align-items-center">
                                                    <input className="mr-2" type="checkbox" name="download_button" ref={register} />
                                                    <p className="m-0 font-bold">Download Audio Button</p>
                                                </div>
                                                <div className="form-group d-flex flex-row justify-content-start align-items-center">
                                                    <input className="mr-2" type="checkbox" disabled={(false === integration.gates.can_remove_widget_branding)} name="remove_branding" ref={register} />
                                                    <p className="m-0 font-bold">Remove Listen2It Branding </p>
                                                    {
                                                        (false === integration.gates.can_remove_widget_branding) &&
                                                        <Link to={`/organisation/${org_id}/upgrade`} className="ml-2 font-italic text-decoration-none">Upgrade</Link>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                {
                                    organization && organization.gates.can_access_extra_audios ? <>
                                        <ExtraAudiosUpload orgId={org_id} integrationId={id} integration={integration} onPlay={(audio) => {
                                            setAudio(() => null);
                                            setAudio(() => `${process.env.REACT_APP_AUDIO_BUCKET}/${audio}`)
                                        }} />
                                        {
                                            (audio) &&
                                            <div className="audio-player-wrapper">
                                                <AudioPlayer onClose={() => setAudio(null)} audio={audio} title={""} />
                                            </div>
                                        }
                                    </> : null
                                }
                                
                                

                                <div className="row mt-4">
                                    <div className="col-12 text-right">
                                        <Link to={`/organisation/${org_id}/integration/${id}/content`}>Cancel</Link>
                                        <button type="button" className="btn btn-outline-primary ml-3 mr-3" onClick={openSaveDraftModal}>Save as Draft</button>
                                        <button type="button" className="btn btn-primary btn-sm" onClick={() => setShowConfirmation(true)}>Publish Changes</button>
                                        
                                        <Modal show={show} onHide={()=>{setShow(false)}}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Save as Draft</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                    <div>
                                                        <div className="form-group">
                                                            <label>Give it a name</label>
                                                            <input ref={draftNameRef} type="text" className={classNames("form-control", { "is-invalid": draftNameError })} value={draftName} onChange={(event)=>{setDraftNameError(null);setDraftName(event.target.value)}} onKeyPress={(event) => handleSaveDraftEnterPress(event)} />
                                                            {
                                                                (draftNameError) &&
                                                                <div className="invalid-feedback">
                                                                    {draftNameError}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <button type="button" className="btn btn-outline-primary mr-3" onClick={()=>setShow(false)}>Cancel</button>
                                                {
                                                    (isSavingDraft)?
                                                    (
                                                        <button type="button" className="btn btn-primary">
                                                            <Spinner color="#FFF" />
                                                        </button>
                                                    )
                                                    :
                                                    (
                                                        <button type="button" className="btn btn-primary" onClick={onSaveDraft}>Save</button>
                                                    )
                                                }
                                            </Modal.Footer>
                                        </Modal>

                                        <Modal show={showConfirmation} onHide={()=>{setShowConfirmation(false)}}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Publish Changes</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>          
                                                <p style={{fontWeight : 500}}>Are you sure you want to publish the new player settings?</p>
                                                <p>The new player settings would go live on all pages where the Listen2It player of this integration has been embedded.</p>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <button type="button" className="btn btn-outline-primary mr-3" onClick={()=>setShowConfirmation(false)}>No&nbsp;</button>
                                                {
                                                    (isUpdating)?
                                                    (
                                                        <button type="button" className="btn btn-primary">
                                                            <Spinner color="#FFF" />
                                                        </button>
                                                    )
                                                    :
                                                    (
                                                        <button type="button" onClick={handleSubmit(onSubmit)} className="btn btn-primary">Yes</button>
                                                    )
                                                }
                                            </Modal.Footer>
                                        </Modal>

                                        <Modal show={deleteDraftId} onHide={()=>{setDeleteDraftId(null)}}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Delete Draft</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>          
                                                <p>Are you sure you want to delete this draft?</p>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <button type="button" className="btn btn-outline-primary mr-3" onClick={()=>setDeleteDraftId(null)}>No&nbsp;</button>
                                                {
                                                    (isDeletingDraft)?
                                                    (
                                                        <button type="button" className="btn btn-primary">
                                                            <Spinner color="#FFF" />
                                                        </button>
                                                    )
                                                    :
                                                    (
                                                        <button type="button" onClick={onDeleteDraft} className="btn btn-primary">Yes</button>
                                                    )
                                                }
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-xl-4 mb-4 order-1 order-xl-2">
                            <PlayerPreview type={playerType} color={watchAll.color} position={playerPosition} title={watchAll.title} text_color={watchAll.text_color} background_color={watchAll.background_color} />
                        </div>
                    </div>
                )
            }
        </React.Fragment>
    );
}

export default Player;
