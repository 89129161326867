import create from "zustand";
import { useAIVoicesStore } from "./useAIVoicesStore";

export interface keyable {
    [key: string]: any  
}

interface SavedProfilesState {
    selectedProfile: keyable | null
    expandedCardIndex: number | null
    showEditProfile: boolean
    showChangeVoice: boolean
    setExpandedProfile: (index: number | null) => void
    setSelectedProfile: (profile: keyable | null) => void
    toggleEditProfile: () => void
    toggleChangeVoice: () => void
    closeModal: () => void
}

export const useSavedProfilesStore = create<SavedProfilesState>()((set) => ({
    selectedProfile: null,
    expandedCardIndex: null,
    showEditProfile: false,
    showChangeVoice: false,
    setExpandedProfile: (index) => set(() => ({
        expandedCardIndex: index
    })),
    setSelectedProfile: (profile) => set(() => {

        if (!profile) {
            return {
                profile: null
            }
        }

        const languages = useAIVoicesStore.getState().languages;
        const language = languages[profile.lang_code];
        const voice = language.voices.find(item => item.code === profile.voice && item.engine === profile.engine)
        useAIVoicesStore.setState({
            selectedVoice: voice
        })

        return ({
            selectedProfile: profile,
        })
    }),
    toggleEditProfile: () => set((state) => ({
        showEditProfile: !state.showEditProfile
    })),
    toggleChangeVoice: () => set((state) => ({
        showChangeVoice: !state.showChangeVoice
    })),
    closeModal: () => set(() => ({
        showEditProfile: false
    }))
}))
