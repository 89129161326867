import {
    FETCH_ORGANISATION_REQUESTED,
    FETCH_ORGANISATION_SUCCESS,
    FETCH_ORGANISATION_FAILURE,
    FETCH_ORGANISATION_USAGE_SUCCESS,
    FETCH_ORGANISATION_USAGE_REQUESTED,
    UPDATE_SIDE_BAR_PAGE_TYPE
} from "../actionTypes"
  
const initialState = {
    organisation : null,
    usage: null,
    isLoading : false,
    isError : false,
    sideBarPageType : "MENU" // Can be MENU, INTEGRATIONS, CREATE_INTEGRATION
};
  
export default function organisationReducer(state = initialState, action) {
    const { type } = action;
  
    switch (type) {
        case UPDATE_SIDE_BAR_PAGE_TYPE: {
            return {
                ...state,
                sideBarPageType : action.payload
            }
        }

        case FETCH_ORGANISATION_SUCCESS:
            return {
            ...state,
            organisation : action.payload,
            isLoading : false,
            isError : false
            };

        case FETCH_ORGANISATION_FAILURE:
            return {
            ...state,
            isLoading : false,
            isError : true
            };
    
        case FETCH_ORGANISATION_REQUESTED:
            return {
              ...state,
              isLoading : true
            };

        case FETCH_ORGANISATION_USAGE_SUCCESS: {
            return {
                ...state,
                usage: action.payload,
            }
        }

        case FETCH_ORGANISATION_USAGE_REQUESTED: {
            return {
                ...state,
            }
        }

      default:
        return state;
    }
}
  