import React, { useEffect, useState } from "react";
import { Crisp } from "crisp-sdk-web";
import "../styles/App.scss";
import { AuthState } from "@aws-amplify/ui-components";
import ProtectedRoutes from "./ProtectedRoutes";
import logo from "../assets/logo-full-white.png";
import logoBlack from "../assets/logo-full.png";
import { API, Auth } from "aws-amplify";
import SignIn from "./auth/SignIn";
import SignUp from "./auth/SignUp";
import ForgotPassword from "./auth/ForgotPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthSidebarImage from "../assets/auth-sidebar-vector.svg"
import { BrowserRouter } from "react-router-dom";
import { useVerifyCheckout } from "../hooks/useVerifyCheckout";
import Spinner from "../components/Spinner";
import { UpgradePlanModalV2 } from "../components/plans/UpgradePlanModal";
// import { useDispatch } from "react-redux";
// import { addMixPanelEvent } from "../store/actions";
Crisp.configure(process.env.REACT_APP_CRISP_WEBSITE_ID, {
  autoload: false
});

function App() {
  let query = new URLSearchParams(new URL(window.location.href).search);
  const verifyingCheckout = useVerifyCheckout();

  let states = ["signin", "signup"];

  // const dispatch = useDispatch()
  const [authState, setAuthState] = React.useState("signin");
  const [user, setUser] = React.useState();
  const [canAccessChatSupport, setCanAccessChatSupport] = useState(false);

  const onChangeAuthState = (state) => {
    setAuthState(state);
  };

  const onSetUser = (user) => {
    setUser(user);
  };

  useEffect(() => {
    Crisp.load()

    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then((user) => {
        // dispatch(addMixPanelEvent({
        //   body: {
        //     eventName: "CREATE_CDP_USER",
        //     eventData: { extra_data, location, access_token }
        //   }
        // }))
        setAuthState("signedin");
        setUser(user);
      })
      .catch((err) => {
        if (query.has("state") && states.includes(query.get("state"))) {
          setAuthState(query.get("state"));
          return;
        }

        setAuthState("signin");
      });

  }, []);

  useEffect(() => {
    if (user) {

      // Set User Details on Crisp
      if (user?.attributes?.email && (user?.attributes?.given_name || user?.attributes?.family_name)) {
        Crisp.user.setEmail(user.attributes.email);
        Crisp.user.setNickname([user.attributes.given_name, user.attributes.family_name].join(" "));
      }

      API.get("main", "/organisation").then((response) => {
        if (response.data.length > 0) {
          const org = response.data[0];
          const orgId = org.PK.split("#")[1];

          window.hj('identify', orgId, {
            orgId: orgId
          });

          API.get("main", `/organisation/${orgId}`).then((response) => {
            const gates = response.data.gates;

            if (gates.can_access_chat_support) {

              Crisp.chat.show()

              setCanAccessChatSupport(true);
            }
          });
        }
      });
    }
  }, [user]);

  if (verifyingCheckout) {
    return (
      <Spinner color="#657B90" />
    )
  }

  return authState === AuthState.SignedIn && user ? (
    <>
      <ProtectedRoutes />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
    </>
  ) : (
    <div className="container-fluid container-fluid--auth">
      <div style={{ minHeight: "100vh" }} className="row auth-main-row">
        <div className="col-md-4 d-flex flex-column col--auth-sidebar justify-content-between">
          <div className="row">
            <div className="col-12 py-3">
              <div className="d-flex align-items-center">
                <img className="logo mr-1" src={logo} alt="logo" />
              </div>
            </div>
          </div>

          <div className="col--auth-sidebar-content d-flex flex-column justify-content-center">
            <h1>Everything you need to make the perfect voiceover</h1>

            <ul>
              <li>Start for free - no credit card required</li>
              <li>900+ premium voices in 140+ languages</li>
              <li>Unlimited previews and exports</li>
              <li>Full commercial rights</li>
              <li>Advanced editing studio</li>
            </ul>

            <img src={AuthSidebarImage} className="auth-sidebar-img" />
          </div>

        </div>
        <div className="col-12 d-flex flex-column col--auth-sidebar-logo-dekstop justify-content-between">
          <div className="row">
            <div className="col-12 py-3">
              <div className="d-flex align-items-center">
                <img className="logo mr-1" src={logoBlack} alt="logo" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8 d-flex flex-column flex-grow-1 col--auth-main justify-content-center">
          <BrowserRouter>
            {authState === AuthState.SignIn && (
              <SignIn
                onChangeAuthState={onChangeAuthState}
                onSetUser={onSetUser}
              />
            )}
            {authState === AuthState.SignUp && (
              <SignUp
                onChangeAuthState={onChangeAuthState}
                onSetUser={onSetUser}
              />
            )}
            {authState === AuthState.ForgotPassword && (
              <ForgotPassword onChangeAuthState={onChangeAuthState} />
            )}
          </BrowserRouter>

        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
    </div>
  );
}

export default App;
