import React, { useState, useEffect } from 'react';
import {Crisp} from "crisp-sdk-web"
import Highlight from 'react-highlight.js';
import { useParams, Link } from "react-router-dom";
import { API } from 'aws-amplify';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Spinner from '../../components/Spinner';
import copyIcon from '../../assets/copy.svg';
import ideaBuldIcon from '../../assets/idea-bulb.svg';
import helpIcon from '../../assets/help.svg';

import wordpress from '../../assets/wordpress.svg';
import ghost from '../../assets/ghost.svg';
import medium from '../../assets/medium.svg';
import webflow from '../../assets/webflow.svg';
import blogger from '../../assets/blogger.svg';
import android from '../../assets/android.svg';
import ios from '../../assets/ios.svg';
import react from '../../assets/react.svg';
import angular from '../../assets/angular.svg';
import wix from '../../assets/wix.svg';
import squarespace from '../../assets/squarespace.svg';
import weebly from '../../assets/weebly.svg';
import joomla from '../../assets/joomla.svg';
import hubspot from '../../assets/hubspot.svg';

const STACKS = [
  {
    logo: wordpress,
    brand: 'Wordpress',
    link: 'how-to-add-audio-to-your-wordpress-blog-posts'
  },
  {
    logo: ghost,
    brand: 'Ghost',
    link: 'how-to-add-audio-to-your-ghost-blog-post'
  },
  {
    logo: medium,
    brand: 'Medium',
    link: 'how-to-add-audio-to-your-medium-blog-posts-using-listen2it'
  },
  {
    logo: webflow,
    brand: 'Webflow',
    link: 'how-to-add-audio-to-your-webflow-blog-posts'
  },
  {
    logo: blogger,
    brand: 'Blogger',
    link: 'how-to-add-audio-to-your-blogger-blog-post'
  },
  {
    logo: android,
    brand: 'Android',
    link: 'how-to-add-audio-to-your-wordpress-blog-posts'
  },
  {
    logo: ios,
    brand: 'Ios',
    link: 'how-to-add-audio-to-your-wordpress-blog-posts'
  },
  {
    logo: react,
    brand: 'React',
    link: 'how-to-add-audio-to-your-react-blog-posts-using-listen2it'
  },
  {
    logo: angular,
    brand: 'Angular',
    link: 'how-to-add-audio-to-your-angular-blog-posts-using-listen2it'
  },
  {
    logo: wix,
    brand: 'Wix',
    link: 'how-to-add-audio-to-your-wix-blog-post'
  },
  {
    logo: squarespace,
    brand: 'Squarespace',
    link: 'how-to-add-audio-to-your-squarespace-blog-posts'
  },
  {
    logo: weebly,
    brand: 'Weebly',
    link: 'how-to-add-audio-to-your-weebly-blog-posts-using-listen2it'
  },
  {
    logo: joomla,
    brand: 'Joomla',
    link: 'how-to-add-audio-to-your-joomla-blog-posts-using-listen2it'
  },
  {
    logo: hubspot,
    brand: 'Hubspot',
    link: 'how-to-add-audio-to-your-hubspot-blog-posts-using-listen2it'
  },
];

function Installation() {

  const { org_id, id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [integration, setIntegration] = useState(null);

  const getIntegration = (org_id, id) => {

    return API.get(`main`, `/organisation/${org_id}/integration/${id}`);

  }

  useEffect(() => {

    getIntegration(org_id, id).then((response) => {

      setIntegration(response.data);

    }).finally(() => {

      setIsLoading(false);

    });

  }, [org_id, id]);

  const EMBED_CODE = `
    <div id="l2it-audio-player"></div>                    
    <script>
        function initListen2It() {
            Listen2It.init({
                url: window.location.href,
                org_id: '${org_id}',
                integration_id: '${id}',
                container: '#l2it-audio-player'
            });
        }
        function initialize(i,t){var n;i.getElementById(t)?initListen2It():((n=i.createElement("script")).id=t,n.async=!0,n.src="${process.env.REACT_APP_WIDGET_URL}",n.onload=initListen2It,i.head.appendChild(n))}function initiateCall(){initialize(document,"listen2it-widget")}window.addEventListener?window.addEventListener("load",initiateCall,!1):window.attachEvent("load",initiateCall,!1);
    </script>
  `;

  return (
    <div>
      <h3 className="page-title">Install Listen2It on your website and/or app</h3>
      <p className="page-subtitle">Follow the instruction below to sync with Listen2It and start offering audio versions of your content. Don’t forget to customize your <Link to={`/organisation/${org_id}/integration/${id}/voice`}>voice</Link> and <Link to={`/organisation/${org_id}/integration/${id}/player`}>player</Link> before installation.</p>
      {
        (isLoading) ?
          (
            <div className="my-1">
              <Spinner color="#3d4852" />
            </div>
          )
          :
          (
            <>
              <div style={{
                border: '1px solid #F0F1F2',
                borderRadius: 6,
                padding: 36
              }} className="bg-white">
                <div className="form-group">
                  <label>API Key</label>
                  <div className="input-group input-group--search">
                    <input type="text" className="form-control border-right-0" value={integration.api_key} readOnly />
                    <div className="input-group-append border-left-0">
                      <span className="input-group-text">
                        <CopyToClipboard text={integration.api_key} onCopy={() => toast.success("Copied to clipboard")}>
                          <img src={copyIcon} style={{ cursor: 'pointer' }} alt="search icon" />
                        </CopyToClipboard>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Organisation ID</label>
                  <div className="input-group input-group--search">
                    <input type="text" className="form-control border-right-0" value={org_id} readOnly />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <CopyToClipboard text={org_id} onCopy={() => toast.success("Copied to clipboard")}>
                          <img src={copyIcon} style={{ cursor: 'pointer' }} alt="search icon" />
                        </CopyToClipboard>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Project ID</label>
                  <div className="input-group input-group--search">
                    <input type="text" className="form-control border-right-0" value={id} readOnly />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <CopyToClipboard text={id} onCopy={() => toast.success("Copied to clipboard")}>
                          <img src={copyIcon} style={{ cursor: 'pointer' }} alt="search icon" />
                        </CopyToClipboard>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <>
                <p className="mt-5 mb-1 font-weight-bold">What platform is your site/app on?</p>
                <p>Choose a platform to view detailed installation instructions for adding Listen2It to your website and/or app</p>
                <span className="mt-4" style={{ fontSize: 14, borderBottom: '1px dashed #C4C4C4' }}>Do you have multiple websites and/or apps? Learn how to integrate Listen2It across them.</span>
                <div className="mt-4 mx-0 row installation-grid">
                  {STACKS.map((stack) => (
                    <a style={{ textDecoration: 'none', color: 'inherit' }} href={`${process.env.REACT_APP_ZOHO_KB_BASE_URL}/portal/en/kb/articles/${stack.link}`} target="_blank" rel="noopener noreferrer">
                      <div className="platform-tile bg-white d-flex flex-column justify-content-center align-items-center">
                        <img className="row" src={stack.logo} alt={stack.brand} />
                        <div className="row text-center">{stack.brand}</div>
                      </div>
                    </a>
                  ))}
                  <a style={{ textDecoration: 'none', color: 'inherit' }} href={`${process.env.REACT_APP_ZOHO_KB_BASE_URL}/portal/en/kb/articles/rest-api`} target="_blank" rel="noopener noreferrer">
                    <div className="platform-tile bg-white d-flex flex-column justify-content-center align-items-center">
                      <span className="font-weight-bold" style={{ fontSize: 18, color: 'black' }}>{'{REST:API}'}</span>
                      <div className="row text-center">REST API</div>
                    </div>
                  </a>
                </div>
              </>

              <div className="mt-5">
                <div className="mb-4 d-flex align-items-center" style={{ backgroundColor: '#E8EFFD', borderRadius: 6, padding: '6px 10px', color: '#3D4852', fontWeight: 600, fontSize: 14, maxWidth: 'fit-content' }}>
                  <img className="mr-3" src={ideaBuldIcon} alt="" />
                  Don’t see your platform listed? Install manually with the Universal Code below.
                </div>
                <p className="mt-3 mb-1 font-weight-bold">Universal Embed Code</p>
                <p>
                  Add the following script to your website’s HTML code as per below:
                  <ol>
                    <li>For Inline Player: Add it where you would like the player to appear.</li>
                    <li>For Floating and Static Player: Add it before {'</body>'} tag in your HTML code.</li>
                  </ol>
                  {`\n`}
                  Add the script to every page you would like to add Listen2It to.
                </p>
                <p></p>
                <div className="well mb-5 position-relative">
                  <CopyToClipboard text={EMBED_CODE} onCopy={() => toast.success("Copied to clipboard")}>
                    <div
                      className="position-absolute row justify-content-center"
                      style={{
                        right: 32,
                        top: 16,
                        gap: 4,
                        borderRadius: 6,
                        padding: '2px 16px',
                        background: '#E6EFFE',
                        cursor: 'pointer',
                      }}
                    >
                      <img src={copyIcon} style={{ cursor: 'pointer' }} alt="search icon" />
                      <span>Copy</span>
                    </div>
                  </CopyToClipboard>
                  <Highlight className="pt-3 pt-sm-0" language="javascript">
                    {EMBED_CODE}
                  </Highlight>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-around mb-5" style={{ padding: 36, gap: '1rem', borderRadius: 6, backgroundColor: '#E6EFFE' }}>
                  <img src={helpIcon} alt="" />
                  <p className="font-weight-bold m-0 text-center">Having trouble with integration?</p>
                  <button type="button" className="btn btn-primary px-5 py-1" onClick={(e) => {
                    Crisp.chat.open()
                  }}>Contact Us</button>
                </div>
              </div>
            </>
          )
      }
    </div >
  );
}

export default Installation;
