
export const fetchCountry = async () => {

    const locationReq = await fetch("https://ipgeolocation.abstractapi.com/v1/?api_key=7c4a5e0655ac45fb8d2cef66ee0ac9f9");
    const location = await locationReq.json();

    return location.country;

}

export const fetchLocation = async () => {

    const locationReq = await fetch("https://ipgeolocation.abstractapi.com/v1/?api_key=7c4a5e0655ac45fb8d2cef66ee0ac9f9");
    const location = await locationReq.json();

    return location;

}
