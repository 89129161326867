import { call, put, takeEvery } from "redux-saga/effects";
import { fetchContentService } from "../../services/edit_content";
import { fetchContentFailure, fetchContentSuccess, updateAudioComposerSettings, updateCurrentAudio, updateAudioComposerIsContentChanged, updateBlockAudios } from "../actions";
import { FETCH_CONTENT_BLOCKS_REQUESTED, FETCH_CONTENT_REQUESTED } from "../actionTypes";

function* fetchContentSaga(action) {
    try {
        const response = yield call(fetchContentService, action.payload);

        const content = response.data;

        if (action.type === FETCH_CONTENT_BLOCKS_REQUESTED) {
            yield put(updateBlockAudios(content.block_audios))
            return;
        }
        
        yield put(fetchContentSuccess(content))

        if (action.payload.shouldNotLoad) {
            return;
        }

        if (content.composer_settings){
            yield put(updateAudioComposerSettings(content.composer_settings))
        }
        if(content.full_audio_url){
            yield put(updateCurrentAudio(content.full_audio_url))
        }
        yield put(updateAudioComposerIsContentChanged(!!content.is_content_changed))
        
        
    } catch (e) {
        yield put(fetchContentFailure(e));
    }
}

function* editContentSaga() {
    yield takeEvery([FETCH_CONTENT_REQUESTED, FETCH_CONTENT_BLOCKS_REQUESTED], fetchContentSaga);
}

export default editContentSaga;
