import React, { useEffect, useState } from 'react';
import only from '../../utils/Only';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Auth, API } from 'aws-amplify';
import { toast } from 'react-toastify';
import cameraIcon from '../../assets/camera.svg';
import editAlt from '../../assets/edit-alt.svg';
import Spinner from '../../components/Spinner';
import Bugsnag from "@bugsnag/js";
const classNames = require('classnames');


export default function EditProfile() {

    const { register, reset, errors, handleSubmit } = useForm();
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isUploadingAvatar, setIsUploadingAvatar] = useState(false);


    const onSubmit = data => {

        data = only(data, ["given_name", "family_name"])

        setIsUpdating(true);

        Auth.updateUserAttributes(user, data)
            .then(() => {

                toast.success('Profile updated successfully!');

            }).catch((error) => {

                toast.error("Something went wrong in updating the profile.")
                Bugsnag.notify(error);

            }).finally(() => {

                setIsUpdating(false);

            });

    };

    const onFileUpload = async (event) => {

        if (event.target.files.length) {

            let file = event.target.files[0];

            setIsUploadingAvatar(true);

            try {

                const signedResponse = await API.get(`main`, `/avatar/signed-url`, {
                    queryStringParameters: {
                        file_name: file.name,
                        content_type: file.type
                    }
                });

                const requestOptions = {
                    method: 'PUT',
                    body: file,
                    redirect: 'follow'
                };

                const uploadURL = signedResponse.data.signed_url;
                const url = signedResponse.data.url;

                await fetch(uploadURL, requestOptions)

                await Auth.updateUserAttributes(user, {
                    "custom:avatar": url
                });

                const userResponse = await Auth.currentAuthenticatedUser({
                    bypassCache: true
                });

                setUser(userResponse);
                reset(userResponse.attributes);

                toast.success('Avatar updated successfully!');


            } catch (error) {

                toast.error("Something went wrong in updating the avatar.");
                Bugsnag.notify(error);

            } finally {

                setIsUploadingAvatar(false);

            }

        }

    }

    const getUser = () => {

        Auth.currentAuthenticatedUser({
            bypassCache: true
        }).then(user => {

            setUser(user);
            reset(user.attributes);

        }).catch(err => {

            toast.error("Something went wrong.")
            Bugsnag.notify(err)

        }).finally(() => {

            setIsLoading(false)

        })

    }

    useEffect(() => {

        getUser();

    }, []);


    return (
        (isLoading) ?
            (
                <div className="edit-profile-shimmer shine" />
            )
            :
            (
                user?.attributes ? (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <span className="form-section-title mb-4 mb-md-0 d-block">General</span>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="form-group">
                                            {
                                                (isUploadingAvatar) ?
                                                    (
                                                        <div className="edit-avatar--shimmer shine" />
                                                    )
                                                    :
                                                    (
                                                        <div className="edit-avatar" style={{ backgroundImage: `url(${(user && user.attributes.hasOwnProperty("custom:avatar")) ? (encodeURI(user.attributes["custom:avatar"])) : ("")})` }}>
                                                            {
                                                                (!user || !user.attributes.hasOwnProperty("custom:avatar")) &&
                                                                <img className="placeholder" src={cameraIcon} alt="avatar" />
                                                            }
                                                            <input type="file" id="avatar" onChange={onFileUpload} />
                                                            <label htmlFor="avatar" className="form-control-edit-avatar">
                                                                <img src={editAlt} alt="edit avatar icon" />
                                                            </label>
                                                        </div>
                                                    )
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label>First Name</label>
                                            <input type="text" name="given_name" className={classNames("form-control", { "is-invalid": errors.given_name })} ref={register({ required: true })} />
                                            {
                                                errors.given_name &&
                                                <div className="invalid-feedback">
                                                    {
                                                        ("required" === errors.given_name.type) && "First Name field is required."
                                                    }
                                                </div>
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label>Last Name</label>
                                            <input type="text" name="family_name" className={classNames("form-control", { "is-invalid": errors.family_name })} ref={register({ required: true })} />
                                            {
                                                errors.family_name &&
                                                <div className="invalid-feedback">
                                                    {
                                                        ("required" === errors.family_name.type) && "Last Name field is required."
                                                    }
                                                </div>
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="email" name="email" className="form-control" ref={register} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12 text-right">
                                <Link to={`/`}><button className="btn btn-outline-primary mr-3" type="button">Cancel</button></Link>
                                {
                                    (isUpdating) ?
                                        (
                                            <button className="btn btn-primary" style={{ width: '145px' }} type="button">
                                                <Spinner color="#fff" />
                                            </button>
                                        )
                                        :
                                        (
                                            <button className="btn btn-primary" style={{ width: '145px' }} type="submit">Save Changes</button>
                                        )
                                }
                            </div>
                        </div>
                    </form>
                ) : null
            )
    );

}
