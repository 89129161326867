import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
    toggleAudioComposerDeleteBlockModal, 
    removeAudioComposerBlock,
} from "../../store/actions";

function DeleteAudioBlockModal() {

    const dispatch = useDispatch()
    const {deleteBlockModalShow : show, currentBlock : currentBlockProperties} = useSelector(state => state.audio_composer)

    const hideModal = () => {
        dispatch(toggleAudioComposerDeleteBlockModal(false))
    }

    return (
        <Modal show={show} onHide={() => hideModal()}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Audio Block</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure want to delete the Audio Block?</p>
                <p><b>This action is irreversible.</b></p>
            </Modal.Body> 
            <Modal.Footer>
                <button type="button" className="btn btn-outline-primary mr-3" onClick={() => hideModal()}>Cancel</button>
                <button onClick={() => {
                    dispatch(removeAudioComposerBlock(currentBlockProperties))
                    setTimeout(() => hideModal(), 100)
                }} type="button" className="btn btn-danger">Delete</button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteAudioBlockModal;
