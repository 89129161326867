import { API } from "aws-amplify"
import { toast } from "react-toastify";

export const putContentService = ({ orgId, integrationId, contentId, body }) => {
    return API.put(
        'main',
        `/organisation/${orgId}/integration/${integrationId}/content/${contentId}/v2`,
        {
            body,
            headers: { "Content-Type": "application/json" }
        },
    ).then(resp => {

        if (!resp.success && resp.failure_type && resp.failure_type === 'CONTENT_TOO_LONG') {
            toast.error(resp.message);
        }

        return resp.data

    }).catch((error) => {

        console.log('error', error)



        return error

    });
}

export const postContentService = ({ orgId, integrationId, body }) => {
    return API.post(
        'main',
        `/organisation/${orgId}/integration/${integrationId}/content/v2`,
        {
            body,
            headers: { "Content-Type": "application/json" }
        }
    ).then(resp => {

        return resp.data

    }).catch((error) => {

        console.log('error', error)

        return error

    });
}