import React from 'react';
import {Crisp} from "crisp-sdk-web"
import helpIcon from '../../assets/help.svg';

const HavingTroubleCard = () => {
    return (
        <div className="d-flex flex-column align-items-center justify-content-around mb-5" style={{ padding: 36, gap: '1rem', borderRadius: 6, backgroundColor: '#E6EFFE' }}>
            <img src={helpIcon} alt="" />
            <p className="font-weight-bold m-0 text-center">Having trouble using Listen2It?</p>
            <button type="button" className="btn btn-primary px-5 py-1" onClick={(e) => {
                Crisp.chat.open()
            }}>Contact Us</button>
        </div>
    );
};

export default HavingTroubleCard;
