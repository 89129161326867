import React, { useState } from 'react';
import { ResponsiveContainer, LineChart, CartesianGrid, Tooltip, Line, XAxis, YAxis } from 'recharts';
import moment from 'moment';
import downChevron from '../../assets/dropdown_icon.svg';

export default function Trend({ metric }){

    const [metricToShow, setMetricToShow] = useState('listens');
    const { data: queryData, isLoading } = metric;

    if (isLoading) {
        return <></>
    }

    let data = queryData.data.map((d)=>{
        return {
            Date: moment(d.binned_timestamp).format('DD MMM'),
            Value: d[metricToShow]
        }
    });

    return(
        <React.Fragment>
            <div className="row">
                <div className="col-12 text-right">
                   <div className="custom-select--trend">
                        <select className="custom-select" onChange={(event)=>{setMetricToShow(event.target.value)}}>
                            <option value="listens">Listens</option>
                            <option value="impressions">Impressions</option>
                        </select>
                        <img className="down-chevron-icon" src={downChevron} alt="icon" />
                   </div>
                </div>
            </div>
            <ResponsiveContainer width="100%" height={350} >
                <LineChart data={data} margin={{left:-35, right:5, top:0, bottom:0}}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="Date" stroke="#A3A3A3" />
                    <YAxis stroke="#A3A3A3" />
                    <Tooltip />
                    <Line type="monotone" dataKey="Value" stroke="#1F78B4" />
                </LineChart>
            </ResponsiveContainer>
        </React.Fragment>
    )
}
