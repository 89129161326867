import {
    ADD_AUDIO_COMPOSER_TRACK,
    REMOVE_AUDIO_COMPOSER_TRACK,
    ADD_AUDIO_COMPOSER_BLOCK,
    ADD_AUDIO_COMPOSER_AUDIO_BLOCK,
    REMOVE_AUDIO_COMPOSER_BLOCK,
    UPDATE_AUDIO_COMPOSER_BLOCK,
    UPDATE_CURRENT_AUDIO_COMPOSER_BLOCK,
    UPDATE_AUDIO_COMPOSER_SETTINGS,
    UPDATE_SLATE_VALUE,
    UPDATE_BLOCK_AUDIOS,
    PUT_BLOCK_AUDIO,
    TOGGLE_AUDIO_COMPOSER_AUDIO_UPLOAD_MODAL,
    TOGGLE_AUDIO_COMPOSER_GENERATE_MODAL,
    UPDATE_AUDIO_COMPOSER_GENERATE_CURRENT_STEP,
    UPDATE_TITLE,
    SHOW_SAVING_IN_DRAFT_LABEL,
    EXIT_EDITOR,
    UPDATE_AUDIO_COMPOSER_IS_CONTENT_CHANGED,
    UPDATE_AUDIO_COMPOSER_SECTIONS,
    TOGGLE_DOWNLOAD_FILE,
    UPDATE_EDITOR_DRAFT_VALUE,
    TOGGLE_AUDIO_COMPOSER_DELETE_BLOCK_MODAL,
    TOGGLE_AUDIO_COMPOSER_BLOCK_ACTIONS,
    UPDATE_AUDIO_COMPOSER_EXTRA_SETTINGS,
    UPDATE_AUDIO_COMPOSER_BLOCK_MOVE_RIGHT,
    UPDATE_AUDIO_COMPOSER_BLOCK_MOVE_LEFT,
    UPDATE_COMPOSER_TTS_BLOCKS,
    INITIALIZE_AUDIO_COMPOSER_REQUESTED,
    INITIALIZE_AUDIO_COMPOSER_SUCCESS,
    GENERATE_COMPOSER_SETTINGS,
    TOGGLE_NO_CREDITS_MODAL,
    TOGGLE_INCLUDE_TITLE,
    UPDATE_VOICE_FOR_ALL_BLOCKS,
    TOGGLE_IS_AUDIO_GENERATING,
    SET_AUDIO_DOWNLOAD_MODE,
    APPLY_EDITOR_TITLE_SETTINGS,
    TOGGLE_AUDIO_COMPOSER_PLAYER
} from "../actionTypes"

export const toggleAudioComposerPlayer = (data) => {
    return {
        type : TOGGLE_AUDIO_COMPOSER_PLAYER,
        payload : data
    }
}

export const addAudioComposerTrack = (data) => {
    return {
        type: ADD_AUDIO_COMPOSER_TRACK,
        payload: data,
    };
};

export const removeAudioComposerTrack = (data) => {
    return {
        type: REMOVE_AUDIO_COMPOSER_TRACK,
        payload: data,
    };
};

export const updateAudioComposerSections = (data) => {
    return {
        type: UPDATE_AUDIO_COMPOSER_SECTIONS,
        payload: data,
    };
}

export const addAudioComposerBlock = (data) => {
    return {
        type: ADD_AUDIO_COMPOSER_BLOCK,
        payload: data,
    };
};

export const removeAudioComposerBlock = (data) => {
    return {
        type: REMOVE_AUDIO_COMPOSER_BLOCK,
        payload: data,
    };
};

export const updateAudioComposerBlock = (data) => {
    return {
        type: UPDATE_AUDIO_COMPOSER_BLOCK,
        payload: data,
    };
};

export const updateCurrentAudioComposerBlock = (data) => {
    return {
        type: UPDATE_CURRENT_AUDIO_COMPOSER_BLOCK,
        payload: data,
    };
}

export const updateAudioComposerSettings = (data) => {
    return {
        type : UPDATE_AUDIO_COMPOSER_SETTINGS,
        payload : data
    }
}

export const updateSlateValue = (data, contentChanged = true) => {
    return {
        type: UPDATE_SLATE_VALUE,
        payload: data,
        contentChanged: contentChanged
    }
}

export const updateBlockAudios = (data) => {
    return {
        type: UPDATE_BLOCK_AUDIOS,
        payload: data
    }
}

export const putBlockAudio = (data) => {
    return {
        type: PUT_BLOCK_AUDIO,
        payload: data
    }
}

export const toggleAudioComposerAudioUploadModal = (data) => {
    return {
        type : TOGGLE_AUDIO_COMPOSER_AUDIO_UPLOAD_MODAL,
        payload : data
    }
}

export const toggleAudioComposerGenerateModal = (data) => {
    return {
        type : TOGGLE_AUDIO_COMPOSER_GENERATE_MODAL,
        payload : data
    }
}

export const updateAudioComposerGenerateCurrentStep = (data) => {
    return {
        type : UPDATE_AUDIO_COMPOSER_GENERATE_CURRENT_STEP,
        payload : data
    }
}

export const updateTitle = (data) => {
    return {
        type: UPDATE_TITLE,
        payload: data
    }
}

export const showSavingInDraftLabel = (data) => {
    return {
        type: SHOW_SAVING_IN_DRAFT_LABEL,
        payload: data
    }
}

export const exitEditor = (data) => {
    return {
        type: EXIT_EDITOR,
        payload: data
    }
}

export const updateAudioComposerIsContentChanged = (data) => {
    return {
        type : UPDATE_AUDIO_COMPOSER_IS_CONTENT_CHANGED,
        payload : data
    }
}

export const toggleDownloadFile = (data) => {
    return {
        type: TOGGLE_DOWNLOAD_FILE,
        payload: data,
    }
}

export const setAudioDownloadMode = (data) => {
    return {
        type: SET_AUDIO_DOWNLOAD_MODE,
        payload: data
    }
}

export const updateEditorDraftValue = () => {
    return {
        type : UPDATE_EDITOR_DRAFT_VALUE,
        payload : {}
    }
}

export const toggleAudioComposerDeleteBlockModal = (data) => {
    return {
        type : TOGGLE_AUDIO_COMPOSER_DELETE_BLOCK_MODAL,
        payload : data
    }
}

export const toggleAudioComposerBlockActions = (data) => {
    return {
        type : TOGGLE_AUDIO_COMPOSER_BLOCK_ACTIONS,
        payload : data
    }
}

export const updateAudioComposerExtraSettings = (data) => {
    return {
        type : UPDATE_AUDIO_COMPOSER_EXTRA_SETTINGS,
        payload : data
    }
}

export const updateAudioComposerBlockMoveLeft = (data) => {
    return {
        type : UPDATE_AUDIO_COMPOSER_BLOCK_MOVE_LEFT,
        payload : data
    }
}

export const updateAudioComposerBlockMoveRight = (data) => {
    return {
        type : UPDATE_AUDIO_COMPOSER_BLOCK_MOVE_RIGHT,
        payload : data
    }
}

export const addAudioComposerAudioBlock= (data) => {
    return {
        type : ADD_AUDIO_COMPOSER_AUDIO_BLOCK,
        payload : data
    }
}

export const updateComposerTTSBlocks = (data) => {
    return {
        type: UPDATE_COMPOSER_TTS_BLOCKS,
        payload: data
    }
}

export const initializeAudioComposerRequested = () => {
    return {
        type : INITIALIZE_AUDIO_COMPOSER_REQUESTED,
        payload : {}
    }
}

export const initializeAudioComposerSuccess = () => {
    return {
        type : INITIALIZE_AUDIO_COMPOSER_SUCCESS,
        payload : {}
    }
}

export const generateComposerSettings = (data) => {
    return {
        type: GENERATE_COMPOSER_SETTINGS,
        payload: data,
    }
}

export const toggleNoCreditsModal = (data) => {
    return {
        type: TOGGLE_NO_CREDITS_MODAL,
        payload: data,
    }
}

export const toggleIncludeTitle = (data) => {
    return {
        type: TOGGLE_INCLUDE_TITLE,
        payload: data,
    }
}

export const applyEditorTitleSettings = (data) => {
    return {
        type : APPLY_EDITOR_TITLE_SETTINGS,
        payload : data
    }
}

export const updateVoiceForAllBlocks = (data) => {
    return {
        type: UPDATE_VOICE_FOR_ALL_BLOCKS,
        payload: data,
    }
}

export const toggleIsAudioGenerating = (data) => {
    return {
        type : TOGGLE_IS_AUDIO_GENERATING,
        payload : data
    }
}