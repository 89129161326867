import { API } from 'aws-amplify';
import React, { useState, useEffect } from 'react';
import {useParams, useHistory} from "react-router-dom"
import { toast } from 'react-toastify';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Content from "./Content"
import UploadAudio from '../components/UploadAudio';
import AudioUploadIcon from "../assets/content/upload-audio.svg"


function ContentVoiceOver(){
    const { org_id, id } = useParams();
    const history = useHistory()
    const [canUploadAudio, setCanUploadAudio] = useState(false);
    const [canCreateAudio, setCanCreateAudio] = useState(false);
    const [selectedAudio, setSelectedAudio] = useState(null);
    const [showUploadAudio, setShowUploadAudio] = useState();

    const displayNotAllowMessage = () => {
        toast.error("You are not allowed to perform this operation");
    }

    const createAudioHandler = () => {
        if(canCreateAudio){
            return history.push(`/organisation/${org_id}/integration/${id}/content/new`)
        }

        displayNotAllowMessage()
    }

    const uploadAudioHandler = () => {
        if(canCreateAudio && canUploadAudio){
            setShowUploadAudio(true);
            setSelectedAudio(null);
            return
        }

        displayNotAllowMessage()
    }

    useEffect(() => {

        const getIntegration = () => {

            return API.get(`main`, `/organisation/${org_id}/integration/${id}`);
    
        }

        getIntegration().then((response)=>{
            const integration = response.data;

            if(true === integration.gates.can_upload_audio){
                setCanUploadAudio(true);
            }

            if (true === integration.gates.can_create_audio) {
                setCanCreateAudio(true);
            }

        });

    },[org_id, id]);

    return(
        <React.Fragment>
            <div className="d-flex w-100">
                <h3 className="page-title flex-grow-1">AI Voiceovers</h3>
                <div>
                <OverlayTrigger placement="bottom" overlay={
                    <Tooltip>Upload Audio</Tooltip>
                }>
                    <img onClick={uploadAudioHandler} style={{cursor : "pointer"}} src={AudioUploadIcon} className="mr-2" />
                </OverlayTrigger>
                <Button onClick={createAudioHandler} variant="primary">Create Audio</Button>
                </div>
                
            </div>

            <UploadAudio
                orgId={org_id}
                integrationId={id}
                show={showUploadAudio}
                selectedAudio={selectedAudio}
                onSuccess={() => {

                    setSelectedAudio(null);
                    window.location.reload();

                }} 
                onHide={() => {

                    setShowUploadAudio(false);
                    setSelectedAudio(null)

                }}
            />
            
            <Content contentType="voice_over" setSelectedAudio={(val) => setSelectedAudio(val)} setShowUploadAudio={(val) => setShowUploadAudio(val)} />
        </React.Fragment>
    );
}

export default ContentVoiceOver;