import {
    FETCH_LANGUAGES_SUCCESS,
    UPDATE_VOICE_SETTINGS_FILTER,
    UPDATE_VOICES,
    UPDATE_VOICE,
    UPDATE_SHOW_DELETE_CONFIRMATION_MODAL,
    UPDATE_SHOW_UPDATE_CONFIRMATION_MODAL,
    UPDATE_AUDIO_PROFILE_REQUESTED,
    UPDATE_AUDIO_PROFILE_SUCCESS,
    UPDATE_AUDIO_PROFILE_FAILURE,
    UPDATE_SHOW_EDITOR_VOICE_PROFILES,
    SET_SHOW_SAVE_AUDIO_PROFILE_MODAL,
    UPDATE_VOICE_CONFIGURATION,
    UPDATE_LANGUAGE,
    UPDATE_SELECTED_AUDIO_PROFILE,
    SET_IS_DEFAULT_PROFILE
} from "../actionTypes"
  
const initialState = {
    languages : {},
    voices : [],
    voice : null,
    selectedAudioProfile: null,
    isDefaultProfile: false,
    language: null,
    configuration: {
        volume: 0,
        speed: 105,
        pitch: 'default',
        style: null
    },
    filters : {
        language : null,
        gender : null,
        quality : null,
        style: null,
        isPitchDisabled : false,
        isBreathingDisabled : false,
        isStyleDisabled : false
    },
    showDeleteConfirmation : false,
    showUpdateConfirmation : false,
    showEditorVoiceProfiles: false,
    showSaveAudioProfileModal : false
};
  
export default function VoiceReducer(state = initialState, action) {
    const { type } = action;

    switch (type) {
        case FETCH_LANGUAGES_SUCCESS:
            return {
            ...state,
            languages : action.payload,
            };
    
        case UPDATE_VOICE_SETTINGS_FILTER:
            return {
              ...state,
              filters : {
                ...state.filters,
                [action.payload.key] : action.payload.value,
              }
            };

        case UPDATE_VOICES:
            return {
                ...state,
                voices : action.payload,
            };

        case UPDATE_VOICE_CONFIGURATION:
            return {
                ...state,
                configuration: {
                    ...state.configuration,
                    [action.payload.key]: action.payload.value
                }
            }
        case SET_IS_DEFAULT_PROFILE:
            return {
                ...state,
                isDefaultProfile: action.payload
            };
        
        case UPDATE_VOICE:
            return {
                ...state,
                voice : action.payload,
            };

        case UPDATE_SELECTED_AUDIO_PROFILE:
            return {
                ...state,
                selectedAudioProfile : action.payload,
            };

        case UPDATE_LANGUAGE:
            return {
                ...state,
                language : action.payload,
            };

        case UPDATE_SHOW_DELETE_CONFIRMATION_MODAL:
            return {
                ...state,
                showDeleteConfirmation : action.payload
            }
        
        case UPDATE_SHOW_UPDATE_CONFIRMATION_MODAL:
            return {
                ...state,
                showUpdateConfirmation : action.payload
            }

        case UPDATE_AUDIO_PROFILE_REQUESTED:
            return {
                ...state,
                isAudioProfileUpdating : true
            }

        case UPDATE_AUDIO_PROFILE_SUCCESS:
            return {
                ...state,
                isAudioProfileUpdating : false,
                showUpdateConfirmation : false,
            }

        case UPDATE_AUDIO_PROFILE_FAILURE:
            return {
                ...state,
                isAudioProfileUpdating : false,
                showUpdateConfirmation : false
            }

        case UPDATE_SHOW_EDITOR_VOICE_PROFILES:
            return {
                ...state,
                showEditorVoiceProfiles : action.payload
            }
        case SET_SHOW_SAVE_AUDIO_PROFILE_MODAL:
            return {
                ...state,
                showSaveAudioProfileModal : action.payload
            }
  
      default:
        return state;
    }
}
  