import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { applyEditorTitleSettings, toggleEditorTitleSettingsModal } from '../../../store/actions';

const UpdateEditorTitleModal = () => {

    const dispatch = useDispatch()

    const editorTitle = useSelector(state => state.audio_composer.title);
    const includeTitle = useSelector(state => state.audio_composer.includeTitle);


    const [title, setTitle] = useState(editorTitle)
    const [isIncludeTitle, setIsIncludeTitle] = useState(includeTitle)

    const clearModal = () => {
        dispatch(toggleEditorTitleSettingsModal(false))
    }

    const applySettings = () => {
        dispatch(applyEditorTitleSettings({ includeTitle: isIncludeTitle, title }))
        dispatch(toggleEditorTitleSettingsModal(false))
    }

    return (
        <>
            <div style={{ opacity: 1, top: 50, left: "250px", zIndex: 100 }} onClick={(e) => e.stopPropagation()} className='hovering-toolbar-popup'>
                <div className="pronunciation-popup-original">
                    <label>Title: </label>
                    <input type="text" value={title} onChange={e => setTitle(e.target.value)} />
                </div>

                <div className="pronunciation-popup-replace-with">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" checked={isIncludeTitle} onChange={e => setIsIncludeTitle(e.target.checked)} />
                        <label className="form-check-label">Add title to audio</label>
                    </div>

                </div>

                <div className='hovering-toolbar-popup-footer'>
                    <button onClick={clearModal}>Cancel</button>
                    <button onClick={applySettings}>Apply</button>
                </div>
            </div>
        </>
    )

}

export default UpdateEditorTitleModal;