import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import Spinner from '../../components/Spinner';
import { API } from "aws-amplify";
import HavingTroubleCard from './HavingTroubleCard';
import APIKeysSection from './APIKeysSection';

function Listen2ItAPI() {

    const { org_id, id } = useParams();

    const [integration, setIntegration] = useState(null);
    const [isLoadingIntegration, setIsLoadingIntegration] = useState(true);
    const [isLoadingOrg, setIsLoadingOrg] = useState(true);
    const [org, setOrg] = useState(null);

    const getOrganisation = (org_id) => {
        return API.get(`main`, `/organisation/${org_id}`);
    }

    const getIntegration = (org_id, id) => {
        return API.get(`main`, `/organisation/${org_id}/integration/${id}`);
    }

    useEffect(() => {

        if (null === org) {
            return;
        }

        if (false === org.gates?.can_access_public_api) {
            return
        }

        getIntegration(org_id, id).then(response => {

            const integration = response.data;

            setIntegration(integration);
            setIsLoadingIntegration(false)

        });
    }, [org]);

    useEffect(() => {

        getOrganisation(org_id).then((response) => {
            setOrg(response.data);
        }).finally(() => {
            setIsLoadingOrg(false)
        });

    }, [org_id])

    return (
        <React.Fragment>
            <div>
                <h3 className="page-title">Listen2It API</h3>
                <p className="page-subtitle">Build custom integrations with your website or app</p>
                {
                    (isLoadingOrg) ?
                        (
                            <div className="my-1">
                                <Spinner color="#3d4852" />
                            </div>
                        )
                        :
                        (
                            (true === org.gates.can_access_public_api) ?
                                (
                                    (isLoadingIntegration) ?
                                        (
                                            <div className="my-1">
                                                <Spinner color="#3d4852" />
                                            </div>
                                        )
                                        :
                                        (
                                            <>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <p>
                                                            With our APIs you can build custom integrations with Listen2It. You can fetch, create, edit and disable audios using APIs.
                                                        </p>
                                                        <ul className="mt-4" style={{
                                                            listStyleType: 'none',
                                                            paddingLeft: 0,
                                                            marginBottom: 0,
                                                        }}>
                                                            <li style={{ marginBottom: 8 }}>
                                                                <a href={process.env.REACT_APP_PUBLIC_API_URL}>API Documentation {'>'}</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <APIKeysSection orgId={org_id} id={id} integration={integration} />
                                                <HavingTroubleCard />
                                            </>
                                        )
                                )
                                :
                                (
                                    <div className="text-center mt-5 bg-white rounded p-5 shadow-sm">
                                        <h5 className="font-weight-bold">Upgrade to get access to the Listen2It API</h5>
                                        <p>Using Listen2It API, you can build custom integrations and easily fetch, create, edit and disable audios. Upgrade to get access to the API.</p>
                                        <Link to={`/organisation/${org_id}/upgrade`} className="btn btn-primary">View Plans</Link>
                                    </div>
                                )
                        )
                }
            </div>
        </React.Fragment>
    );
}

export default Listen2ItAPI;
