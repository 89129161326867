import React, { useEffect, useState ,useRef } from 'react';
import {Crisp} from "crisp-sdk-web"
import { useDispatch, useSelector } from 'react-redux';
import logo from '../assets/logo.svg';
import { NavLink, Link, useParams, useHistory } from "react-router-dom";
import { Button } from 'react-bootstrap';
import Spinner from './Spinner';
import { API } from 'aws-amplify';
import { FREE_AND_PREVIEW_PLANS, PREVIEW_PLANS } from '../constants/constants';
import { UPGRADE_DASHBOARD } from '../constants/hotjar-events';
import AIVoicesIcon from '../assets/menu/ai-voices.svg'
import APIIcon from '../assets/menu/api.svg'
import PodcastsIcon from '../assets/menu/podcasts.svg'
import AudioArticlesIcon from '../assets/menu/audio-articles.svg'
import TickIcon from '../assets/menu/menu-tick.svg'
import VoiceOversIcon from '../assets/menu/voice-overs.svg'
import RightChevronIcon from '../assets/menu/right-chevron.svg'
import DownChevronIcon from '../assets/menu/down-chevron.svg'
import CaretIcon from '../assets/menu/caret.svg'
import PlanBillingIcon from '../assets/menu/plan-billing.svg'
import SupportIcon from '../assets/menu/support.svg'
import HelpCenterIcon from '../assets/menu/help-center.svg'
import RequestFeatureIcon from '../assets/menu/request-feature.svg'
import ProjectSettingsIcon from '../assets/menu/project-settings.svg'
import { createIntegrationRequested, fetchIntegrationRequested, updateSideBarPageType } from '../store/actions';
import { useGlobalStore } from '../zustand-store/useGlobalStore';

const classNames = require('classnames');

function SideBar() {
    const dispatch = useDispatch()
    const sidebarRef = useRef()
    const pageTypeRef = useRef("MENU")
    const history = useHistory();
    const { org_id, id } = useParams();
    const [integrations, setIntegrations] = useState([]);
    const [expandable, setExpandable] = useState(null)
    const [childExpandable, setChildExpandable] = useState(null)
    const [integrationName, setIntegrationName] = useState("")
    const toggleUpgradeModal = useGlobalStore((state) => state.toggleUpgradeModal)

    const {organisation, sideBarPageType : pageType} = useSelector((state) => state.organisation);
    const { integration, newIntegration, isLoading, isNewIntegrationLoading } = useSelector((state) => state.integration);

    const getIntegrations = () => {

        return API.get(`main`, `/organisation/${org_id}/integration`);

    }

    const updatePageType = (val) => {
        dispatch(updateSideBarPageType(val))
        pageTypeRef.current = val
    }

    useEffect(() => {
        setIntegrationName("")

        getIntegrations().then((response) => {
            setIntegrations(response.data)
        });

        dispatch(fetchIntegrationRequested(org_id, id))

        // Handling clicking outside the sidebar menu takes back menu to the menu state
        const fn = function(e){
            const boundingClient = sidebarRef.current.getBoundingClientRect()
            const x = e.clientX
            const y = e.clientY
            if((pageTypeRef.current !== "MENU") && !((x > boundingClient.x) && (x < (boundingClient.x+boundingClient.width)) && (y > boundingClient.y) && (y < (boundingClient.y+boundingClient.height)))){
                // If outside, then change to menu items
                updatePageType("MENU")
            }
        }

        document.addEventListener("click", fn)
        return () => {
            document.removeEventListener("click", fn)
        }

    }, [id]);

    const Expandable = ({ icon, label, children, defaultOpen = false }) => {
        const [isOpen, setIsOpen] = useState(defaultOpen);

        return (
            <li className='flex-column'>
                <div style={{ cursor: 'pointer' }} className="d-flex align-items-center" onClick={() => { setIsOpen(!isOpen) }}>
                    {icon}
                    <span className="align-middle mr-1 flex-grow-1">{label}</span>
                    <img className="mr-0" height="10" src={isOpen ? DownChevronIcon : RightChevronIcon} alt="dropdown icon" />
                </div>
                <ul className={classNames({ "d-none": !isOpen }, "menu-child")} style={{ listStyleType: 'none', padding: 0 }}>
                    {children}
                </ul>
            </li>
        );
    }

    const ExpandableChild = ({ label, children, defaultOpen = false }) => {
        const [isOpen, setIsOpen] = useState(defaultOpen);

        return (
            <li className='flex-column'>
                <div style={{ cursor: 'pointer' }} className="d-flex align-items-center" onClick={() => { setIsOpen(!isOpen) }}>
                    <span className="align-middle mr-1 flex-grow-1">{label}</span>
                    <img className="mr-0" height="8" src={isOpen ? DownChevronIcon : RightChevronIcon} alt="dropdown icon" />
                </div>
                <ul className={classNames({ "d-none": !isOpen }, "menu-child-1")} style={{ listStyleType: 'none', padding: 0 }}>
                    {children}
                </ul>
            </li>
        );
    }

    const handleProdcampRoadmap = function () {
        const token = localStorage.getItem('l2it-prodcamp-token')
        window.open(`https://listen2it.prodcamp.com?token=${token}`)
    }

    const openMenu = (match, type) => {
        if(!match){
            return false;
        }

        setExpandable(type)
        return true
    }

    const openDeepMenu = (match, childType, type) => {
        if(!match){
            return false;
        }

        setExpandable(type)
        setChildExpandable(childType)
        return true
    }

    useEffect(() => {
        if (newIntegration && !isNewIntegrationLoading) {
            history.push(`/organisation/${org_id}/integration/${newIntegration.id}/content`)
        }
    }, [newIntegration, isNewIntegrationLoading])

    return (
        <React.Fragment>
            
            <div className="d-flex flex-column flex-grow-1" ref={sidebarRef}>
                <div className="current-project">

                    <div onClick={() => updatePageType(pageType === "INTEGRATIONS" ? "MENU" : "INTEGRATIONS")} className="d-flex align-items-center flex-grow-1">

                        <img className="logo mr-1" src={logo} alt="logo" />
                        {
                            (!isLoading && integration) ? <span className="project-name-title">{integration.integration_name}</span> : <Spinner color="#48525B" />
                        }
                        <img height="20" src={CaretIcon} />
                    </div>
                </div>

                {
                    pageType === "INTEGRATIONS" ? <div className="integration-menu-items">
                        <ul className="sub-section">
                            {integrations.map((integration, i) => {
                                return <li key={`integration-menu-${i}`}>
                                    <div className="text-center">
                                        <NavLink to={`/organisation/${org_id}/integration/${integration.SK.split('#')[1]}/content`} activeClassName="active">
                                            {integration.integration_name}
                                        </NavLink>
                                    </div>
                                    <span style={{ cursor: "pointer" }} onClick={() => history.push(`/organisation/${org_id}/integration/${integration.SK.split('#')[1]}/general`)}><img src={ProjectSettingsIcon} width="13" height="13" /></span>
                                </li>
                            })}
                            <li>
                                <Button onClick={() => updatePageType("CREATE_INTEGRATION")} variant="outline-primary" className="upgrade-button">
                                    + New Project
                                </Button>
                            </li>
                        </ul>

                    </div> : ((pageType === "CREATE_INTEGRATION") ? (
                        <div className="create-integration-container">
                            <div className="form-group">
                                <label>Project Name</label>
                                <input type="text" name="integration_name" value={integrationName} onChange={e => setIntegrationName(e.target.value)} className="form-control" />
                            </div>
                            <div className="form-group d-flex">
                                <Button onClick={() => updatePageType("INTEGRATIONS")} variant="outline-primary" className="cancel-button">
                                    Cancel
                                </Button>
                                <Button onClick={() => dispatch(createIntegrationRequested(org_id, { integration_name: integrationName }))} variant="primary" className="upgrade-button">
                                    {isNewIntegrationLoading ? <Spinner color="#FFFFFF" /> : "Create"}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className="menu-items">
                            <ul className="sub-section">

                                {[...FREE_AND_PREVIEW_PLANS].includes(organisation?.plan_id) ? (
                                    <li className="mt-0">
                                        <div style={{ background: "#FFFFFF", padding: 8, border: "1px solid #2D79F6", borderRadius: 5 }}>
                                            <div>
                                                <img className="mr-1" src={TickIcon} height="8" />
                                                <span style={{ fontWeight: "normal", fontSize: 12 }}>All premium voices</span>
                                            </div>
                                            <div>
                                                <img className="mr-1" src={TickIcon} height="8" />
                                                <span style={{ fontWeight: "normal", fontSize: 12 }}>Unlimited Downloads</span>
                                            </div>
                                            <div>
                                                <img className="mr-1" src={TickIcon} height="8" />
                                                <span style={{ fontWeight: "normal", fontSize: 12 }}>Unlimited Storage</span>
                                            </div>
                                            <button onClick={() => {
                                                window.hj("event", UPGRADE_DASHBOARD);
                                                toggleUpgradeModal(true)
                                            }} className="btn menu-upgrade-button">Upgrade Now</button>
                                        </div>
                                    </li>
                                ) : null}

                                <li>
                                    <NavLink to={`/organisation/${org_id}/integration/${id}/content`} activeClassName="active" reloadDocument>
                                        <img src={VoiceOversIcon} width="18" height="18" />
                                        Voiceovers
                                    </NavLink>
                                </li>

                                <Expandable icon={<img src={AudioArticlesIcon} width="18" height="18" />} label="Audio Articles" defaultOpen={expandable === "AudioArticles"}>
                                    <li><NavLink isActive={(match) => openMenu(match, "AudioArticles")} to={`/organisation/${org_id}/integration/${id}/content-audio-articles`} activeClassName="active" reloadDocument>All Audios</NavLink></li>

                                    <ExpandableChild label="Analytics" defaultOpen={childExpandable === "Analytics"}>
                                        <li><NavLink isActive={(match) => openDeepMenu(match, "Analytics", "AudioArticles")} to={`/organisation/${org_id}/integration/${id}/analytics_overview`} activeClassName="active">Overview</NavLink></li>
                                        <li><NavLink isActive={(match) => openDeepMenu(match, "Analytics", "AudioArticles")} to={`/organisation/${org_id}/integration/${id}/content_analytics`} activeClassName="active">Audio Level</NavLink></li>
                                    </ExpandableChild>

                                    <ExpandableChild label="Integration" defaultOpen={childExpandable === "Integration"}>
                                        <li><NavLink isActive={(match) => openDeepMenu(match, "Integration", "AudioArticles")} to={`/organisation/${org_id}/integration/${id}/wordpress`} activeClassName="active">WordPress</NavLink></li>
                                        <li><NavLink isActive={(match) => openDeepMenu(match, "Integration", "AudioArticles")} to={`/organisation/${org_id}/integration/${id}/cms`} activeClassName="active">Other CMS</NavLink></li>
                                    </ExpandableChild>

                                    <li><NavLink isActive={(match) => openMenu(match, "AudioArticles")} to={`/organisation/${org_id}/integration/${id}/player`} activeClassName="active">Audio Player</NavLink></li>
                                </Expandable>

                                <li>
                                    <NavLink to={`/organisation/${org_id}/integration/${id}/api`} activeClassName="active">
                                        <img src={APIIcon} width="18" height="18" />
                                        API
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/organisation/${org_id}/integration/${id}/podcast`} activeClassName="active">
                                        <img src={PodcastsIcon} width="18" height="18" />
                                        Podcasts
                                    </NavLink>
                                </li>

                                <Expandable icon={<img src={AIVoicesIcon} width="18" height="18" />} label="AI Voices" defaultOpen={expandable === "AIVoices"}>
                                    <li><NavLink isActive={(match) => openMenu(match, "AIVoices")} to={`/organisation/${org_id}/integration/${id}/voice`} activeClassName="active">All Voices</NavLink></li>
                                    <li><NavLink isActive={(match) => openMenu(match, "AIVoices")} to={`/organisation/${org_id}/integration/${id}/profiles`} activeClassName="active">Saved Profiles</NavLink></li>
                                </Expandable>

                            </ul>

                        </div>))
                }

                <div className="menu-fixed-bottom">

                    <Link className="text-decoration-none" to={`/organisation/${org_id}/integration/${id}/billing`}>
                        <div className="menu-row mb-2">
                            <img width="17" className="mr-2" src={PlanBillingIcon} alt="logo" />
                            <span>Plan & Billing</span>
                            {organisation && FREE_AND_PREVIEW_PLANS.includes(organisation.plan_id) && (
                                <span style={{ fontSize: 9, color: "#ffffff" }} className="badge badge-primary ml-3">Upgrade</span>
                            )}

                        </div>
                    </Link>
                    <a className="text-decoration-none" href="#" onClick={() => Crisp.chat.open()} >
                        <div className="menu-row mb-2">
                            <img width="17" className="mr-2" src={SupportIcon} alt="logo" />
                            <span>Support</span>
                        </div>
                    </a>
                    <Link className="text-decoration-none" target="_blank" to={{ pathname: "https://support.getlisten2it.com/portal/en/kb" }}>
                        <div className="menu-row mb-2">
                            <img width="17" className="mr-2" src={HelpCenterIcon} alt="logo" />
                            <span>Help Center</span>
                        </div>
                    </Link>
                    <a href="#" className="text-decoration-none" onClick={() => handleProdcampRoadmap()}>
                        <div className="menu-row mb-2">
                            <img width="17" className="mr-2" src={RequestFeatureIcon} alt="logo" />
                            <span>Request Feature</span>
                        </div>
                    </a>
                </div>
            </div>

        </React.Fragment>
    );
}

export default SideBar;
