import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { toggleEditorVoicePickerModal } from "../../store/actions";

import dropdownIcon from '../../assets/down_chevron_primary.svg';
import { StylePicker } from "./ProsodyTools/StylePicker";
import { PitchPicker } from "./ProsodyTools/PitchPicker";
import { AddPause } from "./ProsodyTools/AddPause";
import { Pipe } from "./Pipe";
import { MemoizeImage } from "./Modals/MemoizeImage";

export const EditorToolbar = ({ org_id, id, contentId, setShowCustomPauseModal, setBlockProsodySettings }) => {

    const dispatch = useDispatch();

    const { showVoicePickerModal: showVoicePicker } = useSelector(state => state.edit_content);
    const selectedAudioProfile = useSelector((state) => state.voice.selectedAudioProfile);
    const voice = useSelector((state) => state.voice.voice);
    const language = useSelector((state) => state.voice.language);
    const currentBlock = useSelector(state => state.audio_composer.currentBlock);
    const editor = useSelector(state => state.audio_composer.editor);

    const [showProsodySettings, setShowProsodySettings] = useState(true);

    const [showSpeed, setShowSpeed] = useState(false);
    const speedButtonRef = useRef(null);
    const speedSliderRef = useRef(null);
    const [speed, setSpeed] = useState(100)

    const [, setShowVolume] = useState(false);
    const volumeButtonRef = useRef(null);
    const volumeSliderRef = useRef(null);
    const [, setVolume] = useState(0)

    const isPitchDisabled = useSelector((state) => state.voice.filters.isPitchDisabled)
    const [pitch, setPitch] = useState('default')

    const isStyleDisabled = useSelector((state) => state.voice.filters.isStyleDisabled)
    const [style, setStyle] = useState(null);

    useEffect(() => {
        const listener = ('click', ev => {

            if (selectedAudioProfile) {
                setShowSpeed(false);
                return;
            }

            if ((speedSliderRef.current && speedSliderRef.current.contains(ev.target))) {

                setShowSpeed(true);
                setShowVolume(false);

            } else if ((speedButtonRef.current && speedButtonRef.current.contains(ev.target))) {

                setShowSpeed(prev => !prev);
                setShowVolume(false);

            } else {

                setShowSpeed(false);

            }

        });

        window.addEventListener('click', listener);

        return () => window.removeEventListener('click', listener);
    });

    useEffect(() => {
        const listener = ('click', ev => {

            if (selectedAudioProfile) {
                setShowVolume(false);
                return;
            }

            if ((volumeSliderRef.current && volumeSliderRef.current.contains(ev.target))) {

                setShowVolume(true);
                setShowSpeed(false);

            } else if ((volumeButtonRef.current && volumeButtonRef.current.contains(ev.target))) {

                setShowVolume(prev => !prev);
                setShowSpeed(false);

            } else {

                setShowVolume(false);

            }

        });

        window.addEventListener('click', listener);

        return () => window.removeEventListener('click', listener);
    });

    const setVoice = useCallback((selectedBlock) => {
        
        setShowProsodySettings(true);

        const { volume, speed, pitch, style } = selectedBlock.audio_profile;

        if (volume) {
            setVolume(volume)
        } else {
            setVolume(0)
        }

        if (speed) {
            setSpeed(speed)
        } else {
            setSpeed(100)
        }

        if (pitch) {
            setPitch(pitch)
        } else {
            setPitch('default')
        }

        if (style) {
            setStyle(style)
        } else {
            setStyle('default')
        }

    }, []);

    const setAudioProfile = useCallback((selectedBlock) => {

        setShowProsodySettings(false);

    }, [])

    useEffect(() => {

        if (!currentBlock) return;

        const selectedBlock = editor.children[currentBlock.blockIndex];

        if (!selectedBlock) return;

        if (selectedBlock.audio_profile.profile_name) {
            setAudioProfile(selectedBlock);
        } else {
            setVoice(selectedBlock);
        }

    }, [currentBlock, editor.children, setVoice, setAudioProfile])

    if (!selectedAudioProfile && !voice) {
        return <></>
    }

    return (
        <div style={{
            boxShadow: '0 1px 2px 0px #43494e61',
            fontSize: 14,
            background: '#E6EFFE',
            padding: '8px 0',
            position: 'sticky',
            top: 0,
            zIndex: 20,
            rowGap: 8
        }} className='row justify-content-start align-items-center px-4'>
            <div className='d-flex flex-row align-items-stretch' style={{ color: '#48525B' }}>
                {voice && (
                    <>
                        <span>Voice:</span>
                        <span style={{ fontWeight: 600, paddingLeft: 6, cursor: 'pointer' }} onClick={() => dispatch(toggleEditorVoicePickerModal(!showVoicePicker))}>
                            {
                                selectedAudioProfile !== null
                                    ? (
                                        <>
                                            {selectedAudioProfile.profile_name} {`(Profile)`}
                                        </>
                                    )
                                    : (
                                        <>
                                            {voice?.name}, {language?.name} {`(${voice?.gender.charAt(0).toUpperCase()})`}
                                        </>
                                    )
                            }
                            <MemoizeImage className="ml-2 action-icon" width={9} height={6} src={dropdownIcon} alt="dropdown" />
                        </span>
                        <Pipe />
                    </>
                )}
                <AddPause orgId={org_id} integrationId={id} contentId={contentId} onCustomPauseClick={() => setShowCustomPauseModal(true)} />
                <Pipe />
            </div>
            {
                showProsodySettings && (
                    <div className='d-flex justify-content-between'>
                        {voice && (
                            <>
                                <div className='style-picker'>
                                    <span>Style: </span>
                                    <StylePicker
                                        value={style}
                                        disabled={isStyleDisabled}
                                        onChange={(value) => {
                                            setBlockProsodySettings({
                                                style: value
                                            })
                                        }}
                                        styles={voice.styles}
                                    />
                                </div>
                                <Pipe />
                            </>
                        )}
                        <div>
                            <span>Pitch: </span>
                            <PitchPicker
                                value={pitch}
                                disabled={isPitchDisabled}
                                onChange={(value) => {
                                    setBlockProsodySettings({
                                        pitch: value
                                    })
                                }}
                            />
                        </div>
                        <Pipe />
                        {/* <div style={{ position: 'relative', width: 108 }}>
							<div ref={volumeButtonRef} style={{ cursor: 'pointer', }}>
								<span>Volume: </span>
								<span style={{ fontWeight: 600 }}>{volume}</span>
								<img className="ml-2 action-icon" width={9} height={6} src={dropdownIcon} alt="dropdown" />
							</div>
							{
								showVolume && (
									<div className='slider--audio-speed'>
										<input disabled={selectedAudioProfile} ref={volumeSliderRef} type="range" className="form-control-range" min="-6" max="6" step="0.5" defaultValue="0" name="volume" value={volume} onChange={(e) => {
											setBlockProsodySettings({
												volume: e.target.value
											})
										}} />
									</div>
								)
							}
						</div>
						<Pipe /> */}
                        <div style={{ position: 'relative', width: 106 }}>
                            <div ref={speedButtonRef} style={{ cursor: 'pointer', }}>
                                <span>Speed: </span>
                                <span style={{ fontWeight: 600 }}>{speed}%</span>
                                <MemoizeImage className="ml-2 action-icon" width={9} height={6} src={dropdownIcon} alt="dropdown" />
                            </div>
                            {
                                showSpeed && (
                                    <div className='slider--audio-speed'>
                                        <input disabled={selectedAudioProfile} ref={speedSliderRef} type="range" className="form-control-range" min="20" max="200" step="1" defaultValue="100" name="speed" value={speed} onChange={(e) => {
                                            setBlockProsodySettings({
                                                speed: e.target.value
                                            })
                                        }} />
                                    </div>
                                )
                            }
                        </div>
                        <Pipe />
                    </div>
                )
            }
        </div>
    );
}
