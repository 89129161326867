import { useEffect } from 'react';

const useBeforeUnload = ({ when, message }) => {
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault()
            event.returnValue = message
            return message
        }

        if (when) {
            window.addEventListener('beforeunload', handleBeforeUnload)
        }

        return () => window.removeEventListener('beforeunload', handleBeforeUnload)
    }, [when, message])
}

export default useBeforeUnload;
