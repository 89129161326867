import React, { useState } from 'react';
import ReactHowler from 'react-howler';
import ShowVoicesAccordionEditor from "./ShowVoicesAccordionEditor"

export default function ShowVoices({ onVoiceChange, selectedVoice, voices }) {

    const [audio, setAudio] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);

    return (
        <>
            <div style={{ paddingBottom: 50 }} className='row'>
                {/* <div className='col'> */}
                    <ShowVoicesAccordionEditor selectedVoice={selectedVoice} onVoiceChange={onVoiceChange} voices={voices} audioControls={{audio, setAudio, isPlaying, setIsPlaying}} />
                {/* </div>
                <div className='col'>
                    <ShowVoicesAccordionEditor selectedVoice={selectedVoice} onVoiceChange={onVoiceChange} voices={voices.slice(parseInt(Math.ceil(voices.length / 2)), voices.length)} audioControls={{audio, setAudio, isPlaying, setIsPlaying}} />
                </div> */}
            </div>
            {
                audio && (
                    <ReactHowler src={audio} playing={isPlaying} onEnd={() => {
                        setAudio(null);
                        setIsPlaying(false);
                    }} />
                )
            }
        </>
    );
};