import { API } from 'aws-amplify';
import { useMutation } from "@tanstack/react-query";
import clean from '../../utils/Clean';

interface IuseCreateProfileProps {
    onSuccess: () => void
}
interface ICreateProfileRequestProps {
    org_id: string
    id: string 
    profile_id: string
    profile: Object
}

export const useEditProfile = ({ onSuccess }: IuseCreateProfileProps) => {
    return useMutation(({ org_id, id, profile_id, profile }: ICreateProfileRequestProps) =>
        API.put(`main`, `/organisation/${org_id}/integration/${id}/audio_profile/${profile_id}`,{body: clean(profile), headers: {"Content-Type":"application/json"}}),
        {
            onSuccess: onSuccess
        }
    )
}


