import { useForm } from 'react-hook-form';
import { API } from "aws-amplify";
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import Spinner from '../components/Spinner';

import cameraIcon from '../assets/camera-thin.svg';

import PODCAST_CATEGORIES from '../constants/podcast_categories.json';
import clean from '../utils/Clean';
import { toast } from 'react-toastify';

function PodcastEpisodeSettings({ show, onHide, orgId, integrationId, podcastId, isNew, selectedEpisode, onSuccess = () => { } }) {

    const { register, reset, errors, handleSubmit } = useForm();
    const [episodeArtFile, setEpisodeArtFile] = useState(null);
    const [isSavingEpisodeSettings, setIsSavingEpisodeSettings] = useState(false);
    const history = useHistory();

    const onSaveEpisodeSettings = async (data) => {

        setIsSavingEpisodeSettings(true);

        if (episodeArtFile !== null) {
            const signedResponse = await API.get(`main`, `/episode/art/signed-url`, {
                queryStringParameters: {
                    file_name: episodeArtFile.name,
                    content_type: episodeArtFile.type
                }
            });

            const requestOptions = {
                method: 'PUT',
                body: episodeArtFile,
                redirect: 'follow'
            };

            const uploadURL = signedResponse.data.signed_url;
            const url = signedResponse.data.url;

            await fetch(uploadURL, requestOptions);

            data.episode_art = url;
        }

        if (isNew) {
            API.post('main', `/organisation/${orgId}/integration/${integrationId}/podcast/${podcastId}/content/${selectedEpisode.content_id}`, {
                body: clean(data),
                headers: { "Content-Type": "application/json" }
            }).then(() => {
                toast.success('Settings updated successfully!');
                onSuccess(data);
            }).finally(() => {
                setIsSavingEpisodeSettings(false);
                onHide();
                history.push(`/organisation/${orgId}/integration/${integrationId}/podcast/${podcastId}/episodes`);
                setEpisodeArtFile(null);
            })
        } else {
            API.put('main', `/organisation/${orgId}/integration/${integrationId}/podcast/${podcastId}/episode/${selectedEpisode.content_id}`, {
                body: clean(data),
                headers: { "Content-Type": "application/json" }
            }).then(() => {
                toast.success('Settings updated successfully!');
                onSuccess(data);
            }).finally(() => {
                setIsSavingEpisodeSettings(false);
                onHide();
                setEpisodeArtFile(null);
            })
        }

    }

    const PodcastPosterImagePicker = () => {
        return (
            <>
                <div className="poster-container episode-art-container d-flex align-items-center justify-content-center mx-auto mx-xl-0 position-relative mb-3">
                    <input type="file" id="poster" onChange={(event) => { setEpisodeArtFile(event.target.files[0]) }} />
                    <label htmlFor="poster" className="position-absolute">
                        {
                            (episodeArtFile !== null)
                                ? (
                                    <img className="episode-image" alt="poster" src={URL.createObjectURL(episodeArtFile)} />
                                )
                                : (selectedEpisode.episode_art)
                                    ? (
                                        <img className="episode-image" alt="poster" src={selectedEpisode.episode_art} />
                                    ) : (
                                        <img width={64} alt="poster" src={cameraIcon} />
                                    )
                        }
                    </label>
                </div>
            </>
        );
    };

    useEffect(() => {

        if (selectedEpisode) {
            reset({
                episode_title: selectedEpisode.title,
                episode_description: selectedEpisode.description,
                episode_author: selectedEpisode.author,
                episode_category: selectedEpisode.category,
                is_published: selectedEpisode.is_published
            });
        }

    }, [selectedEpisode, reset]);

    return (
        <Modal className="modal-playlist-code" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Episode Settings
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="container">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <PodcastPosterImagePicker />
                        <p>The image should be square and atleast 1400x1400 pixels in size. We support images in jpg, png, gif and tiff formats up to a maximum of 4MB in size.</p>
                    </div>
                    <form className="col-12 col-md-8 mb-4">
                        <div className="form-group">
                            <label>Episode Title</label>
                            <input type="text" name="episode_title" className={classNames("form-control", { "is-invalid": errors.title })} ref={register({ required: true, minLength: 3 })} />
                            {
                                errors.title &&
                                <div className="invalid-feedback">
                                    {
                                        ("required" === errors.title.type) && "Podcast title field is required."
                                    }
                                    {
                                        ("minLength" === errors.title.type) && "Podcast Title field should be at least 3 character long."
                                    }
                                </div>
                            }
                        </div>
                        <div className="form-group">
                            <label>Episode Description</label>
                            <textarea className={classNames("form-control", { "is-invalid": errors.body })} name="episode_description" rows="10" ref={register()} />
                            {
                                errors.body &&
                                <div className="invalid-feedback">
                                    {
                                        ("required" === errors.body.type) && "Text field is required."
                                    }
                                    {
                                        ("minLength" === errors.body.type) && "Text field should be at least 3 character long."
                                    }
                                </div>
                            }
                        </div>
                        <div className="form-group">
                            <label>Episode Host</label>
                            <input type="text" name="episode_author" className={classNames("form-control", { "is-invalid": errors.title })} ref={register({ required: true, minLength: 3 })} />
                            {
                                errors.title &&
                                <div className="invalid-feedback">
                                    {
                                        ("required" === errors.title.type) && "Podcast title field is required."
                                    }
                                    {
                                        ("minLength" === errors.title.type) && "Podcast Title field should be at least 3 character long."
                                    }
                                </div>
                            }
                        </div>
                        <div className="form-group">
                            <label>Category</label>
                            <select className={classNames("custom-select", { "is-invalid": errors.category })} name="episode_category" ref={register({ required: true })} >
                                {
                                    Object.keys(PODCAST_CATEGORIES).map((category, i) => {
                                        return (
                                            <option value={category}>{category}</option>
                                        )
                                    })
                                }
                            </select>
                            {
                                errors.category &&
                                <div className="invalid-feedback">
                                    {
                                        ("required" === errors.category.type) && "Category field is required."
                                    }
                                </div>
                            }
                        </div>
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="is_published" name="is_published" ref={register()} />
                            <label class="custom-control-label" for="is_published">Publish this Episode?</label>
                        </div>
                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-outline-primary mr-3" onClick={onHide}>Cancel</button>
                {
                    (isSavingEpisodeSettings) ?
                        (
                            <button type="button" className="btn btn-primary text-center">
                                <Spinner color="#FFF" />
                            </button>
                        )
                        :
                        (
                            <button type="button" className="btn btn-primary" onClick={handleSubmit(onSaveEpisodeSettings)}>Save</button>
                        )
                }
            </Modal.Footer>
        </Modal>
    );
}

export default PodcastEpisodeSettings;
