import Bugsnag from '@bugsnag/js';
import { API } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";

export const useVerifyCheckout = () => {

    const [verifyingCheckout, setVerifyingCheckout] = useState(false);

    const verifyCheckout = useCallback(async () => {

        const urlParts = window.location.pathname.split('/').splice(1);
        console.log({urlParts})
        
        const orgId = urlParts[1];
        const integrationId = urlParts[3];

        setVerifyingCheckout(true);
        API.post('main', `/organisation/${orgId}/validate_checkout`, {})
            .then((resp) => {
                console.log({resp})
                if (window.location.pathname === `/organisation/${orgId}/integration/${integrationId}/billing`) {
                    return;
                }
                if (integrationId) {
                    window.location.replace(`/organisation/${orgId}/integration/${integrationId}/billing`);
                    return;
                }
                window.location.replace(`/organisation/${orgId}/integration`);
            })
            .catch((error) => {
                console.log({error})
                Bugsnag.notify(error)
            })
            .finally(() => {
                setVerifyingCheckout(false);
            })
    }, []);

    useEffect(() => {
        console.log("USE VERIFY CHECK")
        const search = window.location.search;
        const isCheckoutSuccess = (search.includes('id=') && search.includes('state=succeeded'));

        console.log("checkout", isCheckoutSuccess);

        if (search.includes('id=') && isCheckoutSuccess) {
            verifyCheckout();
        }

    }, [verifyCheckout])

    return verifyingCheckout

}