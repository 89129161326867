import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {initializeAudioComposerRequested} from "../../store/actions"
import Section from "./Section"
import TimeTracker from "./TimeTracker"
import GenerateAudioModal from "./GenerateAudioModal"
import DeleteAudioBlockModal from "./DeleteAudioBlockModal"
import ComposerHeader from "./ComposerHeader"

function AudioComposer({ orgId, integrationId }) {
    const dispatch = useDispatch()
    const {sections, isInitialized, isComposerActive} = useSelector(state => state.audio_composer)
    const {playerSeek} = useSelector(state => state.content)

    const maxTimeOfTracker = useMemo(() => {
        let maxTime = 0
        if (!sections) return;

        for(let section of sections){
            for(let track of section.tracks){
                for(let block of track.blocks){
                    let newDuration = parseFloat(block.delay) + parseFloat(block.duration) - parseFloat(block.starts) - (parseFloat(block.trimRight) || 0)
                    maxTime += newDuration
                }
            }
        }

        const minDuration = 200
        return (maxTime > minDuration) ? maxTime : minDuration
    }, [sections])

    useEffect(() => {
        dispatch(initializeAudioComposerRequested())
    }, [])

    return useMemo(() => (
    <div className="audio-composer-container">
        {
            isInitialized ? (
                <>
                    <GenerateAudioModal orgId={orgId} integrationId={integrationId} />

                    <ComposerHeader orgId={orgId} integrationId={integrationId} />

                    {
                        isComposerActive && (
                            <>
                            <DeleteAudioBlockModal />
                            <div className="audio-composer">
                        
                                <TimeTracker maxTime={maxTimeOfTracker} currentSeek={playerSeek} />
                                {
                                    sections && sections.map((section, sectionIndex) => {
                                        return <Section key={`audio-composer-section-${sectionIndex}`} section={section} sectionIndex={sectionIndex} orgId={orgId} integrationId={integrationId} />
                                    })
                                }
                                
                            </div>
                            </>
                        )
                    }
                    
                </>
            ) : null
        }
    </div>
    ), [isInitialized, orgId, integrationId, isComposerActive, sections, playerSeek])
}

export default AudioComposer