import Bugsnag from "@bugsnag/js";
import { toast } from 'react-toastify';
import { call, put, takeEvery } from "redux-saga/effects";

import {
    FETCH_INTEGRATION_REQUESTED,
    CREATE_INTEGRATION_REQUESTED,
    FETCH_INTEGRATION_PROFILES_REQUESTED,
    DELETE_AUDIO_PROFILE_REQUESTED
} from "../actionTypes"

import {
    fetchIntegrationService,
    createIntegrationService,
    fetchIntegrationProfilesService,
    deleteAudioProfileService
} from "../../services/integration"

import {
    fetchIntegrationSuccess,
    fetchIntegrationFailure,
    createIntegrationSuccess,
    createIntegrationFailure,
    fetchIntegrationProfilesSuccess,
    fetchIntegrationProfilesFailure,
    deleteAudioProfileSuccess,
    deleteAudioProfileFailure,
    updateShowDeleteConfirmation,
    updateSideBarPageType
} from "../actions";

function* fetchIntegrationSaga(action) {
    try {
        const integration = yield call(fetchIntegrationService, action.payload);
        yield put(fetchIntegrationSuccess(integration))
    } catch (e) {
        Bugsnag.notify(e);
        yield put(fetchIntegrationFailure(e));
    }
}

function* createIntegrationSaga(action) {
    try {
        const integration = yield call(createIntegrationService, action.payload);
        if(integration.success){
            yield put(createIntegrationSuccess(integration.data))
            yield put(updateSideBarPageType("MENU"))
        }
    } catch (e) {
        Bugsnag.notify(e);
        yield put(createIntegrationFailure(e));
    }
}

function* fetchIntegrationProfilesSaga(action) {
    try {
        const profiles = yield call(fetchIntegrationProfilesService, action.payload);
        yield put(fetchIntegrationProfilesSuccess(profiles))
    } catch (e) {
        Bugsnag.notify(e);
        yield put(fetchIntegrationProfilesFailure(e));
    }
}

function* deleteAudioProfileSaga(action) {
    try {
        yield call(deleteAudioProfileService, action.payload);
        yield put(deleteAudioProfileSuccess(action.payload.id))
        yield put(updateShowDeleteConfirmation(false))
        toast.success("Audio Profile has been deleted.");
    } catch (e) {
        Bugsnag.notify(e);
        yield put(deleteAudioProfileFailure(e));
        toast.error("Something went wrong in deleting the audio profile.");
    }
}

function* integrationSaga() {
    yield takeEvery(FETCH_INTEGRATION_REQUESTED, fetchIntegrationSaga);
    yield takeEvery(CREATE_INTEGRATION_REQUESTED, createIntegrationSaga);
    yield takeEvery(FETCH_INTEGRATION_PROFILES_REQUESTED, fetchIntegrationProfilesSaga);
    yield takeEvery(DELETE_AUDIO_PROFILE_REQUESTED, deleteAudioProfileSaga);
}

export default integrationSaga;
