import { API } from "aws-amplify";
import { toast } from "react-toastify";
import _ from 'lodash';
import { fetchIntegrationsService } from "../services/integration"

export const goToUpgradePage = async function(history, plan_id=null){

    const getOrganisations = () => {

        return API.get(`main`, `/organisation`);

    }
    const orgs = (await getOrganisations()).data

    if(!orgs.length) return toast.error("You dont have any organisations. Please contact support")
    
    const org_id = orgs[0].PK.split("#")[1]

    if(plan_id){
        history.push(`/organisation/${org_id}/upgrade?plan_id=${plan_id}`);
    }else{
        history.push(`/organisation/${org_id}/upgrade`);
    }

    
}

export const goToCurrentIntegration = async function(history){

    const getOrganisations = () => {

        return API.get(`main`, `/organisation`);

    }
    const orgs = (await getOrganisations()).data

    if(!orgs.length) return toast.error("You dont have any organisations. Please contact support")
    
    const org_id = orgs[0].PK.split("#")[1]
    const ints = await fetchIntegrationsService(org_id)

    let currentIntegration = localStorage.getItem("currentIntegration");

    if (currentIntegration && (ints.find(i => i.SK.split("#")[1] === currentIntegration))) {
        history.push(`/organisation/${org_id}/integration/${currentIntegration}/content`);
        return;
    }

    if (!ints || ints.length <= 0) {
        history.push(`/organisation/${org_id}/integration`);
        return;
    }

    const integrationsSorted = _.sortBy(ints, [(integ) => new Date(integ.created_at)])
    currentIntegration = integrationsSorted[integrationsSorted.length - 1].SK.split("#")[1]
    localStorage.setItem("currentIntegration", currentIntegration)
    history.push(`/organisation/${org_id}/integration/${currentIntegration}/content`);
}

export const returnCurrentIntegrationOfOrg = async function(org_id){
    
    const ints = await fetchIntegrationsService(org_id)

    let currentIntegration = localStorage.getItem("currentIntegration");

    if (currentIntegration && (ints.find(i => i.SK.split("#")[1] === currentIntegration))) {
        return currentIntegration;
    }

    if (!ints || ints.length <= 0) {
        return null;
    }

    const integrationsSorted = _.sortBy(ints, [(integ) => new Date(integ.created_at)])
    currentIntegration = integrationsSorted[integrationsSorted.length - 1].SK.split("#")[1]
    localStorage.setItem("currentIntegration", currentIntegration)

    return currentIntegration
}

