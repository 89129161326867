import {
    FETCH_CONTENT_REQUESTED,
    FETCH_CONTENT_SUCCESS,
    FETCH_CONTENT_FAILURE,
    SET_CONTENT,
    UPDATE_PUBLISHED_AT,
    SET_CONTENT_ID,
    PLAY_TTS_BLOCK_PREVIEW,
    STOP_TTS_BLOCK_PREVIEW,
    TOGGLE_PRONUNCIATION_LIBRARY_MODAL,
    TOGGLE_DOWNLOAD_CONTENT_MODAL,
    TOGGLE_EDITOR_VOICE_PICKER_MODAL,
    TOGGLE_EDITOR_ADD_FROM_URL_MODAL,
    FETCH_CONTENT_BLOCKS_REQUESTED
} from '../actionTypes';

export const toggleDownloadContentModal = (data) => {
    return {
        type : TOGGLE_DOWNLOAD_CONTENT_MODAL,
        payload : data
    }
}

export const toggleEditorVoicePickerModal = (data) => {
    return {
        type : TOGGLE_EDITOR_VOICE_PICKER_MODAL,
        payload : data
    }
}

export const togglePronunciationLibraryModal = (data) => {
    return {
        type : TOGGLE_PRONUNCIATION_LIBRARY_MODAL,
        payload : data
    }
}

export const fetchContentRequested = (org_id, id, content_id, shouldNotLoad) => {
    return {
        type: FETCH_CONTENT_REQUESTED,
        payload: {org_id, id, content_id, shouldNotLoad},
    };
};

export const fetchContentBlocksRequested = (org_id, id, content_id) => {
    return {
        type: FETCH_CONTENT_BLOCKS_REQUESTED,
        payload: {org_id, id, content_id},
    }
}

export const fetchContentSuccess = (data) => {
    return {
        type: FETCH_CONTENT_SUCCESS,
        payload: data,
    };
};

export const fetchContentFailure = (data) => {
    return {
        type: FETCH_CONTENT_FAILURE,
        payload: data,
    };
};

export const setContent = (data) => {
    return {
        type: SET_CONTENT,
        payload: data,
    };
};

export const updatePublishedAt = (data) => {
    return {
        type: UPDATE_PUBLISHED_AT,
        payload: data
    }
}

export const setContentId = (data) => {
    return {
        type: SET_CONTENT_ID,
        payload: data,
    }
}

export const playTTSBlockPreview = (data) => {
    return {
        type: PLAY_TTS_BLOCK_PREVIEW,
        payload: data,
    }
}

export const stopTTSBlockPreview = (data) => {
    return {
        type: STOP_TTS_BLOCK_PREVIEW,
        payload: data,
    }
}

export const toggleEditorAddFromURLModal = (data) => {
    return {
        type : TOGGLE_EDITOR_ADD_FROM_URL_MODAL,
        payload : data
    }
}