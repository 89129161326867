import React from 'react';

export const IconButton = ({ onClick, src, alt, style = {}, width = 14, height = 14 } : IIconButtonProps) => {
    return (
        <button onClick={onClick} style={{ padding: 0, border: 'none', background: 'none', boxShadow: 'none', ...style}}>
            <img width={width} height={height} src={src} alt={alt} />
        </button>
    )
}

interface IIconButtonProps {
    onClick?: () => void
    src: string
    alt: string
    style?: React.CSSProperties
    width?: number
    height?: number
}
