import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from '@redux-devtools/extension';
import rootReducer from "./reducers";
import rootSaga from './saga';
import mixpanelMiddleware from './middlewares/mixpanel'

const sagaMiddleware = createSagaMiddleware();
const store = compose(
  composeWithDevTools(applyMiddleware(mixpanelMiddleware, sagaMiddleware)),
)(createStore)(rootReducer)

sagaMiddleware.run(rootSaga);
export default store;