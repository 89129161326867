import { API } from 'aws-amplify';
import { useMutation } from "@tanstack/react-query";
import clean from '../../utils/Clean';
interface IuseSetDefaultProfileProps {
    onSuccess: () => void
}
interface ISetDefaultProfileRequestProps {
    org_id: string
    id: string 
    profile: Object
}

export const useSetDefaultProfile = ({ onSuccess }: IuseSetDefaultProfileProps) => {
    return useMutation(({ org_id, id, profile }: ISetDefaultProfileRequestProps) =>
        API.put(`main`, `/organisation/${org_id}/integration/${id}/audio_profile`,{body: clean(profile), headers: {"Content-Type":"application/json"}}),
        {
            onSuccess: onSuccess
        }
    )
}


