import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import integrationIcon from '../assets/integration.svg';
import rightArrowIcon from '../assets/arrow-right.svg';
import { useParams } from "react-router-dom";
import { API } from 'aws-amplify';
import _ from 'lodash';
import {Auth} from 'aws-amplify';
import Bugsnag from "@bugsnag/js";

function Integration(){

    const history = useHistory();
    const [org, setOrg] = useState(null);
    const [integrations, setIntegrations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [ userAttr, setUserAttr ] = useState(null);
    const { org_id } = useParams();

    const getOrganisations = (org_id) => {

        return API.get(`main`, `/organisation/${org_id}`);

    }

    const getIntegrations = () => {

        return API.get(`main`, `/organisation/${org_id}/integration`);

    }

    const Shimmer = () => {

        return(
            <React.Fragment>
                <div className="row my-5">
                    <div className="col-12">
                        <h3>Welcome {(userAttr && userAttr.hasOwnProperty("given_name")?(userAttr.given_name):(" to Listen2It"))}!</h3>
                        <p>&nbsp;</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="d-md-flex flex-wrap">
                            {
                                _.range(4).map(()=>{
                                    return(
                                        <div className="card--integration-shimmer mr-md-3 mb-3 shine" />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )

    }

    const Integrations = () => {

        return(
            <React.Fragment>
                <div className="row my-5">
                    <div className="col-12">
                        <h3>Welcome {(userAttr && userAttr.hasOwnProperty("name")?(userAttr.name):(" to Listen2It"))}!</h3>
                        <p>Please choose a project below.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="d-md-flex flex-wrap">
                            {
                                integrations.map((integration)=>{

                                    return (
                                        <div className="card card--integration mr-md-3 mb-3">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <img src={integrationIcon} alt="integration icon" className="mr-2"/>
                                                    <span className="integration-name">{integration.integration_name}</span>
                                                </div>
                                                <p className="mt-3 mb-0 text-muted integration-description">{integration.description}</p>
                                            </div>
                                            <div className="card-footer">
                                                <span className="btn--open" onClick={()=>{history.push(`/organisation/${org_id}/integration/${integration.SK.split("#")[1]}/content`)}}>Open <img src={rightArrowIcon} alt="right arrow" /></span>
                                            </div>
                                        </div>
                                    );

                                })
                            }
                            <div className="card card--new-integration mb-3" onClick={() => history.push(`/organisation/${org_id}/project/create`)}>
                                <div className="card-body text-center">
                                    <svg className="w-6 h-6" fill="none" stroke="#3D4852" style={{marginTop:"30px"}} height="100px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <p className="font-weight-bold">New Project</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )

    }

    const EmptyState = () => {

        return(
            <div className="row">
                <div className="col-md-6 py-5">
                    <h3 className="mb-2">Welcome {(userAttr && userAttr.hasOwnProperty("name")?(userAttr.name):(" to Listen2It"))}!</h3>
                    <p className="mt-5">Get started by creating your first project to sync your website or app content with Listen2It. You can create multiple projects for different Websites and APPs that you manage.</p>
                    <p>For more information, we recommend going through our Getting Started Guide.</p>
                    <button type="button" className="btn btn-primary" onClick={()=>{history.push(`/organisation/${org_id}/project/create`)}}>Create a project</button>
                </div>
            </div>
        )
    }

    const ChoosePlan = () => {

        return (
            <div className="row">
                <div className="col-md-6 py-5">
                    <h3 className="mb-2">Welcome {(userAttr && userAttr.hasOwnProperty("name")?(userAttr.name):(" to Listen2It"))}!</h3>
                    <p className="mt-5">Seems like you have not subscribed to any plan.</p>
                    <button type="button" className="btn btn-primary" onClick={()=>{history.push(`/organisation/${org_id}/plans`)}}>Choose Plan</button>
                </div>
            </div>
        )

    }

    useEffect(()=>{

        Auth.currentAuthenticatedUser({
            bypassCache: true
        }).then(user => { setUserAttr(user.attributes); })
            .catch(err => Bugsnag.notify(err));

        getOrganisations(org_id).then((response)=>{

            setOrg(response.data);

        }).then(getIntegrations).then((response)=>{

            setIntegrations(_.sortBy(response.data, [(integration) => new Date(integration.created_at)]));

        }).finally(()=>{

            setIsLoading(false);

        });


    },[]);

    return(
        <React.Fragment>
            <div className="container-fluid">
                <div className="container">
                    {
                        (isLoading || !org)?
                        (
                            <Shimmer />
                        )
                        :
                        (
                            (org.plan_id)?
                                (
                                    (integrations.length > 0)
                                        ?
                                        (
                                            <Integrations />
                                        )
                                        :
                                        (
                                            <EmptyState />
                                        )
                                )
                                :
                                (
                                    <ChoosePlan />
                                )
                        )
                    }
                </div>
            </div>
        </React.Fragment>
    );

}


export default Integration;
