import { API } from "aws-amplify";
import classNames from "classnames";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import PodcastEpisodeSettings from "../components/PodcastEpisodeSettings";

import Spinner from '../components/Spinner';
import encodeQueryData from '../utils/Query';

import leftArrowIcon from '../assets/arrow-left.svg';
import formatAudioFileDuration from "../utils/AudioDuration";

function AddContentToPodcast() {

    const { org_id, id, podcast_id } = useParams();
    const history = useHistory();

    const [content, setContent] = useState([]);
    const [podcast, setPodcast] = useState(null);
    const [nextPageToken, setNextPageToken] = useState(null);
    const [isFetchingMore, setIsFetchingMore] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [selectedEpisode, setSelectedEpisode] = useState(null)

    const getContents = () => {
        let url = `/organisation/${org_id}/integration/${id}/podcast/${podcast_id}/episodes-add`;

        let params = {};

        if (nextPageToken) {

            params["next_page_token"] = nextPageToken;

        }

        if (Object.keys(params).length > 0) {

            url = url + "?" + encodeQueryData(params);

        }

        return API.get('main', url);
    }

    const getNextPage = (nextPageToken = null) => {

        setIsFetchingMore(true)

        getContents(nextPageToken).then((response) => {
            setContent([...content, ...response.data]);
            if (response.hasOwnProperty("next_page_token")) {
                setNextPageToken(response.next_page_token)
            }
        }).finally(() => {
            setIsFetchingMore(false)
        })

    }

    const getPodcast = () => {
        return API.get('main', `/organisation/${org_id}/integration/${id}/podcast/${podcast_id}`)
    };

    const addItemToPodcast = (podcastItem) => {
        setSelectedEpisode(podcastItem);
        setShow(true);
    }

    useEffect(() => {

        setIsLoading(true);

        getPodcast()
            .then(response => {

                setPodcast(response.data)

                getContents().then((response) => {
                    setContent(response.data)
                    if (response.hasOwnProperty("next_page_token")) {
                        setNextPageToken(response.next_page_token)
                    }
                }).catch((e) => {
                    toast.error('Something went wrong')
                }).finally(() => {
                    setIsLoading(false)
                });

            })
            .catch((e) => {
                toast.error('Something went wrong')
            })

    }, []);

    const Shimmer = () => {

        return (
            <div className="list-group list-group-flush list-group--content">
                {
                    _.range(4).map((index) => {

                        return (
                            <li className="list-group-item list-group-item-action" key={`content-${index}`}>
                                <div className="content-title-shimmer shine" />
                                <div className="content-meta-shimmer shine" />
                            </li>
                        )

                    })
                }
            </div>
        )
    }

    const Content = () => {

        return (
            <React.Fragment>
                <div className="list-group list-group-flush list-group--content">
                    {
                        content.map((item, index) => {
                            return (
                                <li onClick={() => {
                                    !item.is_present && addItemToPodcast(item)
                                }} style={
                                    item.is_present ? {
                                        cursor: 'unset',
                                        opacity: 0.5
                                    } : {
                                        cursor: 'pointer'
                                    }} className="list-group-item list-group-item-action" key={`content-${index}`}>
                                    <h4>{item.title}</h4>
                                    {
                                        (null !== item.published_at)
                                        && (
                                            <span className="publish_date">Published on {moment(item.published_at).format("MMM D, YYYY")}</span>
                                        )
                                    }
                                    {
                                        item.audio_duration && (
                                            <>
                                                <span className="dot-separator" />
                                                <span className="audio-duration">{formatAudioFileDuration(item.audio_duration)}</span>
                                            </>
                                        )
                                    }
                                </li>
                            )
                        })
                    }
                </div>
                {
                    (isFetchingMore) ?
                        (
                            <button className={classNames("btn--fetch-more", "mt-3")}>
                                <Spinner color="#657B90" />
                            </button>
                        )
                        :
                        (
                            <button className={classNames("btn--fetch-more", "mt-3", { "d-none": null == nextPageToken })} onClick={() => { getNextPage() }}>Fetch More</button>
                        )
                }

            </React.Fragment>
        )

    }

    return <>
        {
            isLoading
                ?
                (
                    <Shimmer />
                )
                :
                (
                    <>
                        {
                            selectedEpisode && (
                                <PodcastEpisodeSettings
                                    show={show}
                                    onHide={() => setShow(false)}
                                    orgId={org_id}
                                    integrationId={id}
                                    podcastId={podcast_id}
                                    isNew={true}
                                    selectedEpisode={{
                                        title: selectedEpisode.title,
                                        content_id: selectedEpisode.SK.split('#')[1],
                                        description: "",
                                        author: podcast.author,
                                        category: podcast.category,
                                        is_published: true,
                                    }}
                                />
                            )
                        }
                        <div className="d-flex" style={{ gap: 4, color: 'var(--blue)', cursor: 'pointer' }} onClick={() => history.push(`/organisation/${org_id}/integration/${id}/podcast/${podcast_id}/episodes`)}>
                            <img src={leftArrowIcon} width={18} />
                            Back to podcast episodes
                        </div>
                        <h3 className="page-title">Add episode to {podcast.title}</h3>
                        <p className="page-subtitle">Select an audio from below to add as episode. If the audio does not exist, add it from the Audios section.</p>
                        <Content />
                    </>
                )
        }
    </>
}

export default AddContentToPodcast;
