export const FETCH_LANGUAGES_REQUESTED = "FETCH_LANGUAGES_REQUESTED"

export const FETCH_LANGUAGES_SUCCESS = "FETCH_LANGUAGES_SUCCESS"

export const FETCH_LANGUAGES_FAILURE = "FETCH_LANGUAGES_FAILURE"

export const UPDATE_VOICE_SETTINGS_FILTER = "UPDATE_VOICE_SETTINGS_FILTER"

export const UPDATE_VOICES = "UPDATE_VOICES"

export const UPDATE_VOICE = "UPDATE_VOICE"

export const UPDATE_SHOW_DELETE_CONFIRMATION_MODAL = "UPDATE_SHOW_DELETE_CONFIRMATION_MODAL"

export const UPDATE_SHOW_UPDATE_CONFIRMATION_MODAL = "UPDATE_SHOW_UPDATE_CONFIRMATION_MODAL"

export const SET_SHOW_SAVE_AUDIO_PROFILE_MODAL = "SET_SHOW_SAVE_AUDIO_PROFILE_MODAL"

export const UPDATE_AUDIO_PROFILE_REQUESTED = "UPDATE_AUDIO_PROFILE_REQUESTED"

export const UPDATE_AUDIO_PROFILE_SUCCESS = "UPDATE_AUDIO_PROFILE_SUCCESS"

export const UPDATE_AUDIO_PROFILE_FAILURE = "UPDATE_AUDIO_PROFILE_FAILURE"

export const UPDATE_SHOW_EDITOR_VOICE_PROFILES = "UPDATE_SHOW_EDITOR_VOICE_PROFILES"

export const UPDATE_VOICE_CONFIGURATION = "UPDATE_VOICE_CONFIGURATION"

export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE"

export const UPDATE_SELECTED_AUDIO_PROFILE = "UPDATE_SELECTED_AUDIO_PROFILE"

export const SET_IS_DEFAULT_PROFILE = "SET_IS_DEFAULT_PROFILE"