import React from 'react';
import { Badge, Modal } from 'react-bootstrap';
import { useSavedProfilesStore } from '../../zustand-store/useSavedProfilesStore';

import VoiceModalCloseIcon from '../../assets/voice/editor-modal-close.svg'
import LeftPane from './LeftPane';
import { Voices } from './Voices';

export const ChangeVoiceModal = () => {

    const showChangeVoice = useSavedProfilesStore((state) => state.showChangeVoice);
    const toggleChangeVoice = useSavedProfilesStore((state) => state.toggleChangeVoice);

    return (
        <Modal
			className="modal-voice-picker"
			show={showChangeVoice}
			onHide={toggleChangeVoice}
		>
			<Modal.Header className='d-flex justify-content-between align-items-center' style={{ borderBottom: '1px solid #00000029', marginBottom: 16 }}>
				<div>
					<p className="mb-0" style={{ fontSize: 18, fontWeight: "bold", color: "#333B6C" }}>Select a voice</p>
					<p style={{ fontSize: 13 }} className="mb-0">Choose from 900+ realistic voice in 140+ languages and dialects</p>
				</div>
				<div style={{ cursor: "pointer" }} onClick={toggleChangeVoice}>
					<img alt="close" height="20" width="20" src={VoiceModalCloseIcon} />
					<span style={{ marginLeft: 3, fontSize: 16, fontWeight: 500, color: "#999DB5" }}>Close</span>
				</div>
			</Modal.Header>
			<Modal.Body className='voice-picker-body row'>
                <div className='col-md-3'>
                    <LeftPane />
                </div>
                <div className='col'>
                    <Voices isPicker={true} />
                </div>
			</Modal.Body>
            <Modal.Footer>
                <div className="my-3 d-flex flex-row justify-content-between">
                    <div className="d-flex flex-wrap align-items-center">
                        <div className='mr-3'>
                            <Badge variant="warning">Premium</Badge> <span style={{ fontSize: 12 }}>Highly realistic AI Voices</span>
                        </div>
                        <div>
                            <Badge variant="primary">Styles</Badge> <span style={{ fontSize: 12 }}>Voices with emotion based speaking styles</span>
                        </div>
                    </div>
                    <div className='d-flex justify-content-end align-items-center'>
                        <button style={{ height: 36, whiteSpace: 'nowrap' }} onClick={(e) => {

                            e.preventDefault();
                            
                        }} className='btn btn-primary'>
                            Choose Voice
                        </button>
                    </div>
                </div>
            </Modal.Footer>
		</Modal>
    )
}
